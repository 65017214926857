import React, { useEffect } from 'react';

export interface SignatureProps {
  name: string;
  x: number;
  y: number;
  height: number;
  font?: string;
  defaultImg: string;
  className: string;
  setSignature: (x: string) => void;
}

const TextSignature: React.FunctionComponent<SignatureProps> = (props: SignatureProps) => {
  const { name, x, y, height = 68, font = "30px 'ALLURA'", setSignature } = props;
  const generateBase64Img = (img: string) => {
    setSignature(img);
  };
  useEffect(() => {
    const canvasComponent = document.getElementById('canvasComponent') as HTMLCanvasElement;
    const indent = 15;

    if (canvasComponent) {
      const canvasTxt = canvasComponent.getContext('2d') as CanvasRenderingContext2D;
      canvasComponent.style.width = canvasTxt.measureText(name).width as any;
      canvasTxt.canvas.width = indent + canvasTxt.measureText(name).width + indent;
      canvasTxt.canvas.height = height;
      canvasTxt.font = font;
      canvasTxt.fillText(' ' + name, x, y);
      generateBase64Img(canvasTxt.canvas.toDataURL());
    }
  });

  return (
    <div className='signature-block'>
      <p>------ Medmo generated e-signature ----</p>
      <canvas id='canvasComponent' />
    </div>
  );
};

export default TextSignature;
