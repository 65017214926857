import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import {
  CheckoutSettings,
  CustomerInfoResponse,
  PortalSettings,
  PlanItemPriceResponse,
} from '../../views/portal-settings/tabs/billing/interfaces';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export function getPortalSettings(customerId: string, signal?: AbortSignal): Promise<{ portal_session: PortalSettings }> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/chargebee/host/portal/${customerId}`);
  return request(options);
}

export function getHostSettings(customerId: string, signal?: AbortSignal): Promise<{ hosted_page: CheckoutSettings }> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/chargebee/host/checkout-new-for-items/${customerId}`);
  return request(options);
}

export function checkCustomerExist(signal?: AbortSignal): Promise<CustomerInfoResponse> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/chargebee/po-customer`);
  return request(options);
}

export function createSubscription(): Promise<{ status: number; message: string }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/chargebee/po-subscription`,
  });
}

export function sendMessage(message: string): Promise<{ status: number; message: string }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/chargebee/send-feedback`,
    body: JSON.stringify({ message }),
  });
}

export function getItemPriceList(signal?: AbortSignal): Promise<PlanItemPriceResponse> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/chargebee/item-price-list`);
  return request(options);
}
