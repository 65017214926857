import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';

export function saveTaskNote(id: number, data: { MFPTaskNotes: string | null }): Promise<{ ID: number; MFPTaskNotes: string | null }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/tasks/${id}/notes`,
    body: JSON.stringify(data),
  });
}
