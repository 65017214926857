import React, { useRef, useState } from 'react';
import { FileView } from './interfaces';

const ImageViewer: React.FC<{ file: FileView }> = ({ file }) => {
  const imgRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = () => {
    setIsLoading(false);
  };

  return (
    <img
      ref={imgRef}
      onLoad={onLoad}
      src={file.url || `data:${file.mimeType};base64,${file.data}`}
      style={{
        opacity: isLoading ? 0 : 1,
      }}
    />
  );
};

export default ImageViewer;
