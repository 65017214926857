import React, { useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'rsuite';
import { saveAs } from 'file-saver';
import { Confirmation, FileTypeWithTitle } from '../interfaces';
import { requestScan } from '../../../api/request-scan/RequestScanAPI';
import { FileView } from '../../../components/file-viewer/interfaces';
import FileViewer from '../../../components/doc-viewer-modal';

const ScanConfirmation: React.FC<Confirmation> = (props: Confirmation) => {
  const { onClose, getRequestId, isShow, data, steps, uploadFiles, createNewInsurance } = props;
  const [isValid] = useState<boolean>(true);
  const [step2, setStep2] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [file, setFile] = useState<FileView>({ url: '', name: '', mimeType: '' });

  const {
    FirstName,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    LastName,
    scanType,
    CPTCode,
    physiciansOffice,
    fileListNotes,
    prescription,
    SelfPay,
    Language,
    NotesOptions,
    ...restData
  } = data;

  const {
    ScanRequestFileNames,
    MRN,
    Email,
    Phone,
    Gender,
    ICD,
    PrescriberName,
    Fax,
    DOB,
    Reason,
    ClinicalNotes,
    OtherNotes,
    InsurancePlanID,
    insuranceProvider,
  } = restData;

  const modalClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const sendData = async (flag?: boolean) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const [notes, prscr, insuranceId] = await Promise.all([
      uploadFiles(fileListNotes, 'notes'),
      !ScanRequestFileNames ? uploadFiles(prescription, 'prescription') : ScanRequestFileNames,
      !InsurancePlanID && insuranceProvider ? createNewInsurance(insuranceProvider) : InsurancePlanID,
    ]);

    requestScan({
      ...restData,
      ClinicalNotesFile: NotesOptions,
      SendEmail: Email,
      ScanRequestFileNames: prscr
        ? prscr.map(el => ({
            key: el.key,
            originalName: el.originalName,
            fileTitle: el.fileTitle,
          }))
        : [],
      ScanRequestNoteNames: (notes as FileTypeWithTitle[])
        ? (notes as FileTypeWithTitle[]).map(el => ({
            key: el.key,
            originalName: el.originalName,
            fileTitle: el.fileTitle,
          }))
        : [],
      SelfPay: flag ? null : SelfPay,
      isNote: true,
      InsurancePlanID: insuranceId || null,
    })
      .then(res => {
        getRequestId(res.TestRequestID ? res.TestRequestID : null);
      })
      .catch(e => {
        getRequestId(null, { title: 'Error', content: e.message || '' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const prevStep = () => {
    setStep2(false);
  };

  const nextStep = () => {
    isValid ? setStep2(true) : setStep2(false);
  };

  const downloadNoteFile = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id?: number): void => {
    e && e.preventDefault();

    if (fileListNotes.length && id !== undefined) {
      setFile({
        url: fileListNotes[id].url as string,
        name: fileListNotes[id].name as string,
        mimeType: fileListNotes[id].blobFile?.type,
        blobFile: fileListNotes[id].blobFile,
      });
      setIsShowModal(true);
    }
  };

  const downloadEpxFile = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id?: number): void => {
    e.preventDefault();

    if (ScanRequestFileNames && id !== undefined && ScanRequestFileNames[id]) {
      setFile({
        url: ScanRequestFileNames[id].url as string,
        name: ScanRequestFileNames[id].name,
        mimeType: 'application/pdf',
      });
      setIsShowModal(true);

      return;
    }

    if (prescription.length) {
      setFile({
        url: prescription[0].url as string,
        name: prescription[0].name as string,
        mimeType: prescription[0].blobFile?.type,
        blobFile: prescription[0].blobFile,
      });
      setIsShowModal(true);
    }
  };

  const onCloseFileViewer = (): void => {
    setIsShowModal(false);
  };

  const onDownload = (): void => {
    if (ScanRequestFileNames) {
      window.open(file.url, '_blank');
    }

    saveAs(file.blobFile as Blob);
  };

  return (
    <Modal className='scan-popup-confirmation' size='lg' backdrop={true} open={isShow} onClose={modalClose}>
      {props.steps ? (
        <Modal.Header>
          <h2>Review Form Send</h2>
          <ul>
            <li className={`${step2 ? 'confirm' : ''}`}>1. Order Information</li>
            <li className={`${!step2 ? 'inactive' : ''}`}>2. Contact Information</li>
          </ul>
          <Button disabled={isLoading} onClick={modalClose} classPrefix='btn-close'>
            <span className='rs-icon rs-icon-close'></span>
          </Button>
        </Modal.Header>
      ) : (
        <Modal.Header>
          <Modal.Title className='scan-popup-confirmation-title'>Review Form Send</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className={`scan-popup-confirmation-step-1 ${steps && step2 ? 'inactive' : ''}`}>
          <div className='scan-popup-confirmation-content'>
            <h2>Patient Information</h2>
            <ul>
              <li>
                <b>Patient name:</b>
                {FirstName} {LastName}
              </li>
              <li>
                <b>Patient Sex:</b>
                {Gender}
              </li>
              <li>
                <b>Patient DOB:</b>
                {DOB}
              </li>
              <li>
                <b>MRN ID:</b>
                {MRN ? MRN : '-'}
              </li>
              <li>
                <b>Email address:</b>
                {Email || 'No Email Provided'}
              </li>
              <li>
                <b>Phone number:</b>
                {Phone}
              </li>
              <li>
                <b>Preferred language:</b>
                {Language}
              </li>
            </ul>
          </div>
          <div className='scan-popup-confirmation-content'>
            <h2>Scan Information</h2>
            <ul>
              <li>
                <b>Scan type:</b>
                {scanType}
              </li>
              <li>
                <b>CPT code:</b>
                {CPTCode}
              </li>
              <li>
                <b>ICD-10:</b>
                {ICD}
              </li>
              <li>
                <b>Ordering physician:</b>
                {PrescriberName}
              </li>
              <li>
                <b>Location of ordering physician’s office:</b>
                {physiciansOffice}
              </li>
              <li>
                <b>Fax number: </b>
                {Fax}
              </li>
            </ul>
            {Reason && (
              <>
                <span className='scan-popup-confirmation-label'>
                  <b>Reason for exam:</b>
                </span>
                <div className='scan-popup-confirmation-area'>
                  <p>{Reason}</p>
                </div>
              </>
            )}
          </div>
          <div className='scan-popup-confirmation-content'>
            <h2>Notes</h2>
            {ClinicalNotes && (
              <>
                <span className='scan-popup-confirmation-label'>
                  <b>Clinical notes:</b>
                </span>
                <div className='scan-popup-confirmation-area'>
                  <p>{ClinicalNotes}</p>
                </div>
              </>
            )}
            {fileListNotes && !!fileListNotes.length && (
              <>
                <ol className='scan-popup-confirmation-uploader'>
                  {fileListNotes.map((el, i) => {
                    return (
                      <li key={el.fileTitle + i} className='scan-popup-confirmation-uploader-content'>
                        <a href='#' onClick={e => downloadNoteFile(e, i)}>
                          {el.fileTitle}
                        </a>
                      </li>
                    );
                  })}
                </ol>
              </>
            )}
            {OtherNotes && (
              <span className='scan-popup-confirmation-label'>
                <b>Other Clinical notes:</b>
              </span>
            )}
            <div className='scan-popup-confirmation-holder'>
              {OtherNotes && (
                <>
                  <div className='scan-popup-confirmation-area'>
                    <p>{OtherNotes}</p>
                  </div>
                </>
              )}
            </div>
          </div>
          {(prescription.length || ScanRequestFileNames) && (
            <div className='scan-popup-confirmation-content'>
              <h2>Prescription</h2>
              <div className='scan-popup-confirmation-prescription'>
                {prescription.length ? (
                  <a href='#' onClick={downloadEpxFile}>
                    {prescription[0].fileTitle}
                  </a>
                ) : (
                  <a href='#' onClick={downloadEpxFile}>
                    {ScanRequestFileNames?.[0].fileTitle}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
        {steps && (
          <div className={`scan-popup-confirmation-step-2 ${!step2 ? 'inactive' : ''}`}>
            <div className='scan-popup-confirmation-content'>
              <h2>Patient Information</h2>
              <div className='scan-popup-confirmation-text'>
                <p>We’ll use the following information to contact the patient. </p>
                <p>Note that this request has patient sensitive information and should only be sent to the patient. </p>
              </div>
              <ul>
                <li>
                  <b>Email address:</b>
                  {Email || 'None'}
                </li>
                <li>
                  <b>Phone number:</b>
                  {Phone}
                </li>
              </ul>
            </div>
          </div>
        )}
        {props.steps ? (
          <ButtonToolbar>
            <Button disabled={isLoading} onClick={modalClose} classPrefix='btn-model btn-close btn-lg'>
              Cancel
            </Button>
            <div style={{ display: 'none' }} className='form-error-text'>
              {'Please complete all required fields to continue'}
            </div>
            <div className='scan-popup-confirmation-btns'>
              <Button onClick={prevStep} classPrefix={`btn-model btn-white btn-lg ${!step2 && 'btn-inactive'}`}>
                Previous screen
              </Button>
              {!step2 ? (
                <Button onClick={nextStep} classPrefix='btn-model btn-lg'>
                  Next
                </Button>
              ) : (
                <Button onClick={() => sendData(true)} disabled={!Email && !Phone} classPrefix='btn-model btn-lg'>
                  Send to Patient
                </Button>
              )}
            </div>
          </ButtonToolbar>
        ) : (
          <ButtonToolbar>
            <Button disabled={isLoading} onClick={modalClose} classPrefix='btn-model btn-close btn-lg'>
              Cancel
            </Button>
            <div style={{ display: 'none' }} className='form-error-text'>
              {'Please complete all required fields to continue'}
            </div>
            <Button onClick={() => sendData()} disabled={isLoading} classPrefix='btn-model'>
              CONFIRM AND SUBMIT SCAN REQUEST
            </Button>
          </ButtonToolbar>
        )}
      </Modal.Body>
      <FileViewer isShow={isShowModal} onDownloadFile={onDownload} file={file} onClose={onCloseFileViewer} />
    </Modal>
  );
};

export default ScanConfirmation;
