import { isAfter, isBefore } from 'date-fns';
import { Schema } from 'rsuite';
import { FilterDataType } from './interfaces';

const { ArrayType, DateType } = Schema.Types;

export const FilterSchema = Schema.Model({
  status: ArrayType(),
  from: DateType().addRule((value: string | Date, data: FilterDataType): boolean => {
    if (data.to && isAfter(value as Date, data.to)) {
      return false;
    }

    return true;
  }, 'The start date must be earlier than the end date.'),
  to: DateType().addRule((value: string | Date, data: FilterDataType): boolean => {
    if (data.from && isBefore(value as Date, data.from)) {
      return false;
    }

    return true;
  }, 'The end date must be later than the start date.'),
});
