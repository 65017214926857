import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PageNextIcon from '@rsuite/icons/PageNext';
import React from 'react';
import { Button } from 'rsuite';

const HeaderComponent: React.FunctionComponent<{ goBack: () => void; title?: string; goNext?: () => void; nextText?: string }> = ({
  title,
  nextText,
  goBack,
  goNext,
}) => {
  return (
    <div className='nav-top row'>
      <Button className='btn-back-custom rs-btn-model btn-md' onClick={goBack}>
        <PagePreviousIcon className='btn-icon' />
        Go back
      </Button>
      {!!title && <p className='nav-top-title'>{title}</p>}
      {!!nextText && (
        <Button className='btn-next  rs-btn-model btn-md' onClick={goNext}>
          {nextText}
          <PageNextIcon className='btn-icon' />
        </Button>
      )}
    </div>
  );
};

export default HeaderComponent;
