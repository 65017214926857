import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { convertToQueryString, prepareGetOptions, updateData, updateScanList } from '../../utils/GeneralUtil';
import { Task } from '../../models/Task';
import { Scan } from '../../views/all-scans/interfaces';
import { Patient } from '../../models/Patient';
import { Comment } from '../../views/comments/interfaces';
import { transformDate } from '../../utils/TimeUtil';
import { updatePatientListService } from '../../views/patients/PatientsService';

export function getInfoBySearchQuery(query: string, filters?: string, signal?: AbortSignal): Promise<Scan[]> {
  const paginate = {
    currentPage: 1,
    perPage: 100,
  };

  let queryString = convertToQueryString(paginate);

  if (filters) {
    queryString += `&${filters}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/scans?${queryString}&search=${query}`);
  return request(options).then(res => updateScanList(res.Elements));
}

export function getInfoByPatientName(query: string, filters?: string, signal?: AbortSignal): Promise<Patient[]> {
  const paginate = {
    currentPage: 1,
    perPage: 100,
  };

  let queryString = convertToQueryString(paginate);

  if (filters) {
    queryString += `&${filters}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/patients/patients-paginate?${queryString}&search=${query}`);
  return request(options).then(res => updatePatientListService(res.Elements));
}

export function getTasksByPatientName(query: string, filters?: string, signal?: AbortSignal): Promise<Task[]> {
  const paginate = {
    currentPage: 1,
    perPage: 25,
  };

  let queryString = convertToQueryString(paginate);

  if (filters) {
    queryString += `&${filters}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/tasks?${queryString}&patient=${query}`);
  return request(options).then(res => updateData(res.Elements, { DOB: transformDate }));
}

export function getCommentBySearchQuery(query: string, filters?: string, signal?: AbortSignal): Promise<Comment[]> {
  const paginate = {
    currentPage: 1,
    perPage: 25,
  };

  let queryString = convertToQueryString(paginate);

  if (filters) {
    queryString += `&${filters}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/comments?${queryString}&search=${query}`);
  return request(options).then(res => updateData(res.Elements, { DOB: transformDate }));
}
