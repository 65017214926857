import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { ScanResponse, Scan } from '../../views/all-scans/interfaces';
import { RequestFiles } from './interfaces';
import { Details } from '../../views/all-scans/scan/tabs/scan-details/ScanDetailsUtil';
import { ScansRequest } from './../../views/dashboard/interfaces';
import { ScanSheduleInfo } from '../../views/all-scans/scan/tabs/scheduling/interfaces';
import { convertToQueryString, prepareGetOptions } from '../../utils/GeneralUtil';
import { RequestParams } from '../../models/General';

export function getScanInfo(id: number, signal?: AbortSignal): Promise<Scan> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/list/${id}`);
  return request(options);
}

export function getScans({ filter, perPage, page, field, order }: RequestParams, signal?: AbortSignal): Promise<ScanResponse> {
  const paginate = {
    currentPage: page || 1,
    perPage: perPage || 25,
    sortBy: field,
    order,
  };
  let queryString = convertToQueryString(paginate);

  if (filter) {
    queryString += `&${filter}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/scans?${queryString}`);

  return request(options);
}

export function getScanScheduleInfo(id: string, signal?: AbortSignal): Promise<ScanSheduleInfo> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/scheduling-info/${id}`);
  return request(options);
}

export function editNotes(id: number, data: { [key: string]: string | any[] | undefined }): Promise<ScansRequest> {
  return request({
    url: `${SERVER_URL}/scan-request-notes/edit-notes/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function requestNotes(id: number, signal?: AbortSignal): Promise<RequestFiles[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request-notes/request-notes/${id}`);
  return request(options);
}

export function requestPrescription(id: number, signal?: AbortSignal): Promise<RequestFiles[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/prescription-files/${id}`);
  return request(options);
}

export function getScanDetails(id: string, signal?: AbortSignal): Promise<Details> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/details/${id}`);
  return request(options);
}
