import React from 'react';
import { InvalidPage } from './interfaces';

const InvalidPageComponent: React.FC<InvalidPage> = ({ id, content }) => {
  return (
    <div className='pageInvalid'>
      <div className='title'>
        {content.title} ‘{id}’
      </div>
      <div className='text'>{content.text}</div>
      <div className='img'>
        <img src={content.logo} alt='img' />
      </div>
    </div>
  );
};

export default InvalidPageComponent;
