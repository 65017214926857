import React, { useEffect, useMemo, useState } from 'react';
import { Whisper, Tooltip, Checkbox, Animation, CheckPicker } from 'rsuite';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { NumberBoolean } from '../../../../models/General';
import { CheckBoxFieldProps } from './interfaces';

const CheckboxFieldsBlock: React.FunctionComponent<CheckBoxFieldProps> = props => {
  const { data, locations, checkboxText, checkboxTooltip, locationTooltip, allLocationsValues, setData } = props;
  const [infoModalErrorText, setInfoModalErrorText] = useState<{ title: string; content: string } | null>(null);
  const [locationsListData, setLocationsListData] = useState<{
    selectedValues: number[];
    indeterminate: boolean;
    checkAll: boolean;
  }>({
    selectedValues: [],
    indeterminate: false,
    checkAll: false,
  });
  const [showPicker, setShowPicker] = useState(false);

  const locationNameList = useMemo(() => {
    if (!data?.Locations?.length) {
      return [];
    }

    const labels: string[] = [];

    locations.map(el => {
      if (data.Locations.includes(el.value as number)) {
        labels.push(el.label);
      }
    });

    return labels;
  }, [data?.Locations, locations]);

  useEffect(() => {
    if (!data?.Locations?.length) {
      return;
    }

    setLocationsListData({
      selectedValues: data.Locations,
      indeterminate: data.Locations.length > 0 && data.Locations.length < allLocationsValues.length,
      checkAll: data.Locations.length === allLocationsValues.length,
    });
  }, [data?.Locations]);

  const handleChangeRequestWhenOwner = (checked: boolean) => {
    const status: NumberBoolean = checked ? 1 : 0;

    setData({ Owner: status, Self: !status && !data.SpecificLocation ? 0 : 1 });
  };

  const onCloseInfoModal = (): void => {
    setInfoModalErrorText(null);
  };

  const handleChangeSpecificLocations = (checked: boolean) => {
    if (!checked) {
      setLocationsListData({
        selectedValues: [],
        indeterminate: false,
        checkAll: false,
      });
    } else {
      setShowPicker(true);
    }

    const status: NumberBoolean = checked ? 1 : 0;

    setData({ SpecificLocation: status, Self: !status && !data.Owner ? 0 : 1 });
  };

  const handleSelectLocation = (value: number[]) => {
    setLocationsListData({
      selectedValues: value,
      indeterminate: value.length > 0 && value.length < allLocationsValues.length,
      checkAll: value.length === allLocationsValues.length,
    });
  };

  const handleCheckAllLocations = (checked: boolean) => {
    const nextValue = checked ? allLocationsValues : [];
    setLocationsListData({
      selectedValues: nextValue,
      indeterminate: false,
      checkAll: checked,
    });
  };

  const confirmLocations = () => {
    setShowPicker(false);

    setData({ Locations: locationsListData.selectedValues });
  };

  return (
    <>
      <Animation.Collapse in={!!data?.Self}>
        <ul className='checkbox-setting_list'>
          <li>
            <div className='checkbox-wrapper'>
              <div className='row'>
                <Checkbox checked={!!data?.Owner} onChange={(value: any, checked: boolean) => handleChangeRequestWhenOwner(checked)}>
                  {checkboxText || 'Only the requests where I am the owner'}
                </Checkbox>
                <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={<Tooltip>{checkboxTooltip}</Tooltip>}>
                  <span className='tooltip'></span>
                </Whisper>
              </div>
            </div>
          </li>
          <li>
            <div className='checkbox-wrapper'>
              <div className='row'>
                <div className='select-picker-target'>
                  <Checkbox
                    checked={!!data?.SpecificLocation}
                    onChange={(value: any, checked: boolean) => handleChangeSpecificLocations(checked)}
                  >
                    Select specific locations
                  </Checkbox>
                </div>
                <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={<Tooltip>{locationTooltip}</Tooltip>}>
                  <span className='tooltip'></span>
                </Whisper>
              </div>
              {!!data?.SpecificLocation &&
                (showPicker ? (
                  <CheckPicker
                    data={locations}
                    menuClassName='location-dropdown'
                    renderExtraFooter={() => (
                      <div className='check-sticky-footer'>
                        <Checkbox
                          indeterminate={locationsListData.indeterminate}
                          checked={locationsListData.checkAll}
                          onChange={(value, checked) => handleCheckAllLocations(checked)}
                        >
                          {!locationsListData.checkAll ? 'Check All' : 'Uncheck All'}
                        </Checkbox>
                        <button onClick={confirmLocations} className='btn-model'>
                          Confirm
                        </button>
                      </div>
                    )}
                    value={locationsListData.selectedValues}
                    searchable={true}
                    className='custom-menu'
                    onSelect={handleSelectLocation}
                    open={true}
                  />
                ) : locationNameList.length > 0 ? (
                  <>
                    <div className='location-result'>
                      <div className='row'>
                        <span>{`${locationNameList.length} location${locationNameList.length === 1 ? '' : 's'} selected`}</span>
                        <Whisper
                          placement='top'
                          controlId='control-id-hover'
                          trigger='hover'
                          speaker={
                            <Tooltip>
                              {'You will receive new scan notifcations for the following locations:'}
                              {locationNameList.length
                                ? locationNameList.map((location, index) => <div key={index}>{location}</div>)
                                : 'There are no selected locations'}
                            </Tooltip>
                          }
                        >
                          <span className='tooltip'></span>
                        </Whisper>
                      </div>
                      <button onClick={() => setShowPicker(true)}>Edit locations</button>
                    </div>
                  </>
                ) : null)}
            </div>
          </li>
        </ul>
      </Animation.Collapse>
      {infoModalErrorText && <InfoModalComponent onClose={onCloseInfoModal} type={'error'} texts={infoModalErrorText} />}
    </>
  );
};

export default CheckboxFieldsBlock;
