import { NumberBoolean } from './General';

export type User = {
  Created: string;
  Email: string;
  FirstName: string;
  ID: number;
  UserID: number;
  IsActive: number;
  LastName: string;
  PO: PO;
  Notifications: Notifications;
  Updated: string;
  UserStatus: string;
  UserType: 'Administrator' | 'PO Administrator' | 'Standard' | 'Super Administrator';
  IsCleerly: boolean;
};

export enum UserTypes {
  'PO Administrator' = 1,
  'Administrator' = 2,
  'Standard' = 3,
  'Super Administrator' = 4,
}

export enum AccountStatuses {
  Active = 'Active',
  Expired = 'Expired',
  Trial = 'Trial',
  ERROR = 'ERROR',
}

export type PO = {
  ID: number;
  Name: string;
  Description: string | null;
  InternalName: string;
  IsChargebeeUsed: number;
  AllowCleerlyOrders: number;
  AllowStandardOrders: number;
  Access: {
    AccountStatus: AccountStatuses;
    DaysLeft: number;
    TrialExpiration: Date | null;
  };
  IsReportReviewAutoCreate: number;
};

export type NotificationData = {
  Self: NumberBoolean;
  Owner: NumberBoolean;
  SpecificLocation: NumberBoolean;
  Locations: Array<number>;
};

export interface Notifications {
  NEWSCANREQUEST: NotificationData;
  CANCEL: NotificationData;
  MFPCOMMENT: NotificationData;
  REPORT: NotificationData;
  BOOKING: NotificationData;
  CXCOMMENT: NotificationData;
  TASK: NotificationData;
}

type ServerError = {
  status: number;
  message: string;
};

export type ServerUserResponse = User & ServerError;
