export const checkForEmptyFields = (formValue: { [key: string]: any }, fields: string[]): boolean => {
  return fields.every(field => !!formValue[field] === false);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const replaceEmptyStringToNull = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === '') {
      obj[key] = null;
    }
  });
  return obj;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeTagIfEmptyString = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};
