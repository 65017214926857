import React from 'react';
import { Radio } from 'rsuite';
import { StaticP2PTaskComponentProps } from '../interfaces';
import FileThumbnailsList from '../../../components/file-viewer/FileThumbnailsList';

const StaticP2PTaskComponent: React.FC<StaticP2PTaskComponentProps> = ({ fileList, data, taskNotes }) => {
  return (
    <div className='content-data'>
      <div className='row'>
        <div className='content-data-filed'>
          <div className='label'>Reviewer name:</div>
          <div className='content-data-filed_content'>
            <p>{data.ReviewerName}</p>
          </div>
        </div>
        <div className='content-data-filed'>
          <div className='label'>Reviewer specialty:</div>
          <div className='content-data-filed_content'>
            <p>{data.ReviewerSpecialty}</p>
          </div>
        </div>
      </div>
      <div className='row col-12 rs-radio-group'>
        <Radio className='content-data-filed' checked={data.ReviewResult === 1} disabled>
          Approved
        </Radio>
        <Radio className='content-data-filed' checked={data.ReviewResult === 0} disabled>
          Denied
        </Radio>
      </div>
      {data.ReviewResult === 1 ? (
        <>
          <div className='content-data-filed'>
            <div className='label'>Approval ID:</div>
            <div className='content-data-filed_content'>
              <p>{data.AuthorizationID}</p>
            </div>
          </div>
          <div className='content-data-filed'>
            <div className='label'>Upload documents:</div>
            <FileThumbnailsList filesList={fileList} />
          </div>
        </>
      ) : (
        <div className='content-data-filed'>
          <div className='label'>Denial Reason:</div>
          <div className='content-data-filed_content'>
            <p>{data.DenialReason}</p>
          </div>
        </div>
      )}

      <div className='content-data-filed'>
        <div className='label'>Task notes:</div>
        <div className='content-data-filed_content'>
          <p>{taskNotes}</p>
        </div>
      </div>
    </div>
  );
};

export default StaticP2PTaskComponent;
