import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'rsuite';
import { editNotes } from '../../../../../api/all-scans/AllScansAPI';
import InfoModalComponent from '../../../../../components/modals/info-modal';
import { checkAreObjectsTheSame, DefaultFormValue, preparedFileData } from './ScanDetailsUtil';
import { uploadNotesFile } from '../../../../../api/notes/NotesAPI';
import Notes from '../../../../new-scan/components/Notes';
import { FileTypeWithTitle, NotesFormData } from '../../../../new-scan/interfaces';
import { ErrorContent } from '../../../../../models/General';
import { EditDetailModal } from './interfaces';
import { RequestFiles } from '../../../../../api/all-scans/interfaces';
import { renameFile } from '../../../../../utils/FileUtils';

const EditScanDetailsModal: React.FunctionComponent<EditDetailModal> = props => {
  const { scanId, notes, onClose, getScanData } = props;
  const { list, clinical, logistic, option } = notes;
  const [formValue, setFormValue] = useState<NotesFormData>(DefaultFormValue);
  const [uploadStatus, setUploadStatus] = useState<null | boolean>(null);
  const [editNotesStatus, setEditNotesStatus] = useState<null | boolean>(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [initValue, setInitValue] = useState<NotesFormData | null>(null);
  const [errorText, setErrorText] = useState<ErrorContent>({
    title: '',
    content: '',
  });

  useEffect(() => {
    setInitValue(preparedValue);
  }, [formValue.fileListNotes]);

  useEffect(() => {
    if (clinical || logistic || list) {
      setFormValue(preparedValue);
    }
  }, [clinical, list]);

  const preparedValue = useMemo(() => {
    return {
      ...formValue,
      NotesOptions: option,
      ClinicalNotes: clinical ? clinical : '',
      LogisticNotes: logistic ? logistic : '',
      fileListNotes: list && list.length ? preparedFileData(list, 'Clinical note') : [],
    };
  }, [clinical, list, option, formValue.fileListNotes]);

  const updateAllNotes = async (): Promise<void> => {
    if (!formValue.ClinicalNotes && !!formValue.fileListNotes && !formValue.fileListNotes.length && formValue.NotesOptions !== '2') {
      setErrorText({
        title: 'Validation error',
        content: 'Please upload notes or enter it manually',
      });
      setIsSubmitPressed(false);
      return;
    }

    if (formValue.NotesOptions === '0') {
      const fileData = new FormData();
      const files: FileTypeWithTitle[] = [];
      const keyFiles: RequestFiles[] = [];

      for (let i = 0; i < formValue.fileListNotes.length; i++) {
        const file: FileTypeWithTitle = formValue.fileListNotes[i] as FileTypeWithTitle;
        if (file.blobFile) {
          files.push(file);
          const newFile = await renameFile(file.blobFile);
          fileData.append('files', newFile);
        }
        if (file.key) {
          keyFiles.push({
            key: file.key,
            fileTitle: file.fileTitle,
            originalName: file.name as string,
          });
        }
      }

      if (files.length) {
        uploadNotesFile(fileData)
          .then(response => {
            const res: RequestFiles[] = files.map((el, i: number) => {
              return {
                key: response[i].key,
                fileTitle: el.fileTitle,
                originalName: response[i].originalName || (el.name as string),
              };
            });
            sendEditRequest([...res, ...keyFiles]);
            setUploadStatus(true);
          })
          .catch(err => {
            setErrorText({
              title: 'Edit error',
              content: err.message ? err.message : 'Something goes wrong',
            });
            setUploadStatus(false);
          })
          .finally(() => setIsSubmitPressed(false));
      }
    } else {
      sendEditRequest([]);
    }
  };

  const sendEditRequest = (files: RequestFiles[] = []) => {
    const data = {
      KeysToSave: files,
      ClinicalNotes: formValue.ClinicalNotes,
      ClinicalNotesFile: formValue.NotesOptions,
      LogisticNotes: formValue.LogisticNotes,
      fileNoteName: formValue.fileNoteName,
    };

    editNotes(scanId, data)
      .then(() => {
        setEditNotesStatus(true);
      })
      .catch(err => {
        setErrorText({
          title: 'Edit error',
          content: err.message ? err.message : 'Something goes wrong',
        });
        setEditNotesStatus(false);
      })
      .finally(() => setIsSubmitPressed(false));
  };

  const onCloseInfoModal = (): void => {
    if (checkError) {
      getScanData();
      onClose(false);
    }

    setErrorText({
      title: '',
      content: '',
    });
    setEditNotesStatus(null);
    setUploadStatus(null);
  };

  const checkError = useMemo((): boolean | void => {
    if (
      (uploadStatus && editNotesStatus) ||
      (typeof uploadStatus !== 'boolean' && editNotesStatus) ||
      (typeof editNotesStatus !== 'boolean' && uploadStatus)
    ) {
      return true;
    }

    if (typeof uploadStatus !== 'boolean' && typeof editNotesStatus !== 'boolean') {
      return;
    }

    return false;
  }, [uploadStatus, editNotesStatus]);

  const handleSubmit = () => {
    setIsSubmitPressed(true);
    if (!isSubmitPressed) {
      updateAllNotes();
    }
  };

  const onHideModal = () => {
    if (!isSubmitPressed) {
      onClose();
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (!initValue) {
      return true;
    }

    return (Object.keys(formValue) as [keyof NotesFormData]).every(key => {
      if (key === 'fileListNotes') {
        return checkAreObjectsTheSame(initValue.fileListNotes, formValue.fileListNotes);
      }
      if (key === 'NotesOptions') {
        return formValue.NotesOptions !== '2';
      }
      if (key in initValue) {
        if (!formValue[key]) {
          return !formValue[key];
        }
        return formValue[key] === initValue[key];
      }
      return true;
    });
  }, [formValue]);

  return (
    <div className='content-new-scan'>
      <Modal className='edit-scan_details' open onClose={onHideModal}>
        <Modal.Header>
          <Modal.Title>Edit notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Notes inModal={true} initData={formValue} setNotesData={(data: NotesFormData) => setFormValue({ ...formValue, ...data })} />
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close' disabled={isSubmitPressed} onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button classPrefix='btn-model' onClick={handleSubmit} disabled={isSubmitPressed || isSubmitDisabled}>
              Update and Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {(typeof checkError === 'boolean' || errorText.content) && (
        <InfoModalComponent
          type={checkError ? 'success' : 'error'}
          texts={
            checkError
              ? { title: 'Notes Successfully Edited', content: 'The notes for this scan request have been successfully edited and saved.' }
              : errorText
          }
          onClose={onCloseInfoModal}
        />
      )}
    </div>
  );
};

export default EditScanDetailsModal;
