import React, { SyntheticEvent } from 'react';
import { Button } from 'rsuite';
import { ActionButtonProps } from './interfaces';

export const ActionButton: React.FunctionComponent<ActionButtonProps> = ({ rowData, clickAction, buttonText, isDisabled, actionKey }) => {
  const handleClickAction = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    clickAction(actionKey, rowData);
  };

  return (
    <Button key={Number(rowData.ID)} disabled={isDisabled} classPrefix='btn-edit' onClick={e => handleClickAction(e)}>
      {buttonText}
    </Button>
  );
};
