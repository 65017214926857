import React, { ReactElement } from 'react';
import GeneralTaskComponent from '../components/GeneralTaskComponent';
import NotesReviewTaskComponent from '../components/NotesReviewTaskComponent';
import PeerReviewTaskComponent from '../components/PeerReviewTaskComponent';
import PriorAuthTaskComponent from '../components/PriorAuthTaskComponent';
import ReportReviewTaskComponent from '../components/ReportReviewTaskComponent';
import { FullTaskInfo } from '../interfaces';

export enum TaskTypes {
  PeerToPeerReview = 1,
  ReportReview,
  PendingNotesReview,
  StandartTask,
  PriorAuthTask,
}

export const taskComponentsSwitcher = (
  taskData: FullTaskInfo,
  liftGoBackTextUp: (str: string) => void,
  getAllTaskInfo: () => void,
): ReactElement | null => {
  switch (taskData.task.TaskTypeID) {
    case TaskTypes.PendingNotesReview:
      return <NotesReviewTaskComponent taskData={taskData} liftGoBackTextUp={liftGoBackTextUp} getAllTaskInfo={getAllTaskInfo} />;

    case TaskTypes.PeerToPeerReview:
      return <PeerReviewTaskComponent taskData={taskData} liftGoBackTextUp={liftGoBackTextUp} getAllTaskInfo={getAllTaskInfo} />;

    case TaskTypes.PriorAuthTask:
      return <PriorAuthTaskComponent taskData={taskData} liftGoBackTextUp={liftGoBackTextUp} getAllTaskInfo={getAllTaskInfo} />;

    case TaskTypes.ReportReview:
      return <ReportReviewTaskComponent taskData={taskData} liftGoBackTextUp={liftGoBackTextUp} getAllTaskInfo={getAllTaskInfo} />;

    case TaskTypes.StandartTask:
      return <GeneralTaskComponent taskData={taskData} liftGoBackTextUp={liftGoBackTextUp} getAllTaskInfo={getAllTaskInfo} />;

    default:
      return null;
  }
};

export const texts = {
  taskLoadingErrorText: {
    title: 'Task info failed to load',
    content: 'Please try again later',
  },
};

export const initPeerReviewTaskFormData = {
  authorizationDocuments: [],
  newReviewerName: '',
  newReviewerSpecialty: '',
  newReviewResult: -1,
  newAuthorizationID: '',
  newTaskNotes: '',
  newDenialReason: '',
  currTaskID: null,
};

export const initPriorAuthTaskFormData = {
  files: [],
  ReviewerName: '',
  ReviewStatus: 0,
  ApprovalID: '',
  Comment: '',
  TaskID: null,
};

export const ReportReviewDefaultValue = {
  reviewedBy: '',
  reviewedOn: '',
  reviewNotes: '',
  taskNotes: '',
};
