import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Panel } from 'rsuite';
import TableComponent from '../../components/table-component';
import InfoModalComponent from '../../components/modals/info-modal';
import AddPatientModalComponent from '../../components/add-patient-modal';
import { patientColumns, updatePatientListService, searchColumn } from './PatientsService';
import { useHistory, useLocation } from 'react-router-dom';
import { getPatients } from '../../api/patient/PatientAPI';
import { TableAction } from '../../enum/TableActionEnum';
import { ConfirmModalCancelText, SuccessAddModalText } from './PatientsUtil';
import ConfirmModalComponent from '../../components/modals/confirm-modal-component';
import { checkForEmptyFields } from '../../utils/FormUtil';
import { Patient } from '../../models/Patient';
import SearchBarComponent from '../../components/search-bar-component';
import { RowDataType, SortDataType } from '../../components/table-component/interfaces';
import { getUrlParams } from '../../utils/GeneralUtil';
import CustomPagination from '../../components/custom-pagination';
import useDynamicTableHeight from '../../utils/useDynamicTableHeight';
import { PageSpacingTable } from '../../enum/TableActionEnum';
import LoaderComponent from '../../components/loader-component';
import { getInfoByPatientName } from '../../api/search/SearchBarAPI';
import { useSignal } from '../../utils/UseSignal';
import useDefaultColumnWidth from '../../utils/UseColumnWidth';

const PatientsComponent: React.FunctionComponent = () => {
  const params = new URLSearchParams(useLocation().search);
  const paramsObj = getUrlParams(params?.toString());

  const [tableData, setTableData] = useState<Patient[] | []>([]);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showAddPatientModal, setShowAddPatientModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [numShow, setNumShow] = useState<number>(+paramsObj?.perPage || 25);
  const [activePage, setActivePage] = useState(+paramsObj?.currentPage || 1);
  const [total, setTotal] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<SortDataType>({});
  const { navRef, size } = useDynamicTableHeight(PageSpacingTable.IsSearchPagination, 535);
  const { panelRef, defaultWidth, calculateColumnWidth } = useDefaultColumnWidth(patientColumns.length);
  const signal = useSignal();

  const history = useHistory();

  const getListOfPatients = () => {
    setIsLoading(true);

    getPatients(
      {
        page: activePage,
        perPage: numShow,
        field: sort.field,
        order: sort.order,
      },
      signal,
    )
      .then(res => {
        if (res.Elements.length) {
          const data = updatePatientListService(res.Elements);
          setTableData(data);
        } else {
          setTableData([]);
          calculateColumnWidth();
        }

        setTotal(res.Info.FilteredTotalCount);
        setPages(res.Info.PageCount);

        if (activePage > res.Info.PageCount) {
          setActivePage(1);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          setTotal(0);
          setTableData([]);
          setPages(0);
          calculateColumnWidth();
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    const queryString = `?currentPage=${activePage}&perPage=${numShow}`;

    history.push(`/patients${queryString}`);

    getListOfPatients();
  }, [numShow, activePage, sort]);

  const handleRowClickColumn = (rowData: RowDataType) => {
    const id = rowData.id || rowData.ID || rowData.Id;
    history.push(`/patients/${id}`);
  };

  const onCloseAddPatient = useCallback((eventff: boolean) => {
    if (eventff) {
      getListOfPatients();
      setShowInfoModal(true);
    } else {
      setShowAddPatientModal(false);
    }
  }, []);

  const checkFields = useCallback((formValue: Patient, fields: string[]): boolean => {
    const formStatusIsEmpty = checkForEmptyFields(formValue, fields);
    if (formStatusIsEmpty) {
      setShowAddPatientModal(false);
    } else {
      setShowConfirmModal(true);
    }
    return formStatusIsEmpty;
  }, []);

  const onCloseConfirmModal = useCallback((value: boolean): void => {
    if (value) {
      setShowAddPatientModal(false);
    }
    setShowConfirmModal(false);
  }, []);

  const onCloseInfoModal = useCallback(() => {
    setShowInfoModal(false);
    setShowAddPatientModal(false);
  }, []);

  const onTableAction = (actionName: TableAction) => {
    if (actionName === TableAction.RequestScanRequest) {
      console.log('onTableAction');
    }
  };

  const clickSelectShow = (value: number) => {
    setNumShow(value);
    setActivePage(1);
  };

  const handleSelect = (page: number) => {
    setActivePage(page);
  };

  return (
    <div className='page-content-wrap'>
      <div className='nav-top_wrap' ref={navRef}>
        <div className='nav-top row'>
          <h1>Patients</h1>
        </div>
        <div className='row justify-content-between_align-center mb2'>
          <div className='input-search'>
            <SearchBarComponent
              handleRowClick={handleRowClickColumn}
              placeholderText={'Search for patients by name or DOB'}
              columns={searchColumn}
              searchMethod={getInfoByPatientName}
              searchText={'Begin typing a patient’s name to search for results.'}
            />
          </div>
          <div className='btn-add'>
            <Button className='rs-btn rs-btn-model btn-w-lg' onClick={() => setShowAddPatientModal(true)}>
              + Add a new patient
            </Button>
          </div>
        </div>
      </div>
      <Panel className='patient-panel' ref={panelRef}>
        <div className='d-flex pannel-wrap'>
          <div className='justify-content-end align-items-center'>
            <Dropdown activeKey={String(numShow)} title={`Show ${numShow} results per page`}>
              <Dropdown.Item onSelect={clickSelectShow} eventKey='25'>
                Show 25 results per page
              </Dropdown.Item>
              <Dropdown.Item onSelect={clickSelectShow} eventKey='50'>
                Show 50 results per page
              </Dropdown.Item>
              <Dropdown.Item onSelect={clickSelectShow} eventKey='75'>
                Show 75 results per page
              </Dropdown.Item>
              <Dropdown.Item onSelect={clickSelectShow} eventKey='100'>
                Show 100 results per page
              </Dropdown.Item>
            </Dropdown>
            <div className='number-pages'>
              {total ? `${numShow * (activePage - 1) + 1} - ${numShow * (activePage - 1) + tableData.length} of ${total}` : '0 - 0 of 0'}
            </div>
            <CustomPagination
              className='paging'
              hidden={pages <= 1 && tableData.length <= numShow}
              disabled={isLoading ? true : undefined}
              pages={pages}
              activePage={activePage}
              onSelect={(page: number) => handleSelect(page)}
              prev
              last
              next
              first
              size='lg'
              maxButtons={3}
              ellipsis
              boundaryLinks
            />
          </div>
        </div>
        {isLoading && <LoaderComponent className={'loader-block-center'} />}
        {!isLoading ? (
          <TableComponent
            onAction={onTableAction}
            columns={patientColumns}
            data={tableData}
            sort={sort}
            onRowClick={rowData => handleRowClickColumn(rowData)}
            onSort={sortData => setSort(sortData)}
            customHeight={size}
            defaultWidth={defaultWidth}
            isEdit={false}
            isRemove={false}
          />
        ) : null}
      </Panel>

      {showInfoModal && <InfoModalComponent texts={SuccessAddModalText} type='success' onClose={onCloseInfoModal} />}
      {showAddPatientModal && <AddPatientModalComponent onCheckFields={checkFields} isEdit={false} onClose={onCloseAddPatient} />}
      {showConfirmModal && <ConfirmModalComponent onClose={onCloseConfirmModal} info={ConfirmModalCancelText.cancelAddingPatient} />}
    </div>
  );
};

export default PatientsComponent;
