import React, { MouseEvent, useRef, useState } from 'react';
import { Button, Uploader } from 'rsuite';
import { FileType } from 'rsuite/Uploader';
import TrashIcon from '@rsuite/icons/Trash';
import { fileModalContent, fileRenameModalText } from '../../utils/FileUtil';
import useListVisible from '../../utils/useListVisible';
import RenameFileModal from '../../views/new-scan/components/RenameFileModal';
import { FileTypeWithTitle } from '../../views/new-scan/interfaces';
import FileViewer from '../doc-viewer-modal';
import { FilePreviewerThumbnail } from '../file-viewer';
import { FileView } from '../file-viewer/interfaces';
import ConfirmModalComponent from '../modals/confirm-modal-component';
import InfoModalComponent from '../modals/info-modal';
import { Props } from './interfaces';

const FilesUploader: React.FunctionComponent<Props> = ({
  fileList,
  title,
  updateFiles,
  downloadFileFn,
  uploadFiles,
  checkFile,
  onDelete,
  fileLoadFinish,
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isRename, setIsRename] = useState<boolean>(false);
  const [renameSuccessModal, setRenameSuccessModal] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [file, setFile] = useState<FileView>({ url: '', name: '', mimeType: '' });
  const { listRef, fileOpenedKey, setFileOpenedKey } = useListVisible(-1);
  const activeId = useRef<number>(-1);

  const toogleFileMenuOpen = (key: number): void => {
    let result = key;

    if (fileOpenedKey === key) {
      result = -1;
    }

    setFileOpenedKey(result);
  };

  const onUpdateRenameModal = (val: string): void => {
    if (!val) {
      return;
    }

    const res = fileList.map(item => (item.id === activeId.current && val ? { ...item, fileTitle: val } : item));

    updateFiles(res);

    setIsRename(false);
    setRenameSuccessModal(true);
  };

  const deleteFile = (e: MouseEvent<HTMLAnchorElement>, id: number): void => {
    e.preventDefault();
    onDelete && onDelete();
    activeId.current = id;

    setConfirmDelete(true);
  };

  const renameFile = (e: MouseEvent<HTMLAnchorElement>, id: number): void => {
    e.preventDefault();
    activeId.current = id;

    setFileOpenedKey(-1);
    setIsRename(true);
  };

  const previewFile = (fileNotes: FileTypeWithTitle): void => {
    activeId.current = Number(fileNotes.id);

    setFile({
      url: fileNotes.url as string,
      name: fileNotes.name as string,
      mimeType: fileNotes.mimeType,
    });

    setIsShow(true);
  };

  const downloadFile = (e: { preventDefault: () => void } | null, doc: FileTypeWithTitle): void => {
    e && e.preventDefault();
    const id = doc.id;

    if (id === undefined) {
      return;
    }

    setFileOpenedKey(-1);

    downloadFileFn(doc);
  };

  const onCloseConfirmDeleteModal = (res: boolean) => {
    if (res) {
      const newFileList = fileList.filter(file => file.id !== activeId.current);

      activeId.current = -1;
      updateFiles(newFileList);
    }

    setConfirmDelete(false);
  };

  const onCloseFileViewer = (): void => {
    setIsShow(false);
  };

  const onCloseRenameModal = (): void => {
    setIsRename(false);
  };

  const onDownload = (): void => {
    const file = fileList && fileList.find(el => el.id === activeId.current);

    if (file && file.id) {
      downloadFile(null, file);
    }
  };

  return (
    <>
      <Uploader
        name=''
        shouldUpload={() => false}
        action=''
        fileList={fileList}
        onChange={uploadFiles}
        multiple={true}
        shouldQueueUpdate={(fileList: FileType[]) => checkFile(fileList as FileTypeWithTitle[])}
        draggable
        renderFileInfo={(f: FileType) => {
          const file: FileTypeWithTitle = f as FileTypeWithTitle;

          if (!file.fileTitle) {
            return <></>;
          }

          return (
            <>
              <span className='preview'>
                <FilePreviewerThumbnail
                  file={file as FileView}
                  style={{ width: '100%', height: '100%' }}
                  fileLoadFinish={() => fileLoadFinish && fileLoadFinish()}
                  handleClick={() => previewFile(file)}
                />
              </span>
              <span className='preview-name' onClick={() => previewFile(file)}>
                {file.fileTitle}
              </span>
              <a href='#' className='uploader-delete-btn' onClick={e => deleteFile(e, file.id as number)}>
                <TrashIcon /> delete file
              </a>
              <div className='dots'>
                <span className='dots__text' data-list='list' onClick={() => toogleFileMenuOpen(file.id as number)}>
                  ...
                </span>
                {fileOpenedKey === file.id && (
                  <ul className='dots__list' ref={listRef}>
                    <li>
                      <a href='#' onClick={e => renameFile(e, file.id as number)}>
                        Rename File Description
                      </a>
                    </li>
                    <li>
                      <a href='#' onClick={e => downloadFile(e, file)}>
                        Download File
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </>
          );
        }}
      >
        <Button>
          <span className='icon-upload'></span> {title}
        </Button>
      </Uploader>
      {confirmDelete && <ConfirmModalComponent info={fileModalContent} onClose={onCloseConfirmDeleteModal} />}
      {renameSuccessModal && (
        <InfoModalComponent type={`success`} texts={fileRenameModalText} onClose={() => setRenameSuccessModal(false)} />
      )}
      {isRename && <RenameFileModal onClose={onCloseRenameModal} onUpdate={onUpdateRenameModal} />}
      <FileViewer isShow={isShow} onDownloadFile={onDownload} file={file} onClose={() => onCloseFileViewer()} />
    </>
  );
};

export default FilesUploader;
