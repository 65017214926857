import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Panel, Dropdown } from 'rsuite';
import TableComponent from '../../components/table-component';
import { useHistory } from 'react-router-dom';
import { columns, initDataForFilter, searchBarColumns } from './TasksUtil';
import LoaderComponent from '../../components/loader-component';
import { useLocation } from 'react-router';
import { convertToQueryString, getUrlParams, updateData } from '../../utils/GeneralUtil';
import { RowDataType, SortDataType } from '../../components/table-component/interfaces';
import CustomPagination from '../../components/custom-pagination';
import { FilterDataType } from './interfaces';
import useDynamicTableHeight from '../../utils/useDynamicTableHeight';
import { PageSpacingTable, TableAction } from '../../enum/TableActionEnum';
import FilterTaskComponent from './components/FilterTaskModal';
import { Task } from '../../models/Task';
import { getTasksList, reassignTask } from '../../api/tasks/TasksApi';
import TaskActionComponent from '../../components/action-component';
import SearchBarComponent from '../../components/search-bar-component';
import { getTasksByPatientName } from '../../api/search/SearchBarAPI';
import { SelectOption } from '../../models/General';
import { getSelectOptions } from '../../api/filters/FiltersAPI';
import { TasksFilterData } from '../../api/filters/interfaces';
import { transformDate } from '../../utils/TimeUtil';
import { useSignal } from '../../utils/UseSignal';
import useDefaultColumnWidth from '../../utils/UseColumnWidth';

const TasksComponent: React.FunctionComponent = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramsObj = getUrlParams(params?.toString());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState(+paramsObj?.currentPage || 1);
  const [data, setData] = useState<Task[]>([]);
  const [pages, setPages] = useState<number>(0);
  const [filerOpened, setFilerOpened] = useState<boolean>(false);
  const [filteredTotal, setFilteredTotal] = useState<number>(0);
  const [numShow, setNumShow] = useState<number>(+paramsObj?.perPage || 25);
  const [filters, setFilters] = useState<string>(
    convertToQueryString({
      type: paramsObj?.type,
      status: paramsObj?.status || (!paramsObj?.perPage && !paramsObj?.currentPage && '1'),
      ownerIds: paramsObj?.ownerIds,
      locationIds: paramsObj?.locationIds,
      prescriberIds: paramsObj?.prescriberIds,
    }),
  );
  const [sort, setSort] = useState<SortDataType>({});
  const [filterData, setFilterData] = useState<Record<string, SelectOption[]>>({
    prescribers: [],
    owners: [],
    locations: [],
  });
  const signal = useSignal();

  const history = useHistory();

  const { navRef, size } = useDynamicTableHeight(PageSpacingTable.IsSearchPagination, 550);
  const { panelRef, defaultWidth, calculateColumnWidth } = useDefaultColumnWidth(columns.length, true);

  const initFilterData = useRef<FilterDataType>(initDataForFilter);
  const total = useRef<number>(0);

  const getAllTasks = () => {
    setIsLoading(true);

    getTasksList(
      {
        filter: filters,
        page: activePage,
        perPage: numShow,
        field: sort.field,
        order: sort.order,
      },
      signal,
    )
      .then(result => {
        if (result.Elements.length) {
          const preparedData = updateData(result.Elements, { TaskCreatedDate: transformDate, DOB: transformDate });
          setData(preparedData);
        } else {
          setData([]);
          calculateColumnWidth();
        }

        total.current = result.Info.TotalCount;
        setFilteredTotal(result.Info.FilteredTotalCount);
        setPages(result.Info.PageCount);

        if (activePage > result.Info.PageCount) {
          setActivePage(1);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          total.current = 0;
          setFilteredTotal(0);
          setPages(0);
          setData([]);
          calculateColumnWidth();
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const getFilterData = () => {
    getSelectOptions<TasksFilterData>(['Locations4SR', 'Prescribers', 'Owners'], signal)
      .then(res => {
        const filledLocationsArray: SelectOption[] = [];
        const filledPresciberArray: SelectOption[] = [];
        const filledOwnersArray: SelectOption[] = [];

        Object.values(res.Owners).map(el => {
          filledOwnersArray.push({
            label: el.OwnerName,
            value: el.ID,
          });
        });

        Object.values(res.Locations4SR).map(el => {
          filledLocationsArray.push({
            label: el.Address1 + ' ' + el.City,
            value: el.ID as number,
          });
        });

        Object.values(res.Prescribers).map(el => {
          filledPresciberArray.push({
            label: el.FirstName + ' ' + el.LastName,
            value: el.ID as number,
          });
        });

        setFilterData({
          prescribers: filledPresciberArray,
          owners: filledOwnersArray,
          locations: filledLocationsArray,
        });
      })
      .catch(() => {
        if (!signal.aborted) {
          setFilterData({
            prescribers: [],
            owners: [],
            locations: [],
          });
        }
      });
  };

  useEffect(() => {
    let queryString = `currentPage=${activePage}&perPage=${numShow}`;

    if (filters) {
      queryString += `&${filters}`;
    }

    history.push(`/tasks?${queryString}`);

    getAllTasks();
  }, [numShow, filters, activePage, sort]);

  useEffect(() => {
    if (paramsObj) {
      initFilterData.current = {
        type: paramsObj?.type ? paramsObj.type.split(',').map((i: string) => +i) : initFilterData.current.type,
        status: paramsObj?.status ? paramsObj.status.split(',').map((i: string) => +i) : initFilterData.current.status,
        ownerIds: paramsObj?.ownerIds ? paramsObj.ownerIds.split(',').map((i: string) => +i) : initFilterData.current.ownerIds,
        locationIds: paramsObj?.locationIds ? paramsObj.locationIds.split(',').map((i: string) => +i) : initFilterData.current.locationIds,
        prescriberIds: paramsObj?.prescriberIds
          ? paramsObj.prescriberIds.split(',').map((i: string) => +i)
          : initFilterData.current.prescriberIds,
      };
    }

    getFilterData();
  }, []);

  const handleSelect = (page: number) => {
    setActivePage(page);
  };

  const clickSelectShow = (value: number) => {
    setNumShow(value);
    setActivePage(1);
  };

  const applyFilters = (data: FilterDataType) => {
    if (data) {
      if (!Object.keys(data).length) {
        setFilters('');
        setActivePage(1);
        setFilerOpened(false);
        initFilterData.current = {};
        return;
      }

      const filter = convertToQueryString(data);
      setFilters(filter);
      setActivePage(1);
      setFilerOpened(false);
      initFilterData.current = data;
    }
  };

  const onSelect = (taskID: number, ownerID: number) => {
    reassignTask(taskID, { newAssignedUserID: ownerID }).then(() => {
      getAllTasks();
    });
  };

  const onAction = (actionID: TableAction) => {
    if (actionID === TableAction.Edit) {
      getAllTasks();
    }
  };

  const handleRowClickSearch = (rowData: RowDataType): void => {
    const { ID } = rowData;
    history.push(`/tasks/${ID}`, { from: location });
  };

  const handleRowClick = (rowData: RowDataType): void => {
    const { ID } = rowData;
    history.push(`/tasks/${ID}`, { from: location });
  };

  const filterCuttonText = useMemo(
    () => `Showing ${filteredTotal !== total.current ? `${filteredTotal} of` : 'All'} ${total.current} tasks`,
    [filteredTotal, total.current],
  );

  return (
    <>
      <div className='page-content-wrap page-tasks-content'>
        <div className='nav-top_wrap' ref={navRef}>
          <div className='nav-top row'>
            <h1>Tasks</h1>
          </div>
          <div className='row justify-content-between align-items-baseline mb2'>
            <div className='input-search'>
              {
                <SearchBarComponent
                  handleRowClick={handleRowClickSearch}
                  placeholderText={'Search for task by patient name or DOB'}
                  columns={searchBarColumns}
                  filters={filters}
                  searchMethod={getTasksByPatientName}
                  searchText={'Begin typing a patient’s name or DOB to search for results.'}
                />
              }
            </div>
            <button className='filter-btn' onClick={() => setFilerOpened(true)}>
              {filterCuttonText}
            </button>
            <button className='clear-filter-btn' onClick={() => applyFilters({})} disabled={filters === ''}>
              clear&nbsp;all&nbsp;filters
            </button>
          </div>
        </div>
        <Panel className='patient-panel' ref={panelRef}>
          <div className='d-flex pannel-wrap'>
            {filerOpened && (
              <FilterTaskComponent
                applyFilters={applyFilters}
                filterData={filterData}
                initData={initFilterData.current}
                onClose={() => setFilerOpened(false)}
              />
            )}
            <div className='justify-content-end align-items-center'>
              <Dropdown activeKey={String(numShow)} title={`Show ${numShow} results per page`}>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='25'>
                  Show 25 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='50'>
                  Show 50 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='75'>
                  Show 75 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='100'>
                  Show 100 results per page
                </Dropdown.Item>
              </Dropdown>
              <div className='number-pages'>
                {filteredTotal
                  ? `${numShow * (activePage - 1) + 1} - ${numShow * (activePage - 1) + data.length} of ${filteredTotal}`
                  : '0 - 0 of 0'}
              </div>
              <CustomPagination
                className='paging'
                hidden={pages <= 1 && data.length <= numShow}
                disabled={isLoading ? true : undefined}
                pages={pages}
                activePage={activePage}
                onSelect={(page: number) => handleSelect(page)}
                prev
                last
                next
                first
                size='lg'
                maxButtons={3}
                ellipsis
                boundaryLinks
              />
            </div>
          </div>
          {isLoading && <LoaderComponent className={'loader-block-center'} />}
          {!isLoading ? (
            <TableComponent
              columns={columns}
              data={data}
              sort={sort}
              onSort={(sortData: SortDataType) => setSort(sortData)}
              onRowClick={(rowData: RowDataType) => handleRowClick(rowData)}
              customHeight={size}
              defaultWidth={defaultWidth}
              onSelect={onSelect}
              onAction={onAction}
              selectOptions={filterData.owners}
              isEdit={false}
              isRemove={false}
              widthColumnAction={window.innerWidth > 2100 ? 130 : 100}
              actionComponentRender={(rowData: RowDataType) => <TaskActionComponent rowData={rowData} />}
            />
          ) : null}
        </Panel>
      </div>
    </>
  );
};

export default TasksComponent;
