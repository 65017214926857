import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'rsuite';
import TextField from '../../../components/text-field-component';
import { saveAs } from 'file-saver';
import DetailsTaskTabComponent from './DetailsTaskTabComponent';
import { NotesTaskFormStructure, TaskProps } from '../interfaces';
import { FileView } from '../../../components/file-viewer/interfaces';
import { FileType } from 'rsuite/Uploader';
import { FileTypeWithTitle } from '../../new-scan/interfaces';
import InfoModalComponent from '../../../components/modals/info-modal';
import { checkForEmptyString, checkForWrongFile } from '../../../utils/GeneralUtil';
import { Texts } from '../../../components/modals/interfaces';
import { NotesTaskDefaultValue, TaskStatuses } from '../TasksUtil';
import { FormError } from '../../../models/General';
import { uploadNotesFile } from '../../../api/notes/NotesAPI';
import { FormInstance } from 'rsuite/Form';
import { completeNotesReview } from '../../../api/tasks/TasksApi';
import { RequestFiles } from '../../../api/all-scans/interfaces';
import StaticNotesTaskComponent from './StaticNotesTaskComponent';
import FilesUploader from '../../../components/files-uploader';
import { NotesSchema } from '../Schemas';
import Textarea from '../../../components/textarea';
import { onLoadFile, renameFile } from '../../../utils/FileUtils';
import { useSignal } from '../../../utils/UseSignal';

const NotesReviewTaskComponent: React.FC<TaskProps> = ({ taskData, liftGoBackTextUp, getAllTaskInfo }) => {
  const { special, task, testRequest, insurance, noteFiles, Patient } = taskData;
  const [formData, setFormData] = useState<NotesTaskFormStructure>(NotesTaskDefaultValue);
  const [errorText, setErrorText] = useState<Texts | null>(null);
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const fileLoadedCount = useRef<number>(0);
  const formRef = useRef<FormInstance | null>(null);
  const { clinicalNotes, fileListNotes } = formData;
  const signal = useSignal();

  useEffect(() => {
    const defaultFileListNotes: (FileTypeWithTitle & FileView)[] = noteFiles.map((el, i) => ({
      key: el.key,
      url: el.url as string,
      name: el.originalName || el.key,
      fileTitle: el.fileTitle,
      id: i,
    }));
    liftGoBackTextUp(`Pending clinical notes for Scan Request #${testRequest.ScanID}`);
    setFormData({
      ...formData,
      clinicalNotes: checkForEmptyString(testRequest.ClinicalNotes),
      fileListNotes: defaultFileListNotes,
      taskNotes: checkForEmptyString(task.TaskNotes),
    });
  }, [testRequest, task]);

  const areFilesDifferent = (currentFilesList: Array<FileTypeWithTitle & FileView>, initialFilesList: RequestFiles[]): boolean => {
    if (initialFilesList.length !== currentFilesList.length) {
      return true;
    } else {
      const initialFilesKeys = initialFilesList.map(({ originalName, key }) => originalName || key).sort();
      const currentFilesKeys = currentFilesList.map(({ name }) => name).sort();
      return initialFilesKeys.some((fileKey, index) => fileKey !== currentFilesKeys[index]);
    }
  };

  const handleNoStringValue = (value: string | null | undefined): string => value ?? '';

  const isSubmitEnabled = useMemo(() => {
    const isDifferentFromInitialState =
      handleNoStringValue(clinicalNotes) !== handleNoStringValue(testRequest.ClinicalNotes) ||
      areFilesDifferent(fileListNotes, noteFiles) ||
      handleNoStringValue(formData.taskNotes) !== handleNoStringValue(task.TaskNotes);
    const allRequiredFieldsAreFilled = !!formData.clinicalNotes || formData.fileListNotes.length > 0;
    return isDifferentFromInitialState && allRequiredFieldsAreFilled;
  }, [formData]);

  const checkUploadedNotes = (files: FileTypeWithTitle[]): boolean => {
    if (files && files.length) {
      const isWrongFileExist = checkForWrongFile(files);
      if (isWrongFileExist === false) {
        return true;
      }

      setFormError({ ...formError, fileNoteName: isWrongFileExist });

      return false;
    }

    return true;
  };

  const fileLoadFinish = () => {
    if (!isLoading) {
      return;
    }
    fileLoadedCount.current += 1;
    if (fileLoadedCount.current === fileListNotes.length) {
      fileLoadedCount.current = 0;
    }
  };

  const uploadNotes = async (fileList: Array<FileType>): Promise<void> => {
    if (fileList && fileList.length) {
      const newArr: (FileTypeWithTitle & FileView)[] = [...fileListNotes];

      setFormError({});

      for (let i = 0; i < fileList.length; i++) {
        const item: FileTypeWithTitle = fileList[i] as FileTypeWithTitle;
        if (!fileListNotes[i]) {
          const result = await onLoadFile(item);
          item.id = i;
          item.url = result;
          item.fileTitle = item.name as string;
          item.mimeType = item.blobFile?.type;

          newArr.push(item);
        } else {
          newArr[i].id = i;
        }
      }
      setFormData({
        ...formData,
        fileListNotes: newArr,
        fileNoteName: fileList[fileList.length - 1].name,
      });
    } else {
      setFormData({
        ...formData,
        fileListNotes: [],
        fileNoteName: '',
      });
    }
  };

  const downloadFile = (doc: FileTypeWithTitle): void => {
    if (!doc.blobFile) {
      saveAs(doc.url as string, doc.name);
      return;
    }
    saveAs(doc.blobFile as Blob);
  };

  const onCloseErrorModal = (): void => {
    setErrorText(null);
  };

  const uploadFiles = async (): Promise<RequestFiles[]> => {
    const fileData = new FormData();
    const { fileListNotes } = formData;
    const files: FileTypeWithTitle[] = [];
    const keyFiles: RequestFiles[] = [];

    if (!fileListNotes || !fileListNotes.length) {
      return [];
    }

    for (let i = 0; i < fileListNotes.length; i++) {
      const file: FileTypeWithTitle = fileListNotes[i] as FileTypeWithTitle;
      if (file.blobFile) {
        files.push(file);
        const newFile = await renameFile(file.blobFile);
        fileData.append('files', newFile);
      }
      if (file.key) {
        keyFiles.push({
          key: file.key,
          fileTitle: file.fileTitle,
          originalName: file.name as string,
        });
      }
    }

    if (files.length) {
      return uploadNotesFile(fileData).then(response => {
        const res: RequestFiles[] = files.map((el, i: number) => {
          return {
            key: response[i].key,
            fileTitle: el.fileTitle,
            originalName: response[i].originalName || (el.name as string),
          };
        });
        return [...res, ...keyFiles];
      });
    }

    return [];
  };

  const submit = async () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }
    if (fileListNotes) {
      setIsLoading(true);
      const fileList = await uploadFiles();
      completeNotesReview({
        currTaskID: task.ID,
        newClinicalNotes: clinicalNotes,
        newClinicalNotesFile: '',
        newTaskNotes: formData.taskNotes,
        clinicalNotesFiles: fileList,
      })
        .then(() => {
          if (!signal.aborted) {
            getAllTaskInfo(true);
          }
        })
        .catch(e => {
          if (!signal.aborted) {
            setErrorText({ title: 'Complete error', content: e.message });
          }
        })
        .finally(() => {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        });
    }
  };

  const refreshTaskDetails = () => {
    getAllTaskInfo(false);
  };

  return (
    <>
      <div className='task-content_form'>
        <Form
          className='task-form_pending'
          onChange={formValue => setFormData(formValue as NotesTaskFormStructure)}
          onCheck={formError => setFormError(formError)}
          formValue={formData}
          model={NotesSchema}
          ref={formRef}
        >
          {TaskStatuses.Open === task.TaskStatus ? (
            <div className='content'>
              <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                <TextField
                  name='clinicalNotes'
                  accepter={Textarea}
                  appearance='subtle'
                  label='Clinical notes* :'
                  labelClassName='task-label'
                  error={formError.clinicalNotes}
                  value={formData.clinicalNotes}
                  onChange={val => {
                    if (val) {
                      setFormData({ ...formData, fileNoteName: 'file', clinicalNotes: val });
                    }
                  }}
                />
                {!formError.clinicalNotes && <Form.HelpText>You need to enter or upload clinical notes</Form.HelpText>}
              </Form.Group>
              <div className='form-new-scan-uploader'>
                <span className='form-new-scan-divider'>---OR---</span>
                <div className='title'>Upload clinical notes:</div>
                <FilesUploader
                  fileList={fileListNotes}
                  uploadFiles={uploadNotes}
                  title='Upload clinical notes:'
                  updateFiles={(files: Array<FileTypeWithTitle>) => {
                    setFormData({ ...formData, fileListNotes: files as Array<FileTypeWithTitle & FileView> });
                    setFormError({ ...formError, fileNoteName: '' });
                  }}
                  downloadFileFn={downloadFile}
                  checkFile={checkUploadedNotes}
                  fileLoadFinish={fileLoadFinish}
                />
                {!!formError.fileNoteName && !clinicalNotes && <div className='error-text-new-scan'>{formError.fileNoteName}</div>}
              </div>
              <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                <TextField
                  name='taskNotes'
                  accepter={Textarea}
                  appearance='subtle'
                  label='Notes to Medmo Care Team (optional):'
                  labelClassName='task-label'
                />
                <Form.HelpText>Field for notes to Medmo Care Team or your team members</Form.HelpText>
              </Form.Group>
              <Button className='btn-lg rs-btn-model' onClick={submit} disabled={isLoading || !isSubmitEnabled}>
                {TaskStatuses.Open === task.TaskStatus ? 'Mark as Completed' : 'Update & Save'}
              </Button>
            </div>
          ) : (
            <StaticNotesTaskComponent
              fileListNotes={noteFiles}
              clinicalNotes={testRequest.ClinicalNotes}
              taskNotes={task.TaskNotes as string}
            />
          )}
        </Form>
      </div>
      <div className='task-details'>
        <DetailsTaskTabComponent data={{ special, task, testRequest, insurance, Patient }} taskDetailsChanged={refreshTaskDetails} />
      </div>
      {errorText && <InfoModalComponent type={errorText ? `error` : 'success'} texts={errorText} onClose={onCloseErrorModal} />}
    </>
  );
};

export default NotesReviewTaskComponent;
