import React, { useState, useEffect, useRef, ChangeEvent, useMemo } from 'react';
import TextField from '../../../../components/text-field-component';
import { Button, Modal, Form, Uploader } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { DefaultOrganizationSettings, companyInfoForm, EnumLogoState } from './OrganizationSettingsUtil';
import { FileType } from 'rsuite/Uploader';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { ModalProps } from './interfaces';
import { LogoDataType, OrganizationSettings } from './Types';
import { checkFileExtension, checkFileSize } from '../../../../utils/GeneralUtil';
import { FormError } from '../../../../models/General';
import { FileTypeWithTitle } from '../../../new-scan/interfaces';
import { wrongLogoTexts } from '../../../../utils/Texts';
import { addOrganizationLogo, updateOrganizationInfo } from '../../../../api/organization/OrganizationAPI';
import { useError } from '../../../../utils/UseError';
import Textarea from '../../../../components/textarea';

const OrganizationModal: React.FunctionComponent<ModalProps> = props => {
  const { onClose, dataForUpdate, organizationID } = props;
  const [formValue, setFormValue] = useState<OrganizationSettings>(DefaultOrganizationSettings);
  const [formError, setFormError] = useState<FormError>({});
  const [isLogoFormatWrong, setIsLogoFormatWrong] = useState<null | string>(null);
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);

  const formRef = useRef<FormInstance | null>(null);

  useEffect(() => {
    setFormValue({
      logo: dataForUpdate.Logo?.key || [],
      description: dataForUpdate.Description || '',
      logoState: EnumLogoState.DoNothing,
      name: dataForUpdate.Name || '',
    });
  }, [dataForUpdate]);

  const uploadLogo = (picture: FileType[]): void => {
    setIsLogoFormatWrong(null);

    if (picture && picture.length) {
      setFormValue({
        ...formValue,
        logo: picture,
        logoState: EnumLogoState.Add,
      });
    }
  };

  const handleRemove = (): void => {
    setFormValue({
      ...formValue,
      logo: null,
      logoState: EnumLogoState.Delete,
    });

    setIsLogoFormatWrong(null);
    onClose(null);
  };

  const updateInfo = (data: OrganizationSettings, id: number): void => {
    const preparedData = Array.isArray(data.logo) ? { ...data, logo: dataForUpdate.Logo?.url || null } : data;
    updateOrganizationInfo(preparedData, id)
      .then(() => {
        onClose(true);
      })
      .catch(e => {
        setErrorText(e, formValue);
      });
  };

  const handleChange = (formValue: OrganizationSettings, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const handleSubmit = () => {
    if ((formRef.current && !formRef.current.check()) || isLogoFormatWrong) {
      return;
    }

    const { logo } = formValue;

    if (logo && typeof logo !== 'string' && logo[0] && Object.keys(logo[0]).length !== 1) {
      const imageData = new FormData();

      imageData.append('files', logo[0].blobFile as Blob);

      addOrganizationLogo(imageData)
        .then((response: string[]) => response)
        .then(res => updateInfo({ ...formValue, logo: res[0] }, organizationID))
        .catch(e => setErrorText(e));
    } else {
      updateInfo(formValue, organizationID);
    }
  };

  const previewShouldBeShown = (files: FileType[]): boolean => {
    const isFormatCorrect = checkFileExtension(files[0] as FileTypeWithTitle);
    const isSizeCorrect = !checkFileSize(files[0] as FileTypeWithTitle);

    if (!isSizeCorrect || !isFormatCorrect) {
      const text = !isFormatCorrect ? wrongLogoTexts.wrongFormat : wrongLogoTexts.wrongSize;
      setIsLogoFormatWrong(text);
      return false;
    }

    return true;
  };

  const isSubmitDisabled = useMemo(() => {
    const areDescriptionsEqual = formValue.description === dataForUpdate.Description;
    const areNamesEqual = dataForUpdate.Name === formValue.name;
    const isLogoUpdated = !(typeof formValue.logo === 'string') && !(Array.isArray(formValue.logo) && !formValue.logo?.length);

    if (formValue.logo === null) {
      if (dataForUpdate.Logo) {
        const isLogoProvided = (Object.keys(dataForUpdate.Logo) as [keyof LogoDataType]).every(key => !dataForUpdate.Logo[key]);
        return isLogoProvided && isLogoUpdated && areDescriptionsEqual && areNamesEqual;
      }
    }

    return !isLogoUpdated && areDescriptionsEqual && areNamesEqual;
  }, [formValue, dataForUpdate]);

  return (
    <div>
      <Modal size={'sm'} className='organization-edit-modal' open>
        <Modal.Header>
          <Modal.Title>Physician Organization Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onChange={(formValue, event) => handleChange(formValue as OrganizationSettings, event as ChangeEvent<HTMLInputElement>)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            className='form-edit-data'
            model={companyInfoForm}
          >
            <TextField name='name' value={formValue.name} error={formError.name || dataErrors.name} label='Name of organization' />
            <TextField
              name='description'
              value={formValue.description}
              error={formError.description || dataErrors.description}
              accepter={Textarea}
              label='Description of organization (optional)'
            />
            <div className='organization-file-field'>
              <Uploader
                className={`${
                  (Array.isArray(formValue.logo) && formValue.logo?.length) || (typeof formValue.logo === 'string' && formValue.logo.length)
                    ? 'uploaded'
                    : ''
                }`}
                onChange={uploadLogo}
                listType='picture'
                defaultFileList={
                  Array.isArray(formValue.logo) && formValue.logo.length
                    ? formValue.logo
                    : dataForUpdate.Logo?.url
                    ? [{ name: dataForUpdate.Logo?.originalName || 'name', fileKey: 1, url: dataForUpdate.Logo.url as string }]
                    : []
                }
                removable={true}
                name='Logo'
                shouldQueueUpdate={previewShouldBeShown}
                shouldUpload={() => false}
                action=''
                draggable
                onRemove={handleRemove}
              >
                <div className='uploader-btn'>Upload Logo (optional)</div>
              </Uploader>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {(errorText || isLogoFormatWrong) && <ErrorMessageComponent errorMessage={isLogoFormatWrong || errorText} />}
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close btn-lg' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitDisabled} onClick={handleSubmit}>
              Save & Update Changes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrganizationModal;
