import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { UserFromSettings as User } from '../../models/UserFromSettings';
import { Password } from '../../views/portal-settings/tabs/general/interfaces';
import { UserData } from '../../views/portal-settings/interfaces';
import { NewUser } from '../../views/onboarding/components/new-user/interfaces';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export function registerNewUser(data: NewUser): Promise<{ [key: string]: string | number }> {
  const { firstName, lastName, password, email, confirmPassword, invitationId, isTermsAgreed } = data;

  return request({
    method: 'PUT',
    url: `${SERVER_URL}/onboarding/registration`,
    body: JSON.stringify({
      fName: firstName,
      lName: lastName,
      email,
      password,
      cnfPassword: confirmPassword,
      invitationId,
      isTermsAgreed,
    }),
  });
}

export function inviteUser(email: string, role: string): Promise<UserData> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/onboarding`,
    body: JSON.stringify({
      email,
      role,
    }),
  });
}

export function resendInviteUser(email: string): Promise<UserData> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/onboarding/resend`,
    body: JSON.stringify({
      email,
    }),
  });
}

export function getUsers(signal?: AbortSignal): Promise<User[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/users`);
  return request(options);
}

export function removeUser(id: number): Promise<User> {
  return request({
    method: 'DELETE',
    url: `${SERVER_URL}/users/${id}`,
  });
}

export function editUserInfo(id: number, email: string, role: string): Promise<User> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/users/${id}`,
    body: JSON.stringify({
      role,
      email,
    }),
  });
}

export function checkUserInvitation(email: string): Promise<{ result: boolean }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/available`,
    body: JSON.stringify({ email }),
  });
}

export function changeUserPassword(data: Password): Promise<Password> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/update-password`,
    body: JSON.stringify(data),
  });
}

export function createUserSessionLog(SessionTime: string, UtcOffset: number): Promise<{ id: number | null }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/session-log`,
    body: JSON.stringify({ SessionTime, UtcOffset }),
  });
}
