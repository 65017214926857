import React from 'react';
import { Button, Modal } from 'rsuite';
import { ConfirmModalProps } from '../interfaces';
import { settingsConfirmModal } from '../ModalUtil';

const ConfirmModalComponent: React.FunctionComponent<ConfirmModalProps> = props => {
  const {
    onClose,
    info: { title, text, cancelBtnText, submitBtnText },
    disableButtons = false,
  } = props;

  return (
    <Modal size={'sm'} open onClose={() => onClose(false)}>
      <Modal.Header>
        <Modal.Title>{title && title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='model-desk'>
          <p>{text}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='justify-content-end'>
          <Button classPrefix='btn-model btn-close btn-lg' disabled={disableButtons} onClick={() => onClose(false)}>
            {cancelBtnText ? cancelBtnText : settingsConfirmModal.cancelBtnText}
          </Button>
          <Button classPrefix='btn-model btn-lg' disabled={disableButtons} onClick={() => onClose(true)}>
            {submitBtnText ? submitBtnText : settingsConfirmModal.submitBtnText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModalComponent;
