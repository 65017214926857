import React, { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { Button, IconButton, Table, Tooltip, Whisper, WhisperInstance } from 'rsuite';
import { ArrowRightLine } from '@rsuite/icons';
import PageNextIcon from '@rsuite/icons/PageNext';
import noTastData from '../../assets/svg/no-tast-data.svg';
import { GrEdit } from 'react-icons/gr';
import { NavLink, useLocation } from 'react-router-dom';
import { TableAction } from '../../enum/TableActionEnum';
import ScanRequestsStatus from '../scan-requests-status';
import { transformDate } from '../../utils/TimeUtil';
import { ColumnInterface, TableProps, RowDataType } from './interfaces';
import { highlightText } from './TableUtil';
import FileViewer from '../doc-viewer-modal';
import { FileView } from '../file-viewer/interfaces';
import { chooseStatus, TaskStatuses } from '../../views/tasks/TasksUtil';
import { SortType } from '../../models/General';
import RenderSelect from './RenderSelect';
import {
  DEFAULT_MIN_COLUMN_TABLE_HEIGHT,
  DEFAULT_MIN_COLUMN_WIDTH,
  MAX_COLUMN_TABLE_HEIGHT,
  MIN_WINDOW_WIDTH_4K,
} from '../../utils/GeneralUtil';
import SaveTaskNotesModalComponent from '../task-notes-modal';
import { TaskNote } from '../../models/TaskNote';

const { Column, HeaderCell, Cell } = Table;

const TableComponent: React.FunctionComponent<TableProps> = props => {
  const {
    data,
    columns,
    noLastCellIcon,
    heightColumn,
    onRowClick,
    onSort,
    sort,
    searchQuery,
    customHeight,
    defaultWidth,
    selectOptions,
    actionComponentRender,
    onSelect,
    onAction,
    isEdit = true,
    widthColumnAction = 215,
    isRemove = true,
    wordWrap,
    loading,
    renderEmpty,
  } = props;

  const [sortColumn, setSortColumn] = useState<string>(sort?.field || '');
  const [sortType, setSortType] = useState<SortType>(sort?.order);
  const [viewerOpen, setViewerOpen] = useState<boolean>(false);
  const [activeSelectId, setActiveSelectId] = useState<number | null>(null);
  const [file, setFile] = useState<FileView>({ url: '', name: '' });
  const [isPopoverShow, setIsPopoverShow] = useState<boolean>(false);
  const { pathname } = useLocation();
  const whisperRef = useRef<WhisperInstance>(null);
  const [taskNote, setTaskNote] = useState<TaskNote>({ ID: null, MFPTaskNotes: '' });
  const [taskNoteOpen, setTaskNoteOpen] = useState<boolean>(false);

  const downloadFile = (pdfUrl: string) => {
    window.open(pdfUrl, '_blank');
  };

  const openViewer = (value: string) => {
    setFile({ url: value, name: value });
    setViewerOpen(true);
  };

  const openTaskNote = (taskNote: TaskNote) => {
    setTaskNote(taskNote);
    setTaskNoteOpen(true);
  };

  const changeToSelect = (e: SyntheticEvent, id: number | null) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPopoverShow && id === null) {
      return;
    }

    setActiveSelectId(id);
  };

  const showPopover = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsPopoverShow(true);
  };

  const renderCell = ({ data, column }: { data: RowDataType; column: ColumnInterface }) => {
    const {
      type,
      dataKey,
      statusKey,
      linkUrl,
      url,
      urlName,
      fieldName,
      idKey,
      reasonField,
      infoColor,
      reportsCount,
      onClickHandler,
      usePath = true,
    } = column;
    switch (type) {
      case 'withBoldText':
        if (!data[dataKey]) {
          return '';
        }
        return highlightText(String(data[dataKey]), searchQuery ? searchQuery : '');
      case 'email':
        if (!data[dataKey]) {
          return 'No Email Provided';
        }
        return <a href={`mailto:${data[dataKey]}`}>{data[dataKey]}</a>;
      case 'status': {
        return (
          <ScanRequestsStatus
            tooltipText={reasonField ? (data[reasonField] as string) : ''}
            type={data[dataKey] as string}
            iconColor={infoColor ? (data[infoColor] as string) : ''}
          />
        );
      }
      case 'link': {
        const link = `${usePath ? pathname : ''}${linkUrl || ''}/${data[idKey || dataKey]}`;

        return (
          <span onClick={e => e.stopPropagation()}>
            <NavLink className='rs-btn-link' to={{ pathname: link, state: { pathname: location.pathname } }}>
              {data[dataKey]}
            </NavLink>
          </span>
        );
      }
      case 'scanResult': {
        if (!onClickHandler) {
          return 'pending';
        }

        return (
          <>
            <span className='report-text'>
              {data[dataKey] ? transformDate(data[dataKey] as string) : statusKey && data[statusKey] === 'Closed' ? 'N/A' : 'pending'}
            </span>
            {reportsCount && data[reportsCount] && (
              <>
                <IconButton
                  classPrefix='btn-report'
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClickHandler(data);
                  }}
                ></IconButton>
                <span
                  className='report-number'
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClickHandler(data);
                  }}
                >
                  {data[reportsCount as string]}
                </span>
              </>
            )}
          </>
        );
      }
      case 'download':
        if (url && urlName) {
          return (
            <a href='#' onClick={() => openViewer(String(data[url]))}>
              {data[urlName]}
            </a>
          );
        } else {
          return data[dataKey];
        }
      case 'scanTypeToolTip':
        if (!fieldName || !data[fieldName]) {
          return data[dataKey];
        }

        return (
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>{data[fieldName]}</Tooltip>}>
            <p>{data[dataKey]}</p>
          </Whisper>
        );
      case 'select':
        if (!idKey || !data[idKey] || !selectOptions) {
          return data[dataKey];
        }

        if (data[idKey] !== activeSelectId) {
          return (
            <div style={{ width: '100%' }} onMouseOver={(e: SyntheticEvent) => changeToSelect(e, data[idKey] as number)}>
              {data[dataKey]}
            </div>
          );
        }

        return (
          <Whisper
            placement='autoVerticalStart'
            trigger='click'
            ref={whisperRef}
            onExit={() => setIsPopoverShow(false)}
            speaker={<RenderSelect rowId={data[idKey] as number} onSelect={onSelect} selectOptions={selectOptions} />}
          >
            <div className='select-cell' onClick={showPopover}>
              <span>{data[dataKey]}</span>
              <PageNextIcon className='select-cell-arrow' />
            </div>
          </Whisper>
        );
      case 'taskStatus':
        return <button className={`task-status-button ${chooseStatus(data[dataKey] as TaskStatuses)}`}>{data[dataKey]}</button>;
      case 'capitalize':
        return <span style={{ textTransform: 'capitalize' }}>{data[dataKey]}</span>;
      case 'taskNote':
        if (data['MFPTaskNotes']) {
          return (
            <div className='task-note-container'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>{data['MFPTaskNotes']}</Tooltip>}>
                <span className='task-note'>{data['MFPTaskNotes']}</span>
              </Whisper>
              <a
                className='task-note-link edit-note'
                href='#'
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  openTaskNote({ ID: Number(data.ID), MFPTaskNotes: data.MFPTaskNotes.toString() ?? '' });
                }}
              >
                <i className='task-note-icon'></i>
                Edit
              </a>
            </div>
          );
        }

        return (
          <div className='task-note-container'>
            <a
              className='task-note-link add-note'
              href='#'
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                openTaskNote({ ID: Number(data.ID), MFPTaskNotes: data.MFPTaskNotes.toString() ?? '' });
              }}
            >
              <i className='task-note-icon'></i>
              Add notes
            </a>
          </div>
        );
      default:
        return data[dataKey];
    }
  };

  const handleSortColumn = (sortColumn: string, sortType: SortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);

    if (onSort) {
      onSort({ field: sortColumn, order: sortType });
    }
  };

  const clickAction = (event: SyntheticEvent, actionID: number, rowData: RowDataType) => {
    const { onAction } = props;
    event.preventDefault();
    event.stopPropagation();

    onAction && onAction(actionID, rowData);
  };

  const getData = () => {
    if (sortColumn && sortType && !onSort) {
      return data.sort((a: RowDataType, b: RowDataType) => {
        const x = a[sortColumn];
        const y = b[sortColumn];
        if (sortType === 'asc') {
          return x > y ? 1 : x === y ? 0 : -1;
        } else {
          return y > x ? 1 : x === y ? 0 : -1;
        }
      });
    }
    return data;
  };

  const rowHeight = useMemo(
    () => heightColumn || (window.innerWidth > MIN_WINDOW_WIDTH_4K ? MAX_COLUMN_TABLE_HEIGHT : DEFAULT_MIN_COLUMN_TABLE_HEIGHT),
    [heightColumn],
  );

  const onTaskNoteClose = (wasTaskNoteSaved: boolean, taskNote?: TaskNote) => {
    if (wasTaskNoteSaved) {
      onAction && onAction(TableAction.Edit, taskNote);
    }

    setTaskNoteOpen(false);
  };

  return (
    <div className='table-component'>
      {!data.length && !renderEmpty ? (
        <div className='no-data text-center'>
          <img src={noTastData} alt={`No Data Found`} />
          <p>No Data Found</p>
        </div>
      ) : (
        <Table
          autoHeight={!customHeight}
          height={customHeight}
          rowHeight={rowHeight}
          data={getData()}
          sortColumn={sortColumn}
          sortType={sortType}
          onRowClick={onRowClick}
          onSortColumn={(sc, st) => handleSortColumn(sc, st)}
          wordWrap={wordWrap}
          loading={loading}
          onMouseOut={(e: SyntheticEvent) => changeToSelect(e, null)}
          renderEmpty={renderEmpty}
        >
          {columns.map(column => (
            <Column
              flexGrow={column.flexGrow || 0}
              minWidth={column.minWidth || DEFAULT_MIN_COLUMN_WIDTH}
              width={column.width || defaultWidth || 200}
              key={column.dataKey}
              sortable={column.sortable || false}
            >
              <HeaderCell>
                <Whisper placement='top' trigger='hover' speaker={<Tooltip>{column.tooltip || column.headTitle}</Tooltip>}>
                  <div>{column.headTitle}</div>
                </Whisper>
              </HeaderCell>
              <Cell dataKey={column.dataKey}>{(rowData: RowDataType) => renderCell({ data: rowData, column })}</Cell>
            </Column>
          ))}
          {isEdit || isRemove || actionComponentRender ? (
            <Column width={widthColumnAction} fixed='right'>
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData: RowDataType) => (
                  <>
                    {actionComponentRender && actionComponentRender(rowData)}
                    {isEdit && (
                      <Button classPrefix='btn-edit' onClick={e => clickAction(e, TableAction.Edit, rowData)}>
                        <GrEdit /> Edit
                      </Button>
                    )}
                    {isRemove && (
                      <Button classPrefix='btn-remove' onClick={e => clickAction(e, TableAction.Remove, rowData)}>
                        Remove
                      </Button>
                    )}
                  </>
                )}
              </Cell>
            </Column>
          ) : noLastCellIcon ? null : (
            <Column flexGrow={0.2} width={15} minWidth={15} fixed='right'>
              <HeaderCell> </HeaderCell>
              <Cell className='td-arrow'>
                <ArrowRightLine />
              </Cell>
            </Column>
          )}
        </Table>
      )}
      <FileViewer
        isShow={viewerOpen}
        file={file}
        onDownloadFile={() => downloadFile(file.url as string)}
        onClose={() => setViewerOpen(false)}
      />

      <SaveTaskNotesModalComponent taskNote={taskNote} isShow={taskNoteOpen} onClose={onTaskNoteClose} />
    </div>
  );
};

export default TableComponent;
