import React, { useEffect, useState } from 'react';
import { Form } from 'rsuite';
import { FileType } from 'rsuite/Uploader';
import { saveAs } from 'file-saver';
import TextField from '../../../components/text-field-component';
import { NotesShemas } from '../Schemas';
import { clinicalNotesOptions, FileTypeWithTitle, NotesFormData, NotesProps } from '../interfaces';
import { initNotes } from '../NewScanUtil';
import { checkForWrongFile } from '../../../utils/GeneralUtil';
import { DefaultFormValue, model } from '../../all-scans/scan/tabs/scan-details/ScanDetailsUtil';
import { FormError } from '../../../models/General';
import RadioGroupField from '../../../components/radio-field-component';
import { onLoadFile } from '../../../utils/FileUtils';
import FilesUploader from '../../../components/files-uploader';
import Textarea from '../../../components/textarea';

const Notes: React.FC<NotesProps> = ({ setNotesData, inModal, initData, formRef }) => {
  const [formData, setFormData] = useState<NotesFormData>(initData || initNotes);
  const [formError, setFormError] = useState<FormError>({});

  useEffect(() => {
    if (inModal && initData && formData === DefaultFormValue) {
      setFormData(initData);
    }
  }, [initData]);

  const setData = (data: NotesFormData) => {
    setFormData(data);
    setNotesData(data);
  };

  const editField = (value: string, event: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...formData,
      [event.target.name]: value,
    });
  };

  const checkUploadedNotes = (files: FileTypeWithTitle[]): boolean => {
    if (files && files.length) {
      const isWrongFileExist = checkForWrongFile(files);
      if (isWrongFileExist === false) {
        return true;
      }

      setFormError({ ...formError, fileNoteName: isWrongFileExist });

      return false;
    }

    return true;
  };

  const uploadNotes = async (fileList: Array<FileType>): Promise<void> => {
    delete formError.fileNoteName;
    delete formError.fileListNotes;

    setFormError(formError);
    if (fileList && fileList.length) {
      const newArr: FileTypeWithTitle[] = [...formData.fileListNotes];

      for (let i = 0; i < fileList.length; i++) {
        const item: FileTypeWithTitle = fileList[i] as FileTypeWithTitle;
        if (!formData.fileListNotes[i]) {
          const result = await onLoadFile(item);
          item.id = i;
          item.url = result;
          item.fileTitle = `Clinical note ${i + 1}`;
          item.mimeType = item.blobFile?.type;

          newArr.push(item);
        } else {
          newArr[i].id = i;
        }
      }

      setData({
        ...formData,
        fileListNotes: newArr,
        fileNoteName: fileList[0].name,
      });
    } else {
      setData({
        ...formData,
        fileNoteName: '',
        fileListNotes: [],
      });
    }
  };

  const downloadFile = (file: FileTypeWithTitle): void => {
    if (inModal && file.key !== undefined) {
      window.open(file.url, '_blank');
      return;
    }

    saveAs(file.blobFile as Blob);
  };

  const onChangeRadio = (val: string): void => {
    setData({
      ...formData,
      fileListNotes: [],
      ClinicalNotes: '',
      NotesOptions: val,
    });

    if (val === '0') {
      delete formError.fileNoteName;
    } else {
      delete formError.ClinicalNotes;
    }
  };

  return (
    <Form
      ref={formRef}
      className='form form-new-scan notes-form'
      formValue={formData}
      onCheck={error => setFormError(error)}
      onChange={data => setData(data as NotesFormData)}
      model={inModal ? model : NotesShemas[formData.NotesOptions]}
    >
      <div className='form-container'>
        <h2 className='mb1'>
          <b>Notes</b>
        </h2>
        <div className='form-new-scan-block'>
          <Form.Group className='mb3'>
            <RadioGroupField
              name='NotesOptions'
              label='Clinical notes*:'
              value={formData.NotesOptions}
              data={clinicalNotesOptions}
              onChange={onChangeRadio}
              tooltipText='For insurance prior-authorizations. The more details you provide, the faster we can handle the patient’s
                    prior-authorization with their insurance providers.'
            />
            {formData.NotesOptions === '1' ? (
              <Form.Group className='form-new-scan-group mb2'>
                <TextField
                  accepter={Textarea}
                  onChange={editField}
                  value={formData.ClinicalNotes}
                  error={formError.ClinicalNotes}
                  name='ClinicalNotes'
                />
                {!formError.ClinicalNotes && (
                  <div className='input-form-text'>
                    <p>
                      For insurance prior-authorizations. The more details you provide, the faster we can handle the patient’s
                      prior-authorization with their insurance providers.
                    </p>
                  </div>
                )}
              </Form.Group>
            ) : formData.NotesOptions === '0' ? (
              <div className='form-new-scan-uploader pt2'>
                <FilesUploader
                  fileList={formData.fileListNotes}
                  uploadFiles={uploadNotes}
                  title='Upload clinical notes'
                  updateFiles={(files: Array<FileTypeWithTitle>) => setData({ ...formData, fileListNotes: files })}
                  downloadFileFn={downloadFile}
                  checkFile={checkUploadedNotes}
                  onDelete={() => {
                    setFormData({
                      ...formData,
                      fileNoteName: !formError.fileListNotes ? formData.fileListNotes[0].blobFile?.name : '',
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            {formData.NotesOptions === '0' && (!!formError.fileNoteName || !!formError.fileListNotes) && (
              <div className='error-text-new-scan'>{formError.fileNoteName || formError.fileListNotes}</div>
            )}
          </Form.Group>
          <Form.Group className='form-new-scan-group mb0'>
            <TextField
              accepter={Textarea}
              onChange={editField}
              value={inModal ? formData.LogisticNotes : formData.OtherNotes}
              name={inModal ? 'LogisticNotes' : 'OtherNotes'}
              label='Logistics notes (optional)'
              tooltipText={'Does the patient have allergies or other considerations and/or accommodations our team should consider?'}
            />
          </Form.Group>
        </div>
      </div>
    </Form>
  );
};

export default Notes;
