import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LoginUrl } from '../../../../config/UrlsConfig';
import { Error } from '../../interfaces';
import { errorTextsObject, findErrorDescription } from './ErrorUtil';

const InvalidForgotPasswordFormComponent: React.FunctionComponent<Error> = props => {
  const { errorText } = props;
  const ErrorDescription = useMemo(() => findErrorDescription(errorTextsObject, errorText), [errorText]);

  return (
    <div className='onboarder-wrap'>
      <div className='onboarder-title'>Can’t login?</div>
      <div className='onboarder-form forgot-form'>
        <div className='invalid-content'>
          <div className='invalid-icon'></div>
          <div className='invalid-title'>{errorText}</div>
          <div className='invalid-text text-left'>
            {ErrorDescription}
            <a href='#'> contact our support team.</a>
          </div>
          <div className='form-row-link text-center'>
            <Link to={LoginUrl}>Return to Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidForgotPasswordFormComponent;
