import React from 'react';
import { Whisper, Tooltip, Toggle } from 'rsuite';
import { ToggleFieldProps } from './interfaces';

const ToogleButton: React.FunctionComponent<ToggleFieldProps> = props => {
  const { label, data, tooltipText, handleChange } = props;

  return (
    <>
      <Toggle checked={!!data?.Self} checkedChildren='On' unCheckedChildren='Off' onChange={handleChange} />
      <div className='flex-container'>
        <div className='wrap'>
          <p>{label}</p>
          <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={<Tooltip>{tooltipText}</Tooltip>}>
            <span className='tooltip'></span>
          </Whisper>
        </div>
      </div>
    </>
  );
};

export default ToogleButton;
