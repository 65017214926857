import { RequestScanFormData, ScanResponseType, ScanType, ScanTypeBody, ScanTypeDescription } from './interfaces';
import { SchemaCheckResult } from 'schema-typed';

export const patientAvailablity = [
  {
    label: 'As soon as possible (flexible)',
    value: '1',
    role: 'IsAnyTime',
  },
  {
    label: 'Select custom date and time',
    value: '0',
    role: 'IsAnyTime',
  },
];

export const initPatientInfo = {
  ID: null,
  ZipCode: '',
  State: '',
  Name: '',
  FirstName: '',
  LastName: '',
  MRN: '',
  DOB: '',
  Email: '',
  Phone: '',
  GenderId: null,
  Gender: '',
  PatientId: null,
  UserID: null,
  PreferredLanguageID: null,
  Language: '',
};

export const initScanInfo = {
  SelectScanTypeNameModality: '',
  SelectScanTypeNameBody: '',
  SelectScanTypeNameDetails: '',
  ICD: '',
  PrescriberName: '',
  PrescriberID: null,
  LocationID: null,
  Fax: '',
  Reason: '',
  CPTCode: null,
  ScanServiceID: null,
  ScanServiceIsCleerly: 0,
  physiciansOffice: '',
  TotalPrice: '',
  Fee: '',
  MinPrice: '',
};

export const initNotes = {
  ClinicalNotes: '',
  OtherNotes: '',
  fileListNotes: [],
  NotesOptions: '0',
  ClinicalNotesFile: '',
};

export const initPatientPreferenceData = {
  CardType: '',
  CardLast4: 0,
  PaymentID: '',
  PaymentSecret: '',
  ZipCode: '',
  SelfPay: '',
  fullNameCard: '',
  IsAnyTime: '',
  insuranceProvider: '',
  PolicyNumber: '',
  InsurancePlanID: null,
};

export const scanRequestData: RequestScanFormData = {
  PatientId: null,
  UserID: null,
  ID: null,
  ScanRequestFileNames: null,
  FirstName: '',
  LastName: '',
  Name: '',
  MRN: '',
  DOB: '',
  Email: '',
  Phone: '',
  Gender: '',
  State: '',
  GenderId: null,
  fullNameCard: '',
  CardType: '',
  CardLast4: 0,
  PaymentID: '',
  PaymentSecret: '',
  CPTCode: null,
  ZipCode: '',
  physiciansOffice: '',
  insuranceProvider: '',
  Fax: '',
  ScanServiceID: null,
  ScanServiceIsCleerly: 0,
  PrescriberID: null,
  OwnerID: null,
  LocationID: null,
  ClinicalNotesFile: '',
  SelfPay: null,
  PolicyNumber: null,
  InsurancePlanID: null,
  Reason: '',
  ClinicalNotes: '',
  OtherNotes: '',
  isNote: false,
  IsAnyTime: null,
  StatusID: 2,
  PrescriberName: '',
  OwnerName: '',
  NoteTypeId: 1,
  NoteInfo: '*',
  MfpTestRequestID: null,
  ICD: '',
  SelectScanTypeNameModality: '',
  SelectScanTypeNameBody: '',
  SelectScanTypeNameDetails: '',
  PreferredLanguageID: null,
  Language: '',
  fileListNotes: [],
  AvailableSlots: '',
  AvailableSlotsView: {},
  filePrescriptionName: '',
};

export const scanCoverageSelect = [
  {
    label: 'Insurance',
    value: '0',
    role: 'SelfPay',
  },
  {
    label: `Self-pay`,
    value: '1',
    role: 'SelfPay',
  },
];

export const scanModalContent = {
  title: 'Are You Sure You Want to Change the Scan Type?',
  text: '[changing this file will require you to re-enter payment information copy]',
  submitBtnText: 'Yes, Change Scan Type',
};

export const prescriptionModalContent = {
  title: 'Generate E-Prescription?',
  text: 'Are you sure you want to generate the E-Prescription? This will be attached to the order submitted and if you update the order after generating the e-prescription, you will need to re-generate for the most up to date information.',
  submitBtnText: 'Generate E-prescription',
};

export const leaveModalContent = {
  title: 'Are You Sure You Want to Leave?',
  text: 'The scan request you’ve started has not been saved. If you leave without saving your progress your information will be lost.',
  submitBtnText: 'Yes, leave',
};

export const patientModalContent = {
  title: 'Are You Sure You Want to Leave?',
  text: "Your progress will not be saved. If you abandon the information you've added so far, a new patient will not be added to the portal",
  submitBtnText: 'Yes, leave',
};

// export const timeIntervalToStringMap: Record<string, string> = {
//   '8 AM-11 AM': 'Morning',
//   '11 AM-4 PM': 'Midday',
//   '4 PM-8 PM': 'Evening',
// };

// export const uid = (pre: string | number): string => {
//   return `${pre}_${new Date().getTime()}`;
// };

export const transformScanTypeData = (list: ScanResponseType[]): ScanType[] => {
  const types: Record<string, ScanType> = {};

  list.forEach(item => {
    let scanTypeBodyDescription: ScanTypeDescription | undefined;
    let body: ScanTypeBody | undefined;
    const key = `${item.ScanTypeName} ${item.ScanTypeID}`;

    if (item.ScanDescription) {
      scanTypeBodyDescription = {
        id: item.ScanTypeID,
        label: item.ScanDescription,
        value: item.ScanDescription,
        scanId: item.ScanTypeID,
        ScanServiceID: item.ScanServiceID,
        IsCleerly: item.IsCleerly,
        CPTCode: item.CPTCode,
        TotalPrice: item.TotalPrice,
        Fee: item.Fee,
        MinPrice: item.MinPrice,
        FullName: item.FullName,
      };
    }

    if (types[key]) {
      body = types[key].bodies.find((body: ScanTypeBody) => {
        return body.label === item.ScanServiceName;
      });
    }

    if (!body) {
      body = {
        id: item.ScanTypeID,
        label: item.ScanServiceName,
        value: item.ScanServiceName,
        scanId: item.ScanTypeID,
        ScanServiceID: item.ScanServiceID,
        IsCleerly: item.IsCleerly,
        CPTCode: item.CPTCode,
        TotalPrice: item.TotalPrice,
        Fee: item.Fee,
        MinPrice: item.MinPrice,
        descriptions: [],
      };

      types[key] && types[key].bodies.push(body);
    }

    if (scanTypeBodyDescription !== undefined) {
      body.descriptions.push(scanTypeBodyDescription);
    }

    if (!types[key]) {
      types[key] = {
        id: item.ScanTypeID,
        label: item.ScanTypeName,
        value: item.ScanTypeName,
        scanId: item.ScanTypeID,
        bodies: [body],
      };
    }
  });

  return Object.values(types);
};

export const formatFax = (str: string, mask: string): string => {
  if (mask[0] === str[0]) {
    return str;
  }

  let i = 0;

  const getNextNumber = (str: string, ind: number): { ind: number; char: string } => {
    const char = str[ind];
    if (isNaN(Number(char))) {
      return getNextNumber(str, ind + 1);
    }

    ind = ind + 1;

    return { ind, char };
  };

  return mask.replace(/d/g, () => {
    const { ind, char } = getNextNumber(str, i);

    i = ind;

    return char;
  });
};

// export const preferencesTexts = {
//   error: {
//     IncorrectZipCode: {
//       title: 'Incorrect Zip Code',
//       content: 'Please doublecheck entered Zip Code',
//     },
//
//     ZipCodeRequestFail: {
//       title: 'Failed to check Zip Code',
//       content: 'Please try again later',
//     },
//
//     InsuranceList: {
//       title: 'Failed to load insurance list',
//       content: 'Please try again later',
//     },
//   },
// };

export const notesTexts = {
  error: {
    requiredField: 'Please provide clinical notes or upload notes as a file or provide them later (options above)',
  },
};

export const prescriptionTexts = {
  error: {
    requiredField: 'Please upload clinical prescription or Generate E-prescription by the buttons above',
  },
};

// export const PrescriptionDefaultValue = {
//   fileListPrescription: null,
// };

const getElementWithValidationError = (checkResult: SchemaCheckResult<Partial<RequestScanFormData>, string> | null): Element | null => {
  if (!checkResult) {
    return null;
  }

  const fieldWithError = Object.keys(checkResult).find(
    field => checkResult[field as keyof Partial<RequestScanFormData>]?.hasError === true,
  );

  if (fieldWithError) {
    let element;
    if (fieldWithError === 'fileListNotes') {
      element = document.querySelector('form.notes-form');
    } else if (fieldWithError === 'filePrescriptionName') {
      element = document.querySelector('form.new-scan-prescription');
    } else {
      element = document.getElementById(fieldWithError);
    }

    return element;
  }
  return null;
};

export const scrollToElement = (checkResult: SchemaCheckResult<Partial<RequestScanFormData>, string> | string | null): void => {
  if (typeof checkResult === 'string') {
    const element = document.querySelector(checkResult);
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    return;
  }

  const element = getElementWithValidationError(checkResult);
  if (element) {
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
};
