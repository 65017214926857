import React, { useRef, useState } from 'react';
import { Form, Button } from 'rsuite';
import TextField from '../../../../../../components/text-field-component';
import { model, FormDefaultValue } from '../CommentsUtil';
import { FormInstance } from 'rsuite/Form';
import ErrorMessageComponent from '../../../../../../components/error-message-component';
import { addComment } from '../../../../../../api/comments/CommentsAPI';
import { useError } from '../../../../../../utils/UseError';
import { FormStructureComments, AddCommentProps } from '../interfaces';
import { EventType } from '../../../../../../models/General';
import Textarea from '../../../../../../components/textarea';
import { useSignal } from '../../../../../../utils/UseSignal';

const AddCommentFormComponent: React.FunctionComponent<AddCommentProps> = props => {
  const { getAllComments, scanId } = props;
  const [formValue, setFormValue] = useState<FormStructureComments>(FormDefaultValue);
  const [formError, setFormError] = useState<FormStructureComments>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const form = useRef<FormInstance | null>(null);
  const signal = useSignal();

  const handleSubmit = () => {
    if (!form.current || !form.current.check()) {
      return;
    }

    if (formValue.NoteText) {
      addComment(formValue.NoteText, formValue.notify, scanId)
        .then(() => {
          if (!signal.aborted) {
            getAllComments();
            setFormValue(FormDefaultValue);
          }
        })
        .catch(err => {
          if (!signal.aborted) {
            setErrorText(err, formValue);
          }
        });
    }
  };

  const handleOnChange = (e: EventType) => {
    const checked = e.target.checked ? 1 : 0;
    setFormValue({ ...formValue, notify: checked });
  };

  const handleCommentChange = (value: string) => {
    errorText && setErrorText(null);
    setFormValue({ ...formValue, NoteText: value });
    setDataErrors({ NoteText: '' });
  };

  return (
    <Form ref={form} onCheck={formError => setFormError(formError)} formValue={formValue} model={model}>
      <TextField
        name='NoteText'
        accepter={Textarea}
        error={formError.NoteText || dataErrors.NoteText}
        label='To add a new comment, start typing here...'
        onChange={handleCommentChange}
        value={formValue.NoteText}
      />
      {errorText && <ErrorMessageComponent errorMessage={errorText} />}
      <div className={`btn-list row ${formValue.NoteText === '' ? 'disabled' : ''}`}>
        <div className='form-checkbox'>
          <input type='checkbox' id='notify' checked={formValue.notify} name='notify' onChange={handleOnChange} />
          <label htmlFor='notify'>Notify Medmo Care Team about this comment</label>
        </div>
        <Button className='rs-btn rs-btn-model btn-md' onClick={handleSubmit}>
          Comment
        </Button>
      </div>
    </Form>
  );
};

export default AddCommentFormComponent;
