import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { taskComponentsSwitcher } from './TaskUtil';
import LoaderComponent from '../../../components/loader-component';
import { FullTaskInfo, LocationState } from '../interfaces';
import InvalidPageComponent from '../../invalid';
import { ContentInvalidTask } from '../../invalid/invalidUtil';
import { getTaskInfo } from '../../../api/tasks/TasksApi';
import { useSignal } from '../../../utils/UseSignal';
import { TasksUrl } from '../../../config/UrlsConfig';
import HeaderComponent from '../../../components/header-component';

const TaskComponent: React.FunctionComponent = () => {
  const [taskData, setTaskData] = useState<FullTaskInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [taskName, setTaskName] = useState('');
  const [showInvalidPage, setShowInvalidPage] = useState(false);
  const history = useHistory<LocationState>();
  const { taskId } = useParams<{ taskId: string }>();
  const locationFrom = history.location?.state?.from;
  const filter = locationFrom?.search || '';
  const hash = locationFrom?.hash || '';
  const signal = useSignal();

  useEffect(() => {
    getAllTaskInfo();
  }, [taskId]);

  const getAllTaskInfo = (isComplete?: boolean) => {
    getTaskInfo(+taskId, filter, signal)
      .then(res => {
        if (!signal.aborted) {
          const nextId = isComplete && taskData?.nextTaskID ? taskData?.nextTaskID : null;
          setTaskData({ ...res, nextTaskID: res.nextTaskID || nextId });
        }
      })
      .catch(error => {
        if (!signal.aborted) {
          if (error.status === 404) {
            setShowInvalidPage(true);
            return;
          }
          setTaskData(null);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const liftGoBackTextUp = useCallback((text: string) => {
    setTaskName(text);
  }, []);

  const nextTask = () => {
    const { nextTaskID } = taskData || {};
    if (nextTaskID) {
      history.push(`/tasks/${nextTaskID}`, { from: locationFrom });
    }
  };

  const historyBack = () => {
    if (locationFrom?.pathname) {
      history.push(`${locationFrom.pathname}${hash}${filter}`);
    } else {
      history.push(`${TasksUrl}${filter}`);
    }
  };

  if (isLoading) {
    return (
      <div className='task-wrapper flex-1'>
        <LoaderComponent className={'loader-block-center'} />
      </div>
    );
  }

  if (showInvalidPage) {
    return <InvalidPageComponent id={taskId} content={ContentInvalidTask} />;
  }

  if (!taskData) {
    return null;
  }

  return (
    <div className='task-wrapper flex-1'>
      <HeaderComponent
        title={taskName}
        nextText={taskData?.nextTaskID ? `Next task #${taskData?.nextTaskID}` : ''}
        goBack={historyBack}
        goNext={nextTask}
      />
      <div className='task-content row'>{taskData && taskComponentsSwitcher(taskData, liftGoBackTextUp, getAllTaskInfo)}</div>
    </div>
  );
};

export default TaskComponent;
