import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/logo_main_white.svg';
import { LoginSchema } from './LoginUtil';
import { PrivacyUrl, BaaUrl, TermsUrl, ForgotPasswordUrl } from '../../config/UrlsConfig';
import { getNextLink, resetNextLink } from '../../utils/DeepLinkingUtil';
import { LoginFormData, RouterProps } from './interfaces';
import { Button, Form, FormInstance } from 'rsuite';
import TextFieldPassword from '../../components/text-field-password';
import TextField from '../../components/text-field-component';
import { FormError } from '../../models/General';

const LoginComponent: React.FunctionComponent<RouterProps> = props => {
  const { authenticate } = props;
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: '',
    remember: false,
  });
  const [formError, setFormError] = useState<FormError>({});
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const formRef = useRef<FormInstance | null>(null);

  const onSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    localStorage.removeItem('isSessionExpired');
    authenticate(formData).then(
      () => {
        const nextPath = getNextLink();
        if (nextPath && nextPath !== '/login') {
          history.push(nextPath);
          resetNextLink();
          return;
        }
      },
      (e: React.SetStateAction<Error | null>) => {
        setError(e);
      },
    );
  };

  const handleClick = () => {
    history.push(ForgotPasswordUrl);
  };

  const isDisabled = useMemo(() => !formData.email || !formData.password, [formData]);

  return (
    <div className='onboarder-main'>
      <header className='onboarder-head'>
        <div className='container'>
          <div className='onboarder-head_logo'>
            <img src={logo} alt='' />
          </div>
        </div>
      </header>
      <main className='main'>
        <div className='container'>
          <div className='onboarder-title'>Welcome back to Medmo!</div>
          <div className='onboarder-form login-form'>
            <Form
              model={LoginSchema}
              onChange={formValue => {
                setError(null);
                setFormData(formValue as LoginFormData);
              }}
              onCheck={formError => setFormError(formError)}
              formValue={formData}
              ref={formRef}
            >
              <TextField name='email' label='Email*' error={formError.email} value={formData.email} placeholder='Email' />
              <TextFieldPassword
                name='password'
                label='Password*'
                error={formError.password}
                value={formData.password}
                placeholder='Password'
                errorPlacement='bottomStart'
              />
              <div className='login-form-error'>{error?.message}</div>
              <Button className='btn-model' onClick={onSubmit} disabled={isDisabled}>
                Login
              </Button>
              <a className='login-form-forgot' onClick={handleClick}>
                Can’t login?
              </a>
            </Form>
          </div>
        </div>
      </main>
      <footer className='onboarder-footer'>
        <div className='container'>
          <ul>
            <li>
              {/*    <a href='#'>Terms & Privacy</a>*/}
              {/* <a href={PrivacyUrl} className='terms-link' rel='noreferrer' target='_blank'>
                Privacy Policy
              </a> */}
            </li>
            <li>
              {/*    <a href='#'>Need Help? Contact Medmo Customer Support</a>*/}
              <p>
                <a href={PrivacyUrl} className='terms-link' rel='noreferrer' target='_blank'>
                  Privacy Policy
                </a>
                {'  '}
                <a href={TermsUrl} className='terms-link' rel='noreferrer' target='_blank'>
                  Terms of Use
                </a>
                {'  '}
                <a href={BaaUrl} className='terms-link' rel='noreferrer' target='_blank'>
                  BAA
                </a>
              </p>
            </li>
            <li>
              {/* <a href={BaaUrl} className='terms-link' rel='noreferrer' target='_blank'>
                BAA
              </a> */}
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default LoginComponent;
