import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'rsuite';
import TextField from '../../../components/text-field-component';
import { saveAs } from 'file-saver';
import DetailsTaskTabComponent from './DetailsTaskTabComponent';
import { GeneralTaskFormStructure, TaskProps } from '../interfaces';
import { FileView } from '../../../components/file-viewer/interfaces';
import { FileType } from 'rsuite/Uploader';
import { FileTypeWithTitle } from '../../new-scan/interfaces';
import InfoModalComponent from '../../../components/modals/info-modal';
import { checkForEmptyString, checkForWrongFile } from '../../../utils/GeneralUtil';
import { Texts } from '../../../components/modals/interfaces';
import { GeneralTaskDefaultValue, TaskStatuses } from '../TasksUtil';
import { FormError } from '../../../models/General';
import { FormInstance } from 'rsuite/Form';
import { completeStandartTask, uploadTaskDocuments } from '../../../api/tasks/TasksApi';
import { RequestFiles } from '../../../api/all-scans/interfaces';
import FilesUploader from '../../../components/files-uploader';
import { GeneralTaskSchema } from '../Schemas';
import StaticGeneralTaskComponent from './StaticGeneralTaskComponent';
import Textarea from '../../../components/textarea';
import { onLoadFile, renameFile } from '../../../utils/FileUtils';
import { useSignal } from '../../../utils/UseSignal';

const GeneralTaskComponent: React.FC<TaskProps> = ({ taskData, liftGoBackTextUp, getAllTaskInfo }) => {
  const { special, task, testRequest, insurance, files, Patient } = taskData;
  const [formData, setFormData] = useState<GeneralTaskFormStructure>(GeneralTaskDefaultValue);
  const [errorText, setErrorText] = useState<Texts | null>(null);
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const fileLoadedCount = useRef<number>(0);
  const formRef = useRef<FormInstance | null>(null);
  const { notes, fileList } = formData;
  const signal = useSignal();

  useEffect(() => {
    const defaultFileListNotes: (FileTypeWithTitle & FileView)[] = files.map((el, i) => ({
      key: el.key,
      url: el.url as string,
      name: el.originalName || el.key,
      fileTitle: el.fileTitle,
      id: i,
    }));
    liftGoBackTextUp(`Standard Task for Scan Request #${task.TestRequestID}`);
    setFormData({
      ...formData,
      notes: checkForEmptyString(task.TaskNotes),
      fileList: defaultFileListNotes,
    });
  }, [task]);

  const areFilesDifferent = (currentFilesList: Array<FileTypeWithTitle & FileView>, initialFilesList: RequestFiles[]): boolean => {
    if (initialFilesList.length !== currentFilesList.length) {
      return true;
    } else {
      const initialFilesKeys = initialFilesList.map(({ originalName, key }) => originalName || key).sort();
      const currentFilesKeys = currentFilesList.map(({ name }) => name).sort();
      return initialFilesKeys.some((fileKey, index) => fileKey !== currentFilesKeys[index]);
    }
  };

  const isSubmitEnabled = useMemo(() => {
    const isDifferentFromInitialState =
      checkForEmptyString(notes) !== checkForEmptyString(task.TaskNotes) || areFilesDifferent(fileList, files);
    return isDifferentFromInitialState && !!formData.notes;
  }, [formData]);

  const checkUploadedNotes = (files: FileTypeWithTitle[]): boolean => {
    if (files && files.length) {
      const isWrongFileExist = checkForWrongFile(files);
      if (isWrongFileExist === false) {
        return true;
      }

      setFormError({ ...formError, fileNoteName: isWrongFileExist });

      return false;
    }

    return true;
  };

  const fileLoadFinish = () => {
    if (!isLoading) {
      return;
    }
    fileLoadedCount.current += 1;
    if (fileLoadedCount.current === fileList.length) {
      fileLoadedCount.current = 0;
    }
  };

  const uploadNewFiles = async (uploadFiles: Array<FileType>): Promise<void> => {
    if (uploadFiles && uploadFiles.length) {
      const newArr: (FileTypeWithTitle & FileView)[] = [...fileList];

      setFormError({});

      for (let i = 0; i < uploadFiles.length; i++) {
        const item: FileTypeWithTitle = uploadFiles[i] as FileTypeWithTitle;
        if (!fileList[i]) {
          const result: string = await onLoadFile(item);
          item.id = i;
          item.url = result;
          item.fileTitle = item.name as string;
          item.mimeType = item.blobFile?.type;

          newArr.push(item);
        } else {
          newArr[i].id = i;
        }
      }
      setFormData({
        ...formData,
        fileList: newArr,
        fileNoteName: uploadFiles[0].name,
      });
    } else {
      setFormData({
        ...formData,
        fileList: [],
        fileNoteName: '',
      });
    }
  };

  const downloadFile = (doc: FileTypeWithTitle): void => {
    if (!doc.blobFile) {
      saveAs(doc.url as string, doc.name);
      return;
    }
    saveAs(doc.blobFile as Blob);
  };

  const onCloseErrorModal = (): void => {
    setErrorText(null);
  };

  const uploadFiles = async (): Promise<RequestFiles[]> => {
    const fileData = new FormData();
    const { fileList } = formData;
    const files: FileTypeWithTitle[] = [];
    const keyFiles: RequestFiles[] = [];

    if (!fileList || !fileList.length) {
      return [];
    }

    for (let i = 0; i < fileList.length; i++) {
      const file: FileTypeWithTitle = fileList[i] as FileTypeWithTitle;
      if (file.blobFile) {
        files.push(file);
        const newFile = await renameFile(file.blobFile);
        fileData.append('files', newFile);
      }
      if (file.key) {
        keyFiles.push({
          key: file.key,
          fileTitle: file.fileTitle,
          originalName: file.name as string,
        });
      }
    }

    if (files.length) {
      return uploadTaskDocuments(fileData).then(response => {
        const res: RequestFiles[] = files.map((el, i: number) => {
          return {
            key: response[i].key,
            fileTitle: el.fileTitle,
            originalName: response[i].originalName || (el.name as string),
          };
        });
        return [...res, ...keyFiles];
      });
    }

    return [];
  };

  const submit = async () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }
    if (fileList) {
      setIsLoading(true);
      const fileList = await uploadFiles();
      completeStandartTask({
        currTaskID: task.ID,
        newTaskNotes: notes,
        files: fileList,
      })
        .then(() => {
          if (!signal.aborted) {
            getAllTaskInfo(true);
            setIsLoading(false);
          }
        })
        .catch(e => {
          if (!signal.aborted) {
            setErrorText({ title: 'Complete error', content: e.message });
          }
        });
    }
  };

  const refreshTaskDetails = () => {
    getAllTaskInfo(false);
  };

  return (
    <>
      <div className='task-content_form'>
        {!!checkForEmptyString(task.TaskInstruction) && (
          <>
            <div className='title'>Task Instructions:</div>
            <div className='text'>{task.TaskInstruction}</div>
          </>
        )}
        <Form
          className='task-form_pending'
          onChange={formValue => setFormData(formValue as GeneralTaskFormStructure)}
          onCheck={formError => setFormError(formError)}
          formValue={formData}
          model={GeneralTaskSchema}
          ref={formRef}
        >
          {TaskStatuses.Open === task.TaskStatus ? (
            <div className='content'>
              <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                <TextField
                  name='notes'
                  accepter={Textarea}
                  appearance='subtle'
                  label='Notes for the Medmo Care Team*:'
                  labelClassName='task-label'
                  error={formError.notes}
                  value={formData.notes}
                />
              </Form.Group>
              <div className='form-new-scan-uploader'>
                <div className='title'>Upload any relevant files: (Optional)</div>
                <FilesUploader
                  fileList={fileList}
                  uploadFiles={uploadNewFiles}
                  title='Upload files'
                  updateFiles={(files: Array<FileTypeWithTitle>) => {
                    setFormData({ ...formData, fileList: files as Array<FileTypeWithTitle & FileView> });
                    setFormError({ ...formError, fileNoteName: '' });
                  }}
                  downloadFileFn={downloadFile}
                  checkFile={checkUploadedNotes}
                  fileLoadFinish={fileLoadFinish}
                />
                {!!formError.fileNoteName && <div className='error-text-new-scan'>{formError.fileNoteName}</div>}
              </div>
              <Button className='btn-lg rs-btn-model' onClick={submit} disabled={isLoading || !isSubmitEnabled}>
                {TaskStatuses.Open === task.TaskStatus ? 'Mark as Completed' : 'Update & Save'}
              </Button>
            </div>
          ) : (
            <StaticGeneralTaskComponent fileList={files} notes={task.TaskNotes || ''} />
          )}
        </Form>
      </div>
      <div className='task-details'>
        <DetailsTaskTabComponent data={{ special, task, testRequest, insurance, Patient }} taskDetailsChanged={refreshTaskDetails} />
      </div>
      {errorText && <InfoModalComponent type={errorText ? `error` : 'success'} texts={errorText} onClose={onCloseErrorModal} />}
    </>
  );
};

export default GeneralTaskComponent;
