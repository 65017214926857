import { Schema } from 'rsuite';
import { Patient } from '../../models/Patient';
const { StringType, DateType, NumberType } = Schema.Types;

export const PatientSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required').containsLetter('Please add a valid name'),
  LastName: StringType().isRequired('This field is required').containsLetter('Please add a valid last name'),
  DOB: DateType()
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)')
    .max(new Date(), 'Date of birth cannot be a future date'),
  GenderId: NumberType().isRequired('This field is required'),
  Phone: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number.')
    .isRequired('This field is required'),
  Email: StringType().isEmail('Please enter a valid email address.'),
  State: StringType().containsLetter('Please add a valid state'),
  ZipCode: NumberType('Please add a valid zip code (enter 5 digits)').pattern(/^\d{5}$/, 'Zip code should contain 5 digits'),
  MRN: StringType(),
});

export const PatientFormValue: Patient = {
  ID: null,
  FirstName: '',
  LastName: '',
  MRN: null,
  DOB: null,
  Gender: '',
  GenderId: null,
  Phone: null,
  ZipCode: null,
  Email: '',
  State: null,
  UserID: null,
  PatientId: null,
  Name: '',
  PreferredLanguageID: null,
};

export const SuccessRemoveModalText = {
  title: 'Patient Information Successfully Removed',
  content: 'Patient Information has been successfully removed from the portal.',
};

export const SuccessAddModalText = {
  title: 'Patient Information Successfully Added',
  content: 'A new patient has been successfully added to the portal. You can now manage scan requests for this patient.',
};

export const SuccessEditModalText = {
  title: 'Patient Information Successfully Edited',
  content: 'Patient Information has been successfully edited.',
};

export const confirmModalText = {
  title: 'Remove Patient from Medmo for Physicians?',
  text: (name: string | null): string => {
    const textName = name ? name : '';
    return `Are you sure you’d like to remove ${textName} from the portal? You will no longer be able to view their information and/or request scans on their behalf.`;
  },
};

export const ConfirmModalCancelText = {
  cancelAddingPatient: {
    title: `Are you sure you want to leave?`,
    text: `Your progress will not be saved. If you abandon the information you’ve added so far, a new patient will not be added to the portal.`,
    cancelBtnText: 'Cancel',
    submitBtnText: 'Yes, Leave',
  },
};
