import React from 'react';
import { AppProps } from '../../components/router-component/interfaces';
import SettingsAdminWrapperComponent from './components/SettingsAdminWrapper';
import SettingsWrapperComponent from './components/SettingsWrapper';

const SettingsComponent: React.FunctionComponent<AppProps> = props => {
  const { isAdmin } = props;

  return (
    <div className='page-content'>
      <div className='nav-top row'>
        <h1 className='h1'>Portal Settings</h1>
      </div>
      {isAdmin ? <SettingsAdminWrapperComponent /> : <SettingsWrapperComponent {...props} />}
    </div>
  );
};

export default SettingsComponent;
