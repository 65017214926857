import React, { useState, useMemo, MouseEvent, useEffect } from 'react';
import { Button, Uploader, Form, Whisper, Tooltip } from 'rsuite';
import { FileType } from 'rsuite/Uploader';
import { saveAs } from 'file-saver';
import ConfirmModalComponent from '../../../components/modals/confirm-modal-component';
import { disableEPrescription, ePrescription as GetEPrescription } from '../../../api/e-prescription/EPrescriptionAPI';
import { SERVER_URL } from '../../../config/ServerConfig';
import InfoModalComponent from '../../../components/modals/info-modal';
import { prescriptionModalContent } from '../NewScanUtil';
import { checkForWrongFile } from '../../../utils/GeneralUtil';
import { EPrescription, FileTypeWithTitle, ModalError, PrescriptionProps } from '../interfaces';
import RenameFileModal from './RenameFileModal';
import FileViewer from '../../../components/doc-viewer-modal';
import { FileView } from '../../../components/file-viewer/interfaces';
import { FilePreviewerThumbnail } from '../../../components/file-viewer';
import { FormError } from '../../../models/General';
import { PrescriptionSchema } from '../Schemas';
import { fileModalContent, fileRenameModalText } from '../../../utils/FileUtil';
import Trash from '@rsuite/icons/Trash';
import { onLoadFile } from '../../../utils/FileUtils';
import useListVisible from '../../../utils/useListVisible';
import { convertToLocalDate } from '../../../utils/TimeUtil';

const Prescription: React.FC<PrescriptionProps> = ({
  formData,
  setFormData,
  fileListPrescription,
  setFileListPrescription,
  formRef,
  validate,
}) => {
  const [formError, setFormError] = useState<FormError>({});
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isRename, setIsRename] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<{ filePrescriptionName?: string }>({});
  const [renameSuccessModal, setRenameSuccessModal] = useState<boolean>(false);
  const [modalError, setModalError] = useState<ModalError>({
    title: '',
    content: '',
  });
  const { listRef, fileOpenedKey, setFileOpenedKey } = useListVisible(-1);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [file, setFile] = useState<FileView>({ url: '', name: '', mimeType: '' });
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);

  useEffect(() => {
    if (!formData.ScanRequestFileNames?.length && !fileListPrescription?.length) {
      setFormValue({ filePrescriptionName: '' });
    }
  }, [fileListPrescription, formData]);

  const checkPrescription = (): void => {
    if (!validate()) {
      return;
    }
    if (!formData.ScanRequestFileNames) {
      setShowConfirmModal(true);
    }
  };

  const checkUploadedPrescription = (files: Array<FileType>): boolean => {
    const isWrongFileExist = checkForWrongFile(files as FileTypeWithTitle[]);

    if (isWrongFileExist === false) {
      return true;
    }

    setFormError({ filePrescriptionName: isWrongFileExist });

    return false;
  };

  const uploadPrescription = async (fileList: Array<FileType>): Promise<void> => {
    setFormError({});
    if (!!fileList && fileList.length) {
      const item: FileTypeWithTitle = fileList[0] as FileTypeWithTitle;

      const result = await onLoadFile(item);
      item.id = 0;
      item.url = result as string;
      item.mimeType = item.blobFile?.type;
      item.fileTitle = `${formData.PatientId}_mfp_${convertToLocalDate(new Date(), 'DDMMYYHHmmss')}.${item.name?.slice(-3)}`;

      setFileListPrescription([item]);

      setFormValue({ filePrescriptionName: fileList[0].name });
    } else {
      setFileListPrescription([]);
      setFormValue({ filePrescriptionName: '' });
    }
  };

  const generatePrescription = (): void => {
    const request: EPrescription = {
      PatientId: formData.PatientId,
      ScanServiceID: formData.ScanServiceID,
      ICD: formData.ICD,
      Fax: formData.Fax,
      Reason: formData.Reason,
      OtherNotes: formData.OtherNotes,
      ZipCode: formData.ZipCode,
      CPTCode: formData.CPTCode as number,
      FirstName: formData.FirstName as string,
      LastName: formData.LastName as string,
      DOB: formData.DOB,
      Phone: formData.Phone,
      PrescriberName: formData.PrescriberName,
      LocationID: formData.LocationID,
      PrescriberID: formData.PrescriberID,
    };

    setFormError({});

    if (formData.Email) {
      request.Email = formData.Email;
    }

    GetEPrescription(request)
      .then(res => {
        // if (!Array.isArray(res) || !res.length) {
        //   return;
        // }

        // const ScanRequestFileNames = res.map((file, i) => ({
        //   id: i,
        //   key: file.key,
        //   originalName: file?.originalName,
        //   fileTitle: `Medmo Generated e-prescription ${i}`,
        //   url: `${SERVER_URL}/scan-request/download-pdf/${file.key}`,
        //   name: file.key,
        //   mimeType: 'application/pdf',
        // }));

        const ScanRequestFileNames = [
          {
            id: 1,
            key: res.key,
            originalName: res?.originalName,
            fileTitle: res.key,
            url: `${SERVER_URL}/scan-request/download-pdf/${res.key}`,
            name: res.key,
            mimeType: 'application/pdf',
          },
        ];

        setFormData({
          ...formData,
          ScanRequestFileNames,
        });

        setFormValue({ filePrescriptionName: res.key });
      })
      .catch(() =>
        setModalError({
          title: 'Error',
          content: 'Failed to generate E Prescription',
        }),
      )
      .finally(() => {
        setIsSubmitPressed(false);
        setShowConfirmModal(false);
      });
  };

  const toogleUploadFileMenuOpen = (value?: number): void => {
    setFileOpenedKey(value || -1);
  };

  const deleteFile = (e: MouseEvent): void => {
    setFormError({});
    e.preventDefault();

    setConfirmDelete(true);
  };

  const renameFile = (e: MouseEvent): void => {
    e.preventDefault();

    setFileOpenedKey(-1);
    setIsRename(true);
  };

  const onCloseRenameModal = (): void => {
    setIsRename(false);
  };

  const onUpdateRenameModal = (val: string): void => {
    if (!val) {
      return;
    }

    if (formData.ScanRequestFileNames) {
      const res = formData.ScanRequestFileNames.map(item => (val ? { ...item, fileTitle: val } : item));
      setFormData({
        ...formData,
        ScanRequestFileNames: res,
      });
      return;
    }

    setFileListPrescription([
      {
        ...fileListPrescription[0],
        fileTitle: val,
      },
    ]);

    setIsRename(false);
    setRenameSuccessModal(true);
  };

  const downloadFile = (e: { preventDefault: () => void } | null, file: FileTypeWithTitle): void => {
    e && e.preventDefault();
    setFileOpenedKey(-1);

    if (formData.ScanRequestFileNames) {
      window.open(file.url, '_blank');
      return;
    }

    if (!fileListPrescription.length) {
      return;
    }

    saveAs(file.blobFile as Blob);
  };

  const onCloseConfirmDeleteModal = (res: boolean) => {
    if (res) {
      setIsSubmitPressed(true);
      setFormValue({ filePrescriptionName: '' });

      if (fileListPrescription && fileListPrescription.length) {
        setFileListPrescription([]);
        setFormData({ ...formData });
      }

      if (!!formData.ScanRequestFileNames && formData.ScanRequestFileNames.length) {
        const eData = { keys: [formData.ScanRequestFileNames[0].key] };

        disableEPrescription(eData)
          .then(() => {
            setFormData({
              ...formData,
              ScanRequestFileNames: null,
            });
          })
          .catch(() =>
            setModalError({
              title: 'Error',
              content: 'Failed to disable E Prescription',
            }),
          )
          .finally(() => {
            setIsSubmitPressed(false);
            setConfirmDelete(false);
          });
      } else {
        setConfirmDelete(false);
        setIsSubmitPressed(false);
      }
    } else {
      setConfirmDelete(false);
    }
  };

  const onCloseModal = (res: boolean): void => {
    if (res) {
      setIsSubmitPressed(true);
      generatePrescription();
    } else {
      if (!isSubmitPressed) {
        setShowConfirmModal(false);
      }
    }

    setShowConfirmModal(false);
  };

  const onCloseErrorModal = (): void => {
    setModalError({
      title: '',
      content: '',
    });
  };

  const prescription = useMemo(() => formData.ScanRequestFileNames || fileListPrescription.length > 0, [formData, fileListPrescription]);

  const previewFile = (file: FileTypeWithTitle): void => {
    setFile({
      url: file.url as string,
      name: file.fileTitle as string,
      mimeType: formData.ScanRequestFileNames ? file.mimeType : file.blobFile?.type,
    });

    setIsShow(true);
  };

  const onCloseFileViewer = (): void => {
    setIsShow(false);
  };

  const onDownload = (): void => {
    if (formData.ScanRequestFileNames) {
      const file = formData.ScanRequestFileNames && formData.ScanRequestFileNames[0];

      if (file) {
        downloadFile(null, file);
      }
    } else {
      downloadFile(null, fileListPrescription[0]);
    }
  };

  const shouldUpload = (e: MouseEvent) => {
    if (!validate(true)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <>
      <Form
        ref={formRef}
        className='form form-new-scan new-scan-prescription'
        formValue={formValue}
        onCheck={error => setFormError(error)}
        model={PrescriptionSchema}
      >
        <div className='form-container'>
          <h2 className='mb1'>
            <b>Prescription</b>
          </h2>
          <div className='form-new-scan-block scan--prescription'>
            <div className='form-new-scan-inner-holder'>
              <div className={`row  ${prescription ? 'hideSomeChildren ' : ''}`}>
                <div className='title'>Need prescription?</div>
                <div>
                  <Whisper
                    placement='top'
                    controlId='control-id-hover'
                    trigger='hover'
                    speaker={
                      <Tooltip>We will generate e-prescription base on the information entered, or you can upload a prescription</Tooltip>
                    }
                  >
                    <span className='tooltip'></span>
                  </Whisper>
                </div>
                <Form.Group className='form-new-scan-group form-new-scan-prescription'>
                  <div className='inner-wrapper'>
                    {!prescription ? (
                      <Button onClick={checkPrescription} type='button'>
                        <span className='icon-receipts'></span>
                        Generate E-prescription
                      </Button>
                    ) : (
                      formData.ScanRequestFileNames?.map(file => (
                        <div className='rs-uploader-file-item-content epx' key={file.key}>
                          <span className='preview'>
                            <FilePreviewerThumbnail file={file} style={{ width: '100%', height: '100%' }} />
                          </span>
                          <span onClick={() => previewFile(file)}>{file.fileTitle}</span>
                          <a href='#' className='uploader-delete-btn' onClick={e => deleteFile(e)}>
                            <Trash /> delete file
                          </a>
                          <div className='dots'>
                            <span className='dots__text' data-list='list' onClick={() => toogleUploadFileMenuOpen(file.id as number)}>
                              ...
                            </span>
                            {fileOpenedKey === file.id && (
                              <ul className='dots__list' ref={listRef}>
                                <li>
                                  <a href='#' onClick={e => renameFile(e)}>
                                    Rename File Description
                                  </a>
                                </li>
                                <li>
                                  <a href='#' onClick={e => downloadFile(e, file)}>
                                    Download File
                                  </a>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Form.Group>
                {!prescription && <span className='form-new-scan-divider'>OR</span>}
                <div className={`form-new-scan-uploader ${prescription ? 'hideSomeChildren ' : ''}`}>
                  <Uploader
                    multiple={false}
                    shouldUpload={() => false}
                    action=''
                    removable={true}
                    fileList={fileListPrescription}
                    onChange={uploadPrescription}
                    shouldQueueUpdate={checkUploadedPrescription}
                    draggable
                    className={formData.ScanRequestFileNames && formData.ScanRequestFileNames[0] ? 'filled' : ''}
                    renderFileInfo={(f: FileType) => {
                      const file: FileTypeWithTitle = f as FileTypeWithTitle;

                      if (!file.fileTitle) {
                        return <></>;
                      }

                      return (
                        <>
                          <span className='preview'>
                            <FilePreviewerThumbnail file={file as FileView} style={{ width: '100%', height: '100%' }} />
                          </span>
                          <span onClick={() => previewFile(file)}>{file.fileTitle}</span>
                          <a href='#' className='uploader-delete-btn' onClick={deleteFile}>
                            <Trash /> delete file
                          </a>
                          <div className='dots'>
                            <span
                              className='dots__text'
                              data-list='list'
                              onClick={() => toogleUploadFileMenuOpen(fileOpenedKey === -1 ? 1 : -1)}
                            >
                              ...
                            </span>
                            {fileOpenedKey === 1 && (
                              <ul className='dots__list' ref={listRef}>
                                <li>
                                  <a href='#' onClick={renameFile}>
                                    Rename File Description
                                  </a>
                                </li>
                                <li>
                                  <a href='#' onClick={e => downloadFile(e, file)}>
                                    Download File
                                  </a>
                                </li>
                              </ul>
                            )}
                          </div>
                        </>
                      );
                    }}
                  >
                    <Button style={{ display: `${!prescription ? 'block' : 'none'}` }} onMouseDown={shouldUpload}>
                      <span className='icon-upload'></span>Upload Prescription
                    </Button>
                  </Uploader>
                </div>
              </div>
            </div>
            {formError.filePrescriptionName && <div className='error-text-new-scan'>{formError.filePrescriptionName}</div>}
          </div>
        </div>
        {confirmDelete && (
          <ConfirmModalComponent info={fileModalContent} disableButtons={isSubmitPressed} onClose={onCloseConfirmDeleteModal} />
        )}
        {renameSuccessModal && (
          <InfoModalComponent type={`success`} texts={fileRenameModalText} onClose={() => setRenameSuccessModal(false)} />
        )}
        {modalError.content && <InfoModalComponent type={`error`} texts={modalError} onClose={onCloseErrorModal} />}
        {isRename && <RenameFileModal onClose={onCloseRenameModal} onUpdate={onUpdateRenameModal} />}
        {showConfirmModal && (
          <ConfirmModalComponent info={prescriptionModalContent} disableButtons={isSubmitPressed} onClose={onCloseModal} />
        )}
        <FileViewer isShow={isShow} onDownloadFile={onDownload} file={file} onClose={() => onCloseFileViewer()} />
      </Form>
    </>
  );
};

export default Prescription;
