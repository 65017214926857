import LogoScan from '../../assets/svg/placeholder-scan.svg';
import LogoPatient from '../../assets/svg/placeholder-patient.svg';
import LogoTask from '../../assets/svg/no-tast-data.svg';

export const ContentInvalidScan = {
  title: `Invalid Access for Scan Request`,
  text: `It looks like you don’t have access to this page! `,
  logo: LogoScan,
};

export const ContentInvalidPatient = {
  title: `Invalid Access for Patient`,
  text: `It looks like you don’t have access to this page! `,
  logo: LogoPatient,
};

export const ContentInvalidTask = {
  title: `Invalid Access for Task`,
  text: `It looks like you don’t have access to this page! `,
  logo: LogoTask,
};
