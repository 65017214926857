import React from 'react';
import { StaticGeneralTaskComponentProps } from '../interfaces';
import FileThumbnailsList from '../../../components/file-viewer/FileThumbnailsList';

const StaticGeneralTaskComponent: React.FC<StaticGeneralTaskComponentProps> = ({ fileList, notes }) => {
  return (
    <div className='content-data'>
      <div className='content-data-filed'>
        <div className='label'>Notes for the Medmo Care Team:</div>
        <div className='content-data-filed_content'>
          <p>{notes}</p>
        </div>
      </div>
      <div className='content-data-filed'>
        <div className='label'>Uploaded relevant files:</div>
        <FileThumbnailsList filesList={fileList} />
      </div>
    </div>
  );
};

export default StaticGeneralTaskComponent;
