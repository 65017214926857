import { SERVER_URL } from '../../../../../config/ServerConfig';
import { transformDate } from '../../../../../utils/TimeUtil';
import { Report } from './types';

export const columns = [
  {
    dataKey: 'PathToFile',
    width: 200,
    align: 'left',
    type: 'download',
    url: 'Url',
    urlName: 'ReportName',
    headTitle: 'Report',
    flexGrow: 1,
  },
  {
    dataKey: 'DateCreated',
    width: 150,
    align: 'left',
    headTitle: 'Upload date',
    flexGrow: 1,
  },
];

const getReportNameAndUrl = (report: Report): { ReportName: string; Url: string | null } => {
  const ReportName = report.Report || '';
  const [ext] = report.PathToFile.match(/\.[a-z]+$/i) || [];
  if (!ext) {
    return {
      ReportName,
      Url: null,
    };
  }
  const result = {
    ReportName,
    Url: `${SERVER_URL}/reports/download/${report.PathToFile}`,
  };
  if (report.Report.endsWith(ext)) {
    return result;
  }
  if (ext === '.jpeg' && report.Report.endsWith('.jpg')) {
    return result;
  }
  if (ext === '.jpg' && report.Report.endsWith('.jpeg')) {
    return result;
  }
  return {
    ...result,
    ReportName: ReportName + ext,
  };
};

export const transformReport = (reports: Report[]): Report[] => {
  return reports.map((report: Report) => ({
    ...report,
    ...getReportNameAndUrl(report),
    DateCreated: transformDate(report.DateCreated),
  }));
};
