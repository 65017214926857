import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Panel, Dropdown, Button } from 'rsuite';
import TableComponent from '../../components/table-component';
import { useHistory } from 'react-router-dom';
import { columns, initDataForFilter, searchBarColumns } from './AllScansUtil';
import { getScans } from '../../api/all-scans/AllScansAPI';
import LoaderComponent from '../../components/loader-component';
import { useLocation } from 'react-router';
import { convertToQueryString, getUrlParams } from '../../utils/GeneralUtil';
import SearchBarComponent from '../../components/search-bar-component';
import { RowDataType, SortDataType } from '../../components/table-component/interfaces';
import CustomPagination from '../../components/custom-pagination';
import FilterScansModal from './filter-scans-modal';
import { FilterDataType } from './filter-scans-modal/interfaces';
import { FilterType, ExpandScanResult } from './interfaces';
import useDynamicTableHeight from '../../utils/useDynamicTableHeight';
import { PageSpacingTable } from '../../enum/TableActionEnum';
import CreatScanButton from '../../components/new-scan-button';
import { updateScanList } from '../../utils/GeneralUtil';
import { getInfoBySearchQuery } from '../../api/search/SearchBarAPI';
import { SelectOption } from '../../models/General';
import { getSelectOptions } from '../../api/filters/FiltersAPI';
import { AllScansFilterData } from '../../api/filters/interfaces';
import { useSignal } from '../../utils/UseSignal';
import { convertToLocalDate } from '../../utils/TimeUtil';
import useDefaultColumnWidth from '../../utils/UseColumnWidth';
import { SERVER_URL } from '../../config/ServerConfig';

const AllScansComponent: React.FunctionComponent = () => {
  const params = new URLSearchParams(useLocation().search);
  const paramsObj = getUrlParams(params?.toString());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState(+paramsObj?.currentPage || 1);
  const [data, setData] = useState<ExpandScanResult[]>([]);
  const [pages, setPages] = useState<number>(0);
  const [filteredTotal, setFilteredTotal] = useState<number>(0);
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const [numShow, setNumShow] = useState<number>(+paramsObj?.perPage || 25);
  const [filterData, setFilterData] = useState<Record<string, SelectOption[]>>({
    scanStatuses: [],
    prescribers: [],
    owners: [],
    locations: [],
  });
  const [filters, setFilters] = useState<string>(
    convertToQueryString({
      status: paramsObj?.status,
      from: paramsObj?.from,
      to: paramsObj?.to,
      ownerIds: paramsObj?.ownerIds,
      locationIds: paramsObj?.locationIds,
      prescriberIds: paramsObj?.prescriberIds,
    }) || '',
  );
  const [sort, setSort] = useState<SortDataType>({});
  const signal = useSignal();

  const { navRef, size } = useDynamicTableHeight(PageSpacingTable.IsSearchPagination, 535);

  const history = useHistory();

  const initFilterData = useRef<FilterDataType>(initDataForFilter);
  const total = useRef<number>(0);
  const { panelRef, defaultWidth, calculateColumnWidth } = useDefaultColumnWidth(columns.length);

  const getFilterData = () => {
    getSelectOptions<AllScansFilterData>(['Locations4SR', 'Prescribers', 'AllOwners'], signal)
      .then(({ AllOwners, Prescribers, Locations4SR }) => {
        const filledLocationsArray: SelectOption[] = [];
        const filledPrescriberArray: SelectOption[] = [];
        const filledOwnersArray: SelectOption[] = [];

        Object.values(AllOwners).map(el => {
          filledOwnersArray.push({
            label: el.OwnerName,
            value: el.ID,
          });
        });

        Object.values(Locations4SR).map(el => {
          filledLocationsArray.push({
            label: el.Address1 + ' ' + el.City,
            value: el.ID as number,
          });
        });

        Object.values(Prescribers).map(el => {
          filledPrescriberArray.push({
            label: el.FirstName + ' ' + el.LastName,
            value: el.ID as number,
          });
        });

        setFilterData({
          prescribers: filledPrescriberArray,
          owners: filledOwnersArray,
          locations: filledLocationsArray,
        });
      })
      .catch(() => {
        if (!signal.aborted) {
          setFilterData({
            prescribers: [],
            owners: [],
            locations: [],
          });
        }
      });
  };

  const getAllScans = () => {
    setIsLoading(true);

    getScans(
      {
        filter: filters,
        page: activePage,
        perPage: numShow,
        field: sort.field,
        order: sort.order,
      },
      signal,
    )
      .then(result => {
        if (result.Elements.length) {
          const updatedData = updateScanList(result.Elements);
          setData(updatedData);
        } else {
          setData([]);
          calculateColumnWidth();
        }

        total.current = result.Info.TotalCount;
        setFilteredTotal(result.Info.FilteredTotalCount);
        setPages(result.Info.PageCount);

        if (activePage > result.Info.PageCount) {
          setActivePage(1);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          total.current = 0;
          setFilteredTotal(0);
          setPages(0);
          setData([]);
          calculateColumnWidth();
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    let queryString = `currentPage=${activePage}&perPage=${numShow}`;

    if (filters) {
      queryString += `&${filters}`;
    }

    history.push(`/all-scans?${queryString}`);

    getAllScans();
  }, [numShow, filters, activePage, sort]);

  useEffect(() => {
    if (paramsObj) {
      initFilterData.current = {
        status: paramsObj?.status ? paramsObj.status.split(',').map((i: string) => +i) : initFilterData.current.status,
        from: paramsObj?.from || initFilterData.current.from,
        to: paramsObj?.to || initFilterData.current.to,
        owner: paramsObj?.ownerIds ? paramsObj.ownerIds.split(',').map((i: string) => +i) : initFilterData.current.owner,
        location: paramsObj?.locationIds ? paramsObj.locationIds.split(',').map((i: string) => +i) : initFilterData.current.location,
        prescriber: paramsObj?.prescriberIds
          ? paramsObj.prescriberIds.split(',').map((i: string) => +i)
          : initFilterData.current.prescriber,
      };
    }

    getFilterData();
  }, []);

  const handleSelect = (page: number) => {
    setActivePage(page);
  };

  const handleRowClickSearch = (rowData: RowDataType): void => {
    const { TestRequestID } = rowData;
    history.push(`/all-scans/${TestRequestID}`);
  };

  const handleRowClick = (rowData: RowDataType): void => {
    const { ScanID } = rowData;
    history.push(`/all-scans/${ScanID}`);
  };

  const clickSelectShow = (value: number) => {
    setNumShow(value);
    setActivePage(1);
  };

  const applyFilters = (data: FilterDataType) => {
    if (data) {
      if (!Object.keys(data).length) {
        setFilters('');
        setActivePage(1);
        initFilterData.current = initDataForFilter;
        setFilterOpened(false);
        return;
      }

      const newData: FilterType = {
        status: data?.status,
        from: data.from ? convertToLocalDate(data.from, 'YYYY-MM-DD') : undefined,
        to: data.to ? convertToLocalDate(data.to, 'YYYY-MM-DD') : undefined,
        orderFrom: data.orderFrom ? convertToLocalDate(data.orderFrom, 'YYYY-MM-DD') : undefined,
        orderTo: data.orderTo ? convertToLocalDate(data.orderTo, 'YYYY-MM-DD') : undefined,
        ownerIds: data.owner,
        locationIds: data.location,
        prescriberIds: data.prescriber,
      };

      const filter = convertToQueryString(newData);
      setFilters(filter);
      setActivePage(1);
      initFilterData.current = data;
    }

    setFilterOpened(false);
  };

  const exportFile = () => {
    const sorting = {
      sortBy: sort.field,
      order: sort.order,
    };

    let queryString = convertToQueryString(sorting);

    if (filters) {
      queryString += `&${filters}`;
    }

    const link = `${SERVER_URL}/scan-request/scans?${queryString}${queryString ? '&' : ''}downloadCsv=1`;

    window.open(link, '_blank');
  };

  const filterCuttonText = useMemo(
    () => `Showing ${filteredTotal !== total.current ? `${filteredTotal} of` : 'All'} ${total.current} scans`,
    [filteredTotal, total.current],
  );

  return (
    <>
      <div className='page-content-wrap page-all-scans-content'>
        <div className='nav-top_wrap' ref={navRef}>
          <div className='nav-top row'>
            <h1>Scans</h1>
          </div>
          <div className='row justify-content-between_align-center mb2'>
            <div className='input-search'>
              {
                <SearchBarComponent
                  handleRowClick={handleRowClickSearch}
                  placeholderText={'Search for scan requests by ID, patient name or DOB'}
                  columns={searchBarColumns}
                  filters={filters}
                  searchMethod={getInfoBySearchQuery}
                  searchText={'Begin typing a scan request’s ID, a patient’s name or DOB to search for results.'}
                />
              }
            </div>
            <button className='filter-btn' onClick={() => setFilterOpened(true)}>
              {filterCuttonText}
            </button>
            <button className='clear-filter-btn' onClick={() => applyFilters({})} disabled={filters === ''}>
              clear&nbsp;all&nbsp;filters
            </button>
            <div className='btn-add'>
              <CreatScanButton className='rs-btn rs-btn-model btn-w-lg' buttonText='+ Request a New Scan' />
            </div>
          </div>
        </div>
        <Panel className='patient-panel' ref={panelRef}>
          <div className='row pannel-wrap justify-content-between'>
            <Button className='mr1 rs-btn-model btn-md' onClick={() => exportFile()} disabled={filteredTotal === 0}>
              Export
            </Button>
            {filterOpened && (
              <FilterScansModal
                applyFilters={applyFilters}
                filterData={filterData}
                initData={initFilterData.current}
                onClose={() => setFilterOpened(false)}
              />
            )}
            <div className='justify-content-end align-items-center table-options'>
              <Dropdown activeKey={String(numShow)} title={`Show ${numShow} results per page`}>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='25'>
                  Show 25 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='50'>
                  Show 50 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='75'>
                  Show 75 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='100'>
                  Show 100 results per page
                </Dropdown.Item>
              </Dropdown>
              <div className='number-pages'>
                {filteredTotal
                  ? `${numShow * (activePage - 1) + 1} - ${numShow * (activePage - 1) + data.length} of ${filteredTotal}`
                  : '0 - 0 of 0'}
              </div>
              <CustomPagination
                className='paging'
                hidden={pages <= 1 && data.length <= numShow}
                disabled={isLoading ? true : undefined}
                pages={pages}
                activePage={activePage}
                onSelect={(page: number) => handleSelect(page)}
                prev
                last
                next
                first
                size='lg'
                maxButtons={3}
                ellipsis
                boundaryLinks
              />
            </div>
          </div>
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <TableComponent
              columns={columns}
              data={data}
              sort={sort}
              onRowClick={(rowData: RowDataType) => handleRowClick(rowData)}
              onSort={(sortData: SortDataType) => setSort(sortData)}
              noLastCellIcon
              customHeight={size}
              isRemove={false}
              isEdit={false}
              defaultWidth={defaultWidth}
            />
          )}
        </Panel>
      </div>
    </>
  );
};

export default AllScansComponent;
