import { Physician } from './../../models/Physician';
import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { FormStructurePhysicians, PhysicianTypeSchema } from '../../views/portal-settings/tabs/physicians/interfaces';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export function getPhysicians(signal?: AbortSignal): Promise<Physician[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/prescribers`);
  return request(options);
}

export function removePhysician(id: number): Promise<{ ID: number }> {
  return request({
    method: 'DELETE',
    url: `${SERVER_URL}/prescribers/${id}`,
  });
}

export function editPhysicianInfo(id: number, data: FormStructurePhysicians): Promise<{ ID: number }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/prescribers/${id}`,
    body: JSON.stringify(data),
  });
}

export function addPhysician(data: FormStructurePhysicians): Promise<{ ID: number }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/prescribers`,
    body: JSON.stringify(data),
  });
}

export function getPhysicianTypes(signal?: AbortSignal): Promise<PhysicianTypeSchema[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/physician-types`);
  return request(options);
}
