import React, { useEffect, useState } from 'react';
import { Button } from 'rsuite';

import TableComponent from '../../../../components/table-component';
import LocationModal from './LocationModal';
import { PageSpacingTable, TableAction } from '../../../../enum/TableActionEnum';
import RemoveLocationModal from './RemoveLocationModal';
import { SettingsLocationsTexts } from './Texts';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { manageLocationColumns, prepareLocationsData } from './LocationsUtil';
import { getLocations, deleteLocation } from '../../../../api/locations/LocationsAPI';
import ConfirmModalComponent from '../../../../components/modals/confirm-modal-component';
import { ConfirmModalTexts } from '../../PortalSettingsUtil';
import useDynamicTableHeight from '../../../../utils/useDynamicTableHeight';
import LoaderComponent from '../../../../components/loader-component';
import { Location } from '../../../../models/Location';
import { useSignal } from '../../../../utils/UseSignal';
import useDefaultColumnWidth from '../../../../utils/UseColumnWidth';

const SettingsLocationTabComponent: React.FunctionComponent = () => {
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [showRemoveLocationModal, setShowRemoveLocationModal] = useState(false);
  const [infoModalTexts, setInfoModalTexts] = useState<Record<string, string>>({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [tableData, setTableData] = useState<Location[]>([]);
  const { size } = useDynamicTableHeight(PageSpacingTable.Setting, 533);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRemovingLocation, setIsRemovingLocation] = useState(false);
  const signal = useSignal();
  const { panelRef, defaultWidth, calculateColumnWidth } = useDefaultColumnWidth(manageLocationColumns.length, true);

  const getUpdatedLocations = () => {
    setIsLoading(true);
    getLocations(signal)
      .then(res => {
        if (!res.length) {
          setTableData([]);
          calculateColumnWidth();
          return;
        }
        setTableData(prepareLocationsData(res));
      })
      .catch(() => {
        if (!signal.aborted) {
          setTableData([]);
          calculateColumnWidth();
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getUpdatedLocations();
  }, []);

  const onCloseLocationModal = (isUpdate: boolean, isFormEmpty?: boolean) => {
    if (isUpdate) {
      setSelectedLocation(null);
      getUpdatedLocations();
      setShowLocationModal(false);
      return;
    }

    if (!selectedLocation && !isFormEmpty) {
      setShowConfirmModal(true);
    } else {
      setShowLocationModal(false);
    }
  };

  const onCloseConfirmModal = (value: boolean): void => {
    if (value) {
      setShowLocationModal(false);
    }
    setShowConfirmModal(false);
  };

  const onCloseRemoveLocationModal = (event: boolean) => {
    if (event && selectedLocation && selectedLocation.ID) {
      setInfoModalTexts(SettingsLocationsTexts.successRemoveLocation);
      setIsRemovingLocation(true);
      deleteLocation(selectedLocation.ID as number)
        .then(() => {
          setShowInfoModal(true);
          setSelectedLocation(null);
          getUpdatedLocations();
        })
        .catch(err => err)
        .finally(() => {
          setShowRemoveLocationModal(false);
          setIsRemovingLocation(false);
        });
    } else {
      setShowRemoveLocationModal(false);
    }
  };

  const onTableAction = (actionName: TableAction, rowData: React.SetStateAction<Location | null>) => {
    setSelectedLocation(rowData);
    if (actionName === TableAction.Remove) {
      setShowRemoveLocationModal(true);
    }
    if (actionName === TableAction.Edit) {
      setShowLocationModal(true);
    }
  };

  return (
    <>
      {isLoading && <LoaderComponent className={'loader-block-center'} />}
      <div ref={panelRef}>
        <div className='btn-add'>
          <Button
            className='rs-btn-model btn-md'
            onClick={() => {
              setSelectedLocation(null);
              setShowLocationModal(true);
            }}
          >
            + Add Location
          </Button>
        </div>
        {!isLoading && (
          <div>
            <TableComponent
              columns={manageLocationColumns}
              data={tableData}
              onAction={onTableAction}
              customHeight={size}
              defaultWidth={defaultWidth}
            />
          </div>
        )}
        {showLocationModal && <LocationModal onClose={onCloseLocationModal} location={selectedLocation} />}
        {showRemoveLocationModal && (
          <RemoveLocationModal location={selectedLocation} onClose={onCloseRemoveLocationModal} isLoading={isRemovingLocation} />
        )}
        {showInfoModal && <InfoModalComponent type='success' onClose={() => setShowInfoModal(false)} texts={infoModalTexts} />}
        {showConfirmModal && <ConfirmModalComponent onClose={onCloseConfirmModal} info={ConfirmModalTexts.cancelAddingLocation} />}
      </div>
    </>
  );
};

export default SettingsLocationTabComponent;
