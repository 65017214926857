import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Tooltip, Whisper } from 'rsuite';
import { CHARGBEE_ACTIVE } from '../../config/FrontConfig';
import { DashboardUrl, PatientsUrl } from '../../config/UrlsConfig';
import { UserContextType, UserContext } from '../../contexts/UserContext';
import { AccountStatuses } from '../../models/User';
import InfoModalComponent from '../modals/info-modal';
import { Props, trialExpiredTexts } from './newScanButtonUtil';

const CreatScanButton: React.FunctionComponent<Props> = ({ className, buttonText, patientId, goBackLink, disabled }) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const currentUser = useContext<UserContextType>(UserContext);
  const history = useHistory();

  const AccountStatus: AccountStatuses = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return currentUser.PO.Access.AccountStatus;
    }

    return AccountStatuses.Active;
  }, [currentUser]);

  const IsChargebeeUsed: boolean = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return !!currentUser.PO.IsChargebeeUsed;
    }

    return false;
  }, [currentUser]);

  const handleNewScanRequest = (): void => {
    if (AccountStatus === AccountStatuses.Expired && CHARGBEE_ACTIVE && IsChargebeeUsed) {
      setModalShow(true);
      return;
    }
    if (patientId) {
      history.push(`/create-new-scan?patientId=${patientId}`, { pathname: PatientsUrl });
      return;
    }
    if (goBackLink) {
      history.push('/create-new-scan', { pathname: goBackLink });
      return;
    }
    history.push('/create-new-scan', DashboardUrl);
  };

  return (
    <>
      {disabled ? (
        <Whisper placement='bottom' speaker={<Tooltip>Patient is removed from the system by a user</Tooltip>}>
          <span>
            <Button appearance='primary' className={className} disabled style={{ pointerEvents: 'none' }}>
              {buttonText}
            </Button>
          </span>
        </Whisper>
      ) : (
        <Button appearance='primary' className={className} onClick={handleNewScanRequest}>
          {buttonText}
        </Button>
      )}
      {modalShow && <InfoModalComponent onClose={() => setModalShow(false)} type='warning' texts={trialExpiredTexts} />}
    </>
  );
};

export default CreatScanButton;
