import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Uploader, ButtonToolbar } from 'rsuite';
import TextField from '../../../../components/text-field-component';
import { DefaultOrganizationFormValue } from './NewOrganizationUtil';
import { FormInstance } from 'rsuite/Form';
import { formOnboarder } from './NewOrganizationUtil';
import { FileType } from 'rsuite/Uploader';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { addOrganizationLogo } from '../../../../api/organization/OrganizationAPI';
import { checkFileExtension, checkFileSize } from '../../../../utils/GeneralUtil';
import { NewOrganization, Organization } from './interfaces';
import { FileTypeWithTitle } from '../../../new-scan/interfaces';
import { wrongLogoTexts } from '../../../../utils/Texts';
import { useError } from '../../../../utils/UseError';
import Textarea from '../../../../components/textarea';

const NewOrganizationOnBoardingComponent: React.FunctionComponent<NewOrganization> = props => {
  const { onBack, onSubmit, setIsLoading, organizationValue, setOrganizationValue } = props;
  const [formValue, setFormValue] = useState<Organization>(DefaultOrganizationFormValue);
  const [formError, setFormError] = useState<Organization>({});
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [logoErrorText, setLogoErrorText] = useError(null);
  const formRef = useRef<FormInstance | null>(null);

  useEffect(() => {
    if (organizationValue) {
      setFormValue(organizationValue);

      if (organizationValue.logoData.length) {
        setIsDisabled(true);
      }
    }
  }, []);

  const checkLogo = (files: FileType[]): boolean => {
    const isFormatCorrect = checkFileExtension(files[0] as FileTypeWithTitle);
    const isSizeCorrect = !checkFileSize(files[0] as FileTypeWithTitle);

    if (!isSizeCorrect || !isFormatCorrect) {
      const text = !isFormatCorrect ? wrongLogoTexts.wrongFormat : wrongLogoTexts.wrongSize;
      setLogoErrorText(text);
      return false;
    }

    return true;
  };

  const handleChange = (picture: FileType[]): void => {
    if (picture[0] && !checkLogo(picture)) {
      return;
    }

    setFormValue({ ...formValue, logoData: picture });
    setIsDisabled(!isDisabled);
    setLogoErrorText(null);
  };

  const handleSubmit = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }

    setIsLoading(true);

    if (formValue.logoData && formValue.logoData.length) {
      const imageData = new FormData();

      imageData.append('files', formValue.logoData[0].blobFile);

      addOrganizationLogo(imageData)
        .then((response: string[]) => {
          onSubmit(formValue, response[0]);
        })
        .catch(e => setLogoErrorText(e))
        .finally(() => setIsLoading(false));
    } else {
      onSubmit(formValue, '');
    }
  };

  return (
    <div className='onboarder-wrap'>
      <div className='onboarder-title'>{'Welcome to Medmo!'}</div>
      <div className='onboarder-form onboarder-form_org'>
        <Form
          ref={formRef}
          onChange={formValue => setFormValue(formValue)}
          onCheck={formError => setFormError(formError)}
          formValue={formValue}
          model={formOnboarder}
        >
          <TextField error={formError.name} name='name' accepter={Input} label='Name of organization*' value={formValue.name} />
          <TextField
            error={formError.description}
            name='description'
            label='Description of organization (optional)'
            accepter={Textarea}
            value={formValue.description}
          />
          <div className='form-onboarding-uploader'>
            <Uploader
              onChange={handleChange}
              action=''
              listType='picture'
              defaultFileList={organizationValue?.logoData || []}
              fileList={formValue?.logoData}
              name='file-2'
              autoUpload={false}
              draggable
              disabled={isDisabled}
              multiple={false}
            >
              <div hidden={formValue?.logoData.length}>
                <span className='icon-download'></span>
                Click or Drag files to this area to upload
              </div>
            </Uploader>
            {logoErrorText && <ErrorMessageComponent errorMessage={logoErrorText} />}
          </div>
          <ButtonToolbar>
            <Button
              classPrefix='btn-model btn-close'
              onClick={() => {
                onBack();
                setOrganizationValue(formValue);
              }}
            >
              Go Back to Previous Screen
            </Button>
            <Button disabled={!formValue.name} classPrefix='btn-model' onClick={handleSubmit}>
              Complete Account Setup
            </Button>
          </ButtonToolbar>
        </Form>
      </div>
    </div>
  );
};

export default NewOrganizationOnBoardingComponent;
