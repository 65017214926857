import React from 'react';
import { PreviewBarProps } from './interfaces';
import { Button } from 'rsuite';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';

const PreviewBar: React.FC<PreviewBarProps> = ({ onDownload, onZoomIn, onZoomOut }) => {
  return (
    <div className='preview-bar'>
      <div className='preview-bar-rigth'>
        <Button onClick={onZoomOut}>
          <BsZoomOut />
        </Button>
        <Button onClick={onZoomIn}>
          <BsZoomIn />
        </Button>
        <Button onClick={onDownload}>
          <FileDownloadIcon />
        </Button>
      </div>
    </div>
  );
};

export default PreviewBar;
