import React, { useState } from 'react';
import { Button, Modal } from 'rsuite';
import { LocationProps } from './interfaces';

const RemoveLocationModal: React.FunctionComponent<LocationProps & { isLoading: boolean }> = props => {
  const { onClose, location, isLoading } = props;
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);

  const handleHide = () => {
    if (!isSubmitPressed) {
      onClose(false);
    }
  };

  const handleSubmit = () => {
    setIsSubmitPressed(true);
    onClose(true);
  };

  return (
    <div>
      <Modal size={'sm'} open onClose={handleHide}>
        <Modal.Header>
          <Modal.Title>Remove {location?.Name} from Medmo for Physicians? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='model-desk'>
            <p>Are you sure you’d like to remove {location?.Name} from Medmo for Physicians?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close' onClick={() => onClose(false)} disabled={isLoading}>
              Cancel
            </Button>
            <Button classPrefix='btn-model' onClick={handleSubmit} disabled={isLoading}>
              Yes, Remove
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RemoveLocationModal;
