export const SettingsLocationsTexts = {
  successAddLocation: {
    title: 'Location Successfully Added',
    content: 'You have successfully added this location from the portal.',
  },
  successEditLocation: {
    title: 'Location Information Successfully Edited',
    content: 'You have successfully updated this location from the portal.',
  },
  successRemoveLocation: {
    title: 'Location Successfully Removed',
    content: 'You have successfully removed this location from the portal.',
  },
};
