import { FiEye, FiEyeOff } from 'react-icons/fi';
import React, { useMemo, useState } from 'react';
import { Form } from 'rsuite';
import { TextFieldProps } from './interfaces';

const regs = [/^.{8,}$/, /[A-Z]/, /[\d]/, /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/];

const TextFieldPassword: React.FunctionComponent<TextFieldProps> = props => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const { name, label, accepter, error, className, value, rules, placeholder, errorPlacement, withoutFocus = false } = props;
  const [focus, setFocus] = useState<boolean>(false);
  const [val, setValue] = useState<string>(value);

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }

    return null;
  }, [error]);

  const validate = (type: string) => {
    let result = false;
    switch (type) {
      case 'length':
        result = regs[0].test(val || '');
        break;
      case 'capital':
        result = regs[1].test(val || '');
        break;
      case 'number':
        result = regs[2].test(val || '');
        break;
      case 'special':
        result = regs[3].test(val || '');
        break;
    }

    return result;
  };

  return (
    <div className={`input-wrap ${className ? className : ''} ${rules && 'input-password'}`}>
      <Form.Group
        controlId={name}
        className={`${val || !!value || placeholder ? 'show' : ''} ${focus ? 'focus' : ''} ${errorMessage ? 'error' : ''} anim-placeholder`}
      >
        <Form.ControlLabel>{!errorMessage || !!errorPlacement ? label : ''} </Form.ControlLabel>
        <Form.Control
          onFocus={() => (withoutFocus ? null : setFocus(true))}
          onBlur={() => setFocus(false)}
          onChange={setValue}
          name={name}
          accepter={accepter}
          type={passwordShown ? 'text' : 'password'}
          {...props}
          errorPlacement={errorPlacement || 'topStart'}
        />
        <div className='password-view' onClick={() => setPasswordShown(!passwordShown)}>
          {passwordShown ? <FiEye /> : <FiEyeOff />}
          <span>View</span>
        </div>
        {rules === 'true' && (
          <ul className='password-valid-list'>
            <li className={validate('length') ? 'show' : ''}>At least 8 characters</li>
            <li className={validate('special') ? 'show' : ''}>At least 1 special character</li>
            <li className={validate('capital') ? 'show' : ''}>At least 1 capital letter</li>
            <li className={validate('number') ? 'show' : ''}>At least 1 number</li>
          </ul>
        )}
      </Form.Group>
    </div>
  );
};

export default TextFieldPassword;
