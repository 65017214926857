import React from 'react';
import { useHistory } from 'react-router';
import { ActionButton } from '../action-button-component';
import { ActionComponentProps } from '../table-component/interfaces';
// import { availabelActionsForTaskTypeID, AvailableTaskTypeIDs, TaskAction, AvailableTaskActionIDs } from './ActionUtil';

const TaskActionComponent: React.FC<ActionComponentProps> = ({ rowData }) => {
  const history = useHistory();

  const handleClick = (): void => {
    const { ID } = rowData;
    history.push(`/tasks/${ID}`, { from: history.location });
  };

  return <ActionButton clickAction={() => handleClick()} rowData={rowData} buttonText='Manage' actionKey={5} isDisabled={false} />;
};

export default TaskActionComponent;
