import React, { useEffect, useRef } from 'react';
import visa from '../../../../../assets/svg/payment/Visa.svg';
import { getPortalSettings } from '../../../../../api/billing/BillingAPI';
import { BillingDashboardComponentProps, CardInstance, CardPortal, ExtendedWindowType } from '../interfaces';
import { planText } from '../BillingUtil';
import { useSignal } from '../../../../../utils/UseSignal';

const BillingDashboardComponent: React.FunctionComponent<BillingDashboardComponentProps> = ({
  customer,
  cardData,
  subscriptionData,
  updateCustomer,
  updateUser,
}) => {
  const cbPortal = useRef<CardPortal | null>(null);
  const isSubscriptionVisited = useRef<boolean>(false);
  const signal = useSignal();

  const getPortal = () => {
    const cbInstance: CardInstance = (window as ExtendedWindowType).Chargebee.getInstance();
    cbInstance.setPortalSession(() => {
      return getPortalSettings(customer.id, signal).then(res => res.portal_session);
    });
    cbPortal.current = cbInstance.createChargebeePortal();
  };

  useEffect(() => {
    if (!customer.id) {
      return;
    }

    getPortal();
  }, [customer]);

  const openBillingHistoryPortal = () => {
    cbPortal.current?.openSection(
      {
        sectionType: (window as ExtendedWindowType).Chargebee.getPortalSections().BILLING_HISTORY,
      },
      {},
    );
  };

  const openPaymentInfoPortal = () => {
    cbPortal.current?.open({
      // paymentSourceUpdate: status => {
      //   if (status === 'success') {
      //     updateCustomer();
      //   }
      // },
      visit: sectionType => {
        if (sectionType === 'sub_details') {
          isSubscriptionVisited.current = true;
        }
      },
      close: () => {
        if (isSubscriptionVisited.current) {
          updateUser();
        } else {
          updateCustomer();
        }
      },
    });
  };

  return (
    <>
      <h2>{planText[subscriptionData.status] || ''}</h2>
      <div className='billing-content'>
        <div className='billing-panel'>
          <div className='billing-panel_wrap'>
            <button className='btn-edit big' onClick={openPaymentInfoPortal}>
              <span className='icon-edit'></span>Edit billing details
            </button>
            <div className='title'>Billing History</div>
            <div className='payment-row row'>
              <div className='payer col'>
                <div className='title'>Payment method</div>
                <div className='row'>
                  <div className='payer-logo'>
                    {/* there are many other payment systems in the folder svg/payment/*/}
                    <img src={visa} alt='Visa' />
                  </div>
                  <div className='payer-info'>
                    <div className='card'>{cardData.masked_number}</div>
                    <p>
                      {cardData.first_name} {cardData.last_name}
                    </p>
                    <p>
                      Expires {cardData.expiry_month}/{cardData.expiry_year}
                    </p>
                  </div>
                </div>
              </div>
              <div className='period col'>
                <div className='title'>Billing period</div>
                <p>Monthly</p>
              </div>
            </div>
            <button className='btn-view' onClick={openBillingHistoryPortal}>
              View full history
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingDashboardComponent;
