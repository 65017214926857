export type Props = {
  className?: string;
  buttonText: string;
  patientId?: string;
  goBackLink?: string;
  disabled?: boolean;
};

export const trialExpiredTexts: Record<string, string> = {
  title: 'Trial period expired',
  content: `Your free trial to our 'Unlimited Plan' features has expired.`,
};
