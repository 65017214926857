import { useRef, useState, useEffect, LegacyRef } from 'react';

const useDynamicTableHeight = (
  initialHeightTable: number,
  minHeight = 0,
): { navRef: LegacyRef<HTMLDivElement> | undefined; size: number } => {
  const [size, setSize] = useState<number>(window.innerHeight - initialHeightTable);
  const navRef = useRef<HTMLDivElement>(null);
  const Element = !!document.querySelector('.modelInfo-hint');

  const updateSize = () => {
    if (navRef && navRef.current) {
      calcHeight(window.innerHeight, navRef.current.clientHeight + initialHeightTable, Element);
    } else {
      calcHeight(window.innerHeight, initialHeightTable, Element);
    }
  };
  const calcHeight = (a: number, b: number, c: boolean): void => {
    c ? setSize(Math.max(minHeight, a - (b + 46))) : setSize(Math.max(minHeight, a - b));
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { navRef, size };
};

export default useDynamicTableHeight;
