import { SERVER_URL } from '../../config/ServerConfig';
import { ServerUserResponse } from '../../models/User';
import { request } from '../Request';

export function checkAuth(): Promise<ServerUserResponse> {
  return request({
    url: `${SERVER_URL}/authenticate-me`,
    method: 'GET',
  });
}
