import React, { useState } from 'react';
import { Button, Modal } from 'rsuite';
import { removeUser } from '../../../../api/users/UsersAPI';
import { useSignal } from '../../../../utils/UseSignal';
import { RemoveUserProps } from './interfaces';

const RemoveUserModal: React.FunctionComponent<RemoveUserProps> = props => {
  const { onClose, userName, userId, setIsLoading } = props;
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const signal = useSignal();

  const handleSubmit = () => {
    setIsLoading(true);
    setIsSubmitPressed(true);

    if (userId) {
      removeUser(userId)
        .then(() => {
          onClose(true);
        })
        .catch(err => err)
        .finally(() => {
          if (!signal.aborted) {
            setIsLoading(false);
            setIsSubmitPressed(false);
          }
        });
    }
  };

  const handleHide = () => {
    if (!isSubmitPressed) {
      onClose(false);
    }
  };

  return (
    <div>
      <Modal size={'sm'} open onClose={handleHide}>
        <Modal.Header>
          <Modal.Title>Remove {userName} from Medmo for Physicians? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='model-desk'>
            <p>Are you sure you’d like to remove {userName} from Medmo for Physicians? They will no longer have access to the portal.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close btn-lg' disabled={isSubmitPressed} onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitPressed} onClick={handleSubmit}>
              Yes, Remove
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RemoveUserModal;
