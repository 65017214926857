import { Schema } from 'rsuite';
import { notesTexts, prescriptionTexts } from './NewScanUtil';

const { StringType, DateType, NumberType, ArrayType } = Schema.Types;

export const GeneralSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required'),
  MRN: StringType(),
  DOB: DateType().isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)'),
  GenderId: NumberType().isRequired('Please select a gender'),
  Phone: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number')
    .isRequired('This field is required'),
  Email: StringType().isEmail('Please enter a valid email address.').isRequired('This field is required'),
});

export const PatientInfoSchema = Schema.Model({
  Name: StringType().isRequired('Please select a patient or create a new one'),
});

export const PatientScanInfoSchemaWithDetails = Schema.Model({
  PrescriberID: NumberType().isRequired('Please select Physician'),
  LocationID: NumberType().isRequired('Please select Location'),
  SelectScanTypeNameModality: StringType().isRequired('Please select Scan modality'),
  SelectScanTypeNameBody: StringType().isRequired('Please select Body part'),
  SelectScanTypeNameDetails: StringType().isRequired('Please select Scan details'),
  Fax: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid fax number.')
    .isRequired('Please enter Fax number'),
  Reason: StringType().isRequired('This information is required'),
  ICD: StringType().isRequired('This field is required'),
});

export const PatientScanInfoSchema = Schema.Model({
  PrescriberID: NumberType().isRequired('Please select Physician'),
  LocationID: NumberType().isRequired('Please select Location'),
  SelectScanTypeNameModality: StringType().isRequired('Please select Scan modality'),
  SelectScanTypeNameBody: StringType().isRequired('Please select Body part'),
  Fax: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid fax number')
    .isRequired('Please enter Fax number'),
  Reason: StringType().isRequired('This information is required'),
  ICD: StringType().isRequired('This field is required'),
});

export const PrescriptionSchema = Schema.Model({
  filePrescriptionName: StringType().isRequired(prescriptionTexts.error.requiredField),
});

export const NotesSchemaWithFileNotes = Schema.Model({
  fileListNotes: ArrayType().addRule((value, data) => {
    if (!value.length && data) {
      return false;
    }

    return true;
  }, notesTexts.error.requiredField),
  OtherNotes: StringType(),
  fileNoteName: StringType().isRequired(notesTexts.error.requiredField),
});

export const NotesSchemaWithTextNotes = Schema.Model({
  ClinicalNotes: StringType().isRequired('Please provide clinical notes or upload notes as a file or provide them later (options above)'),
  OtherNotes: StringType(),
});

export const NotesSchemaWithNoRequired = Schema.Model({
  OtherNotes: StringType(),
});

export const ScanOwnerSchema = Schema.Model({
  OwnerID: NumberType().isRequired('This field is required'),
});

export const NotesShemas: Record<string, any> = {
  '0': NotesSchemaWithFileNotes,
  '1': NotesSchemaWithTextNotes,
  '2': NotesSchemaWithNoRequired,
};

export const PatientPreferencesSelfPaySchema = Schema.Model({
  ZipCode: NumberType('Please add a valid zip code').isRequired('This field is required'),
  IsAnyTime: StringType().isRequired('Please choose at least one option'),
  SelfPay: StringType().isRequired('Please choose at least one option'),
  fullNameCard: StringType().isRequired('This field is required'),
  PaymentID: StringType(),
  PaymentSecret: StringType(),
  CardType: StringType().isRequired('This field is required'),
  CardLast4: NumberType(),
});

export const PatientPreferencesInsuranceSchema = Schema.Model({
  ZipCode: NumberType('Please add a valid zip code').isRequired('This field is required'),
  IsAnyTime: StringType().isRequired('Please choose at least one option'),
  SelfPay: StringType().isRequired('Please choose at least one option'),
  insuranceProvider: StringType().isRequired('This field is required'),
  InsurancePlanID: NumberType(),
  PolicyNumber: StringType().isRequired('This field is required'),
});

export const DateSchema = Schema.Model({
  AvailableSlots: StringType().isRequired('Please select dates and times'),
});

export const SendMailSchema = Schema.Model({
  SendEmail: StringType().isRequired('This field is required').isEmail('Please enter a valid email address.'),
});

export const renameFileSchema = Schema.Model({
  FileTitle: StringType(),
});
