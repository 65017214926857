import React, { useMemo, useRef, useState } from 'react';
import { ModalGenApiTokenProps } from './interfaces';
import { Button, Form, Modal } from 'rsuite';
import { DefaultGenerateApiTokenFormValue, GenerateTokenSchema } from '../general/GeneralUtil';
import { useError } from '../../../../utils/UseError';
import { FormInstance } from 'rsuite/Form';
import TextField from '../../../../components/text-field-component';
import { generateAPiToken } from '../../../../api/general/GeneralAPI';

const GenerateApiTokenModal: React.FunctionComponent<ModalGenApiTokenProps> = props => {
  const { onClose, organizationID } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState<{ TokenName?: string }>(DefaultGenerateApiTokenFormValue);
  const [formError, setFormError] = useState<{ TokenName?: string }>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const form = useRef<FormInstance | null>(null);

  const handleChange = (value: string) => {
    errorText && setErrorText(null);
    setFormValue({ ...formValue, TokenName: value });
    let dataErrorValue = '';
    if (value && value.length > 50) {
      dataErrorValue = `The maximum character length for the name is 50 characters you have entered ${value.length} characters`;
    }
    setDataErrors({ TokenName: dataErrorValue });
    setFormError({ TokenName: '' });
  };

  const handleSubmit = () => {
    if (!form.current || !form.current.check()) {
      return;
    }

    if (formValue.TokenName && formValue.TokenName !== '') {
      setIsLoading(true);
      generateAPiToken(organizationID, { Name: formValue.TokenName })
        .then(tokenData => {
          onClose(tokenData);
        })
        .catch(err => {
          setIsLoading(false);
          setErrorText(err, formValue);
        });
    }
  };

  const isSubmitDisabled = useMemo(() => formValue.TokenName?.trim() === '' || dataErrors.TokenName !== '', [formValue, dataErrors]);

  return (
    <div>
      <Modal size={'sm'} className='organization-edit-modal organization-edit-modal_api' open>
        <Modal.Header>
          <Modal.Title>New API token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form
              ref={form}
              onCheck={formError => setFormError(formError)}
              formValue={formValue}
              model={GenerateTokenSchema}
              className='form-edit-data'
            >
              <TextField
                disabled={isLoading}
                onChange={handleChange}
                label='Token name'
                error={formError.TokenName || dataErrors.TokenName}
                value={formValue.TokenName}
                name='password'
                tooltipText='Maximum character length is 50'
              />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close btn-lg' disabled={isLoading} onClick={() => onClose(null)}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitDisabled || isLoading} onClick={handleSubmit}>
              Generate
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GenerateApiTokenModal;
