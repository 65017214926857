import { SERVER_URL } from '../../config/ServerConfig';
import { InsurancePlans } from '../../models/Insurance';
import { request } from '../Request';

export function addInsurance(name: string): Promise<InsurancePlans> {
  return request({
    url: `${SERVER_URL}/insurance-plans`,
    method: 'POST',
    body: JSON.stringify({ PolicyName: name }),
  });
}
