import { Schema } from 'rsuite';
import moment from 'moment';
import { notesTexts } from '../new-scan/NewScanUtil';

const { StringType, NumberType, DateType, ArrayType } = Schema.Types;

export const FilterSchema = Schema.Model({
  assigned: NumberType(),
  type: NumberType(),
  status: NumberType(),
  patient: StringType(),
});

export const PeerReviewTaskSchema = Schema.Model({
  newReviewerName: StringType().isRequired('This field is required'),
  newReviewerSpecialty: StringType(),
  newReviewResult: NumberType().isRequired('This field is required'),
  newAuthorizationID: StringType(),
  fileNoteName: StringType(),
});

export const PriorAuthTaskApprovedSchema = Schema.Model({
  ReviewerName: StringType().isRequired('This field is required'),
  ReviewStatus: NumberType().isRequired('This field is required'),
  ApprovalID: StringType(),
  Comment: StringType(),
  fileNoteName: StringType(),
});

export const PriorAuthTaskSchema = Schema.Model({
  ReviewerName: StringType().isRequired('This field is required'),
  ReviewStatus: NumberType().isRequired('This field is required'),
  Comment: StringType().isRequired('This field is required'),
});

export const ReportReviewSchema = Schema.Model({
  reviewedBy: StringType().isRequired('This field is required'),
  reviewedOn: DateType()
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)')
    .addRule((value, data) => {
      if (moment(value).format('X') > moment().format('X') && data) {
        return false;
      }

      return true;
    }, 'You can not choose future date'),
  reviewNotes: StringType(),
  taskNotes: StringType(),
});

export const NotesSchema = Schema.Model({
  fileListNotes: ArrayType().addRule((value, data) => {
    if (!value.length && !data.clinicalNotes) {
      return false;
    }

    return true;
  }, notesTexts.error.requiredField),
  fileNoteName: StringType().isRequired('Please enter or upload clinical notes.'),
});

export const GeneralTaskSchema = Schema.Model({
  notes: StringType().isRequired('This field is required'),
});

export const TaskNoteSchema = Schema.Model({
  MFPTaskNotes: StringType(' ').maxLength(100, ' '),
});
