import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'rsuite';
import TableComponent from '../../../../components/table-component';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { SettingsUsersTexts } from './Texts';
import InviteUserModal from './InviteUserModal';
import { PageSpacingTable, TableAction } from '../../../../enum/TableActionEnum';
import RemoveUserModal from './RemoveUserModal';
import LoaderComponent from '../../../../components/loader-component';
import { manageUserColumns, prepareUsersData } from './UsersUtil';
import { getUsers, resendInviteUser } from '../../../../api/users/UsersAPI';
import { UserFromSettings as User } from '../../../../models/UserFromSettings';
import useDynamicTableHeight from '../../../../utils/useDynamicTableHeight';
import { Event } from '../../../../models/General';
import { RowDataType } from '../../../../components/table-component/interfaces';
import { ActionButton } from '../../../../components/action-button-component';
import { useSignal } from '../../../../utils/UseSignal';

const SettingsUserTabComponent: React.FunctionComponent = () => {
  const [usersList, setUsersList] = useState<User[] | []>([]);
  const [showInviteUserModal, setShowInviteUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState<boolean>(false);
  const [infoModalTexts, setInfoModalTexts] = useState<{ [key: string]: string }>({});
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { size } = useDynamicTableHeight(PageSpacingTable.Setting, 533);
  const signal = useSignal();

  const getListOfUsers = () => {
    setIsLoading(true);
    getUsers(signal)
      .then(res => setUsersList(prepareUsersData(res)))
      .catch(() => {
        if (!signal.aborted) {
          setUsersList([]);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getListOfUsers();
  }, []);

  const onCloseInviteUser = useCallback(
    (event: boolean | Event) => {
      setShowInviteUserModal(false);
      if (event) {
        if (editMode) {
          setInfoModalTexts(SettingsUsersTexts.successUpdateUserInfo);
        } else {
          setInfoModalTexts(SettingsUsersTexts.successInviteUser);
        }
        getListOfUsers();
        setShowInfoModal(true);
      }
      setEditMode(false);
    },
    [editMode],
  );
  const onCloseRemoveUserModal = useCallback((event: boolean | Event) => {
    setShowRemoveUserModal(false);
    if (event) {
      setInfoModalTexts(SettingsUsersTexts.successRemoveUser);
      setShowInfoModal(true);
      getListOfUsers();
    }
  }, []);
  const onTableAction = (actionName: TableAction, rowData: User) => {
    setSelectedUser(rowData);
    if (actionName === TableAction.Remove) {
      setShowRemoveUserModal(true);
    }
    if (actionName === TableAction.Edit) {
      setEditMode(true);
    }
    if (actionName === TableAction.Resend) {
      resendInviteUser(rowData.Email)
        .then(() => {
          if (!signal.aborted) {
            setInfoModalTexts(SettingsUsersTexts.successInviteUser);
            setShowInfoModal(true);
          }
        })
        .catch(err => {
          if (!signal.aborted) {
            console.error(err);
          }
        });
    }
  };

  const getSelectedUserFullName = useMemo((): string => {
    if (selectedUser) {
      return `${selectedUser.FirstName} ${selectedUser.LastName}`;
    }
    return '';
  }, [selectedUser]);

  return (
    <>
      <div className='btn-add'>
        <Button
          className='rs-btn-model btn-md'
          onClick={() => {
            setShowInviteUserModal(true);
          }}
        >
          + Invite Additional Users
        </Button>
      </div>
      {isLoading && <LoaderComponent className={'loader-block-center'} />}
      {usersList.length > 0 && !isLoading && (
        <div>
          <TableComponent
            columns={manageUserColumns}
            data={usersList}
            onAction={onTableAction}
            customHeight={size}
            widthColumnAction={window.innerWidth > 2100 ? 250 : undefined}
            actionComponentRender={(rowData: RowDataType) => (
              <ActionButton
                rowData={rowData}
                clickAction={onTableAction}
                buttonText='Resend'
                isDisabled={rowData.Status !== 'Invited'}
                actionKey={3}
              />
            )}
          />
        </div>
      )}
      {showInfoModal && <InfoModalComponent type='success' onClose={() => setShowInfoModal(false)} texts={infoModalTexts} />}
      {(showInviteUserModal || editMode) && (
        <InviteUserModal
          user={selectedUser}
          isLoading={isLoading}
          onClose={onCloseInviteUser}
          editMode={editMode}
          setIsLoading={setIsLoading}
        />
      )}
      {showRemoveUserModal && (
        <RemoveUserModal
          userName={getSelectedUserFullName}
          userId={selectedUser?.ID}
          onClose={onCloseRemoveUserModal}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default SettingsUserTabComponent;
