import React, { useMemo, useRef, useState } from 'react';
import { Form, Button } from 'rsuite';
import TextField from '../../../../../../components/text-field-component';
import { EditFormDefaultValue, model, isAdmin } from '../CommentsUtil';
import { FormInstance } from 'rsuite/Form';
import ErrorMessageComponent from '../../../../../../components/error-message-component';
import { editComment } from '../../../../../../api/comments/CommentsAPI';
import { useError } from '../../../../../../utils/UseError';
import { transformToLocalDateWithTime } from '../../../../../../utils/TimeUtil';
import { CommentProps, EditFormStructure } from '../interfaces';
import ConfirmModalComponent from '../../../../../../components/modals/confirm-modal-component';
import { ConfirmModalTexts } from '../../../../../comments/AllCommentsUtil';
import Textarea from '../../../../../../components/textarea';
import { useSignal } from '../../../../../../utils/UseSignal';

const CommentComponent: React.FunctionComponent<CommentProps> = props => {
  const { commentData, getAllComments, onConfirmRemove } = props;
  const { UserInfo, EventTypeName, Created, NoteText, EventTypeID } = commentData;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<EditFormStructure>(EditFormDefaultValue);
  const [formError, setFormError] = useState<EditFormStructure>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const form = useRef<FormInstance | null>(null);
  const signal = useSignal();

  const handleClickEdit = () => {
    setErrorText(null);
    setEditMode(true);
    setFormValue({ NoteText });
  };

  const handleClickRemove = () => {
    errorText && setErrorText(null);
    setShowConfirmModal(true);
  };

  const handleCommentChange = (value: string) => {
    errorText && setErrorText(null);
    setFormValue({ ...formValue, NoteText: value });
    setDataErrors({ NoteText: '' });
  };

  const onSave = (comment: string, commentID: number) => {
    editComment(comment, commentID)
      .then(() => {
        if (!signal.aborted) {
          setEditMode(false);
          getAllComments();
        }
      })
      .catch(err => {
        if (!signal.aborted) {
          setErrorText(err, formValue);
        }
      });
  };

  const onSubmit = (e: boolean) => {
    if (!e) {
      setEditMode(false);
      setFormValue({ NoteText });
      setFormError({ NoteText: '' });
      return;
    }

    if (!form.current || !form.current.check()) {
      return;
    }

    if (e && formValue.NoteText) {
      onSave(formValue.NoteText, commentData.ID);
      return;
    }

    setEditMode(false);
  };

  const onCloseRemoveNotification = (flag: boolean) => {
    if (flag) {
      onConfirmRemove(commentData.ID);
    }
    setShowConfirmModal(false);
  };

  const fullName = useMemo(() => `${UserInfo.FirstName} ${UserInfo.LastName}`, [UserInfo]);
  const adminClass = useMemo(() => isAdmin(EventTypeName), [EventTypeName]);
  const dateCreated = useMemo(() => transformToLocalDateWithTime(Created), [Created]);
  const isSubmitDisabled = useMemo(() => commentData.NoteText === formValue.NoteText, [formValue]);

  return (
    <>
      <li className={`comments-list_item ${adminClass}`}>
        {editMode ? (
          <>
            <div className='head row'>
              <div className='box'>
                <strong className='name'>{fullName}</strong>
                <div className='state'>{EventTypeName}</div>
                <time dateTime={dateCreated}>{dateCreated}</time>
              </div>
            </div>
            <Form ref={form} onCheck={formError => setFormError(formError)} formValue={formValue} model={model}>
              <TextField
                name='NoteText'
                accepter={Textarea}
                error={formError.NoteText || dataErrors.NoteText}
                value={formValue.NoteText}
                label='Edit comment*'
                onChange={handleCommentChange}
              />
              {errorText && <ErrorMessageComponent errorMessage={errorText} />}
              <div className='btn-list row'>
                <Button className='btn-md rs-btn-model btn-close btn-md' onClick={() => onSubmit(false)}>
                  Cancel
                </Button>
                <Button disabled={isSubmitDisabled} className='btn-md rs-btn-model btn-md' onClick={() => onSubmit(true)}>
                  Save changes
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <>
            <div className='head row'>
              <div className='box'>
                <strong className='name'>{fullName}</strong>
                {EventTypeID === 30 ? (
                  <div className='state state-team'>Medmo Care Team</div>
                ) : EventTypeID === 43 ? (
                  <div className='state'>Notified Medmo Care Team</div>
                ) : (
                  <div className='pr1'></div>
                )}
                <time dateTime={dateCreated}>{dateCreated}</time>
              </div>
              {EventTypeID === 43 || EventTypeID === 14 ? (
                <div className='btn-list'>
                  <Button classPrefix='btn-edit' onClick={handleClickEdit}>
                    Edit
                  </Button>
                  <Button classPrefix='btn-edit' onClick={handleClickRemove}>
                    Remove
                  </Button>
                </div>
              ) : null}
            </div>
            <div className='text'>
              <p>{NoteText}</p>
            </div>
            {showConfirmModal && <ConfirmModalComponent onClose={onCloseRemoveNotification} info={ConfirmModalTexts.removeNote} />}
          </>
        )}
      </li>
    </>
  );
};

export default CommentComponent;
