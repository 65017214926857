import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Content, SelectPicker, Form } from 'rsuite';
import ScanConfirmation from './components/ScanConfirmation';
import ConfirmModalComponent from '../../components/modals/confirm-modal-component';
import { uploadEPrescriptionPdf } from '../../api/e-prescription/EPrescriptionAPI';
import Popup from './components/Popup';
import InfoModalComponent from '../../components/modals/info-modal';
import { scanRequestData, scanCoverageSelect, leaveModalContent, scrollToElement } from './NewScanUtil';
import { FormInstance } from 'rsuite/Form';
import { uploadNotesFile } from '../../api/notes/NotesAPI';
import HeaderComponent from '../../components/header-component';
import PatientInformation from './components/PatientInformation';
import {
  RequestScanFormData,
  ScanInformationFormData,
  TotalPrice,
  NotesFormData,
  ScanCoverageSelect,
  PatientPreferencesFormData,
  FileTypeWithTitle,
  Location,
  SelectData,
} from './interfaces';
import ScanInformation from './components/ScanInformation';
import Notes from './components/Notes';
import PatientPreferences from './components/PatientPreferences';
import Prescription from './components/Prescription';
import { Texts } from '../../components/modals/interfaces';
import TextField from '../../components/text-field-component';
import { UserContext, UserContextType } from '../../contexts/UserContext';
import { Owner } from '../../models/Owner';
import { FormError, SelectOption } from '../../models/General';
import { getSelectOptions } from '../../api/filters/FiltersAPI';
import { ScanOptionsData } from '../../api/filters/interfaces';
import {
  NotesShemas,
  PatientInfoSchema,
  PatientPreferencesInsuranceSchema,
  PatientPreferencesSelfPaySchema,
  PatientScanInfoSchema,
  PatientScanInfoSchemaWithDetails,
  PrescriptionSchema,
  ScanOwnerSchema,
} from './Schemas';
import { CommonContext, CommonContextType } from '../../contexts/CommonContext';
import { useSignal } from '../../utils/UseSignal';
import { HomeUrl } from '../../config/UrlsConfig';
import { renameFile } from '../../utils/FileUtils';
import { addInsurance } from '../../api/insurance/InsuranceAPI';
import { Patient } from '../../models/Patient';

const NewScan: React.FC = (): React.ReactElement | null => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [agreeModal, setAgreeModal] = useState<boolean>(false);
  const [thanksModal, setThanksModal] = useState<boolean>(false);
  const [steps, setSteps] = useState<null | boolean>(null);
  const [requestId, setRequestId] = useState<null | number>(null);
  const [fileListPrescription, setFileListPrescription] = useState<FileTypeWithTitle[] | []>([]);
  const [modalError, setModalError] = useState<Texts>({
    title: '',
    content: '',
  });
  const [price, setTotalPrice] = useState<TotalPrice>({
    total: 0,
    deposit: 0,
    exam: 0,
  });
  const [insuranceObj, setInsuranceObj] = useState<ScanCoverageSelect>(scanCoverageSelect);
  const [formData, setFormData] = useState<RequestScanFormData>(scanRequestData);
  const [formError, setFormError] = useState<FormError>({});
  const [showElementDetails, setShowElementDetails] = useState<boolean>(false);
  const [isZipCodeHasError, setIsZipCodeHasError] = useState(false);
  const [selectData, setSelectData] = useState<SelectData>({
    prescribers: [],
    owners: [],
    locations: [],
  });
  const history = useHistory<{ pathname: string }>();

  const patientFormRef = useRef<FormInstance | null>(null);
  const scanInfoRef = useRef<FormInstance | null>(null);
  const notesFormRef = useRef<FormInstance | null>(null);
  const prescriptionRef = useRef<FormInstance | null>(null);
  const patientPreferencesRef = useRef<FormInstance | null>(null);
  const ownerRef = useRef<FormInstance | null>(null);
  const scanType = useRef<string>('');
  const { UserID, IsCleerly } = useContext<UserContextType>(UserContext);
  const { updateCommonData } = useContext<CommonContextType>(CommonContext);
  const signal = useSignal();
  const locationFrom = history.location?.state?.pathname;

  useEffect(() => {
    if (!formData.OwnerID) {
      const findUser = selectData.owners.find(owner => owner.value === UserID);
      if (findUser) {
        setFormData({
          ...formData,
          OwnerID: Number(findUser.value),
          OwnerName: findUser.label,
        });
      }
    }
  }, [selectData.owners]);

  const getAllData = () => {
    getSelectOptions<ScanOptionsData>(['Locations', 'Prescribers', 'Owners'], signal)
      .then(res => {
        const filledLocationsArray: Location[] = [];
        const filledPresciberArray: SelectOption[] = [];
        const filledOwnersArray: SelectOption[] = [];

        Object.values(res.Owners).forEach(el => {
          filledOwnersArray.push({
            label: el.OwnerName,
            value: el.ID,
          });
        });

        Object.values(res.Locations).forEach(el => {
          filledLocationsArray.push({
            label: el.Address1 + ' ' + el.City,
            value: el.ID as number,
            fax: el.DefaultFax,
          });
        });

        Object.values(res.Prescribers).forEach(el => {
          filledPresciberArray.push({
            label: el.FirstName + ' ' + el.LastName,
            value: el.ID as number,
          });
        });

        setSelectData({
          prescribers: filledPresciberArray,
          owners: filledOwnersArray,
          locations: filledLocationsArray,
        });
      })
      .catch(() => {
        if (!signal.aborted) {
          setSelectData({
            prescribers: [],
            owners: [],
            locations: [],
          });
          setModalError({
            title: 'Error',
            content: 'Failed to load data for scan',
          });
        }
      });
  };

  useEffect(() => {
    updateCommonData();
    getAllData();
  }, []);

  const changeOwner = (value: string, item: Owner): void => {
    setFormData({
      ...formData,
      OwnerName: item.label,
      OwnerID: Number(value),
    });
  };

  const createNewInsurance = async (name: string) => {
    return addInsurance(name)
      .then(response => {
        if (response) {
          const res = response.ID;

          setFormData({
            ...formData,
            InsurancePlanID: res,
          });

          return res;
        }
      })
      .catch(() =>
        setModalError({
          title: 'Error',
          content: 'Failed to create new insurance',
        }),
      );
  };

  const uploadFiles = async (data: FileTypeWithTitle[], type: string) => {
    const files = data;
    const fileData = new FormData();

    if (!data || !data.length) {
      return null;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i].blobFile;

      if (file) {
        const newFileName = type === 'prescription' ? files[i].fileTitle : '';
        const newFile = await renameFile(file, newFileName);
        fileData.append('files', newFile);
      }
    }

    if (type === 'notes') {
      return uploadNotesFile(fileData)
        .then(response => {
          if (response) {
            const res = data.map((el: FileTypeWithTitle, i: number) => {
              return {
                ...response[i],
                fileTitle: el.fileTitle,
                url: el.url,
              };
            });

            setFormData({
              ...formData,
              isNote: true,
              ScanRequestNoteNames: res,
            });

            return res;
          }
        })
        .catch(() =>
          setModalError({
            title: 'Error',
            content: 'Failed to upload notes files',
          }),
        );
    }

    if (type === 'prescription') {
      return uploadEPrescriptionPdf(fileData)
        .then(response => {
          if (response) {
            const res = [
              {
                ...response[0],
                fileTitle: data[0].fileTitle,
                url: data[0].url,
              },
            ];

            setFormData({
              ...formData,
              ScanRequestFileNames: res,
            });
            setFileListPrescription([]);

            return res;
          }
        })
        .catch(() => {
          setModalError({
            title: 'Error',
            content: 'Failed to upload E Prescription',
          });
        });
    }

    return null;
  };

  const validatePrescription = (isOnlyPatient?: boolean) => {
    const isPatientFormHasError = patientFormRef.current && !patientFormRef.current.check();
    const isScanInfoFormHasError = !isOnlyPatient && scanInfoRef.current && !scanInfoRef.current.check();

    if (isPatientFormHasError) {
      scrollToElement(PatientInfoSchema.check(formData));
      return false;
    }

    if (!isOnlyPatient && isScanInfoFormHasError) {
      if (showElementDetails) {
        scrollToElement(PatientScanInfoSchemaWithDetails.check(formData));
      } else {
        scrollToElement(PatientScanInfoSchema.check(formData));
      }

      return false;
    }

    return true;
  };

  const sendToPatient = (partialValid = false) => {
    const isPatientFormHasError = patientFormRef.current && !patientFormRef.current.check();
    const isScanInfoFormHasError = scanInfoRef.current && !scanInfoRef.current.check();
    const isNotesFormHasError = notesFormRef.current && !notesFormRef.current.check();
    const isOwnerHasError = ownerRef.current && !ownerRef.current.check();
    const isPrescriptionFormHasError = prescriptionRef.current && !prescriptionRef.current.check();
    const isPatientPreferencesHasError = partialValid ? false : patientPreferencesRef.current && !patientPreferencesRef.current.check();
    if (isPatientFormHasError) {
      scrollToElement(PatientInfoSchema.check(formData));
      return;
    }
    if (isScanInfoFormHasError) {
      if (showElementDetails) {
        scrollToElement(PatientScanInfoSchemaWithDetails.check(formData));
      } else {
        scrollToElement(PatientScanInfoSchema.check(formData));
      }

      return;
    }
    if (isNotesFormHasError) {
      scrollToElement(formData.NotesOptions ? NotesShemas[formData.NotesOptions].check(formData) : null);
      return;
    }
    if (isOwnerHasError) {
      scrollToElement(ScanOwnerSchema.check(formData));
      return;
    }
    if (isPrescriptionFormHasError) {
      scrollToElement(PrescriptionSchema.check(formData));
      return;
    }
    if (!partialValid) {
      if (isPatientPreferencesHasError || isZipCodeHasError) {
        scrollToElement(
          formData.SelfPay === '1' ? PatientPreferencesSelfPaySchema.check(formData) : PatientPreferencesInsuranceSchema.check(formData),
        );
        return;
      }
    }

    const { SelectScanTypeNameModality, SelectScanTypeNameBody, SelectScanTypeNameDetails } = formData;
    scanType.current = `${SelectScanTypeNameModality} ${SelectScanTypeNameBody} ${SelectScanTypeNameDetails}`.trim();
    setConfirmModal(true);
  };

  const sendScan = (TestRequestID: number | null, errorText?: Texts): void => {
    if (TestRequestID) {
      setRequestId(TestRequestID);
      setThanksModal(true);
    } else {
      if (errorText) {
        setModalError(errorText);
      }
    }
  };

  const onCloseModal = (flag: boolean): void => {
    if (flag) {
      returnToPrevPage();
    }

    setAgreeModal(false);
  };

  const onCloseErrorModal = (): void => {
    setModalError({
      title: '',
      content: '',
    });
  };

  const goBack = (): void => {
    if (checkIfChanges()) {
      setAgreeModal(true);
      return;
    }

    returnToPrevPage();
  };

  const liftZipCodeStateUp = (value: boolean): void => setIsZipCodeHasError(value);

  const returnToPrevPage = () => {
    if (locationFrom) {
      history.push(locationFrom);
      return;
    }
    history.push(HomeUrl);
  };

  const fillScanTypeInfo = (scan: ScanInformationFormData): void => {
    const insuranceObj = [...scanCoverageSelect];

    insuranceObj[1].label = `Self-pay ($${scan.TotalPrice})`;
    setInsuranceObj(insuranceObj);

    setTotalPrice({
      total: +scan.TotalPrice,
      deposit: +(scan.Fee || ''),
      exam: +(scan.MinPrice || ''),
    });
  };

  const setPatientData = (data: Patient) => {
    setFileListPrescription([]);
    setFormData(prev => ({
      ...prev,
      ...data,
      ScanRequestFileNames:
        prev.PreferredLanguageID !== data.PreferredLanguageID && prev.PatientId === data.PatientId ? prev.ScanRequestFileNames : null,
      ZipCode: prev.ZipCode,
    }));
  };

  const setScanData = (data: ScanInformationFormData) => {
    if (data.TotalPrice && +data.TotalPrice !== price.total) {
      fillScanTypeInfo(data);
    }

    setFormData(prev => ({
      ...prev,
      ...data,
      ScanRequestFileNames: null,
    }));
  };

  const setNotes = (data: NotesFormData) => {
    setFormData(prev => ({
      ...prev,
      ...data,
      ScanRequestFileNames: prev.OtherNotes !== data.OtherNotes ? null : prev.ScanRequestFileNames,
    }));
  };

  const checkIfChanges = (): boolean =>
    Object.entries(formData).some(([key, value]) => {
      if (['OwnerID', 'OwnerName'].includes(key)) {
        return false;
      }

      if (
        IsCleerly &&
        [
          'SelectScanTypeNameModality',
          'SelectScanTypeNameBody',
          'SelectScanTypeNameDetails',
          'ScanServiceID',
          'CPTCode',
          'TotalPrice',
          'Fee',
          'MinPrice',
        ].includes(key)
      ) {
        return false;
      }

      if (Array.isArray(value)) {
        return value.length !== 0;
      }

      if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length !== 0;
      }

      return value !== scanRequestData[key as keyof RequestScanFormData];
    });

  return (
    <>
      <HeaderComponent goBack={goBack} title='New Scan Request' />
      <Content className='content-new-scan'>
        <PatientInformation formRef={patientFormRef} setPatientData={setPatientData} />
        <ScanInformation
          formRef={scanInfoRef}
          setScanData={setScanData}
          locations={selectData.locations}
          prescribers={selectData.prescribers}
          setShowElementDetails={(value: boolean) => setShowElementDetails(value)}
          showElementDetails={showElementDetails}
        />
        <Notes formRef={notesFormRef} setNotesData={setNotes} />
        <Prescription
          formData={formData}
          formRef={prescriptionRef}
          setFormData={(data: RequestScanFormData) => setFormData(data)}
          fileListPrescription={fileListPrescription}
          setFileListPrescription={(fileList: FileTypeWithTitle[] | []) => setFileListPrescription(fileList)}
          validate={validatePrescription}
        />
        {!formData.ScanServiceIsCleerly && (
          <PatientPreferences
            setPatientPreferencesData={(data: PatientPreferencesFormData) => setFormData(prev => ({ ...prev, ...data }))}
            price={price}
            insuranceObj={insuranceObj}
            formRef={patientPreferencesRef}
            liftZipCodeStateUp={liftZipCodeStateUp}
          />
        )}
        <Form
          className='form form-new-scan form-new-scan-owner pb3'
          formValue={formData}
          model={ScanOwnerSchema}
          onCheck={error => setFormError(error)}
          ref={ownerRef}
        >
          <div className='form-container'>
            <div className='form-new-scan-block scan--information'>
              <Form.Group className='form-new-scan-group'>
                <TextField
                  accepter={SelectPicker}
                  name='OwnerID'
                  data={selectData.owners}
                  onSelect={changeOwner}
                  value={formData.OwnerID}
                  appearance='subtle'
                  label='Scan owner'
                  menuMaxHeight={190}
                  error={formError.OwnerID}
                  tooltipText={
                    'Scan owner will receive future task notifications associated with this order. This can be reassigned later on'
                  }
                />
              </Form.Group>
            </div>
          </div>
          <div className='form-btns'>
            <ButtonToolbar>
              <Button classPrefix='btn-model btn-close' className='btn-lg' onClick={goBack}>
                Quit
              </Button>
              <Button
                className='btn-lg'
                classPrefix={formData.ScanServiceIsCleerly ? 'btn-model btn-cleerly' : 'btn-model btn-white'}
                onClick={() => {
                  sendToPatient(true);
                  setSteps(true);
                }}
              >
                Send Form to Patient
              </Button>
              {!formData.ScanServiceIsCleerly && (
                <Button
                  className='btn-lg'
                  classPrefix='btn-model'
                  onClick={() => {
                    sendToPatient();
                    setSteps(false);
                  }}
                >
                  Complete Scan Request
                </Button>
              )}
            </ButtonToolbar>
          </div>
        </Form>
        {confirmModal && (
          <ScanConfirmation
            isShow={confirmModal}
            getRequestId={sendScan}
            steps={steps}
            uploadFiles={uploadFiles}
            createNewInsurance={createNewInsurance}
            data={{ ...formData, scanType: scanType.current, prescription: fileListPrescription }}
            onClose={() => setConfirmModal(false)}
          />
        )}
      </Content>

      {requestId && (
        <Popup
          isShow={thanksModal}
          requestId={requestId}
          scanType={scanType.current}
          fullName={formData.PrescriberName}
          onClose={() => {
            setThanksModal(false);
            setConfirmModal(false);
          }}
        />
      )}
      {agreeModal && <ConfirmModalComponent info={leaveModalContent} onClose={onCloseModal} />}
      {modalError.content && <InfoModalComponent type={`error`} texts={modalError} onClose={onCloseErrorModal} />}
    </>
  );
};

export default NewScan;
