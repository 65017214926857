import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { Comment } from '../../views/all-scans/scan/tabs/comments/interfaces';
import { CommentResponse } from './interface';
import { convertToQueryString, prepareGetOptions } from '../../utils/GeneralUtil';
import { RequestParams } from '../../models/General';

export function removeComment(id: number): Promise<Comment[]> {
  return request({
    method: 'DELETE',
    url: `${SERVER_URL}/comments/${id}`,
  });
}

export function getComments(scanId: number, signal?: AbortSignal): Promise<Comment[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/comments/scan-comments/${scanId}`);
  return request(options);
}

export function getCommentsList({ filter, perPage, page, field, order }: RequestParams, signal?: AbortSignal): Promise<CommentResponse> {
  const paginate = {
    currentPage: page || 1,
    perPage: perPage || 25,
    sortBy: field,
    order,
  };
  let queryString = convertToQueryString(paginate);

  if (filter) {
    queryString += `&${filter}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/comments?${queryString}`);
  return request(options);
}

export function editComment(comment: string, id: number): Promise<Comment[]> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/comments/${id}`,
    body: JSON.stringify({
      NoteText: comment,
    }),
  });
}

export function addComment(comment: string, notify: number, id: number): Promise<Comment[]> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/comments`,
    body: JSON.stringify({
      NoteText: comment,
      TestRequestID: id,
      IsNotifyCxTeam: notify,
    }),
  });
}
