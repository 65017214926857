import React from 'react';
import Logo from '../../assets/logo_main.svg';

const BaaComponent: React.FunctionComponent = () => {
  return (
    <div className='page-text'>
      <header className='page-text-header'>
        <a href='/' className='page-header-logo'>
          <img src={Logo} alt='Medmo' width='125' height='30' />
        </a>
      </header>
      <div className='page-text-wrap'>
        <h1 className='page-text-h1'>HIPAA Business Associate Addendum</h1>
        <p>
          This HIPAA Business Associate Addendum (<strong>&quot;Addendum&quot;</strong>) is entered into between the parties pursuant to the
          Covered Entity&apos;s participation in Medmo.com as a radiology provider (the
          <strong>&quot;Underlying Subscription&quot;</strong>) and is being entered as of the date of the Underlying Subscription (the{' '}
          <strong>&quot;Execution Date&quot;</strong>) by and between <strong>MEDMO, INC.</strong>
          (hereinafter referred to as the &quot;Business Associate&quot;), and the agreeing provider (hereinafter referred to as
          &quot;Covered Entity&quot;).
        </p>
        <h2 className='page-text-h2'>RECITALS</h2>
        <p>
          A. Covered Entity wishes to disclose certain information to Business Associate pursuant to the terms of the Underlying
          Subscription, some of which information may constitute Protected Health Information as defined below.
        </p>
        <p>
          B. Covered Entity and Business Associate intend to protect the privacy and provide for the security of PHI disclosed to Business
          Associate pursuant to the Underlying Subscription in compliance with the Privacy Rule and Security Standards (defined below)
          promulgated pursuant to the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191 as modified by the
          Health Information Technology for Economic and Clinical Health Act, found in Title XIII of the American Recovery and Reinvestment
          Act of 2009, Public Law 111-005.
        </p>
        <p>
          In consideration of the mutual premises and the covenants and agreements contained in this Addendum, the Parties agree as follows:
        </p>
        <h3 className='page-text-h3'>1. DEFINITIONS</h3>
        <p>
          Terms used in this Addendum that are specifically defined in HIPAA shall have the same meaning as set forth in HIPAA. A change to
          HIPAA which modifies any defined HIPAA term, or which alters the regulatory citation for the definition shall be deemed
          incorporated into this Addendum.
        </p>
        <p>
          1.1 <strong>&quot;Business Associate&quot;</strong> shall mean the entity described above. Where the term
          <strong>&quot;business associate&quot;</strong> appears without an initial capital letter, it shall have the meaning given to such
          term under the Privacy Rule, including, but not limited to, 45 CFR § 160.103.
        </p>
        <p>
          1.2 <strong>&quot;Covered Entity&quot;</strong> means the entity identified as such in the caption above.
        </p>
        <p>
          1.3 <strong>&quot;Data Aggregation&quot;</strong> shall have the meaning given to the term under the Privacy Rule, including, but
          not limited to, 45 CFR § 164.501.
        </p>
        <p>
          1.4 <strong>&quot;Designated Record Set&quot;</strong> shall have the meaning given to the term under the Privacy Rule, including,
          but not limited to, 45 CFR § 164.501.
        </p>
        <p>
          1.5 <strong>&quot;Electronic Protected Health Information&quot;</strong> and/or <strong>&quot;EPHI&quot;</strong> shall have the
          same meaning as the term &quot;electronic protected health information&quot; in 45 CFR § 160.103, and shall include, without
          limitation, any EPHI provided by Covered Entity or created or received by Business Associate on behalf of Covered Entity.
        </p>
        <p>
          1.6 <strong>&quot;Effective Date&quot;</strong>. For purposes of this Agreement, the <strong>&quot;Effective Date&quot;</strong>{' '}
          shall be the Effective Date as such term is defined in the Underlying Subscription.
        </p>
        <p>
          1.7 <strong>&quot;HIPAA&quot;</strong> means the Health Insurance Portability and Accountability Act of 1996, Public Law 104-91,
          as amended, and related HIPAA regulations (45 CFR. Parts 160-1 64).
        </p>
        <p>
          1.8 <strong>&quot;HITECH&quot;</strong> means the Health Information Technology for Economic and Clinical Health Act, found in
          Title XIII of the American Recovery and Reinvestment Act of 2009, Public Law 111-005.
        </p>
        <p>
          1.9 <strong>&quot;Individual&quot;</strong> shall have the meaning given to the term under the Privacy Rule, including, but not
          limited to, 45 CFR § 160.103. It shall also include a person who qualifies as a personal representative in accordance with 45 CFR
          § 164.502(g).
        </p>
        <p>
          1.10 <strong>&quot;Privacy Rule&quot;</strong> shall mean the Standards for Privacy of Individually Identifiable Health
          Information, and Security Standards for the Protection of Electronic Protected Health Information (the &quot;Security Rule&quot;),
          that are codified at 45 CFR parts 160 and 164, Subparts A, C, and E and any other applicable provision of HIPAA, and any
          amendments thereto, including HITECH.
        </p>
        <p>
          1.11 <strong>&quot;Protected Health Information&quot; and/or &quot;PHI&quot;</strong> shall have the meaning given to the term
          under the Privacy Rule, including but not limited to, 45 CFR § 164.103, and shall include, without limitation, any PHI provided by
          Covered Entity or created or received by Business Associate on behalf of Covered Entity. Unless otherwise stated in this Addendum,
          any provision, restriction, or obligation in this Addendum related to the use of PHI shall apply equally to EPHI. PHI does not
          include any information independently given to or sourced by Business Associate directly from third parties.
        </p>
        <p>
          1.12 <strong>&quot;Required By Law&quot;</strong> shall have the meaning given to the term under the Privacy Rule, including but
          not limited to, 45 CFR § 164.103, and any additional requirements created under HITECH.
        </p>
        <p>
          1.13 <strong>&quot;Secretary&quot;</strong> shall mean the Secretary of the Department of Health and Human Services or his
          designee.
        </p>
        <p>
          1.14 <strong>&quot;Security Incident&quot;</strong> shall mean the attempted or successful unauthorized access, use, disclosure,
          modification, or destruction of information or interference with system operations in an information system as provided in 45 CFR
          § 164.304.
        </p>
        <p>
          1.15 <strong>&quot;Underlying Subscription&quot;</strong> shall mean the Underlying Subscription(s) that outline the functionality
          Business Associate agrees to provide to Covered Entity and that fall within the functions, activities or services described in the
          definition of &quot;Business Associate&quot; at 45 CFR § 160.103.
        </p>
        <p>
          1.16 <strong>&quot;Unsecured PHI&quot;</strong> shall have the same definition that the Secretary gives the term in guidance
          issued pursuant to § 13402 of HITECH.
        </p>
        <h3 className='page-text-h3'>2. BUSINESS ASSOCIATE OBLIGATIONS</h3>
        <p>
          2.1 Business Associate agrees that it shall only use and disclose PHI in accordance with the terms of this Addendum or as is
          Required By Law.
        </p>
        <p>
          2.2 Business Associate shall not use or disclose PHI except for the purpose of performing Business Associate&apos;s obligations to
          Covered Entity, as such use or disclosure is limited by this Addendum. These obligations are as set forth or necessary and
          appropriate to Business Associate&apos;s performance of its activities under the Underlying Subscription.
        </p>
        <p>
          2.3 Business Associate shall not use or disclose PHI in any manner that would constitute a violation of the Privacy Rule. So long
          as such use or disclosure does not violate the Privacy Rule or this Addendum, Business Associate may use PHI: (a) as is necessary
          for the proper management and administration of Business Associate&apos;s organization, or (b) to carry out the legal
          responsibilities of Business Associate, as provided in 45 CFR § 164.504(e)(4).
        </p>
        <p>
          2.4 Business Associate will ensure that any agents, including subcontractors, to whom it provides PHI agree in writing to the same
          restrictions and conditions, including but not limited to those relating to termination of the contract for improper disclosure,
          that apply to Business Associate with respect to such information. Further, Business Associate shall implement and maintain
          sanctions against agents and subcontractors, if any, that violate such restrictions and conditions. Business Associate shall
          terminate any agreement with an agent or subcontractor, if any, who fails to abide by such restrictions and obligations, subject
          to cure opportunities as available.
        </p>
        <p>
          2.5 Business Associate shall develop, implement, maintain, and use appropriate safeguards to prevent any use or disclosure of the
          PHI or EPHI other than as provided by this Addendum, and to implement administrative, physical, and technical safeguards as
          required by sections 164.308 (Administrative Safeguards), 164.310 (Physical Safeguards), 164.312 (Technical Safeguards), and
          164.316 (Policies and Documentation) of title 45, Code of Federal Regulations and HITECH in order to protect the confidentiality,
          integrity, and availability of EPHI or PHI that Business Associate creates, receives, maintains, or transmits, to the same extent
          as if Business Associate were a Covered Entity.
        </p>
        <p>
          2.6 The additional requirements of Title XIII of HITECH that relate to privacy and security and that are made applicable with
          respect to covered entities shall also be applicable to Business Associate and shall be and by this reference hereby incorporated
          into this Addendum.
        </p>
        <p>
          2.7 Business Associate agrees to adopt the technology and methodology standards provided in any guidance issued by the Secretary
          pursuant to HITECH §§ 13401-13402.
        </p>
        <p>
          2.8 Business Associate agrees to mitigate any harmful effect that is known to Business Associate of a use or disclosure of PHI by
          Business Associate in violation of the requirements of this Addendum and to notify Covered Entity of any breach of unsecured PHI,
          as required under HITECH § 13402.
        </p>
        <p>
          2.9 Business Associate shall report, in writing, to Covered Entity any unauthorized or violating use or disclosure of PHI that is
          not authorized by the Underlying Subscription, the beneficiary who is the subject of such PHI or this Addendum. Such written
          notice shall be provided to Covered Entity within five (5) business days of becoming aware of such use or disclosure.
        </p>
        <p>
          2.10 In the case of a breach of Unsecured PHI, Business Associate shall, following the discovery of a breach of such information,
          promptly notify the Covered Entity of such breach in accordance with the specific statutory and regulatory requirements. For
          example, the notice shall include the identification of each individual whose Unsecured PHI has been, or is reasonably believed by
          the Business Associate to have been, accessed, acquired, or disclosed during the breach.
        </p>
        <p>
          2.11 Business Associate will obtain, prior to making any permitted disclosure as set forth herein, reasonable assurances from such
          third party that such PHI will be held secure and confidential as provided pursuant to this Addendum and only disclosed as
          required by law or for the purposes for which it was disclosed to such third party, and that any breaches of confidentiality of
          the PHI which becomes known to such third party will be promptly reported to Business Associate. As part of obtaining this
          reasonable assurance, Business Associate agrees to enter into a Business Associate Agreement with each of its subcontractors
          pursuant to 45 CFR § 164.308(b)(1) and HITECH § 13401.
        </p>
        <p>
          2.12 Business Associate shall make PHI in Designated Record Sets that are maintained by Business Associate or its agents or
          subcontractors, if any, available to Covered Entity for inspection and copying within ten (10) business days of a request by
          Covered Entity to enable Covered Entity to fulfill its obligations under the Privacy Rule, including, but not limited to, 45 CFR §
          164.524.
        </p>
        <p>
          2.13 Within ten (10) business days of receipt of a request from Covered Entity for an amendment of PHI or a record about an
          Individual contained in a Designated Record Set, Business Associate or its agents or subcontractors, if any, shall make such PHI
          available to Covered Entity for amendment and shall incorporate any such amendment to enable Covered Entity to fulfill its
          obligations under the Privacy Rule, including, but not limited to, 45 CFR § 164.524. If an Individual requests an amendment of PHI
          directly from Business Associate or its agents or subcontractors, if any, Business Associate will notify Covered Entity in writing
          within five (5) business days of the request. Any denial of amendment of PHI maintained by Business Associate or its agents or
          subcontractors, if any, shall be the responsibility of Covered Entity. Upon the approval of Covered Entity, Business Associate
          shall appropriately amend the PHI maintained by it, or any agents or subcontractors.
        </p>
        <p>
          2.14 Within ten (10) business days of notice by Covered Entity of a request for an accounting of disclosures of PHI, Business
          Associate shall make available to Covered Entity the information required to provide an accounting of disclosures to enable
          Covered Entity to fulfill its obligations under the Privacy Rule, including, but not limited to, 45 CFR §164.528. Except in the
          case of a direct request from an Individual for an accounting related to treatment, payment or healthcare operations disclosures
          through an electronic health record, if the request for an accounting is delivered directly to Business Associate or its agents or
          subcontractors, if any, Business Associate shall within five (5) business days of a request notify Covered Entity about such
          request. Covered Entity shall either inform Business Associate to provide such information directly to the Individual, or it shall
          request the information to be promptly forwarded to Covered Entity for compilation and distribution to such Individual. In the
          case of a direct request for an accounting from an Individual related to treatment, payment or healthcare operations disclosures
          through electronic health records, Business Associate shall provide such accounting to the Individual in accordance with HITECH §
          13405(c), but only after the applicable effective date of such HITECH provision. Business Associate shall not disclose any PHI
          unless such disclosure is Required by Law or is in accordance with this Addendum. Business Associate shall document such
          disclosures.
        </p>
        <p>
          2.15 Business Associate shall make its internal practices, books and records relating to the use and disclosure of PHI available
          to the Secretary for purposes of determining Covered Entity&apos;s compliance with the Privacy Rule. Business Associate shall
          notify Covered Entity regarding any PHI that Business Associate provides to the Secretary concurrently with providing such PHI to
          the Secretary, and upon request by Covered Entity, shall provide Covered Entity with a duplicate copy of such PHI.
        </p>
        <p>
          2.16 Business Associate and its agents or subcontractors, if any, shall only request, use and disclose the minimum amount of PHI
          necessary to accomplish the purpose of the request, use or disclosure. Business Associate agrees to comply with the
          Secretary&apos;s guidance on what constitutes minimum necessary.
        </p>
        <p>
          2.17 Business Associate and its subcontractors or agents, if any, shall retain any PHI throughout the term of the Underlying
          Subscription and this Addendum and for such time periods as required by law and regulation.
        </p>
        <p>
          2.18 During the term of this Addendum, Business Associate shall notify Covered Entity within twenty-four (24) hours of any
          suspected or actual Security Incident or breach of security, intrusion or unauthorized use or disclosure of PHI or EPHI and/or any
          actual or suspected use or disclosure of data in violation of any applicable federal or state laws or regulations, or any legal
          action against Business Associate arising from an alleged HIPAA violation. Business Associate shall take (i) prompt action to
          correct any such deficiencies and (ii) any action pertaining to such unauthorized disclosure required by applicable federal and
          state laws and regulations.
        </p>
        <h3 className='page-text-h3'>3. COVERED ENTITY OBLIGATIONS</h3>
        <p>
          3.1 Covered Entity shall provide Business Associate with notice of any changes to, revocation of, or permission by Individual to
          use or disclose PHI, if such changes affect Business Associate&apos;s permitted uses or disclosures, promptly after Covered Entity
          becomes aware of such changes to or revocation of permission.
        </p>
        <p>
          3.2 Covered Entity shall notify Business Associate of any restriction to the use or disclosure of PHI that has been agreed to or
          must be complied with in accordance with 45 CFR § 164.522 and HITECH § 13405(a).
        </p>
        <p>
          3.3 Covered Entity shall not request Business Associate to use or disclose PHI in any manner that would not be permissible under
          the Privacy Rule if done by Covered Entity.
        </p>
        <p>
          3.4 Covered Entity shall at all times comply with HIPAA, HITECH and state privacy laws and regulations and shall indemnify and
          hold harmless Business Associate and its owners, directors, officers, employees, agents, affiliates and successors from and
          against any allegations, claims, actions, investigations, losses, liabilities, fines, penalties and costs (including reasonable
          attorneys&apos; fees) arising from violation of federal and state privacy laws and regulations, including HIPAA and HITECH, by
          Covered Entity, its owners, employees, agents and affiliates, including following termination of the Underlying Subscription. This
          obligation shall survive termination of this Addendum and the Underlying Subscription.
        </p>
        <p>
          3.5 If Business Associate knows of a pattern of activity or practice by the Covered Entity that constitutes a material breach or
          violation of the Covered Entity&apos;s obligations under this Addendum, Covered Entity will take reasonable steps to cure the
          breach or end the violation. If such steps are unsuccessful, Business Associate will either: 1) terminate the Underlying
          Subscription and this Addendum, if feasible; or 2) report the problem to the Secretary.
        </p>
        <h3 className='page-text-h3'>4. TERMINATION</h3>
        <p>
          4.1 The term of this Addendum shall be effective as of the date of this Addendum and continue until terminated by either party or
          until the Underlying Subscription expires or is terminated. Any provision related to the use, disclosure, access, or protection of
          EPHI or PHI or that by its terms should survive termination of this Addendum shall survive termination.
        </p>
        <p>
          4.2 A breach by either party, or their agents or subcontractors, if any, of any provision of this Addendum shall constitute a
          material breach of the Addendum. If a party breaches this Addendum, the non-breaching party may, in its discretion: (i)
          immediately terminate this Addendum; (ii) provide an opportunity for the breaching party to cure the breach or end the violation
          and terminate this Addendum if the breaching party does not promptly cure the breach or end the violation within a period not to
          exceed 30 days; or (iii) report the violation to the Secretary if neither termination nor cure is feasible. Notwithstanding the
          foregoing, Business Associate shall in all events have a cure opportunity of up to 30 days unless it is in willful violation of
          law and/or regulation.
        </p>
        <h3 className='page-text-h3'>5. MISCELLANEOUS</h3>
        <p>
          5.1 A reference in this Addendum to a section in the Privacy or Security Rule or any other HIPAA regulatory or statutory provision
          means the applicable statutory or regulatory provision as in effect or as amended.
        </p>
        <p>
          5.2 Nothing express or implied in this Addendum is intended to confer, nor shall anything herein confer, upon any person other
          than Covered Entity, Business Associate, or their respective successors or assigns, any rights, remedies, obligations or
          liabilities whatsoever.
        </p>
        <p>
          5.3 The parties are independent contracting parties and nothing in this Addendum shall be deemed to make them partners or joint
          venturers.
        </p>
        <p>
          5.4 If any modification to this Addendum is Required By Law or required by HITECH or any other federal or state law affecting this
          Addendum, or if either party reasonably concludes that an amendment to this Addendum is needed because of a change in federal or
          state law or changing industry standards, such party shall notify the other of such proposed modification(s)
          (&quot;Legally-Required Modifications&quot;). Such Legally Required Modifications shall be deemed accepted by each party to the
          extent required to comply with the Legally Required Modification, and this Addendum so amended, if either party does not, within
          thirty (30) calendar days following the date of the notice (or within such other time period as may be mandated by applicable
          state or federal law), deliver to the other party its written rejection of such Legally-Required Modifications.
        </p>
        <p>
          5.5 All notices which are required or permitted to be given pursuant to this Addendum shall be in writing and provided in such
          manner as set forth in the Underlying Subscription.
        </p>
        <p>
          5.6 If any provision of this Addendum is determined by a court of competent jurisdiction to be invalid, void, or unenforceable,
          the remaining provisions hereof shall continue in full force and effect.
        </p>
        <p>
          5.7 This Addendum supplements the Underlying Subscription between them. No modification, addition to or waiver of any right,
          obligation or default shall be effective unless in writing and signed by the party against whom the same is sought to be enforced.
          No delay or failure of either party to exercise any right or remedy available hereunder, at law or in equity, shall act as a
          waiver of such right or remedy, and any waiver shall not waive any subsequent right, obligation, or default. Notwithstanding
          anything herein to the contrary, this Addendum is subject to modification by Business Associate in accordance with the terms of
          the Underlying Subscription. Business Associate may de-identify and aggregate PHI for any purpose for its own use notwithstanding
          anything herein or in the Underlying Subscription to the contrary.
        </p>
        <p>
          5.8 All questions concerning the validity, operation, interpretation, and construction of this Agreement shall be governed by and
          determined in accordance with the laws of the State of New York (without regard to any forum&apos;s conflict of law principles).
        </p>
        <p>
          5.9 The provisions of this Addendum are severable. If any provision is determined to be invalid, illegal, or unenforceable, in
          whole or in part, the remaining provisions and any partially enforceable provisions shall remain in full force and effect.
        </p>
        <p>
          5.10 In the event of litigation relating to this Addendum, the substantially prevailing party shall be entitled to recover costs
          and attorneys&apos; fees in addition to all other remedies available at law or in equity.
        </p>
        <p>
          5.11 This Addendum may be executed by electronic, facsimile or e-mail signature and in any number of counterparts, each of which
          shall be an original, and all such counterparts shall together constitute but one in the same agreement.
        </p>
      </div>
    </div>
  );
};

export default BaaComponent;
