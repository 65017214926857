export const SettingsPhysicianTexts = {
  successAddLocation: {
    title: 'Physician Successfully Added',
    content:
      'A new physician has been successfully added to the portal. You can now add them as an ordering physician when submitting a new scan request.',
  },
  successEditLocation: {
    title: 'Physician Information Successfully Edited',
    content: 'A physician has been successfully updated in the portal.',
  },
  successRemoveLocation: {
    title: 'Physician Successfully Removed',
    content:
      'By removing this physician from the portal, you will no longer be able to select them as an ordering physician when submitting a new scan request.',
  },
};
