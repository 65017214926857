import React from 'react';
import { Radio } from 'rsuite';
import { StaticPriorAuthTaskComponentProps } from '../interfaces';
import FileThumbnailsList from '../../../components/file-viewer/FileThumbnailsList';

const StaticPriorAuthTaskComponent: React.FC<StaticPriorAuthTaskComponentProps> = ({ fileList, data, taskNotes }) => {
  return (
    <div className='content-data'>
      <div className='content-data-filed'>
        <div className='label'>Reviewer name:</div>
        <div className='content-data-filed_content'>
          <p>{data.ReviewerName}</p>
        </div>
      </div>
      <div className='row col-12 rs-radio-group'>
        <Radio className='content-data-filed' checked={data.ReviewStatus === 1} disabled>
          Approved
        </Radio>
        <Radio className='content-data-filed' checked={data.ReviewStatus === 2} disabled>
          Denied
        </Radio>
        <Radio className='content-data-filed' checked={data.ReviewStatus === 3} disabled>
          Other
        </Radio>
      </div>
      {data.ReviewStatus === 1 && (
        <>
          <div className='content-data-filed'>
            <div className='label'>Approval ID:</div>
            <div className='content-data-filed_content'>
              <p>{data.ApprovalID}</p>
            </div>
          </div>

          <div className='content-data-filed'>
            <div className='label'>Upload documents:</div>
            <FileThumbnailsList filesList={fileList} />
          </div>
        </>
      )}
      {data.ReviewStatus === 0 ? (
        <div className='content-data-filed'>
          <div className='label'>Denial Reason and other comment:</div>
          <div className='content-data-filed_content'>
            <p>{taskNotes}</p>
          </div>
        </div>
      ) : (
        <div className='content-data-filed'>
          <div className='label'>Comment:</div>
          <div className='content-data-filed_content'>
            <p>{taskNotes}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaticPriorAuthTaskComponent;
