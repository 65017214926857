import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import TextField from '../../../../components/text-field-component';
import { Button, Modal, Form, Toggle } from 'rsuite';
import { model, OrganizationFormValue } from './OrganizationUtil';
import { addOrganization, editOrganization } from '../../../../api/organization/OrganizationAPI';
import { FormInstance } from 'rsuite/Form';
import { useError } from '../../../../utils/UseError';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { OrganizationFormData, OrganizationProps } from './interfaces';
import { FormError } from '../../../../models/General';
import { useSignal } from '../../../../utils/UseSignal';

const OrganizationModal: React.FunctionComponent<OrganizationProps> = props => {
  const { onClose, organization, editMode, editFormValue } = props;
  const [formValue, setFormValue] = useState<OrganizationFormData>(OrganizationFormValue);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const formRef = useRef<FormInstance | null>(null);
  const signal = useSignal();

  useEffect(() => {
    if (editMode && editFormValue) {
      return setFormValue(editFormValue);
    }
    setFormValue(OrganizationFormValue);
  }, [editMode]);

  const handleChange = (formValue: OrganizationFormData, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const handleSubmit = () => {
    const { internalName, superAdmin, IsChargebeeUsed } = formValue;

    if ((formRef.current && !formRef.current.check()) || !internalName || !superAdmin) {
      return;
    }

    setIsSubmitPressed(true);

    if (!organization) {
      addOrganization(internalName, superAdmin, IsChargebeeUsed)
        .then(() => onClose(formValue))
        .catch(err => {
          setErrorText(err, formValue);
        })
        .finally(() => setIsSubmitPressed(false));
    } else {
      organization.ID &&
        editOrganization(organization.ID, internalName, superAdmin, IsChargebeeUsed)
          .then(() => onClose(true))
          .catch(err => {
            setErrorText(err, formValue);
          })
          .finally(() => {
            if (!signal.aborted) {
              setIsSubmitPressed(false);
            }
          });
    }
  };

  const handleClose = () => {
    if (!isSubmitPressed) {
      onClose(false);
    }
  };

  const isSubmitDisabled = useMemo(() => {
    return (
      !!(editFormValue && editFormValue.internalName === formValue.internalName) &&
      formValue.IsChargebeeUsed === editFormValue.IsChargebeeUsed
    );
  }, [formValue]);

  return (
    <div>
      <Modal size={'sm'} open onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{organization ? 'Edit' : 'Add'} Physician Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onChange={(formValue, event) => handleChange(formValue as OrganizationFormData, event as ChangeEvent<HTMLInputElement>)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={model}
          >
            <TextField
              error={formError.internalName || dataErrors.internalName}
              name='internalName'
              label='Internal Name*'
              value={formValue.internalName}
            />
            <TextField
              disabled={editMode}
              error={formError.superAdmin || dataErrors.superAdmin}
              name='superAdmin'
              label='Super Admin Email*'
              value={formValue.superAdmin}
            />
            <div className='checkbox-setting-nav_item checkbox-row mb1'>
              <Toggle
                checked={!!formValue.IsChargebeeUsed}
                checkedChildren='On'
                unCheckedChildren='Off'
                onChange={value => setFormValue({ ...formValue, IsChargebeeUsed: value ? 1 : 0 })}
              />
              <div className='flex-container'>
                <div className='wrap'>
                  <p>Billing</p>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close btn-lg' disabled={isSubmitPressed} onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitPressed || isSubmitDisabled} onClick={handleSubmit}>
              {organization ? 'Update Physician Information' : 'Add Organization'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrganizationModal;
