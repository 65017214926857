import React from 'react';
import { Props, State } from './interfaces';
import SystemError from './../../assets/svg/system-error.svg';

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      stack: undefined,
      message: undefined,
      status: undefined,
      isPageCanceled: false,
    };
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    if (event.reason?.status === 401 || event.reason?.message === 'Worker was terminated') {
      return;
    }

    if (event.reason?.message === 'Failed to load Stripe.js' || event.reason?.message === 'Failed to fetch') {
      this.setState({
        hasError: true,
        stack: undefined,
        message: 'Failed to fetch',
        status: undefined,
        isPageCanceled: true,
      });

      return;
    }

    this.setState({
      hasError: true,
      stack: undefined,
      message: event.reason,
      status: event.reason?.status,
      isPageCanceled: false,
    });
  };

  public static getDerivedStateFromError(error: Error & { status?: number }): State {
    return { hasError: true, stack: error.stack, message: error.message, status: error.status, isPageCanceled: false };
  }

  componentDidMount(): void {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  public render(): React.ReactNode {
    const { status, isPageCanceled } = this.state;
    const isStatusGreaterOrEqualTo500 = status && status >= 500;

    if (this.state.hasError) {
      return (
        <div className='wrapper'>
          <main className='main'>
            <section className='page-section'>
              <div className='system-error container'>
                <div className='system-error_wrap'>
                  <figure className='system-error_figure'>
                    <img src={SystemError} alt='error' title='system error' />
                    <figcaption>
                      {isStatusGreaterOrEqualTo500 || isPageCanceled ? 'Ooops! Something went wrong.' : 'We’re working on an issue.'}
                    </figcaption>
                  </figure>
                  {status && (
                    <div className='system-error_num'>
                      Error code: <span>{status}</span>
                    </div>
                  )}
                  <div className='system-error_text'>
                    <p>An error has occured and we’re working to fix the problem.</p>
                    <p>
                      {isStatusGreaterOrEqualTo500 || isPageCanceled ? 'Try to go back to ' : 'You can try the '}
                      <a href={window.location.href}>previous page</a> or{' '}
                      {isStatusGreaterOrEqualTo500 || isPageCanceled ? 'start working' : 'start'} from the <a href='/'>home page</a>.
                    </p>
                    <p>
                      {`If this does not help - please${isStatusGreaterOrEqualTo500 || isPageCanceled ? ' ' : ' feel free '}`}
                      <a href='mailto:support@medmo.com'>contact us</a>.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
