export const tasksColumns = [
  {
    dataKey: 'TaskType',
    width: 200,
    align: 'center',
    headTitle: 'Task',
    tooltip: 'Task Type',
    flexGrow: 2,
    sortable: true,
  },
  {
    dataKey: 'PatientName',
    width: 140,
    align: 'center',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 1,
    sortable: false,
  },
  {
    dataKey: 'DOB',
    width: 140,
    align: 'left',
    headTitle: 'DOB',
    tooltip: 'Patient date of birth',
    flexGrow: 1,
    sortable: false,
  },
  {
    dataKey: 'Modality',
    width: 130,
    align: 'center',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    flexGrow: 1,
    sortable: true,
    type: 'scanTypeToolTip',
    fieldName: 'FriendlyName',
  },
  {
    dataKey: 'AssignedUser',
    width: 200,
    align: 'center',
    type: 'select',
    headTitle: 'Assignee',
    tooltip: 'Task Assignee',
    flexGrow: 2,
    sortable: true,
    idKey: 'ID',
  },
  // {
  //   dataKey: 'TestRequestID',
  //   width: 198,
  //   align: 'left',
  //   headTitle: 'SRID',
  //   tooltip: 'Scan Request ID',
  //   flexGrow: 1,
  //   sortable: true,
  // },
  {
    dataKey: 'TaskCreatedDate',
    width: 130,
    align: 'center',
    headTitle: 'Created',
    tooltip: 'Task Created Date',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'DiffDate',
    width: 140,
    align: 'left',
    headTitle: 'Days',
    tooltip: 'How many days have passed',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'TaskStatus',
    width: 130,
    align: 'center',
    type: 'taskStatus',
    headTitle: 'Status',
    tooltip: 'Task Status',
    flexGrow: 1,
    sortable: true,
  },
];
