import ResponseError from '../errors/ResponseError';

export interface RequestMain extends RequestInit {
  url: string;
}

const mergeFetchOpt = (options: RequestMain): RequestMain => {
  const headers =
    options.headers ||
    new Headers({
      Expires: '-1',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    });

  options.mode = 'cors';
  options.credentials = 'include';
  const defaults = { headers };
  return { ...defaults, ...options };
};

const handleErrors = (
  res: ArrayBuffer | { status: number; message: string },
): ArrayBuffer | Error | { status?: number; message?: string } => {
  let json = {};

  if (!(res instanceof ArrayBuffer)) {
    if (res.status && res.status >= 500) {
      throw new Error(res.message);
    } else if (res.status && res.status !== 200 && res.message) {
      throw new ResponseError(res);
    }
  }

  try {
    json = res;
  } catch (e) {
    throw Error('Response is not in json format');
  }

  return json;
};

export const request = (options: RequestMain, type?: string): Promise<any> => {
  const opts = mergeFetchOpt(options);

  const shouldStop = localStorage.getItem('isSessionExpired');

  if (shouldStop) {
    return new Promise((resolve, reject) => reject);
  }

  return fetch(opts.url, opts)
    .then(response => (!!type && type === 'arrayBuffer' ? response.arrayBuffer() : response.json()))
    .then(response => handleErrors(response));
};
