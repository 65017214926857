import { FilterDataType } from './filter-scans-modal/interfaces';
import { RowDataType } from '../../components/table-component/interfaces';

export const columns = [
  {
    dataKey: 'ScanType',
    minWidth: 135,
    align: 'left',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    flexGrow: 0.75,
    sortable: true,
    type: 'scanTypeToolTip',
    fieldName: 'FriendlyName',
  },
  {
    dataKey: 'ScanID',
    minWidth: 70,
    align: 'center',
    type: 'link',
    headTitle: 'ID',
    tooltip: 'Scan request ID',
    flexGrow: 0.35,
    sortable: true,
  },
  {
    dataKey: 'OrderDate',
    minWidth: 145,
    align: 'center',
    headTitle: 'Order Date',
    tooltip: 'Order Date',
    flexGrow: 0.35,
    sortable: true,
  },
  {
    dataKey: 'PatientName',
    minWidth: 155,
    align: 'left',
    headTitle: 'Patient',
    tooltip: `Patient name`,
    flexGrow: 1.1,
    sortable: true,
    type: 'link',
    idKey: 'POUserID',
    usePath: false,
    linkUrl: '/patients',
  },
  {
    dataKey: 'DOB',
    align: 'left',
    minWidth: 85,
    headTitle: 'DOB',
    tooltip: `Patient date of birth`,
    flexGrow: 0.5,
    sortable: true,
  },
  {
    dataKey: 'OrderingPhysician',
    minWidth: 155,
    align: 'left',
    headTitle: 'Physician',
    tooltip: 'Ordering physician',
    flexGrow: 1.1,
    sortable: true,
  },
  {
    dataKey: 'LocationAddress',
    minWidth: 155,
    align: 'center',
    headTitle: 'Location',
    flexGrow: 1.1,
    sortable: true,
  },
  {
    dataKey: 'StageMFP',
    align: 'left',
    type: 'status',
    headTitle: 'Status',
    minWidth: 145,
    tooltip: 'Status of the scan request',
    flexGrow: 0.7,
    sortable: true,
    reasonField: 'Reason',
    infoColor: 'InfoColor',
  },
  {
    dataKey: 'AppointmentDate',
    minWidth: 165,
    align: 'left',
    type: 'scanResult',
    headTitle: 'Appointment Date',
    tooltip: 'Appointment date',
    flexGrow: 0.8,
    sortable: true,
    reportsCount: 'Reports',
    statusKey: 'StageMFP',
    onClickHandler: (data: RowDataType): void => {
      window.location.href = `${window.location.pathname}/${data.ScanID}#reports`;
    },
  },
  {
    dataKey: 'OwnerName',
    minWidth: 120,
    align: 'left',
    headTitle: 'Owner',
    tooltip:
      'The order owner will be auto-assigned all tasks associated with this scan request.  You can can re-assign tasks after they are created',
    flexGrow: 0.7,
    sortable: true,
  },
];

export const ConfirmModalTexts = {
  removeNote: {
    title: `Are you sure you want to delete this comment?`,
    text: `You will not be able to undo this action.`,
    cancelBtnText: 'Cancel',
    submitBtnText: 'Yes, Delete It',
  },
};

export const searchBarColumns = [
  {
    dataKey: 'TestRequestID',
    //width: 198,
    align: 'left',
    headTitle: 'ID',
    tooltip: 'Scan request ID',
    flexGrow: 0.5,
    sortable: false,
    type: 'withBoldText',
  },
  {
    dataKey: 'PatientName',
    //width: 198,
    align: 'left',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 2,
    sortable: false,
    type: 'withBoldText',
  },
  {
    dataKey: 'DOB',
    //width: 198,
    align: 'center',
    headTitle: 'DOB',
    tooltip: 'Patient date of birth',
    flexGrow: 0.8,
    sortable: false,
    type: 'withBoldText',
  },
  {
    dataKey: 'ScanType',
    //width: 198,
    align: 'left',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    flexGrow: 0.8,
    sortable: false,
  },
  {
    dataKey: 'StageMFP',
    //width: 198,
    align: 'left',
    headTitle: 'Status',
    tooltip: 'Status of the scan request',
    flexGrow: 0.8,
    sortable: false,
  },
];

export const searchBarColumnsClientOrderId = [...searchBarColumns];
searchBarColumnsClientOrderId.splice(1, 0, {
  dataKey: 'ClientOrderID',
  align: 'left',
  headTitle: 'Client ID',
  tooltip: 'Client Order ID',
  flexGrow: 1,
  sortable: false,
  type: 'withBoldText',
});

export const TABS_NAME = {
  Comment: 'comments',
  Report: 'reports',
  Details: 'request_details',
  Scheduling: 'scheduling_information',
  Tasks: 'tasks',
};

export const initDataForFilter: FilterDataType = {
  status: [],
  from: null,
  to: null,
  owner: [],
  prescriber: [],
  location: [],
};
