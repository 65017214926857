import React, { FC, useEffect, useRef, useState } from 'react';
import { loadStripe, StripeCardElement, StripeCardElementChangeEvent, StripeError } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import TextField from '../text-field-component';
import { STRIPE_API_KEY } from '../../config/FrontConfig';
import { Checkout } from './interfaces';
import CardField from './Card';

const stripePromise = loadStripe(STRIPE_API_KEY);

const CheckoutForm: FC<Checkout> = props => {
  const { formError, setFullName, cardOwner, callback } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<StripeError | boolean | string | null>(null);
  const [cardComplete, setCardComplete] = useState<boolean>(false);

  const ownerNameIsChecked = useRef<boolean>(false);

  useEffect(() => {
    setError(formError.CardType || null);
  }, [formError]);

  const checkFields = async (name: string = cardOwner) => {
    if (!stripe || !elements) {
      return;
    }

    if (!name) {
      setError('Please fill card owner name field');
      return;
    }

    const ownerInfo = {
      owner: {
        name,
      },
    };

    const card = elements.getElement(CardElement);

    const payload = await stripe.createSource(card as StripeCardElement, ownerInfo);

    if (payload.error !== undefined) {
      setError(payload.error);
      callback(payload.error, null);
    } else {
      setError(null);
      callback(null, payload.source);
    }
  };

  const changeOwner = (val: string) => {
    setFullName(val);
    delete formError.fullNameCard;

    if (!val) {
      ownerNameIsChecked.current = false;
      return;
    }

    if (cardComplete && !ownerNameIsChecked.current) {
      ownerNameIsChecked.current = true;
      checkFields(val);
    }
  };

  const changeCard = (e: (StripeCardElementChangeEvent | undefined) | { error: StripeError | any; complete: boolean }) => {
    if (e) {
      error && setError(e.error);
      setCardComplete(e.complete);

      checkFields();
    }
  };

  return (
    <div className='form-new-scan-stripe'>
      <div className='rs-form-group form-new-scan-group width-l'>
        <TextField
          name='fullNameCard'
          error={formError.fullNameCard}
          onChange={val => changeOwner(val)}
          autoComplete='off'
          value={cardOwner}
          label='Full name on card*'
        />
      </div>
      <CardField onChange={changeCard} error={error} />
    </div>
  );
};

const Stripe: FC<Checkout> = props => {
  const { formError, setFullName, cardOwner, callback } = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm formError={formError} cardOwner={cardOwner} setFullName={setFullName} callback={callback} />
    </Elements>
  );
};

export default Stripe;
