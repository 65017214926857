export const InfoTexts = {
  successUserRegister: {
    title: 'Account Successfully Activated',
    content: 'Your account has been successfully activated!',
    buttonText: 'Log in',
  },
  successAdminRegister: {
    title: 'Account Setup Successfull',
    content: 'Your account has been successfully activated!',
    buttonText: 'Log in',
  },
};
