import { TextObjectInterface } from '../../interfaces';

export const errorTextsObject = {
  'Max attempts reached': 'Sorry, you have reached maximum amount of attempts to sent a reset link. If you still can not login, please',
};

export const findErrorDescription = (textsObject: TextObjectInterface, text: string): string => {
  if (textsObject[text]) {
    return textsObject[text];
  }
  return '';
};
