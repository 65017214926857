import React from 'react';
import { StaticNotesTaskComponentProps } from '../interfaces';
import FileThumbnailsList from '../../../components/file-viewer/FileThumbnailsList';

const StaticNotesTaskComponent: React.FC<StaticNotesTaskComponentProps> = ({ fileListNotes, clinicalNotes, taskNotes }) => {
  // const reopen = () => {
  //   reopenTask(taskID)
  //     .then(() => {
  //       history.push(TasksUrl);
  //     })
  //     .catch(e => {
  //       setModalError({
  //         title: 'Complete error',
  //         content: e.message,
  //       });
  //     });
  // };

  return (
    <div className='content-data'>
      <div className='content-data-filed'>
        <div className='label'>Clinical notes:</div>
        <div className='content-data-filed_content'>
          <p>{clinicalNotes}</p>
        </div>
      </div>
      <div className='content-data-filed'>
        <div className='label'>Upload clinical notes:</div>
        <FileThumbnailsList filesList={fileListNotes} />
      </div>
      <div className='content-data-filed'>
        <div className='label'>Notes to Medmo Care Team (optional):</div>
        <div className='content-data-filed_content'>
          <p>{taskNotes}</p>
        </div>
      </div>
    </div>
  );
};

export default StaticNotesTaskComponent;
