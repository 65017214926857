import { Schema } from 'rsuite';
import { updateData } from '../../../../utils/GeneralUtil';
import { transformToLocalDateWithTime } from '../../../../utils/TimeUtil';
import { ApiToken } from '../../../../api/general/interfaces';

const { StringType } = Schema.Types;
export const companyInfoForm = Schema.Model({
  name: StringType().isRequired('This field is required'),
  description: StringType(),
});

export const generateAPiTokenData = (tokens: ApiToken[]) => {
  const rawTokens = tokens.map(t => {
    t.Token = '********************' + t.Token;
    t.Active = t.IsActive ? 'Active' : 'Revoked';
    return t;
  });
  return updateData(rawTokens, { Created: transformToLocalDateWithTime });
};

export enum EnumLogoState {
  Delete = -1,
  Add = 1,
  DoNothing = 0,
}

export const DefaultOrganizationValue = {
  Description: '',
  Email: '',
  ID: 0,
  InternalName: '',
  IsActive: 0,
  Logo: {
    key: '',
    originalName: '',
    fileTitle: '',
    url: '',
  },
  Name: '',
};

export const DefaultOrganizationSettings = {
  name: '',
  description: '',
  logo: [],
  logoState: 0,
};

export const companyInfoEditedText = {
  title: 'Success',
  content: 'Organization info successfully edited',
  buttonText: 'Close',
};

export const tokenTableColumns = [
  {
    dataKey: 'Name',
    align: 'left',
    minWidth: 150,
    width: 200,
    headTitle: 'Name',
  },
  {
    dataKey: 'Token',
    align: 'left',
    minWidth: 300,
    width: 350,
    headTitle: 'Token',
  },
  {
    dataKey: 'Created',
    align: 'left',
    minWidth: 300,
    width: 350,
    headTitle: 'Created',
  },
];
