import React from 'react';
import { ClinicalNotesTaskTabProps } from '../interfaces';
import FileListPreviewer from '../../../components/file-viewer/FileListPreviewerComponent';

const ClinicalNotesTaskTabComponent: React.FunctionComponent<ClinicalNotesTaskTabProps> = ({ noteFiles, ClinicalNotes }) => {
  return (
    <div className='task-details_tabs_item'>
      <div className='title'>Clinical notes:</div>
      <div className='text-wrap'>
        <div className='wrap-scroll'>
          <p>{ClinicalNotes}</p>
        </div>
      </div>
      <div className='preview-pdf'>
        <FileListPreviewer filesList={noteFiles} />
      </div>
    </div>
  );
};

export default ClinicalNotesTaskTabComponent;
