import { useState } from 'react';
import ResponseError, { ResponseErrorType } from '../errors/ResponseError';
import { FormError, Message } from '../models/General';

const DEFAULT_TEXT = 'Something went wrong';

export function useError(
  initMessage: Message,
): [
  errorMessage: Message,
  setError: (error: ResponseErrorType | Error | string | null, formData?: Record<string, any>) => void,
  dataErrors: FormError,
  updateErrors: (data: FormError) => void,
] {
  const [errorMessage, setErrorMessage] = useState<Message>(initMessage);
  const [dataErrors, setDataErrors] = useState<FormError>({});

  const setError = (error: ResponseErrorType | Error | string | null, formData?: Record<string, any>) => {
    if (!error) {
      setErrorMessage('');
      setDataErrors({});
      return;
    }

    if (typeof error === 'string') {
      setErrorMessage(error || DEFAULT_TEXT);
      setDataErrors({});
      return;
    }

    if (!(error instanceof ResponseError) || !error?.errors || Object.keys(error?.errors).length === 0 || !formData) {
      setErrorMessage(error.message || DEFAULT_TEXT);
      setDataErrors({});
      return;
    }

    let message = '';
    const errors: FormError = {};

    Object.entries(error?.errors).forEach(([field, msg]) => {
      if (formData[field] !== undefined) {
        errors[field] = msg;
      } else {
        message = `${message ? '/n' : ''}${msg}`;
      }
    });

    setErrorMessage(message);
    setDataErrors(errors);
  };

  const updateErrors = (data: FormError) => setDataErrors({ ...dataErrors, ...data });

  return [errorMessage, setError, dataErrors, updateErrors];
}
