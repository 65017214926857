import React, { useContext, useMemo } from 'react';
import { SettingsUrl } from '../../config/UrlsConfig';
import { UserContextType, UserContext } from '../../contexts/UserContext';
import { AccountStatuses } from '../../models/User';

const BannerComponent: React.FunctionComponent = () => {
  const currentUser = useContext<UserContextType>(UserContext);

  const { DaysLeft, AccountStatus } = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return currentUser.PO.Access;
    }

    return { DaysLeft: 0, AccountStatus: AccountStatuses.Active };
  }, [currentUser]);

  if (AccountStatus === AccountStatuses.Active) {
    return <></>;
  }

  if (AccountStatus === AccountStatuses.Expired) {
    return (
      <div className='modelInfo-hint modelInfo-hint-big'>
        Your free trial to our &apos;Unlimited Plan&apos; features has expired. You currently have access to our Base Plan. Learn more and
        unlock features <a href={`${SettingsUrl}#billing`}>here</a>.
      </div>
    );
  }

  return (
    <div className='modelInfo-hint'>
      Enjoy our &apos;Unlimited Plan&apos; features for free for another{' '}
      <b>
        {DaysLeft} day{DaysLeft === 1 ? '' : 's'}.
      </b>{' '}
      Learn more and see plans <a href={`${SettingsUrl}#billing`}>here</a>.
    </div>
  );
};

export default BannerComponent;
