import { FilterDataType } from './interfaces';
import { TaskNote } from '../../models/TaskNote';

export const columns = [
  {
    dataKey: 'TaskType',
    minWidth: 140,
    align: 'center',
    headTitle: 'Task',
    tooltip: 'Task type',
    flexGrow: 1.6,
    sortable: true,
  },
  {
    dataKey: 'PatientName',
    minWidth: 140,
    align: 'center',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 1.4,
    sortable: false,
  },
  {
    dataKey: 'DOB',
    minWidth: 90,
    align: 'left',
    headTitle: 'DOB',
    tooltip: 'Patient date of birth',
    flexGrow: 1,
    sortable: false,
  },
  {
    dataKey: 'Modality',
    minWidth: 130,
    align: 'center',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    flexGrow: 1,
    sortable: true,
    type: 'scanTypeToolTip',
    fieldName: 'FriendlyName',
  },
  {
    dataKey: 'AssignedUser',
    minWidth: 160,
    align: 'center',
    type: 'select',
    headTitle: 'Assignee',
    tooltip: 'Task assignee',
    flexGrow: 1.6,
    sortable: true,
    idKey: 'ID',
  },
  {
    dataKey: 'MFPTaskNotes',
    minWidth: 200,
    align: 'center',
    type: 'taskNote',
    headTitle: 'Task notes',
    tooltip: 'Task notes',
    flexGrow: 2,
    sortable: false,
  },
  {
    dataKey: 'LocationAddress',
    minWidth: 170,
    align: 'center',
    headTitle: 'Location',
    flexGrow: 1.5,
    sortable: true,
  },
  {
    dataKey: 'TestRequestID',
    minWidth: 90,
    align: 'left',
    headTitle: 'SRID',
    tooltip: 'Scan request ID',
    flexGrow: 0.7,
    sortable: true,
    type: 'link',
    linkUrl: '/all-scans',
    usePath: false,
  },
  {
    dataKey: 'TaskCreatedDate',
    minWidth: 110,
    align: 'center',
    headTitle: 'Created',
    tooltip: 'Task created date',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'DiffDate',
    minWidth: 90,
    align: 'left',
    headTitle: 'Days',
    tooltip: 'How many days have passed',
    flexGrow: 0.7,
    sortable: true,
  },
  {
    dataKey: 'TaskStatus',
    minWidth: 90,
    align: 'center',
    type: 'taskStatus',
    headTitle: 'Status',
    tooltip: 'Task status',
    flexGrow: 0.7,
    sortable: true,
  },
];

export const searchBarColumns = [
  {
    dataKey: 'TaskType',
    // width: 200,
    align: 'center',
    headTitle: 'Task',
    tooltip: 'Task type',
    flexGrow: 2,
    sortable: true,
  },
  {
    dataKey: 'PatientName',
    // width: 140,
    align: 'center',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 2.5,
    sortable: true,
    type: 'withBoldText',
  },
  {
    dataKey: 'DOB',
    // width: 140,
    align: 'left',
    headTitle: 'DOB',
    tooltip: 'Patient date of birth',
    type: 'withBoldText',
    flexGrow: 1.2,
    sortable: true,
  },
  {
    dataKey: 'Modality',
    width: 148,
    align: 'left',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    lexGrow: 1.1,
    sortable: false,
  },
  {
    dataKey: 'TestRequestID',
    // width: 198,
    align: 'left',
    headTitle: 'SRID',
    tooltip: 'Scan request ID',
    flexGrow: 1,
    sortable: false,
  },
  {
    dataKey: 'TaskStatus',
    align: 'center',
    type: 'taskStatus',
    headTitle: 'Status',
    tooltip: 'Task status',
    flexGrow: 1.5,
    sortable: true,
  },
];

export const TASK_TABS_NAME = {
  Details: 'details',
  ClinicalNotes: 'clinical_notes',
  UploadedReports: 'uploaded_reports',
};

export enum TaskStatuses {
  Closed = 'Closed',
  Completed = 'Completed',
  Open = 'Open',
}

export const chooseStatus = (status: TaskStatuses): string => {
  switch (status) {
    case TaskStatuses.Closed:
      return 'close';

    case TaskStatuses.Completed:
      return 'completed';

    default:
      return '';
  }
};

export const texts = {
  error: {
    fileSize: {
      title: 'File size error',
      content: 'File size should not be greater than 20mb',
    },

    fileFormat: {
      title: 'Upload error',
      content: 'Upload only png | jpg | pdf | jpeg',
    },

    deleteNote: {
      title: 'Failed to delete note file',
      content: 'Please try later',
    },

    uploadNotes: {
      title: 'Error',
      content: 'Notes were failed to upload',
    },

    uploadDocuments: {
      title: 'Error',
      content: 'Documents were failed to upload',
    },
  },
};

export const NotesTaskDefaultValue = {
  fileListNotes: [],
  clinicalNotes: '',
  taskNotes: '',
};

export const GeneralTaskDefaultValue = {
  fileList: [],
  notes: '',
};

export const initDataForFilter: FilterDataType = {
  type: [],
  status: [1],
  ownerIds: [],
  prescriberIds: [],
  locationIds: [],
};

export const TaskFormValue: TaskNote = {
  ID: 0,
  MFPTaskNotes: null,
};
