import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Form, Radio, RadioGroup, Button } from 'rsuite';
import TextField from '../../../components/text-field-component';
import { Nav } from 'rsuite';
import { saveAs } from 'file-saver';
import ClinicalNotesTaskTabComponent from './ClinicalNotesTaskTabComponent';
import DetailsTaskTabComponent from './DetailsTaskTabComponent';
import { TaskStatuses, TASK_TABS_NAME, texts } from '../TasksUtil';
import { PriorAuthResponse, PriorAuthTaskFormData, TaskProps } from '../interfaces';
import { FileView } from '../../../components/file-viewer/interfaces';
import { FileType } from 'rsuite/Uploader';
import { FileTypeWithTitle, ModalError } from '../../new-scan/interfaces';
import { initPriorAuthTaskFormData } from '../task/TaskUtil';
import { checkForEmptyString, checkForWrongFile } from '../../../utils/GeneralUtil';
import InfoModalComponent from '../../../components/modals/info-modal';
import { PriorAuthTaskApprovedSchema, PriorAuthTaskSchema } from '../Schemas';
import { FormInstance } from 'rsuite/Form';
import { completePriorAuthTask, uploadAuthDocuments } from '../../../api/tasks/TasksApi';
import { FormError } from '../../../models/General';
import StaticPriorAuthTaskComponent from './StaticPriorAuthTaskComponent';
import { RequestFiles } from '../../../api/all-scans/interfaces';
import FilesUploader from '../../../components/files-uploader';
import { transformDateWithTime } from '../../../utils/TimeUtil';
import { ValueType } from 'rsuite/esm/Radio';
import Textarea from '../../../components/textarea';
import { onLoadFile, renameFile } from '../../../utils/FileUtils';
import { useSignal } from '../../../utils/UseSignal';

const PriorAuthTaskComponent: React.FC<TaskProps> = ({ taskData, liftGoBackTextUp, getAllTaskInfo }) => {
  const { special, task, testRequest, insurance, Patient, ImagingCenter, authorizationFiles } = taskData;
  const [modalError, setModalError] = useState<ModalError>({
    title: '',
    content: '',
  });
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const [formData, setFormData] = useState<PriorAuthTaskFormData>(initPriorAuthTaskFormData);
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const fileLoadedCount = useRef<number>(0);
  const formRef = useRef<FormInstance>(null);
  const signal = useSignal();

  useEffect(() => {
    setActiveNav(TASK_TABS_NAME.Details);
    liftGoBackTextUp(`Prior authorization of Scan Request  #${testRequest.ScanID}`);
    let files: Array<FileTypeWithTitle & FileView> = [];
    if (authorizationFiles) {
      files = authorizationFiles.map((file, i) => ({
        key: file.key,
        url: file.url as string,
        name: file.originalName,
        fileTitle: file.fileTitle,
        id: i,
      }));
    }
    setFormData({
      ...formData,
      TaskID: task.ID,
      ReviewerName: checkForEmptyString((special as PriorAuthResponse).ReviewerName),
      ReviewStatus: (special as PriorAuthResponse).ReviewStatus !== undefined ? (special as PriorAuthResponse).ReviewStatus : 0,
      ApprovalID: checkForEmptyString((special as PriorAuthResponse).ApprovalID),
      files,
      Comment: checkForEmptyString(task.TaskNotes),
    });
  }, [testRequest, task]);

  const isSubmitEnabled = useMemo(() => {
    return (
      !!formData.ReviewerName &&
      formData.ReviewStatus !== null &&
      (formData.ReviewStatus === 1 ? !!formData.ApprovalID || formData.files.length > 0 : !!formData.Comment)
    );
  }, [formData]);

  const checkDocuments = (files: FileTypeWithTitle[]): boolean => {
    if (files && files.length) {
      const isWrongFileExist = checkForWrongFile(files);
      if (isWrongFileExist === false) {
        return true;
      }

      setFormError({ ...formError, fileNoteName: isWrongFileExist });

      return false;
    }

    return true;
  };

  const fileLoadFinish = () => {
    if (!isLoading) {
      return;
    }
    fileLoadedCount.current += 1;
    if (fileLoadedCount.current === formData.files.length) {
      fileLoadedCount.current = 0;
    }
  };

  const uploadDocuments = async (fileList: Array<FileType>) => {
    if (fileList && fileList.length) {
      const newArr: (FileTypeWithTitle & FileView)[] = [...formData.files];
      for (let i = 0; i < fileList.length; i++) {
        const item: FileTypeWithTitle = fileList[i] as FileTypeWithTitle;
        if (!formData.files[i]) {
          const result: string = await onLoadFile(item);
          item.id = i;
          item.url = result;
          item.fileTitle = item.name as string;
          item.mimeType = item.blobFile?.type;

          newArr.push(item);
        } else {
          newArr[i].id = i;
        }
      }
      setFormData({
        ...formData,
        files: newArr,
      });
      setFormError({ ...formError, fileNoteName: '' });
    } else {
      setFormData({
        ...formData,
        files: [],
      });
      setFormError({ ...formError, fileNoteName: '' });
    }
  };

  const downloadFile = (doc: FileTypeWithTitle): void => {
    if (!doc.blobFile) {
      saveAs(doc.url as string, doc.name);
      return;
    }
    saveAs(doc.blobFile as Blob);
  };

  const uploadFiles = async (data: FileTypeWithTitle[]) => {
    const files: FileTypeWithTitle[] = [];
    const keyFiles: RequestFiles[] = [];
    const fileData = new FormData();

    if (!data || !data.length) {
      return [];
    }

    for (let i = 0; i < data.length; i++) {
      const file: FileTypeWithTitle = data[i] as FileTypeWithTitle;
      if (file.blobFile) {
        files.push(file);
        const newFile = await renameFile(file.blobFile);
        fileData.append('files', newFile);
      }
      if (file.key) {
        keyFiles.push({
          key: file.key,
          fileTitle: file.fileTitle,
          originalName: file.name as string,
        });
      }
    }

    if (!files.length) {
      return [];
    }

    return uploadAuthDocuments(fileData)
      .then(response => {
        if (response) {
          const res: FileTypeWithTitle[] = files.map((el: { fileTitle: string }, i: number) => {
            return {
              ...response[i],
              fileTitle: el.fileTitle,
            };
          });
          return [...res, ...keyFiles];
        }
      })
      .catch(() => setModalError(texts.error.uploadDocuments));
  };

  const submit = async () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    const { formError: newFormError } = await formRef.current.checkAsync();
    const { ReviewStatus, ApprovalID, files } = formData;

    if (formData && ReviewStatus === 1 && !ApprovalID && !!files && !files.length) {
      setFormError({ ...newFormError, ApprovalID: 'Please enter or upload authorization documents.' });
      return;
    }

    if (Object.keys(newFormError).length) {
      setFormError(newFormError);
      return;
    }

    setIsLoading(true);
    const docsList = await uploadFiles(files);
    completePriorAuthTask({
      ...formData,
      files: docsList as (FileTypeWithTitle & FileView)[],
      ReviewStatus: ReviewStatus === 0 ? null : ReviewStatus,
    })
      .then(() => {
        if (!signal.aborted) {
          getAllTaskInfo(true);
        }
      })
      .catch(e => {
        if (!signal.aborted) {
          setModalError({
            title: 'Complete error',
            content: e.message,
          });
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const onCloseErrorModal = (): void => {
    setModalError({
      title: '',
      content: '',
    });
  };

  const refreshTaskDetails = () => {
    getAllTaskInfo(false);
  };

  return (
    <>
      <div className='task-content_form'>
        <Form
          className='task-form_prior'
          formValue={formData}
          model={formData.ReviewStatus === 1 ? PriorAuthTaskApprovedSchema : PriorAuthTaskSchema}
          ref={formRef}
          onCheck={formError => setFormError(formError)}
          onChange={data => setFormData(data as PriorAuthTaskFormData)}
        >
          <div className='content'>
            <div className='title'>Please obtain prior authorization for this order with the following imaging center:</div>
            <div className='imaging-info'>
              <div className='title'>Imaging Center Information:</div>
              <div className='row'>
                <div className='th'>Imaging Center Name:</div>
                <div className='td'>{ImagingCenter?.PracticeLocationName || ImagingCenter?.PracticeName || 'N/A'}</div>
              </div>
              <div className='row'>
                <div className='th'>Address:</div>
                <div className='td'>{ImagingCenter?.Address || 'N/A'}</div>
              </div>
              <div className='row'>
                <div className='th'>NPI:</div>
                <div className='td'>{(special as PriorAuthResponse)?.ImagingCenterNPI || 'N/A'}</div>
              </div>
              <div className='row'>
                <div className='th'>Appointment date:</div>
                <div className='td'>{ImagingCenter?.AppointmentDate ? transformDateWithTime(ImagingCenter.AppointmentDate) : 'N/A'}</div>
              </div>
            </div>

            {TaskStatuses.Open === task.TaskStatus ? (
              <>
                <Form.Group className='form-new-scan-group '>
                  <TextField
                    name='ReviewerName'
                    appearance='subtle'
                    label='Reviewer name *:'
                    value={formData.ReviewerName}
                    labelClassName='task-label'
                    error={formError.ReviewerName}
                  />
                </Form.Group>
                <Form.Group className={`col-12 form-group-radio ${formError.ReviewStatus ? 'error' : ''}`}>
                  <Form.ControlLabel className='title'>Review status:</Form.ControlLabel>
                  <Form.Control
                    name='ReviewStatus'
                    errorPlacement='bottomStart'
                    accepter={RadioGroup}
                    value={formData.ReviewStatus as number}
                    onChange={(value: ValueType) => setFormData({ ...formData, ReviewStatus: +value, Comment: '' })}
                    errorMessage=''
                    inline
                  >
                    <Radio value={1} checked>
                      Approved
                    </Radio>
                    <Radio value={2}>Denied</Radio>
                    <Radio value={3}>Other</Radio>
                  </Form.Control>
                  <Form.ErrorMessage className='error-text' show={!!formError.ReviewStatus} placement={'bottomStart'}>
                    {formError.ReviewStatus}
                  </Form.ErrorMessage>
                </Form.Group>
                {formData.ReviewStatus === 1 && (
                  <Form.Group className='form-new-scan-group flex-1 mx-0'>
                    <TextField
                      name='ApprovalID'
                      appearance='subtle'
                      label={'Approval ID *:'}
                      labelClassName='task-label'
                      error={formError.ApprovalID}
                      value={formData.ApprovalID}
                    />
                  </Form.Group>
                )}
                <div className='form-new-scan-uploader'>
                  {formData.ReviewStatus === 1 && <span className='form-new-scan-divider'>---OR---</span>}
                  {(formData.ReviewStatus === 1 || formData.ReviewStatus === 3) && (
                    <>
                      <div className='title'>Uploaded authorization documents:</div>
                      <FilesUploader
                        fileList={formData.files}
                        uploadFiles={uploadDocuments}
                        title='Upload document'
                        updateFiles={(files: Array<FileTypeWithTitle>) => {
                          setFormData({ ...formData, files: files as Array<FileTypeWithTitle & FileView> });
                          setFormError({ ...formError, fileNoteName: '' });
                        }}
                        downloadFileFn={downloadFile}
                        checkFile={checkDocuments}
                        fileLoadFinish={fileLoadFinish}
                      />
                    </>
                  )}
                  {!!formError.fileNoteName && <div className='error-text-new-scan'>{formError.fileNoteName}</div>}
                </div>
                {formData.ReviewStatus !== null && (
                  <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                    <TextField
                      name='Comment'
                      accepter={Textarea}
                      appearance='subtle'
                      error={formError.Comment}
                      label={
                        formData.ReviewStatus === 2
                          ? 'Denial Reason and other comment *:'
                          : `Comment${formData.ReviewStatus === 3 ? ' *' : ''}:`
                      }
                      labelClassName='task-label'
                      value={formData.Comment}
                    />
                  </Form.Group>
                )}

                <Button className='rs-btn-model btn-lg' onClick={submit} disabled={isLoading || !isSubmitEnabled}>
                  Mark as Completed
                </Button>
              </>
            ) : (
              <StaticPriorAuthTaskComponent
                fileList={authorizationFiles}
                data={special as PriorAuthResponse}
                taskNotes={task.TaskNotes as string}
              />
            )}
          </div>
        </Form>
      </div>
      <div className='task-details'>
        <div className='task-details_tabs'>
          <div className='tabs-panel'>
            <Nav appearance='tabs' activeKey={activeNav} onSelect={setActiveNav}>
              <Nav.Item eventKey={TASK_TABS_NAME.Details}>Details</Nav.Item>
              <Nav.Item eventKey={TASK_TABS_NAME.ClinicalNotes}>Clinical notes</Nav.Item>
            </Nav>
          </div>
          <div className='task-details_tabs_content'>
            {activeNav === TASK_TABS_NAME.Details && (
              <DetailsTaskTabComponent data={{ special, task, testRequest, insurance, Patient }} taskDetailsChanged={refreshTaskDetails} />
            )}
            {activeNav === TASK_TABS_NAME.ClinicalNotes && (
              <ClinicalNotesTaskTabComponent noteFiles={taskData.noteFiles} ClinicalNotes={taskData.testRequest?.ClinicalNotes} />
            )}
          </div>
        </div>
      </div>
      {modalError.content && <InfoModalComponent type={`error`} texts={modalError} onClose={onCloseErrorModal} />}
    </>
  );
};

export default PriorAuthTaskComponent;
