import { RefObject, useRef, useState } from 'react';
import { DEFAULT_MIN_COLUMN_WIDTH } from './GeneralUtil';

const useDefaultColumnWidth = (
  count: number,
  isAction?: boolean,
): {
  panelRef: RefObject<HTMLDivElement>;
  defaultWidth: number;
  calculateColumnWidth: () => void;
} => {
  const [defaultWidth, setDefaultWidth] = useState<number>(0);
  const panelRef = useRef<HTMLDivElement>(null);

  const calculateColumnWidth = () => {
    if (panelRef.current) {
      const width = isAction ? panelRef.current.clientWidth - DEFAULT_MIN_COLUMN_WIDTH : panelRef.current.clientWidth;
      setDefaultWidth(width / count);
    }
  };

  return { panelRef, defaultWidth, calculateColumnWidth };
};

export default useDefaultColumnWidth;
