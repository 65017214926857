import React from 'react';
import { AccountStatuses, NotificationData, Notifications, User } from '../models/User';

export type UserContextType = User & {
  updateUser: () => void;
  expandUser: (data: Record<string, any>) => void;
};

export const INIT_CHECKBOXES_STATE: NotificationData = {
  Self: 0,
  Owner: 1,
  SpecificLocation: 0,
  Locations: [],
};

export const EMPTY_NOTIFICATIONS: Notifications = {
  CANCEL: INIT_CHECKBOXES_STATE,
  MFPCOMMENT: INIT_CHECKBOXES_STATE,
  REPORT: INIT_CHECKBOXES_STATE,
  NEWSCANREQUEST: INIT_CHECKBOXES_STATE,
  BOOKING: INIT_CHECKBOXES_STATE,
  CXCOMMENT: INIT_CHECKBOXES_STATE,
  TASK: INIT_CHECKBOXES_STATE,
};

export const UserContext = React.createContext<UserContextType>({
  Created: '',
  Email: '',
  FirstName: '',
  ID: 0,
  UserID: 0,
  IsActive: 0,
  LastName: '',
  PO: {
    ID: 0,
    Name: '',
    Description: '',
    InternalName: '',
    IsChargebeeUsed: 0,
    AllowCleerlyOrders: 0,
    AllowStandardOrders: 1,
    Access: {
      AccountStatus: AccountStatuses.Active,
      DaysLeft: 0,
      TrialExpiration: null,
    },
    IsReportReviewAutoCreate: 0,
  },
  Notifications: EMPTY_NOTIFICATIONS,
  Updated: '',
  UserStatus: '',
  UserType: 'Standard',
  IsCleerly: false,
  updateUser: () => {
    return;
  },
  expandUser: () => {
    return;
  },
});
