import { getServerUrl } from './ConfigUtil';

// eslint-disable-next-line no-undef
export const NODE_ENV = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;
// eslint-disable-next-line no-undef
const APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER_URL = getServerUrl(NODE_ENV, APP_SERVER_URL);
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN_KEY = 'Bearer';
