import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { User } from '../../models/User';
import { CheckToken, ForgotPassword, Info, ResetPassword } from './interfaces';
import { LoginFormData } from '../../views/login/interfaces';

export function login(data: LoginFormData): Promise<User | any> {
  return request({
    url: `${SERVER_URL}/users/login`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function logout(): Promise<Info> {
  return request({
    url: `${SERVER_URL}/users/logout`,
    method: 'POST',
  });
}

export function checkToken(token: string): Promise<CheckToken> {
  return request({
    url: `${SERVER_URL}/users/check/${token}`,
    method: 'GET',
  });
}

export function resetPassword(email: string): Promise<ForgotPassword> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/forgot`,
    body: JSON.stringify({
      email,
    }),
  });
}

export function createNewPassword(pass: string, cnfPass: string, token: string): Promise<ResetPassword> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/reset/${token}`,
    body: JSON.stringify({
      password: pass,
      confirmPassword: cnfPass,
    }),
  });
}
