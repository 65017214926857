import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../../components/table-component';
import { columns, transformReport } from './ReportsUtil';
import { getReports } from '../../../../../api/reports/ReportsAPI';
import ErrorMessageComponent from '../../../../../components/error-message-component';
import { useError } from '../../../../../utils/UseError';
import { Report } from './types';
import LoaderComponent from '../../../../../components/loader-component';
import { useSignal } from '../../../../../utils/UseSignal';

const ScanReportsTabComponent: React.FunctionComponent<{
  scanId: number;
  reportCollectionStatus?: string;
  additionalNote?: string;
}> = props => {
  const { scanId, reportCollectionStatus, additionalNote } = props;
  const [errorText, setErrorText] = useError(null);
  const [reports, setReports] = useState<Report[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const signal = useSignal();

  useEffect(() => {
    getAllReports();
  }, []);

  const getAllReports = () => {
    getReports(scanId, signal)
      .then(response => {
        const res = transformReport(response);
        setReports(res);
      })
      .catch(err => {
        if (!signal.aborted) {
          setErrorText(err);
          setReports([]);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className='page-content-wrap page-scan-tab'>
          <div className='page-scan-tab_title'>Reports</div>
          <div className='page-scan-tab_nodata text-center' hidden={reports.length > 0 || !!errorText}>
            {reportCollectionStatus ? (
              <>
                <p>The current status to obtain the reports: {reportCollectionStatus}</p>
                {additionalNote && <p>Additional Note: {additionalNote}</p>}
                <p>Don&apos;t hesitate to get in touch with the Medmo Care Team in the comment section for additional questions.</p>
              </>
            ) : (
              'Reports will be shown here. Please contact the Medmo Care Team in the comment section to check the status of a report.'
            )}
          </div>
          {!errorText && reports.length > 0 && (
            <TableComponent columns={columns} data={reports} noLastCellIcon heightColumn={36} isRemove={false} isEdit={false} />
          )}
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
        </div>
      )}
    </>
  );
};

export default ScanReportsTabComponent;
