import React from 'react';
import { Button, Modal } from 'rsuite';
import { FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa';
import { InfoModalProps } from '../interfaces';

const InfoModalComponent: React.FunctionComponent<InfoModalProps> = props => {
  const { type, onClose, texts } = props;
  return (
    <div>
      <div>
        <Modal className={`${type}-modal`} open onClose={() => onClose(false)}>
          <Modal.Header>
            <Modal.Title>{texts?.title || `${type} modal`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {type === 'success' && <div className='icon-box icon-success'>&nbsp;</div>}
            {type === 'error' && (
              <div className='icon-box icon-error'>
                <FaExclamationCircle />
              </div>
            )}
            {type === 'warning' && (
              <div className='icon-box icon-warning'>
                <FaExclamationTriangle />
              </div>
            )}
            <div className='model-desk'>{texts?.content || `${type} text content`}</div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row justify-content-center'>
              <Button classPrefix='btn-model' onClick={() => onClose(false)}>
                {texts?.buttonText ? texts.buttonText : 'Close'}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default InfoModalComponent;
