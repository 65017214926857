import { RefObject } from 'react';
import { FormInstance } from 'rsuite/Form';
import { FileType } from 'rsuite/Uploader';
import { Texts } from '../../components/modals/interfaces';
import { SelectOption } from '../../models/General';
import { Patient } from '../../models/Patient';

export type Location = {
  label?: string;
  value?: number;
  fax?: string;
};

export type TotalPrice = {
  total?: number;
  deposit?: number;
  exam?: number;
};

export type ScanResponseType = {
  Active: number | null;
  CPTCode: number | null;
  Fee: string;
  FullName: string;
  MinPrice: string;
  ScanDescription: string;
  ScanExtraOption: string;
  ScanServiceID: number;
  IsCleerly: number;
  ScanServiceName: string;
  ScanTypeID: number;
  ScanTypeName: string;
  TotalPrice: string;
};

export interface ScanTypeDescription {
  id: number;
  label: string;
  value: string;
  scanId: number;
  ScanServiceID: number;
  IsCleerly: number;
  CPTCode: number | null;
  TotalPrice: string;
  Fee?: string;
  MinPrice?: string;
  FullName?: string;
  descriptions?: Array<ScanTypeDescription | undefined>;
}

export interface ScanTypeBody extends ScanTypeDescription {
  descriptions: Array<ScanTypeDescription | undefined>;
}

export type ScanType = {
  id: number;
  label: string;
  value: string;
  scanId: number;
  bodies: Array<ScanTypeBody>;
};

export type Prescriber = {
  label: string;
  value?: number;
  FirstName?: string;
  LastName?: string;
  ID?: number;
};

// export interface FilteredLocation {
//   label: string;
//   value: number;
//   fax: string;
// }

export type ScanCoverageSelect = Array<{
  label: string;
  value: string;
  role: string;
}>;

export type FileTypeWithTitle = FileType & {
  fileTitle: string;
  id?: number;
  key?: string;
  originalName?: string;
  mimeType?: string;
};

export interface ScanInformationFormData {
  SelectScanTypeNameModality: string;
  SelectScanTypeNameBody: string;
  SelectScanTypeNameDetails: string;
  ICD: string;
  PrescriberName: string;
  PrescriberID: number | null;
  LocationID: number | null;
  Fax: string;
  Reason: string;
  CPTCode: number | null;
  ScanServiceID: number | null;
  ScanServiceIsCleerly: number;
  physiciansOffice?: string;
  TotalPrice: string;
  Fee?: string;
  MinPrice?: string;
}

export interface PatientPreferencesFormData {
  ZipCode?: string;
  SelfPay?: string;
  PolicyNumber?: string | null;
  InsurancePlanID?: number | null;
  fullNameCard?: string;
  CardType?: string | null;
  CardLast4?: number;
  insuranceProvider?: string;
  AvailableSlots?: string;
  AvailableSlotsView?: Record<string, string[]>;
  PaymentID?: string;
  PaymentSecret?: string;
  IsAnyTime?: string | null;
}

export interface EPrescription {
  ZipCode?: string | null;
  PatientId: null | number;
  ScanServiceID: null | number;
  ICD: string;
  Fax: string;
  Reason: string;
  OtherNotes?: string;
  CPTCode: number | null;
  FirstName: string;
  LastName: string;
  DOB: Date | string | null;
  Phone: string | null;
  PrescriberName: string;
  Email?: string | null;
  LocationID: number | null;
  PrescriberID: number | null;
}

export interface RequestScanFormData {
  PatientId: null | number;
  UserID: null | number;
  ID: null | number;
  ScanRequestFileNames: FileTypeWithTitle[] | null;
  ScanServiceID: number | null;
  ScanServiceIsCleerly?: number;
  ICD: string;
  LocationID: number | null;
  Fax: string;
  Gender: string | null;
  Reason: string;
  ClinicalNotes: string;
  ClinicalNotesFile: string | undefined;
  OtherNotes?: string;
  LogisticNotes?: string;
  isNote: boolean;
  ZipCode: string | null;
  SelfPay: string | null;
  PolicyNumber: string | null;
  InsurancePlanID: number | null;
  PaymentID: string | undefined;
  PaymentSecret: string | undefined;
  CardType: string | null | undefined;
  CardLast4: number | undefined;
  StatusID: number;
  PrescriberID: number | null;
  NoteTypeId: number | null;
  NoteInfo: string | null;
  CPTCode?: number | null;
  MRN: string | null;
  GenderId: number | null;
  FirstName?: string;
  FullName?: string;
  LastName?: string;
  Name: string;
  Email?: string | null;
  Phone: string | null;
  DOB: Date | string | null;
  PrescriberName: string;
  fullNameCard: string | undefined;
  physiciansOffice?: string;
  insuranceProvider: string | undefined;
  filePrescription?: string;
  filePrescriptionName: string;
  IsAnyTime: string | null;
  MfpTestRequestID: null;
  AvailableSlots: string;
  AvailableSlotsView?: Record<string, string[]>;
  SelectScanTypeNameModality: string;
  SelectScanTypeNameBody: string;
  SelectScanTypeNameDetails: string;
  SendEmail?: string | null;
  ScanRequestNoteNames?: FileTypeWithTitle[];
  OwnerName: string;
  OwnerID: number | null;
  fileListNotes: Array<FileTypeWithTitle> | [];
  State?: string | null;
  Language?: string;
  PreferredLanguageID: number | null;
  NotesOptions?: string;
}

export type NotesFormData = {
  ClinicalNotesFile?: string;
  ClinicalNotes?: string;
  OtherNotes?: string;
  LogisticNotes?: string;
  fileListNotes: Array<FileTypeWithTitle>;
  fileNoteName?: string;
  NotesOptions: string;
};

// export type PrescriptionFormData = {
//   ScanRequestFileNames: FileTypeWithTitle[] | null;
// };
//
// export type PrescribersFormData = {
//   POID: number;
//   PhysicianTypeID: number;
// };

export type ModalError = { title: string; content: string };

export interface PatientInformationProps {
  setPatientData: (data: Patient) => void;
  formRef: RefObject<FormInstance>;
}

export interface ScanInformationProps {
  setScanData: (data: ScanInformationFormData) => void;
  formRef: RefObject<FormInstance>;
  locations: Array<Location>;
  prescribers: Array<Prescriber>;
  showElementDetails: boolean;
  setShowElementDetails: (value: boolean) => void;
}

export interface NotesProps {
  setNotesData: (data: NotesFormData) => void;
  model?: any;
  inModal?: boolean;
  initData?: NotesFormData;
  formRef?: RefObject<FormInstance>;
}

export interface PatientPreferencesProps {
  setPatientPreferencesData: (data: PatientPreferencesFormData) => void;
  liftZipCodeStateUp: (value: boolean) => void;
  formRef: RefObject<FormInstance>;
  insuranceObj: ScanCoverageSelect;
  price: TotalPrice;
}

export interface PrescriptionProps {
  setFormData: (data: RequestScanFormData) => void;
  formData: RequestScanFormData;
  fileListPrescription: FileTypeWithTitle[] | [];
  setFileListPrescription: (fileList: FileTypeWithTitle[] | []) => void;
  formRef: RefObject<FormInstance>;
  validate: (isOnlyPatient?: boolean) => boolean;
}

export type DescriptionFormData = {
  FileTitle: string;
};

// export type UploadedFiles = {
//   data: null | [];
//   type: string;
// };

export interface SelectData {
  prescribers: SelectOption[];
  owners: SelectOption[];
  locations: Location[];
}

export interface RenameFileModalProps {
  onUpdate: (val: string) => void;
  onClose: () => void;
}

export interface ScanData extends RequestScanFormData {
  prescription: [] | FileTypeWithTitle[];
  scanType: string;
}

export interface Confirmation {
  isShow: boolean;
  data: ScanData;
  steps: boolean | null;
  uploadFiles: (
    a: FileTypeWithTitle[],
    b: string,
  ) => Promise<{ key?: string; originalName?: string; name?: string; fileTitle: string }[] | null | void>;
  createNewInsurance: (name: string) => Promise<number | void | null | undefined>;
  onClose: (a?: boolean) => void;
  getRequestId: (scanID: number | null, erroText?: Texts) => void;
}

export interface PopupWindow {
  isShow: boolean;
  requestId: number | null;
  scanType: string;
  fullName: string;
  onClose: (a?: boolean) => void;
}

export const clinicalNotesOptions = [
  {
    value: '0',
    label: 'Upload files',
  },
  {
    value: '1',
    label: 'Type in the box (text)',
  },
  {
    value: '2',
    label: 'I will provide clinical notes later',
  },
];
