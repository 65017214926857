import { ColumnInterface } from '../../components/table-component/interfaces';
import { RecentActivity } from '../../models/RecentActivity';
import { RecentActivityExpand, ScanStaitsics, Tab } from './interfaces';

export const columnsObject: Record<Tab, ColumnInterface[]> = {
  Tasks: [
    {
      dataKey: 'TaskType',
      minWidth: 200,
      align: 'left',
      headTitle: 'Task',
      tooltip: 'Task Type',
      flexGrow: 2,
      sortable: true,
    },
    {
      dataKey: 'PatientName',
      minWidth: 140,
      align: 'left',
      headTitle: 'Patient',
      tooltip: 'Patient name',
      flexGrow: 2,
      sortable: true,
    },
    {
      dataKey: 'DOB',
      minWidth: 95,
      align: 'left',
      headTitle: 'DOB',
      tooltip: 'Patient date of birth',
      flexGrow: 1,
      sortable: true,
    },
    {
      dataKey: 'Modality',
      minWidth: 130,
      align: 'left',
      headTitle: 'Modality',
      flexGrow: 1.5,
      sortable: true,
      tooltip: 'Type of modality',
      type: 'scanTypeToolTip',
      fieldName: 'FriendlyName',
    },
    {
      dataKey: 'AssignedUser',
      minWidth: 150,
      width: 150,
      align: 'left',
      headTitle: 'Assignee',
      tooltip: 'Task Assignee',
      flexGrow: 1.8,
      sortable: true,
      type: 'select',
      idKey: 'ID',
    },
    {
      dataKey: 'LocationAddress',
      minWidth: 150,
      width: 150,
      align: 'left',
      headTitle: 'Location',
      flexGrow: 1.8,
      sortable: true,
    },
    {
      dataKey: 'TestRequestID',
      minWidth: 85,
      width: 85,
      align: 'left',
      headTitle: 'SRID',
      tooltip: 'Scan Request ID',
      flexGrow: 0.9,
      sortable: true,
      type: 'link',
      linkUrl: '/all-scans',
      usePath: false,
    },
    {
      dataKey: 'TaskCreatedDate',
      minWidth: 105,
      width: 105,
      align: 'left',
      headTitle: 'Created',
      tooltip: 'Task Created Date',
      flexGrow: 1.1,
      sortable: true,
    },
  ],

  Comments: [
    {
      dataKey: 'PatientName',
      align: 'center',
      headTitle: 'Patient',
      tooltip: 'Patient name',
      flexGrow: 1,
      minWidth: 180,
      sortable: true,
    },
    {
      dataKey: 'DOB',
      align: 'left',
      headTitle: 'DOB',
      tooltip: 'DOB',
      minWidth: 125,
      flexGrow: 0.7,
      sortable: true,
    },
    {
      dataKey: 'Modality',
      align: 'center',
      headTitle: 'Modality',
      tooltip: 'Type of modality',
      flexGrow: 0.3,
      sortable: true,
      minWidth: 135,
      type: 'scanTypeToolTip',
      fieldName: 'FriendlyName',
    },
    {
      dataKey: 'TestRequestID',
      align: 'left',
      headTitle: 'SRID',
      tooltip: 'Scan request ID',
      flexGrow: 0.3,
      sortable: true,
      minWidth: 100,
      type: 'link',
      linkUrl: '/all-scans',
      usePath: false,
    },
    {
      dataKey: 'CreatorName',
      align: 'left',
      minWidth: 180,
      headTitle: 'From',
      flexGrow: 1,
      sortable: true,
    },
    {
      dataKey: 'Created',
      align: 'center',
      headTitle: 'Created',
      tooltip: 'Comment created date',
      flexGrow: 1,
      minWidth: 165,
      sortable: true,
    },
    {
      dataKey: 'NoteText',
      align: 'left',
      headTitle: 'Message',
      tooltip: 'Message',
      width: 400,
      flexGrow: 1.5,
      type: 'modal',
      idKey: 'TestRequestID',
      sortable: true,
    },
  ],

  Activity: [
    {
      dataKey: 'EventTypeText',
      align: 'center',
      headTitle: 'Recent Activities',
      type: 'capitalize',
      flexGrow: 1,
      minWidth: 360,
      sortable: true,
    },
    {
      dataKey: 'TestRequestID',
      align: 'left',
      headTitle: 'SRID',
      tooltip: 'Scan request ID',
      width: 120,
      sortable: true,
      minWidth: 120,
      type: 'link',
      linkUrl: '/all-scans',
      usePath: false,
    },
  ],
};

export const texts = {
  reassignErrorText: {
    title: 'Reassign was not successfull',
    content: 'Please try again later',
  },

  statisticsErrorText: {
    title: 'Statistics was failed to load',
    content: 'Please try again later',
  },

  ownersErrorText: {
    title: 'Owners list was failed to load',
    content: 'Please try again later',
  },

  recentActivityErrorText: {
    title: 'Statistics was failed to load',
    content: 'Please try again later',
  },

  tasksErrorText: {
    title: 'Tasks list was failed to load',
    content: 'Please try again later',
  },
  commentsErrorText: {
    title: 'Comments were failed to load',
    content: 'Please try again later',
  },
};

export const options = {
  radius: 60,
  borderWidth: 0,
  cutout: 50,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      bodyFont: {
        size: 14,
      },
    },
  },
};

export const DashboardTabs: Record<string, string> = {
  Tasks: 'Tasks',
  Comments: 'Comments',
  Activity: 'Activity',
};

export const prepareDataForDonut = (data: ScanStaitsics): number[] => {
  return [data?.RequestedTestRequestCount, data?.ScheduledTestRequestCount, data?.InProgressTestRequestCount, data?.OnHoldTestRequestCount];
};

const replacementMap: Record<string, string> = {
  'MFP comment (by MCT)': 'New Comment by Medmo Care Team',
  'MFP comment (by MFP)': 'New Comment',
  'MFP comment (to CX)': 'New Comment to Medmo Care Team',
  'Scan request closed (CX)': 'Scan Request Closed',
};

export const updateRecentActivities = (data: RecentActivity[]): RecentActivityExpand[] => {
  return data.map(el => ({
    ...el,
    EventTypeText: replacementMap[el.EventType] || el.EventType.replace(/CX|MCT/, 'Medmo Care Team'),
  }));
};
