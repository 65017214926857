import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import TextField from '../../../../components/text-field-component';
import { Button, Modal, Form, Input, ButtonToolbar, SelectPicker } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { locationModel, FormDefaultValue, fieldsForCheck, getEditLocationInfo } from './LocationsUtil';
import { getState } from './LocationState';
import { checkForEmptyFields } from '../../../../utils/FormUtil';
import InputMask from '../../../../components/mask-field';
import { LocationProps, LocationWithFullLocation } from './interfaces';
import { FormError } from '../../../../models/General';
import { addLocation, editLocation } from '../../../../api/locations/LocationsAPI';
import { SettingsLocationsTexts } from './Texts';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { useError } from '../../../../utils/UseError';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { Location } from '../../../../models/Location';

const LocationModal: React.FunctionComponent<LocationProps> = props => {
  const { onClose, location } = props;
  const [formValue, setFormValue] = useState<LocationWithFullLocation>(location || FormDefaultValue);
  const [formError, setFormError] = useState<FormError>({});
  const [infoModalTexts, setInfoModalTexts] = useState<Record<string, string>>({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const formRef = useRef<FormInstance | null>(null);

  const handleSubmit = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }
    setIsSubmitPressed(true);
    const submitData = { ...formValue };
    delete submitData.FullLocation;
    if (location && location.ID) {
      const submitLocationData = getEditLocationInfo(submitData);
      editLocation(submitLocationData, location.ID as number)
        .then(() => {
          setInfoModalTexts(SettingsLocationsTexts.successEditLocation);
          setShowInfoModal(true);
        })
        .catch(err => setErrorText(err, formValue))
        .finally(() => setIsSubmitPressed(false));
    } else {
      addLocation(submitData)
        .then(() => {
          setInfoModalTexts(SettingsLocationsTexts.successAddLocation);
          setShowInfoModal(true);
        })
        .catch(err => setErrorText(err, formValue))
        .finally(() => setIsSubmitPressed(false));
    }
  };

  const handleChange = (formValue: LocationWithFullLocation, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const handleClickCancel = () => {
    if (formRef.current && !isSubmitPressed) {
      onClose(false, checkForEmptyFields(formValue, fieldsForCheck));
    }
  };

  const onCloseInfoModal = () => {
    onClose(true);
    setShowInfoModal(false);
  };

  const isSubmitDisabled = useMemo(() => {
    if (!location) {
      return false;
    }

    return (Object.keys(location) as [keyof Location]).every(key => {
      if (key in formValue) {
        if (!formValue[key]) {
          return !formValue[key];
        }
        return formValue[key] === location[key];
      }
      return true;
    });
  }, [formValue]);

  return (
    <div>
      <Modal size={'sm'} className='location-model' open onClose={handleClickCancel}>
        <Modal.Header>
          <Modal.Title>{location ? 'Edit' : 'Add'} Location to Medmo for Physicians</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onChange={(formValue, event) => handleChange(formValue as LocationWithFullLocation, event as ChangeEvent<HTMLInputElement>)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={locationModel}
          >
            <TextField
              name='Name'
              error={formError.Name || dataErrors.Name}
              accepter={Input}
              label='Location Name*'
              value={formValue.Name}
            />
            <TextField
              name='Address1'
              error={formError.Address1 || dataErrors.Address1}
              accepter={Input}
              label='Address*'
              value={formValue.Address1}
            />
            <TextField
              name='Address2'
              error={formError.Address2 || dataErrors.Address2}
              accepter={Input}
              label='Address 2'
              value={formValue.Address2}
            />
            <TextField name='City' error={formError.City || dataErrors.City} accepter={Input} label='City*' value={formValue.City} />
            <div className='row'>
              <TextField
                accepter={SelectPicker}
                className='state department'
                name='State'
                data={getState}
                error={formError.State || dataErrors.State}
                value={formValue.State}
                appearance='subtle'
                placeholder='State*'
                searchable={true}
                menuMaxHeight={220}
              />
              <TextField
                className='zip-code'
                name='ZipCode'
                error={formError.ZipCode || dataErrors.ZipCode}
                accepter={InputMask}
                label='Zip code*'
                value={formValue.ZipCode}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
              />
            </div>
            <div className='row'>
              <TextField
                className='phone'
                accepter={InputMask}
                error={formError.Phone || dataErrors.Phone}
                value={formValue.Phone}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                name='Phone'
                label='Phone number*'
              />
              <TextField
                className='ext'
                name='Extension'
                error={formError.Extension || dataErrors.Extension}
                accepter={Input}
                label='Extension'
                value={formValue.Extension}
              />
            </div>
            <TextField
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              name='DefaultFax'
              error={formError.DefaultFax || dataErrors.DefaultFax}
              accepter={InputMask}
              label='Fax number*'
              value={formValue.DefaultFax}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
          <ButtonToolbar>
            <div style={{ display: 'none' }} className='form-error-text'>
              {'Please complete all required fields to continue'}
            </div>
            <Button classPrefix='btn-model btn-close btn-lg' disabled={isSubmitPressed} onClick={handleClickCancel}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitPressed || isSubmitDisabled} onClick={handleSubmit}>
              {location ? 'Update Location Information' : 'Add Location'}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
      {showInfoModal && <InfoModalComponent type='success' onClose={onCloseInfoModal} texts={infoModalTexts} />}
    </div>
  );
};

export default LocationModal;
