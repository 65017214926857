import { SERVER_URL } from '../../config/ServerConfig';
import { RecentActivity } from '../../models/RecentActivity';
import { prepareGetOptions } from '../../utils/GeneralUtil';
import { RequestScanFormData } from '../../views/new-scan/interfaces';
import { RequestScanFormDataMulti } from '../../views/new-multi-scan/interfaces';
import { request } from '../Request';
import { RequestScan } from './interfaces';

export function requestScan(data: Omit<RequestScanFormData, 'fileListNotes'>): Promise<RequestScan> {
  return request({
    url: `${SERVER_URL}/scan-request/complete-form/`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function requestScanMulti(data: Omit<RequestScanFormDataMulti, 'fileListNotes'>): Promise<RequestScan[]> {
  return request({
    url: `${SERVER_URL}/scan-request/complete-form-multi/`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function GetRecentActivity(signal?: AbortSignal): Promise<RecentActivity[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/recent-activity-last-30-days`);
  return request(options);
}
