import { SERVER_URL } from '../../config/ServerConfig';
import { convertToQueryString, prepareGetOptions } from '../../utils/GeneralUtil';
import { StatisticResponse } from '../../views/dashboard/interfaces';
import { request } from '../Request';
import { ReportReviewData, TasksResponse, TaskResponse } from './interfaces';
import {
  FullTaskInfo,
  GeneralTaskDataToSend,
  NotesTaskDataToSend,
  PeerReviewTaskFormData,
  PriorAuthTaskFormData,
} from '../../views/tasks/interfaces';
import { RequestParams } from '../../models/General';

export function getTasksList({ filter, perPage, page, field, order }: RequestParams, signal?: AbortSignal): Promise<TasksResponse> {
  const paginate = {
    currentPage: page || 1,
    perPage: perPage || 25,
    sortBy: field,
    order,
  };
  let queryString = convertToQueryString(paginate);

  if (filter) {
    queryString += `&${filter}`;
  }

  const options = prepareGetOptions(signal, `${SERVER_URL}/tasks?${queryString}`);

  return request(options);
}

export function reassignTask(id: number, data: { newAssignedUserID: number }): Promise<{ ID: number }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/tasks/reassign/${id}`,
    body: JSON.stringify(data),
  });
}

export function reopenTask(id: number): Promise<{ ID: number }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/tasks/reopen/${id}`,
  });
}

export function getTasksForScan(id: number, signal?: AbortSignal): Promise<TasksResponse> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/tasks/test-request/${id}`);
  return request(options);
}

export function getUserTaskList(signal?: AbortSignal): Promise<TasksResponse> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/tasks/user`);
  return request(options);
}

export function getStatistic(signal?: AbortSignal): Promise<StatisticResponse> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/users/statistic`);
  return request(options);
}

export function getTaskInfo(id: number, filter?: string, signal?: AbortSignal): Promise<FullTaskInfo> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/tasks/${id}${filter}`);
  return request(options);
}

export function completePeerToPeerTask(data: PeerReviewTaskFormData): Promise<{ ID: number }> {
  return request({
    url: `${SERVER_URL}/tasks/peer-to-peer`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function completePriorAuthTask(data: PriorAuthTaskFormData): Promise<{ ID: number }> {
  return request({
    url: `${SERVER_URL}/tasks/prior-auth`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function completeReportReview(data: ReportReviewData): Promise<TaskResponse> {
  return request({
    url: `${SERVER_URL}/tasks/report-review`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function uploadAuthDocuments(data: FormData): Promise<
  {
    key: string;
    originalName: string;
  }[]
> {
  return request({
    url: `${SERVER_URL}/tasks/upload-auth-docs`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  });
}

export function uploadTaskDocuments(data: FormData): Promise<
  {
    key: string;
    originalName: string;
  }[]
> {
  return request({
    url: `${SERVER_URL}/tasks/upload-task-docs`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  });
}

export function completeNotesReview(data: NotesTaskDataToSend): Promise<TaskResponse> {
  return request({
    url: `${SERVER_URL}/tasks/pending-clinical-notes`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function completeStandartTask(data: GeneralTaskDataToSend): Promise<TaskResponse> {
  return request({
    url: `${SERVER_URL}/tasks/standard`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}
