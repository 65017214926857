import React, { useContext, useMemo } from 'react';
import { Dropdown } from 'rsuite';
import { LoginDropdownProps } from './interfaces';
import { UserContextType, UserContext } from '../../contexts/UserContext';

const LoginDropdownComponent: React.FunctionComponent<LoginDropdownProps> = props => {
  const { logout } = props;
  const currentUser = useContext<UserContextType>(UserContext);

  const nameUser = useMemo(() => {
    if (currentUser && currentUser.FirstName) {
      const name = currentUser.FirstName;
      if (currentUser.LastName) {
        const lastName = currentUser.LastName;
        return name.substring(0, 1) + lastName.substring(0, 1);
      }
      return name.substring(0, 1);
    }

    return '';
  }, [currentUser]);

  return (
    <Dropdown className='profile-icon' title={nameUser}>
      <Dropdown.Item
        onClick={() => {
          localStorage.removeItem('isAuth');
          logout();
        }}
      >
        Logout
      </Dropdown.Item>
    </Dropdown>
  );
};
export default LoginDropdownComponent;
