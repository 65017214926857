import { CheckRound } from '@rsuite/icons';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal } from 'rsuite';
import { PopupWindow } from '../interfaces';

const Popup: React.FC<PopupWindow> = (Props: PopupWindow) => {
  const { onClose, isShow, scanType, fullName, requestId } = Props;
  const history = useHistory();

  const modalClose = () => {
    onClose();
    history.push('/all-scans');
  };

  return (
    <Modal className='scan-popup-submitted' backdrop={true} open={isShow} onClose={modalClose}>
      <Modal.Header>
        <Modal.Title>Scan Request Successfully Submitted</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CheckRound />
        <strong>
          Scan request ID: <Link to='/all-scans'>{requestId}</Link>
        </strong>
        <p>
          <b>Scan request Type:</b> <span>{scanType}</span>
        </p>
        <p>
          <b>Referring Physician:</b> <span>{fullName}</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={modalClose} classPrefix='btn-model' className='btn-lg'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
