import React, { useState } from 'react';
import TextField from '../../../components/text-field-component';
import { Button, Modal, Form } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { DescriptionFormData, RenameFileModalProps } from '../interfaces';
import { renameFileSchema } from '../Schemas';
import { FormError } from '../../../models/General';

const RenameFileModal: React.FunctionComponent<RenameFileModalProps> = props => {
  const { onClose, onUpdate } = props;
  const [formValue, setFormValue] = useState<DescriptionFormData>({
    FileTitle: '',
  });
  const [formError, setFormError] = useState<FormError>({});
  const formRef = React.useRef<FormInstance | null>(null);

  const handleSubmit = () => {
    if (!formRef.current?.check()) {
      return;
    }

    onUpdate(formValue.FileTitle);
    onClose();
  };

  return (
    <div>
      <Modal size={'sm'} open onClose={() => onClose()}>
        <Modal.Header>
          <Modal.Title>File Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onChange={formValue => setFormValue(formValue as DescriptionFormData)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={renameFileSchema}
          >
            <TextField error={formError.FileTitle} value={formValue.FileTitle} name='FileTitle' label='File Description' />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button classPrefix='btn-model' disabled={formValue.FileTitle ? false : true} onClick={handleSubmit}>
              Update &amp; Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RenameFileModal;
