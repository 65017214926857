export const ConfirmModalTexts = {
  cancelAddingLocation: {
    title: `Are you sure you want to leave?`,
    text: `If you leave now, all progress will be lost.`,
    cancelBtnText: 'Cancel',
    submitBtnText: 'Yes, Leave',
  },
};

export const TABS_NAME = {
  General: 'general',
  Users: 'manage_users',
  OrganizationSetting: 'manage_organization_setting',
  Location: 'manage_location',
  Physicians: 'manage_physicians',
  Organization: 'manage_organization',
  Billing: 'billing',
};

export const isAvailableTab = (tabName: string): boolean => {
  return Object.values(TABS_NAME).some(value => value === tabName);
};
