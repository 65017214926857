import React from 'react';
import { Props } from './interfaces';

const ErrorMessageComponent: React.FunctionComponent<Props> = props => {
  const { errorMessage } = props;

  return <p className='block-error-text'>{errorMessage}</p>;
};

export default ErrorMessageComponent;
