import React, { useContext, useEffect, useState } from 'react';
import { Form, SelectPicker } from 'rsuite';
import TextField from '../../../components/text-field-component';
import InputMask from '../../../components/mask-field';
import { initScanInfo, formatFax } from '../NewScanUtil';
import {
  ScanInformationFormData,
  ScanInformationProps,
  ScanTypeDescription,
  Location,
  Prescriber,
  ScanTypeBody,
  ScanType,
} from '../interfaces';
import { PatientScanInfoSchema, PatientScanInfoSchemaWithDetails } from '../Schemas';
import { FormError } from '../../../models/General';
import { CommonContext, CommonContextType } from '../../../contexts/CommonContext';
import Textarea from '../../../components/textarea';
import { UserContext, UserContextType } from '../../../contexts/UserContext';

const ScanInformation: React.FC<ScanInformationProps> = ({
  setScanData,
  formRef,
  locations,
  prescribers,
  showElementDetails,
  setShowElementDetails,
}: ScanInformationProps) => {
  const [formData, setFormData] = useState<ScanInformationFormData>(initScanInfo);
  const [formError, setFormError] = useState<FormError>({});
  const [dropdownTypeNameBody, setDropdownTypeNameBody] = useState<ScanTypeBody[]>([]);
  const [dropdownTypeNameDetails, setDropdownTypeNameDetails] = useState<ScanTypeDescription[]>([]);
  const { ScanTypes } = useContext<CommonContextType>(CommonContext);
  const { IsCleerly } = useContext<UserContextType>(UserContext);

  useEffect(() => {
    if (formData.Fax) {
      formRef.current?.checkForField('Fax');
    }
  }, [formData.Fax]);

  useEffect(() => {
    if (formData.physiciansOffice) {
      formRef.current?.checkForField('Fax');
    }
  }, [formData.physiciansOffice]);

  useEffect(() => {
    if (IsCleerly && ScanTypes.length === 1) {
      const scanType = ScanTypes[0];
      let scanDetailsLabel = '';
      let scanBody: ScanTypeBody | undefined;
      setDropdownTypeNameBody(scanType.bodies);
      if (scanType.bodies.length === 1) {
        scanBody = scanType.bodies[0];
        if (scanBody.descriptions && scanBody.descriptions.length) {
          setDropdownTypeNameDetails(scanBody.descriptions as ScanTypeDescription[]);
          setShowElementDetails(true);
          if (scanBody.descriptions.length === 1 && scanBody.descriptions[0]) {
            scanDetailsLabel = scanBody.descriptions[0].label;
          }
        }
      }
      setFormData({
        ...initScanInfo,
        SelectScanTypeNameModality: scanType.label,
        SelectScanTypeNameBody: scanBody?.label || '',
        SelectScanTypeNameDetails: scanDetailsLabel,
        ScanServiceID: scanBody?.ScanServiceID || null,
        ScanServiceIsCleerly: scanBody?.IsCleerly || 0,
        CPTCode: scanBody?.CPTCode || null,
        TotalPrice: scanBody?.TotalPrice || '',
        Fee: scanBody?.Fee || '',
        MinPrice: scanBody?.MinPrice || '',
      });
    }
  }, [ScanTypes, IsCleerly]);

  const setData = (data: ScanInformationFormData) => {
    setFormData(data);
    setScanData(data);
  };

  const changeLocationId = (value: string, item: Location): void => {
    const Fax = formatFax(item.fax as string, '(ddd) ddd-dddd');

    setData({
      ...formData,
      physiciansOffice: item?.label,
      LocationID: Number(value),
      Fax,
    });
  };

  const changePrescriberID = (value: string, item: Prescriber): void => {
    setData({
      ...formData,
      PrescriberName: item.label,
      PrescriberID: Number(value),
    });
  };

  const changeSelectScanTypeName = (value: string, item: ScanType): void => {
    if (!item) {
      return;
    }

    let objectData: ScanInformationFormData = {
      ...formData,
      SelectScanTypeNameModality: value,
      SelectScanTypeNameBody: '',
    };

    if (formData.SelectScanTypeNameModality !== item.label && (formData.SelectScanTypeNameBody || formData.SelectScanTypeNameDetails)) {
      objectData = {
        ...objectData,
        SelectScanTypeNameBody: '',
        SelectScanTypeNameDetails: '',
      };
    }

    setData(objectData);

    setDropdownTypeNameBody(item.bodies);
    setShowElementDetails(false);
  };

  const changeSelectScanTypeBody = (value: string, item: ScanTypeDescription): void => {
    let newData = {
      ...formData,
      SelectScanTypeNameBody: value,
    };

    if (formData.SelectScanTypeNameBody !== item.label && formData.SelectScanTypeNameDetails) {
      newData = {
        ...newData,
        SelectScanTypeNameDetails: '',
      };
    }

    if (item.descriptions && item.descriptions.length) {
      setDropdownTypeNameDetails(item.descriptions as ScanTypeDescription[]);
      setShowElementDetails(true);
    } else {
      setShowElementDetails(false);
      newData = {
        ...newData,
        CPTCode: item.CPTCode,
        ScanServiceID: item.ScanServiceID,
        ScanServiceIsCleerly: item?.IsCleerly || 0,
        TotalPrice: item.TotalPrice,
        Fee: item.Fee,
        MinPrice: item.MinPrice,
      };
    }

    setData(newData);
  };

  const changeSelectScanTypeDetails = (value: string, item: ScanTypeDescription): void => {
    if (item) {
      setData({
        ...formData,
        SelectScanTypeNameDetails: value,
        CPTCode: item.CPTCode,
        ScanServiceID: item.ScanServiceID,
        ScanServiceIsCleerly: item?.IsCleerly || 0,
        TotalPrice: item.TotalPrice,
        Fee: item.Fee,
        MinPrice: item.MinPrice,
      });
    }
  };

  const onChangeTextField = (value: string, field: string) => {
    setData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <Form
      className='form form-new-scan pb3'
      ref={formRef}
      formValue={formData}
      checkTrigger='change'
      onCheck={error => setFormError(error)}
      model={showElementDetails ? PatientScanInfoSchemaWithDetails : PatientScanInfoSchema}
    >
      <div className='form-container'>
        <h2>
          <b>Scan Information</b>
        </h2>
        <div className='form-new-scan-block scan--information'>
          <div className='form-new-scan-block full-scan-type full-scan-type-fileds'>
            <Form.Group className='form-new-scan-group width-l'>
              <TextField
                accepter={SelectPicker}
                name='SelectScanTypeNameModality'
                data={ScanTypes}
                onSelect={changeSelectScanTypeName}
                value={formData.SelectScanTypeNameModality}
                appearance='subtle'
                placeholder=' '
                searchable={false}
                label='Scan modality*'
                error={formError.SelectScanTypeNameModality}
                className={`${formError.SelectScanTypeNameModality ? 'error-border' : ''}`}
                menuMaxHeight={450}
              />
            </Form.Group>
            <Form.Group className='form-new-scan-group width-l'>
              <TextField
                accepter={SelectPicker}
                name='SelectScanTypeNameBody'
                data={dropdownTypeNameBody}
                onSelect={changeSelectScanTypeBody}
                value={formData.SelectScanTypeNameBody}
                appearance='subtle'
                placeholder=' '
                searchable={false}
                label='Body part*'
                error={formError.SelectScanTypeNameBody}
                className={`${formError.SelectScanTypeNameBody ? 'error-border' : ''}`}
                menuMaxHeight={450}
              />
            </Form.Group>
            {showElementDetails ? (
              <Form.Group className='form-new-scan-group width-l'>
                <TextField
                  accepter={SelectPicker}
                  name='SelectScanTypeNameDetails'
                  data={dropdownTypeNameDetails}
                  onSelect={changeSelectScanTypeDetails}
                  value={formData.SelectScanTypeNameDetails}
                  appearance='subtle'
                  placeholder=' '
                  label='Scan details*'
                  searchable={false}
                  error={formError.SelectScanTypeNameDetails}
                  className={`${formError.SelectScanTypeNameDetails ? 'error-border' : ''}`}
                />
              </Form.Group>
            ) : null}
          </div>
          <Form.Group className='form-new-scan-group width-xl'>
            <TextField
              value={formData.ICD}
              name='ICD'
              autoComplete='off'
              label='ICD-10 Code*'
              onChange={(val: string) => onChangeTextField(val, 'ICD')}
              error={formError.ICD}
            />
          </Form.Group>
        </div>
        <div className='form-new-scan-block scan--information'>
          <div className='form-new-scan-block full-scan-type full-scan-mob'>
            <Form.Group className='form-new-scan-group width-l'>
              <TextField
                accepter={SelectPicker}
                name='PrescriberID'
                data={prescribers}
                onSelect={changePrescriberID}
                value={formData.PrescriberID}
                appearance='subtle'
                placeholder=' '
                label='Ordering physician*'
                error={formError.PrescriberID}
                className={`${formError.PrescriberID ? 'error-border' : ''}`}
                searchable={true}
              />
            </Form.Group>
            <Form.Group className='form-new-scan-group width-l'>
              <TextField
                accepter={SelectPicker}
                name='LocationID'
                data={locations}
                onSelect={changeLocationId}
                value={formData.LocationID}
                appearance='subtle'
                placeholder=' '
                label='Location of ordering physician’s office*'
                error={formError.LocationID}
                className={`${formError.LocationID ? 'error-border' : ''}`}
                searchable={true}
              />
            </Form.Group>
            <Form.Group className='form-new-scan-group width-l'>
              <TextField
                accepter={InputMask}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                name='Fax'
                autoComplete='off'
                placeholder=' '
                label='Fax number*'
                value={formData.Fax}
                error={formError.Fax}
                onChange={(val: string) => onChangeTextField(val, 'Fax')}
              />
            </Form.Group>
          </div>
        </div>
        <div className='form-new-scan-block'>
          <Form.Group className='form-new-scan-group width-xl'>
            <TextField
              accepter={Textarea}
              name='Reason'
              value={formData.Reason}
              error={formError.Reason}
              label='Reason for exam*'
              tooltipText={'Why does this patient need this test? We’ll use this information for the patient’s prescription.'}
              onChange={(val: string) => onChangeTextField(val, 'Reason')}
            />
          </Form.Group>
        </div>
      </div>
    </Form>
  );
};

export default ScanInformation;
