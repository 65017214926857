import { Schema } from 'rsuite';
import { Physician } from '../../../../models/Physician';
import { FormStructurePhysicians, PhysicianTypeList, PhysicianTypeSchema } from './interfaces';

export const managePhysicianColumns = [
  {
    dataKey: 'FullName',
    minWidth: 250,
    align: 'center',
    headTitle: 'Physician Name',
    sortable: true,
    flexGrow: 1,
  },
  {
    dataKey: 'NPI',
    minWidth: 250,
    align: 'left',
    headTitle: 'Physician Number',
    flexGrow: 1,
    sortable: true,
  },
];

const { StringType } = Schema.Types;
export const model = Schema.Model({
  PhysicianType: StringType().isRequired('Please choose type'),
  FirstName: StringType().isRequired('This field is required').containsLetter('Please add a valid name'),
  LastName: StringType().isRequired('This field is required').containsLetter('Please add a valid last name'),
  NPI: StringType()
    .pattern(/^\d{10}$/, 'Please enter 10-digit identifier')
    .isRequired('This field is required'),
});

export const prepareSelectData = (data: PhysicianTypeSchema[]): PhysicianTypeList[] => {
  return data.map(element => ({
    label: element.PhysicianType,
    value: element.PhysicianType,
    role: element.ID,
  }));
};

export const getEditPhysicianInfo = (signature: string, data: Physician): FormStructurePhysicians => {
  const submitData = {
    FirstName: data.FirstName,
    LastName: data.LastName,
    NPI: data.NPI,
    PhysicianTypeID: data.PhysicianTypeID,
  };
  if (signature) {
    return {
      ...submitData,
      Signature: signature,
    };
  }
  return submitData;
};

export const physicianTypeList = [
  {
    label: 'Cardiology',
    value: 'Cardiology',
  },
  {
    label: 'Chiropractic',
    value: 'Chiropractic',
  },
  {
    label: 'Critical Care',
    value: 'Critical Care',
  },
  {
    label: 'Dermatology',
    value: 'Dermatology',
  },
];

export const FormDefaultValue = {
  FirstName: '',
  LastName: '',
  NPI: '',
  PhysicianTypeID: null,
  PhysicianType: '',
  Signature: '',
};

export const PhysicianTypeDefaultValue = {
  ID: 0,
  PhysicianType: '',
};

export const DefaultPhysician = {
  ID: 0,
  POID: 0,
  FirstName: '',
  LastName: '',
  NPI: '',
  Signature: '',
  PhysicianTypeID: 0,
  PhysicianType: '',
  IsActive: 0,
  Created: '',
  CreatedBy: 0,
  Updated: '',
  UpdatedBy: 0,
};

export function findPhysicianTypeId(data: PhysicianTypeSchema[], type: string): number {
  const targetType = data.find(physicianType => physicianType.PhysicianType === type);

  if (targetType) {
    return targetType.ID;
  } else {
    throw new Error('Physician type is not found');
  }
}

export const fieldsForCheck = ['FirstName', 'LastName', 'NPI', 'PhysicianTypeID'];
