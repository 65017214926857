import * as React from 'react';
import classNames from 'classnames';
import { Animation } from 'rsuite';
import { getOffset, on } from 'dom-lib';
import { RippleProps, RippleState } from './interfaces';

const { Transition } = Animation;

class Ripple extends React.Component<RippleProps, RippleState> {
  triggerRef: React.RefObject<HTMLElement>;

  constructor(props: RippleProps) {
    super(props);
    this.state = {
      rippling: false,
      position: {},
    };

    this.triggerRef = React.createRef();
  }

  mousedownListener: {
    off: () => void;
  } | null = null;

  componentDidMount(): void {
    if (this.triggerRef.current) {
      this.mousedownListener = on(this.triggerRef.current.parentNode as Node, 'mousedown', this.handleMouseDown);
    }
  }
  componentWillUnmount(): void {
    if (this.mousedownListener) {
      this.mousedownListener.off();
    }
  }

  getPosition = (event: React.MouseEvent): Record<string, number> => {
    const offset = getOffset(this.triggerRef.current as HTMLElement) as DOMRect;
    const offsetX = (event.pageX || 0) - offset.left;
    const offsetY = (event.pageY || 0) - offset.top;

    const radiusX = Math.max(offset.width - offsetX, offsetX);
    const radiusY = Math.max(offset.height - offsetY, offsetY);
    const radius = Math.sqrt(Math.pow(radiusX, 2) + Math.pow(radiusY, 2));

    return {
      width: radius * 2,
      height: radius * 2,
      left: offsetX - radius,
      top: offsetY - radius,
    };
  };

  handleMouseDown = (event: React.MouseEvent): void => {
    const position = this.getPosition(event);
    const { onMouseDown } = this.props;

    this.setState({
      rippling: true,
      position,
    });

    onMouseDown?.(position, event);
  };

  handleRippled = (): void => {
    this.setState({
      rippling: false,
    });
  };

  render(): JSX.Element {
    const { className } = this.props;
    const classes = classNames('rs-ripple-pond', className);
    const { position, rippling } = this.state;

    return (
      <span className={classes} ref={this.triggerRef}>
        <Transition in={rippling} enteringClassName={'rs-rippling'} onEntered={this.handleRippled}>
          {(props, ref) => {
            const { className, ...transitionRest } = props;
            return <span {...transitionRest} ref={ref} className={classNames('rs-rippling', className)} style={position} />;
          }}
        </Transition>
      </span>
    );
  }
}

export default Ripple;
