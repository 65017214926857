import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { Form, Schema, SelectPicker, Tooltip, Whisper } from 'rsuite';
import TextField from '../../../components/text-field-component';
import InputMask from '../../../components/mask-field';
import { initPatientPreferenceData, patientAvailablity as patientAvailablityData } from '../NewScanUtil';
import StripeComponent from '../../../components/stripe-component';
import { DateSchema, PatientPreferencesInsuranceSchema, PatientPreferencesSelfPaySchema } from '../Schemas';
import { InsurancePlans } from '../../../models/Insurance';
import { PatientPreferencesFormData, PatientPreferencesProps } from '../interfaces';
import { Source, StripeError } from '@stripe/stripe-js';
import { FormError } from '../../../models/General';
import { Texts } from '../../../components/modals/interfaces';
import InfoModalComponent from '../../../components/modals/info-modal';
import { verifyZipCode } from '../../../api/zip-code/ZipCodeAPI';
import { CommonContext, CommonContextType } from '../../../contexts/CommonContext';
import { useSignal } from '../../../utils/UseSignal';
import { isString } from '../../../utils/GeneralUtil';

const DateTimePicker = React.lazy(() => import('../../../components/date-time-picker'));

const PatientPreferences: React.FC<PatientPreferencesProps> = ({
  insuranceObj,
  price,
  formRef,
  setPatientPreferencesData,
  liftZipCodeStateUp,
}) => {
  const [isDatePicker, setIsDatePicker] = useState<boolean>(false);
  const [filteredInsurance, setFilteredInsurance] = useState<InsurancePlans[]>([]);
  const [stripeError, setStripeError] = useState<boolean>(false);
  const [patientAvailablity, setPatientAvailablity] = useState(patientAvailablityData);
  const [formData, setFormData] = useState<PatientPreferencesFormData>(initPatientPreferenceData);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setErrorText] = useState<Texts | null>(null);
  const [zipCodeHasError, setZipCodeHasError] = useState(false);
  const [isPolicyNameAnyMatches, setIsPolicyNameAnyMatches] = useState(false);
  const { InsurancePlans } = useContext<CommonContextType>(CommonContext);
  const signal = useSignal();

  const currentIsAnyTimeVal = useRef<string>('');

  useEffect(() => {
    if (formData !== initPatientPreferenceData) {
      setPatientPreferencesData(formData);
    }
  }, [formData.CardLast4, formData.AvailableSlots]);

  useEffect(() => {
    setZipCodeHasError(false);
    if (formData.ZipCode && formData.ZipCode.replaceAll('_', '').length === 5) {
      verifyZipCode(formData.ZipCode, signal)
        .then(res => {
          if (!res.IsZipCodeCorrect) {
            liftZipCodeStateUp(true);
            setFormError({ ...formError, ZipCode: 'Zip code is not valid' });
            setZipCodeHasError(true);
          } else {
            setFormError({ ...formError, ZipCode: '' });
            setZipCodeHasError(false);
            liftZipCodeStateUp(false);
            formRef.current?.checkForField('ZipCode');
          }
        })
        .catch(e => e);
    }
  }, [formData.ZipCode]);

  const setData = (data: PatientPreferencesFormData) => {
    setFormData(data);
    setPatientPreferencesData(data);
  };

  const checkError = (error: FormError) => {
    setFormError({
      ...error,
      AvailableSlots: currentIsAnyTimeVal.current === '1' ? '' : error.AvailableSlots,
      ZipCode: formError.ZipCode ? formError.ZipCode : error.ZipCode,
    });
  };

  const getIsAnyTimeValue = (value: string) => {
    if (value === '0') {
      setIsDatePicker(true);
    }

    if (value === '1') {
      setFormData(prev => ({ ...prev, AvailableSlots: '', AvailableSlotsView: {} }));
    }

    currentIsAnyTimeVal.current = value;
    setFormError(prev => ({
      ...prev,
      AvailableSlots: '',
      IsAnyTime: '',
    }));
  };

  const getSelfPayValue = (value: string) => {
    setData({
      ...formData,
      SelfPay: value,
      insuranceProvider: '',
      CardLast4: 0,
      PolicyNumber: '',
      InsurancePlanID: null,
      fullNameCard: '',
      CardType: '',
      PaymentID: '',
      PaymentSecret: '',
    });
  };

  const editInsuranceField = (value: string): void => {
    if (!InsurancePlans.length) {
      return;
    }
    const filtered: InsurancePlans[] = InsurancePlans.filter(el => {
      return el.PolicyName.toLowerCase().includes(value.toLowerCase());
    });
    const isAnyMatches = !!InsurancePlans.find(el => el.PolicyName.toLocaleLowerCase().includes(value.toLowerCase()));

    setIsPolicyNameAnyMatches(isAnyMatches);

    if (!value) {
      setFilteredInsurance([]);
    } else {
      const noMatches = [{ PolicyName: 'No matches found', ID: 0, PokitdokTradingPartnerID: null }];
      setFilteredInsurance(isAnyMatches ? filtered : noMatches);
    }
    setData({
      ...formData,
      InsurancePlanID: null,
      insuranceProvider: value,
    });
  };

  const fillInsurance = (el: InsurancePlans): void => {
    if (!el) {
      return;
    }

    setData({
      ...formData,
      insuranceProvider: el.PolicyName,
      InsurancePlanID: el.ID,
    });

    setFilteredInsurance([]);
  };

  const getDate = (flag: boolean, date: string, formatedDate: Record<string, string[]>) => {
    setFormData(prev => {
      return {
        ...prev,
        AvailableSlots: date,
        AvailableSlotsView: formatedDate,
      };
    });
    const newPatientAvailablity = [{ ...patientAvailablity[0] }, { ...patientAvailablity[1] }];
    newPatientAvailablity[1].label = date ? 'Date and time selected' : 'Select custom date and time';
    setFormError({
      ...formError,
      AvailableSlots: date ? '' : 'Please select dates and times',
    });
    setPatientAvailablity(newPatientAvailablity);
    setIsDatePicker(flag);
  };

  const stripeCallbackFunc = (error: StripeError | boolean | null, paymentMethod: Source | null) => {
    if (!paymentMethod || !!error) {
      setStripeError(true);

      if (formData.PaymentID) {
        setFormData(prev => {
          return {
            ...prev,
            CardLast4: 0,
            CardType: '',
            PaymentID: '',
            PaymentSecret: '',
          };
        });
      }
      return;
    }

    setStripeError(false);
    setFormData(prev => {
      return {
        ...prev,
        CardLast4: Number(paymentMethod.card?.last4),
        CardType: paymentMethod.card?.brand as string,
        PaymentID: paymentMethod.id,
        PaymentSecret: paymentMethod.client_secret,
      };
    });
    setFormError({
      ...formError,
      CardType: '',
    });
  };

  const setFullName = (data: string): void => {
    setData({ ...formData, fullNameCard: data });
  };

  const handleChangeZipCode = (value: string): void => {
    if ((formError.ZipCode === 'Zip code is not valid' || !zipCodeHasError) && value !== formData.ZipCode) {
      delete formError.ZipCode;
    }
  };

  const UpdatedPatientPreferencesSelfPaySchema =
    formData.IsAnyTime === '0' ? Schema.Model.combine(PatientPreferencesSelfPaySchema, DateSchema) : PatientPreferencesSelfPaySchema;
  const UpdatedPatientPreferencesInsuranceSchema =
    formData.IsAnyTime === '0' ? Schema.Model.combine(PatientPreferencesInsuranceSchema, DateSchema) : PatientPreferencesInsuranceSchema;

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <DateTimePicker
          isShow={isDatePicker}
          onClose={getDate}
          forbidenDaysCount={4}
          minDateCount={3}
          maxDateCount={5}
          dateFormat='MMM do'
        />
      </Suspense>
      <Form
        className='form form-new-scan'
        ref={formRef}
        formValue={formData}
        onCheck={checkError}
        checkTrigger='change'
        onChange={(data: PatientPreferencesFormData) => setData(data)}
        model={formData.SelfPay === '1' ? UpdatedPatientPreferencesSelfPaySchema : UpdatedPatientPreferencesInsuranceSchema}
      >
        <div className='form-new-scan-block scan-patient-preferences'>
          <div className='form-container'>
            <h2 className='mb1'>
              <b>Patient Preferences</b>
            </h2>
            <div className='sub-text mb5'>
              <p>This section is required to complete scan request or you can send this section to patients to complete.</p>
            </div>
            <div className='form-new-scan-block full-scan-type full-scan-mob'>
              <Form.Group className='form-new-scan-group width-l'>
                <TextField
                  accepter={InputMask}
                  name='ZipCode'
                  autoComplete='off'
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                  label='Zip code*'
                  error={formError.ZipCode}
                  value={formData.ZipCode}
                  onChange={handleChangeZipCode}
                />
              </Form.Group>
              <Form.Group className='form-new-scan-group width-l'>
                <TextField
                  accepter={SelectPicker}
                  name='IsAnyTime'
                  data={patientAvailablity}
                  onChange={getIsAnyTimeValue}
                  appearance='subtle'
                  placeholder='Select custom dates and times'
                  searchable={false}
                  value={formData.IsAnyTime}
                  error={formError.IsAnyTime || formError.AvailableSlots}
                  className={`${formError.IsAnyTime || formError.AvailableSlots ? 'error-border' : ''}`}
                />
              </Form.Group>
              <Form.Group className='form-new-scan-group width-l'>
                <TextField
                  accepter={SelectPicker}
                  name='SelfPay'
                  data={insuranceObj}
                  onSelect={getSelfPayValue}
                  appearance='subtle'
                  placeholder='Insurance or self-pay'
                  searchable={false}
                  value={formData.SelfPay}
                  error={formError.SelfPay}
                  className={`${formError.SelfPay ? 'error-border' : ''}`}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        {formData.SelfPay === '0' && (
          <div className='form-new-scan-block scan--preferences form-container'>
            <Form.Group className='form-new-scan-group width-xl'>
              <TextField
                name='insuranceProvider'
                value={formData.insuranceProvider}
                onChange={editInsuranceField}
                onBlur={() => setFilteredInsurance([])}
                autoComplete='off'
                label='Insurance provider*'
                error={isString(formError.insuranceProvider) ? 'This field is required' : ''}
              />
              {!!filteredInsurance && (
                <ul className='form-new-scan-list insurance--list'>
                  {filteredInsurance.map((el: InsurancePlans) => {
                    return (
                      <li key={el.ID + el.PolicyName} onMouseDown={() => fillInsurance(el)}>
                        <button type='button' disabled={!isPolicyNameAnyMatches} onMouseDown={() => delete formError.InsurancePlanID}>
                          {el.PolicyName}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Form.Group>
            <Form.Group className='form-new-scan-group width-m'>
              <TextField
                name='PolicyNumber'
                autoComplete='off'
                error={formError.PolicyNumber}
                value={formData.PolicyNumber}
                label='Member ID*'
              />
            </Form.Group>
          </div>
        )}
        {formData.SelfPay === '1' && (
          <div className={`form-new-scan-block scan--card form-container ${stripeError ? 'invalid' : ''}`}>
            <div className='form-new-scan-block-headings'>
              <h3>
                <b>Total cost of scans:</b> <span>{price.total ? `$${price.total}` : '0'}</span>
              </h3>
              <p>
                <b>
                  Deposit due today:
                  <Whisper
                    placement='top'
                    controlId='control-id-hover'
                    trigger='hover'
                    speaker={
                      <Tooltip>
                        Deposit will be refunded if appointment
                        <br />
                        isn&#180;t&nbsp;accommodated or cancelled, please contact <a href='mailto:support@medmo.com'>
                          support@medmo.com
                        </a>{' '}
                        for assistance
                      </Tooltip>
                    }
                  >
                    <span className='tooltip'></span>
                  </Whisper>
                </b>{' '}
                <span>{price.deposit ? `$${price.deposit}` : '0'}</span>
              </p>
              <p>
                <b>Remainder due at the imaging center on the day of the exam:</b> <span>{price.exam ? `$${price.exam}` : '0'}</span>
              </p>
            </div>
            <StripeComponent
              callback={stripeCallbackFunc}
              formError={formError}
              setFullName={setFullName}
              cardOwner={formData.fullNameCard as string}
            />
          </div>
        )}
      </Form>
      {errorText && <InfoModalComponent onClose={() => setErrorText(null)} type='error' texts={errorText} />}
    </>
  );
};

export default PatientPreferences;
