export const SettingsUsersTexts = {
  successInviteUser: {
    title: 'Invitation Successfully Sent',
    content: 'Your invitation has been sent. Once they accept their invitation, they can view and edit their profiles.',
  },
  successRemoveUser: {
    title: 'User Successfully Removed',
    content: 'You have successfully removed this user from the portal. The user will no longer be able to access the portal.',
  },
  successUpdateUserInfo: {
    title: 'User Info Successfully Updated',
    content: 'You have successfully updated this user information',
  },
};
