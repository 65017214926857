import React, { useState, useEffect, useContext } from 'react';
import { Nav, Panel } from 'rsuite';
import SettingsUserTabComponent from '../tabs/users';
import SettingsLocationTabComponent from '../tabs/locations';
import SettingsPhysicianTabComponent from '../tabs/physicians';
import SettingsOrgananizationTabComponent from '../tabs/organization';
import SettingsGeneralTabComponent from '../tabs/general';
import { UserContextType, UserContext } from '../../../contexts/UserContext';
import SettingsGeneralOrgananizationTabComponent from '../tabs/organization-settings';
import { AppProps } from '../../../components/router-component/interfaces';
import { isAvailableTab, TABS_NAME } from '../PortalSettingsUtil';
import ChargebeeBillingTabComponent from '../tabs/billing';
import { useHistory, useLocation } from 'react-router';
import { CHARGBEE_ACTIVE } from '../../../config/FrontConfig';
import { SettingsUrl } from '../../../config/UrlsConfig';

const SettingsWrapperComponent: React.FunctionComponent<AppProps> = props => {
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [isStandardUser, setIsStandardUser] = useState<boolean>(false);
  const [IsChargebeeUsed, setIsChargebeeUsed] = useState<boolean>(false);
  const hash = useLocation().hash?.slice(1);
  const history = useHistory();
  const currentUser = useContext<UserContextType>(UserContext);

  useEffect(() => {
    if (currentUser) {
      setRole(currentUser.UserType);
      const isStandard = currentUser.UserType === 'Standard';
      setIsStandardUser(isStandard);
      if (currentUser?.PO) {
        if (!!currentUser.PO?.IsChargebeeUsed && CHARGBEE_ACTIVE && hash === TABS_NAME.Billing) {
          setActiveNav(TABS_NAME.Billing);
        } else if (hash && hash !== TABS_NAME.Billing && isAvailableTab(hash)) {
          setActiveNav(hash);
        } else {
          changeActiveTab(TABS_NAME.General);
        }
        setIsChargebeeUsed(!!currentUser.PO?.IsChargebeeUsed);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!hash) {
      setActiveNav(TABS_NAME.General);
      return;
    }

    if (hash && activeNav !== hash) {
      setActiveNav(hash);
    }
  }, [hash]);

  const changeActiveTab = (value: string) => {
    if (value) {
      history.push(`/settings#${value}`);
    } else {
      history.push(SettingsUrl);
    }
  };

  return (
    <div className='page-content-main'>
      <div className='tabs-panel'>
        <Nav appearance='tabs' activeKey={activeNav} onSelect={changeActiveTab}>
          {role !== 'Super Administrator' && <Nav.Item eventKey={TABS_NAME.General}>My Profile</Nav.Item>}
          {role !== 'Super Administrator' && !isStandardUser && (
            <Nav.Item disabled={isStandardUser} eventKey={TABS_NAME.OrganizationSetting}>
              Organization Settings
            </Nav.Item>
          )}
          {role !== 'Super Administrator' && !isStandardUser && (
            <Nav.Item disabled={isStandardUser} eventKey={TABS_NAME.Users}>
              Manage Users
            </Nav.Item>
          )}
          {role !== 'Super Administrator' && !isStandardUser && (
            <Nav.Item disabled={isStandardUser} eventKey={TABS_NAME.Location}>
              Manage Locations
            </Nav.Item>
          )}
          {role !== 'Super Administrator' && !isStandardUser && (
            <Nav.Item disabled={isStandardUser} eventKey={TABS_NAME.Physicians}>
              Manage Physicians
            </Nav.Item>
          )}
          {CHARGBEE_ACTIVE && IsChargebeeUsed && role !== 'Super Administrator' && !isStandardUser && (
            <Nav.Item disabled={isStandardUser} eventKey={TABS_NAME.Billing}>
              Billing
            </Nav.Item>
          )}
          {role === 'Super Administrator' && !isStandardUser && <Nav.Item eventKey={TABS_NAME.Organization}>Manage Organization</Nav.Item>}
        </Nav>
      </div>
      <div className='tab-content'>
        <Panel header={<></>}>
          {activeNav === TABS_NAME.General && <SettingsGeneralTabComponent {...props} />}
          {activeNav === TABS_NAME.OrganizationSetting && <SettingsGeneralOrgananizationTabComponent />}
          {activeNav === TABS_NAME.Users && <SettingsUserTabComponent />}
          {activeNav === TABS_NAME.Location && <SettingsLocationTabComponent />}
          {activeNav === TABS_NAME.Physicians && <SettingsPhysicianTabComponent />}
          {activeNav === TABS_NAME.Organization && <SettingsOrgananizationTabComponent />}
          {activeNav === TABS_NAME.Organization && <SettingsOrgananizationTabComponent />}
          {activeNav === TABS_NAME.Billing && <ChargebeeBillingTabComponent />}
        </Panel>
      </div>
    </div>
  );
};

export default SettingsWrapperComponent;
