import { Schema } from 'rsuite';
import { FileTypeWithTitle } from '../../../../new-scan/interfaces';

const { StringType } = Schema.Types;

export const model = Schema.Model({
  ClinicalNotes: StringType(),
});

export const DefaultFormValue = {
  KeysToSave: [],
  LogisticNotes: '',
  ClinicalNotes: '',
  NotesOptions: '',
  fileListNotes: [],
};

export const texts = {
  wait: 'Pending patient response',
  custom: 'Custom availability selected',
  flexible: 'As soon as possible; flexible',
  confirmText: {
    title: 'Are you sure you want to delete this file?',
    text: '',
  },
};

export const preparedFileData = (list: FileTypeWithTitle[], defaultName?: string): FileTypeWithTitle[] => {
  return list.map((item: FileTypeWithTitle, i: number) => {
    const defaultN = defaultName ? `${defaultName} ${i + 1}` : '';
    return {
      fileTitle: item.fileTitle || defaultN,
      name: item.originalName || defaultN,
      key: item.key,
      id: i,
      url: item.url,
    };
  });
};

export const scanCoverageInfo = (value: number | null): string => {
  if (typeof value === 'number') {
    return value ? 'selfpay' : 'insurance';
  }

  return texts.wait;
};

export type Details = {
  ClinicalNotes: string;
  ClinicalNotesFile: string;
  OtherNotes: string;
  PatientPreferences: { [key: string]: any };
  Location: { [key: string]: any };
  Patient: { [key: string]: any };
  ScanInfo: { [key: string]: any };
  Prescriber: { [key: string]: any };
};

export const DetailsDefaultValue = {
  ClinicalNotes: '',
  ClinicalNotesFile: '',
  OtherNotes: '',
  PatientPreferences: { TestRequest: {} },
  Location: {},
  Patient: {},
  ScanInfo: { ScanService: {} },
  Prescriber: {},
};

export const checkAreObjectsTheSame = (initObjects: FileTypeWithTitle[], updatedObjects: FileTypeWithTitle[]): boolean => {
  if (initObjects.length !== updatedObjects.length) {
    return false;
  }

  if (!initObjects.length) {
    return true;
  }

  return updatedObjects.every(obj => {
    if ('blobFile' in obj) {
      return false;
    }

    return initObjects.find(item => item.fileTitle === obj.fileTitle);
  });
};
