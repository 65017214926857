import React from 'react';
import ReactDOM from 'react-dom';
import ConfirmModalComponent from '../components/modals/confirm-modal-component';

const UserConfirmation = (message: string, callback: (flag: boolean) => void): void => {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const closeModal = (callbackState: boolean) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  ReactDOM.render(<ConfirmModalComponent onClose={closeModal} info={JSON.parse(message)} />, container);
};

export default UserConfirmation;
