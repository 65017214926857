import * as React from 'react';
import classNames from 'classnames';

import SafeAnchor from './SafeAnchor';
import Ripple from './Ripple';

import { PaginationButtonProps } from './interfaces';

class PaginationButton extends React.Component<PaginationButtonProps> {
  handleClick = (event: React.MouseEvent): void => {
    const { disabled, onSelect, eventKey } = this.props;
    if (disabled) {
      return;
    }

    onSelect?.(eventKey, event);
  };

  render(): JSX.Element {
    const { active, disabled, className, style, children, renderItem } = this.props;

    const classes = classNames('rs-pagination-btn', className, {
      ['rs-pagination-btn-active']: active,
      ['rs-pagination-btn-disabled']: disabled,
    });

    const itemProps = {
      disabled,
      onClick: this.handleClick,
    };

    const item = (
      <SafeAnchor {...itemProps}>
        {children}
        <Ripple />
      </SafeAnchor>
    );

    return (
      <li className={classes} style={style}>
        {renderItem ? renderItem(item) : item}
      </li>
    );
  }
}

export default PaginationButton;
