import React from 'react';
import { Button, Message, ButtonToolbar, Toggle, Input, Panel, Table } from 'rsuite';
import logo from '../../assets/logo-color.png';
import fake from './FakeUsers.json';

const { Column, HeaderCell, Cell } = Table;
const UiKitComponent: React.FunctionComponent = () => {
  return (
    <div className='bg'>
      <img src={logo} alt='logo' />
      <h1>Ui Kit page</h1>
      <div className='container'>
        <Panel header={<h3>Manage Users</h3>}>
          <div className='btn-add'>
            <Button appearance='primary'>+ Invite User</Button>
          </div>
          <div>
            <Table minHeight={0} data={fake}>
              <Column width={70} align='center' fixed>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column width={200} fixed>
                <HeaderCell>User Role</HeaderCell>
                <Cell dataKey='firstName' />
              </Column>
              <Column width={200}>
                <HeaderCell>Last Name</HeaderCell>
                <Cell dataKey='lastName' />
              </Column>
              <Column width={200}>
                <HeaderCell>Email Address</HeaderCell>
                <Cell dataKey='city' />
              </Column>
              <Column width={200}>
                <HeaderCell>Account Status </HeaderCell>
                <Cell dataKey='street' />
              </Column>
            </Table>
          </div>
        </Panel>
      </div>
      <ButtonToolbar>
        <div>
          <Button appearance='default'></Button>
        </div>
        <div>
          <Button appearance='primary'>+ Invite User</Button>
        </div>
        <div>
          <Button href='https://stackoverflow.com/' appearance='link'>
            Link
          </Button>
        </div>
        <ul>
          <li>
            <div>
              <Button classPrefix='btn-state draft' appearance='subtle'>
                Draft
              </Button>
            </div>
          </li>
          <li>
            <div>
              <Button classPrefix='btn-state requested' appearance='subtle'>
                Requested
              </Button>
            </div>
          </li>
          <li>
            <div>
              <Button classPrefix='btn-state completed' appearance='subtle'>
                Completed
              </Button>
            </div>
          </li>
          <li>
            <div>
              <Button classPrefix='btn-state scheduled' appearance='subtle'>
                Scheduled
              </Button>
            </div>
          </li>
        </ul>

        <div>
          <Button classPrefix='btn-edit'>Edit</Button>
        </div>
        <div>
          <Button appearance='ghost'>+ Request a new scan</Button>
        </div>
      </ButtonToolbar>

      <Button className='btn'>Default</Button>
      <div>
        <Toggle checkedChildren='On' unCheckedChildren='Off' />
      </div>
      <div>
        <Input style={{ width: 300 }} placeholder='Default Input' />
      </div>

      <div style={{ maxWidth: 800, margin: '20px auto 40px' }}>
        <Message type='info'>Informational</Message>
        <Message type='success'>Success</Message>
        <Message type='warning'>Warning</Message>
        <Message type='error'>Error</Message>
      </div>
      <div style={{ maxWidth: 800, margin: '20px auto 40px' }}>
        <Message type='info' title='Informational'>
          <p>
            Additional description and informations about copywriting.
            <br />
            <a href='#'>This is a Link.</a>
          </p>
        </Message>

        <Message type='success' title='Success'>
          <p>
            Additional description and informations about copywriting.
            <br />
            <a href='#'>This is a Link.</a>
          </p>
        </Message>

        <Message type='warning' title='Warning'>
          <p>
            Additional description and informations about copywriting.
            <br />
            <a href='#'>This is a Link.</a>
          </p>
        </Message>

        <Message type='error' title='Error'>
          <p>
            Additional description and informations about copywriting.
            <br />
            <a href='#'>This is a Link.</a>
          </p>
        </Message>
      </div>
    </div>
  );
};

export default UiKitComponent;
