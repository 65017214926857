import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getScanScheduleInfo } from '../../../../../api/all-scans/AllScansAPI';
import { transformDate, transformDateToDayOfWeek, transformDateToTime } from '../../../../../utils/TimeUtil';
import ErrorMessageComponent from '../../../../../components/error-message-component';
import { useError } from '../../../../../utils/UseError';
import { ScanSheduleInfo } from './interfaces';
import LoaderComponent from '../../../../../components/loader-component';
import { useSignal } from '../../../../../utils/UseSignal';

const ScanSchedulingComponent: React.FunctionComponent = () => {
  const [scanScheduleInfo, setScanScheduleInfo] = useState<ScanSheduleInfo | Record<string, string>>({});
  const [errorText, setErrorText] = useError(null);
  const [isLoading, setIsLoading] = useState(true);
  const signal = useSignal();
  const { scanId } = useParams<{ scanId: string }>();

  useEffect(() => {
    getScanScheduleInfo(scanId, signal)
      .then(res => {
        setScanScheduleInfo(res);
        setErrorText(null);
      })
      .catch(error => {
        if (!signal.aborted) {
          setErrorText(error);
          setScanScheduleInfo({});
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className='page-content-wrap page-scan-tab'>
          <div className='page-scan-tab_title'>Scheduling Information</div>
          {!Object.keys(scanScheduleInfo).length && !errorText && (
            <div className='page-scan-tab_nodata text-center'>
              This scan request has not be scheduled yet. Scheduling information will appear once the scan has been assigned to a center. If
              you have any questions or concerns please contact Medmo Support
            </div>
          )}
          {Object.keys(scanScheduleInfo).length ? (
            <div className='page-content-data'>
              <div className='page-content-data_item'>
                <div className='h2'>Imaging Center Details</div>
                <ul>
                  <li>
                    <b>Imaging center:&nbsp;</b>
                    {scanScheduleInfo.IC_Name}
                  </li>
                  <li>
                    <b>Location:&nbsp;</b>
                    {scanScheduleInfo.IC_Address1 +
                      ', ' +
                      scanScheduleInfo.IC_City +
                      ', ' +
                      scanScheduleInfo.IC_State +
                      ' ' +
                      scanScheduleInfo.IC_ZipCode}
                  </li>
                  <li>
                    <b>Phone number:&nbsp;</b>
                    {scanScheduleInfo.IC_Phone}
                  </li>
                  <li>
                    <b>Fax number:&nbsp;</b>
                    {scanScheduleInfo.IC_FaxNumber}
                  </li>
                  <li>
                    <b>Imaging center representative:&nbsp;</b>
                    {scanScheduleInfo.IC_Rep}
                  </li>
                  <li>
                    <b>Representative email:&nbsp;</b>
                    {scanScheduleInfo.IC_RepEmail}
                  </li>
                </ul>
              </div>
              <div className='page-content-data_item'>
                <div className='h2'>Scan Appointment Information</div>
                <ul>
                  <li>
                    <b>Appointment date:&nbsp;</b>
                    {scanScheduleInfo.Appointment
                      ? transformDateToDayOfWeek(scanScheduleInfo.Appointment) + ', ' + transformDate(scanScheduleInfo.Appointment)
                      : null}
                  </li>
                  <li>
                    <b>Appointment time:&nbsp;</b>
                    {scanScheduleInfo.Appointment ? transformDateToTime(scanScheduleInfo.Appointment) : null}
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className='page-content-data'>&nbsp;</div>
          )}
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
        </div>
      )}
    </>
  );
};

export default ScanSchedulingComponent;
