export const NavLinks = [
  {
    title: 'Dashboard',
    icoName: 'icon dashboard-icon',
    url: '/dashboard',
  },
  {
    title: 'Scans',
    icoName: 'icon scans-icon',
    url: '/all-scans',
  },
  {
    title: 'Patients',
    icoName: 'icon patients-icon',
    url: '/patients',
  },
  {
    title: 'Tasks',
    icoName: 'icon tasks-icon',
    url: '/tasks',
  },
  {
    title: 'Comments',
    icoName: 'icon comments-icon',
    url: '/comments',
  },
  {
    title: 'Portal Settings',
    icoName: 'icon preferences-icon',
    url: '/settings',
  },
];
