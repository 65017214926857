import React, { useState, useCallback } from 'react';
import logo from '../../assets/logo_main_white.svg';
import NewUserOnBoardingComponent from './components/new-user';
import InvalidOnBoardingComponent from './components/error';
import NewOrganizationOnBoardingComponent from './components/new-organization';
import LoaderComponent from '../../components/loader-component';
import { InfoTexts } from './Texts';
import { useHistory } from 'react-router';
import InfoModalComponent from '../../components/modals/info-modal';
import { Redirect } from 'react-router-dom';
import { registerNewOrganization } from '../../api/organization/OrganizationAPI';
import { prepareOrganizationData } from './components/new-organization/NewOrganizationUtil';
import { prepareUserData } from './components/new-user/NewUserUtil';
import { registerNewUser } from '../../api/users/UsersAPI';
import { LoginUrl } from '../../config/UrlsConfig';
import { AccountInfo, Organization } from './components/new-organization/interfaces';

const OnBoardingComponent: React.FunctionComponent = () => {
  const [error, setError] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [infoModalTexts, setInfoModalTexts] = useState<Record<string, string>>({});
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const history = useHistory();
  const [redirect, setRedirect] = useState<boolean>(false);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [newUserFormValue, setNewUserFormValue] = useState<Record<string, string> | null>(null);
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [newUserInvitationId, setNewUserInvitationId] = useState<string>('');
  const [organizationValue, setOrganizationValue] = useState<Organization | null>(null);
  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false);
  const isAdmin = history.location.pathname.includes('admin');

  const liftingNewUserStateUp = (formValue: Record<string, string>, email: string, invitationId: string) => {
    setNewUserFormValue(formValue);
    setNewUserEmail(email ? email : formValue.email);
    setNewUserInvitationId(invitationId ? invitationId : formValue.invitationId);
  };

  const registerOrganization = function (organizationLogo: string, formValue: Organization, POID: number) {
    registerNewOrganization(prepareOrganizationData(organizationLogo, formValue, POID))
      .then(() => {
        setShowInfoModal(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const onCloseUserRegistration = useCallback(
    event => {
      if (event) {
        if (isAdmin) {
          setInfoModalTexts(InfoTexts.successAdminRegister);
          setStep(2);
        } else {
          setInfoModalTexts(InfoTexts.successUserRegister);
          setShowInfoModal(true);
        }
      }
    },
    [step],
  );

  const submitNewOrganization = useCallback(
    (formValue: Organization, logoKey: string) => {
      if (!newUserFormValue) {
        return;
      }

      setIsLoading(true);
      registerNewUser(prepareUserData(newUserFormValue, newUserEmail, newUserInvitationId))
        .then(response => {
          setAccountInfo({ ...response, password: newUserFormValue.password });
          if (response.status && response.message) {
            setIsLoading(false);
            return;
          }
          onCloseUserRegistration(true);
          return response;
        })
        .then(res => registerOrganization(logoKey, formValue, res?.POID as number))
        .catch(() => {
          setError(true);
          setAccountInfo(null);
        })
        .finally(() => setIsLoading(false));
    },
    [newUserFormValue],
  );

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
    setRedirect(true);
  };

  const onBackOrganization = () => {
    setStep(1);
    setAccountInfo({ ...newUserFormValue, email: newUserEmail, invitationId: newUserInvitationId });
  };

  return (
    <div className='onboarder-main'>
      <header className='onboarder-head'>
        <div className='container'>
          <div className='onboarder-head_logo'>
            <img src={logo} alt='' />
          </div>
        </div>
      </header>
      <main className='main'>
        <div className='container'>
          {redirect && <Redirect to={LoginUrl} />}
          {isLoading ? <LoaderComponent /> : null}
          {error || isLinkExpired ? (
            <InvalidOnBoardingComponent isLinkExpired={isLinkExpired} />
          ) : (
            <>
              {step === 1 && (
                <NewUserOnBoardingComponent
                  liftingStateUp={liftingNewUserStateUp}
                  isAdmin={isAdmin}
                  accountInfo={accountInfo}
                  setStep={() => setStep(2)}
                  onCloseUserRegistration={onCloseUserRegistration}
                  setIsLinkExpired={setIsLinkExpired}
                  setError={error => setError(error)}
                />
              )}
              {step === 2 && (
                <NewOrganizationOnBoardingComponent
                  onBack={onBackOrganization}
                  onSubmit={submitNewOrganization}
                  setIsLoading={setIsLoading}
                  organizationValue={organizationValue}
                  setOrganizationValue={setOrganizationValue}
                />
              )}
              {showInfoModal && <InfoModalComponent type='success' onClose={handleCloseInfoModal} texts={infoModalTexts} />}
            </>
          )}
        </div>
      </main>
      <footer className='onboarder-footer'>
        <div className='container'>
          {/*<ul>*/}
          {/*  <li>*/}
          {/*    <a href='#'>Terms & Privacy</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href='#'>Need Help? Contact Medmo Customer Support</a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
      </footer>
    </div>
  );
};

export default OnBoardingComponent;
