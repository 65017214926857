import React, { useEffect, useState } from 'react';
import { Button } from 'rsuite';

import TableComponent from '../../../../components/table-component';
import OrganizationModal from './OrganizationModal';
import { SettingsOrganizationTexts } from './Texts';
import { PageSpacingTable, TableAction } from '../../../../enum/TableActionEnum';
import RemoveOrganizationModal from './RemoveOrganizationModal';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { manageOrganizationColumns } from './OrganizationUtil';
import { getOrganizations } from '../../../../api/organization/OrganizationAPI';
import useDynamicTableHeight from '../../../../utils/useDynamicTableHeight';
import LoaderComponent from '../../../../components/loader-component';
import { OrganizationFormData } from './interfaces';
import { Organization } from '../../../../models/Organization';
import { useSignal } from '../../../../utils/UseSignal';

const SettingsOrganizationTabComponent: React.FunctionComponent = () => {
  const [showOrganizationModal, setShowOrganizationModal] = useState<boolean>(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [showRemoveOrganizationModal, setShowRemoveOrganizationModal] = useState<boolean>(false);
  const [infoModalTexts, setInfoModalTexts] = useState<Record<string, string>>({});
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Organization[] | []>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editFormValue, setEditFormValue] = useState<OrganizationFormData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { navRef, size } = useDynamicTableHeight(PageSpacingTable.SettingOrganization, 608);
  const signal = useSignal();

  const getListOfOrganizations = () => {
    setIsLoading(true);
    getOrganizations(signal)
      .then(res => setTableData(res))
      .catch(() => {
        if (!signal.aborted) {
          setTableData([]);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getListOfOrganizations();
  }, []);

  const onCloseOrganizationModal = (data: OrganizationFormData | boolean) => {
    setShowOrganizationModal(false);
    if (data) {
      const modalText = selectedOrganization ? SettingsOrganizationTexts.successEditLocation : SettingsOrganizationTexts.successAddLocation;
      setInfoModalTexts(modalText);
      setShowInfoModal(true);
      setSelectedOrganization(null);
      getListOfOrganizations();
    }
  };

  const onCloseRemoveUserModal = (event: boolean) => {
    setShowRemoveOrganizationModal(false);
    if (event) {
      setInfoModalTexts(SettingsOrganizationTexts.successRemoveLocation);
      setShowInfoModal(true);
      setSelectedOrganization(null);
      getListOfOrganizations();
    }
  };

  const onTableAction = (actionName: TableAction, rowData: Organization) => {
    setSelectedOrganization(rowData);
    if (actionName === TableAction.Remove) {
      setShowRemoveOrganizationModal(true);
    }
    if (actionName === TableAction.Edit) {
      setShowOrganizationModal(true);
      setEditMode(true);
      setEditFormValue({ internalName: rowData.InternalName, superAdmin: rowData.Email, IsChargebeeUsed: rowData.IsChargebeeUsed });
    }
  };

  return (
    <>
      <div className='nav-top_wrap' ref={navRef}>
        <div className='btn-add'>
          <Button
            className='rs-btn-model btn-md'
            onClick={() => {
              setEditMode(false);
              setSelectedOrganization(null);
              setShowOrganizationModal(true);
            }}
          >
            + Add PO
          </Button>
        </div>
      </div>
      {isLoading && <LoaderComponent className={'loader-block-center'} />}
      {!isLoading && (
        <div>
          <TableComponent columns={manageOrganizationColumns} data={tableData} onAction={onTableAction} customHeight={size} />
        </div>
      )}
      {showOrganizationModal && (
        <OrganizationModal
          editMode={editMode}
          editFormValue={editFormValue}
          onClose={onCloseOrganizationModal}
          organization={selectedOrganization}
        />
      )}
      {showRemoveOrganizationModal && selectedOrganization && (
        <RemoveOrganizationModal
          organizationName={selectedOrganization.Name || ''}
          onClose={onCloseRemoveUserModal}
          organizationId={selectedOrganization.ID}
        />
      )}
      {showInfoModal && <InfoModalComponent type='success' onClose={() => setShowInfoModal(false)} texts={infoModalTexts} />}
    </>
  );
};

export default SettingsOrganizationTabComponent;
