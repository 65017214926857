export const fileRenameModalText = {
  title: 'File Description Successfully Renamed',
  content: 'Your file description has been successfully updated.',
};

export const fileModalContent = {
  title: 'Are You Sure You Want to Delete this File?',
  text: 'Deleting this file will remove it permanently from the application. Would you like to delete this file?',
  submitBtnText: 'Yes, Delete File',
};
