import React, { useEffect, useMemo, useState } from 'react';
import { InputGroup } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import TableComponent from '../table-component';
import { DebounceInput } from 'react-debounce-input';
import { Props } from './interfaces';
import { fixedEncodeURIComponent } from '../../utils/GeneralUtil';
import { Task } from '../../models/Task';
import { Scan } from '../../views/all-scans/interfaces';
import { Comment } from '../../views/comments/interfaces';
import { Patient } from '../../models/Patient';
import { useSignal } from '../../utils/UseSignal';
import { searchBarColumnsClientOrderId } from '../../views/all-scans/AllScansUtil';

const SearchBarComponent: React.FunctionComponent<Props> = props => {
  const { placeholderText, columns, searchMethod, searchText, handleRowClick, filters } = props;
  const [data, setData] = useState<Array<Patient | Scan | Task | Comment>>([]);
  const [isClientOrderIDShowed, setIsClientOrderIDShowed] = useState<boolean | undefined>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideResults, setHideResults] = useState<boolean>(true);
  const trimmedQueryLength = searchQuery.trim().length;
  const [tableHeight, setTableHeight] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const signal = useSignal();

  useEffect(() => {
    setSearchQuery('');
  }, [filters]);

  const checkArr = (res: (Patient | Scan | Task | Comment)[]): void => {
    if (res.length) {
      const height = res.length * 60 + 40;
      const preparedHeight = height < 400 ? height : 400;
      const preparedData = res;
      const IsShowClientOrderIDs = res && res[0].IsShowClientOrderID;
      setIsClientOrderIDShowed(IsShowClientOrderIDs);
      setData(preparedData);
      setTableHeight(preparedHeight);
    } else {
      setData([]);
      setIsClientOrderIDShowed(false);
    }
  };

  const handleChange = (newQuery: string) => {
    const trimmedQuery = newQuery.trim();

    if (trimmedQuery.length < 2) {
      setSearchQuery(newQuery);
      setData([]);
      return;
    }

    setIsLoading(true);
    setSearchQuery(newQuery);

    searchMethod(fixedEncodeURIComponent(trimmedQuery), filters || '', signal)
      .then(res => checkArr(res))
      .catch(() => {
        if (!signal.aborted) {
          setData([]);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const ClearInput = (): void => {
    setSearchQuery('');
    document.getElementById('search-input')?.focus();
  };

  const noResultTextShouldBeHidden = useMemo(
    () => !!data.length || trimmedQueryLength < 2 || isLoading,
    [data, trimmedQueryLength, isLoading],
  );

  return (
    <>
      {!hideResults && <div className='search-bar-mask' onClick={() => setHideResults(true)}></div>}
      <InputGroup>
        <DebounceInput
          className='rs-input'
          id='search-input'
          onFocus={() => setHideResults(false)}
          style={{ width: '100%' }}
          placeholder={placeholderText}
          autoComplete='off'
          debounceTimeout={300}
          onChange={e => handleChange(e.target.value)}
          value={searchQuery}
        />
        <InputGroup.Addon className='icon-search'>
          <SearchIcon />
        </InputGroup.Addon>
        <button className='btn-cancel-search' onClick={ClearInput} hidden={searchQuery.length === 0}>
          Cancel search
        </button>
        <div className='search-dropdown' hidden={hideResults}>
          <div className='search-line-nohistory text-center' hidden={trimmedQueryLength >= 2}>
            {searchText}
          </div>
          <div className='search-line-result' hidden={trimmedQueryLength < 2}>
            Search results for <i>“{searchQuery}”</i>
          </div>
          <div className='search-dropdown-noresult'>
            <div className='text-center' hidden={noResultTextShouldBeHidden}>
              <i>We found 0 results.</i>
            </div>
          </div>
          {!!data.length && trimmedQueryLength >= 2 && (
            <div className='search-dropdown-list'>
              <TableComponent
                onRowClick={rowData => handleRowClick(rowData)}
                heightColumn={60}
                columns={isClientOrderIDShowed ? searchBarColumnsClientOrderId : columns}
                data={data}
                searchQuery={searchQuery}
                noLastCellIcon
                customHeight={tableHeight}
                isEdit={false}
                isRemove={false}
              />
            </div>
          )}
        </div>
      </InputGroup>
    </>
  );
};

export default SearchBarComponent;
