import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import './assets/js/scripts';
import initPromise from './utils/PromiseUtil';
import App from './App';
import ErrorBoundary from './components/error-boundary';

initPromise(Promise);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
