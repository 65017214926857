import { Schema } from 'rsuite';
import { OrganizationFormData } from './interfaces';

const { StringType } = Schema.Types;

export const model = Schema.Model({
  internalName: StringType().isRequired('This field is required'),
  superAdmin: StringType().isEmail('Invalid email*').isRequired('This field is required'),
});

export const OrganizationFormValue: OrganizationFormData = {
  internalName: '',
  superAdmin: '',
  IsChargebeeUsed: 1,
};

export const manageOrganizationColumns = [
  {
    dataKey: 'Name',
    minWidth: 220,
    headTitle: 'Candidates',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'ID',
    minWidth: 110,
    align: 'center',
    headTitle: 'ID',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'IsActive',
    minWidth: 140,
    align: 'center',
    headTitle: 'Is Active',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'InternalName',
    minWidth: 180,
    align: 'left',
    headTitle: 'Internal Name',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'Description',
    minWidth: 180,
    align: 'left',
    headTitle: 'Description',
    flexGrow: 1,
    sortable: true,
  },
];
