import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { GeneralErrorFormData } from '../../models/General';
import { OrganizationSettings, OrganizationInfo } from '../../views/portal-settings/tabs/organization-settings/Types';
import { RemoveOrganization, WebhookType } from './interfaces';
import { Organization as NewOrganization } from '../../views/onboarding/components/new-organization/interfaces';
import { OrganizationData } from '../../views/portal-settings/tabs/organization/interfaces';
import { Organization } from '../../models/Organization';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export const addOrganizationLogo = (image: FormData): Promise<string[]> => {
  return request({
    url: `${SERVER_URL}/onboarding/upload-logo`,
    method: 'POST',
    body: image,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  });
};

export const registerNewOrganization = (data: NewOrganization): Promise<NewOrganization | GeneralErrorFormData> => {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/onboarding/po-setup`,
    body: JSON.stringify({
      name: data.name,
      description: data.description,
      logo: data.logoData,
      POID: data.POID,
    }),
  });
};

export function addOrganization(internalName: string, email: string, IsChargebeeUsed: number): Promise<OrganizationData> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/onboarding/po-invitation`,
    body: JSON.stringify({
      internalName,
      email,
      IsChargebeeUsed,
    }),
  });
}

export function addWebhookOrganization(id: number, WebhookURL: string): Promise<WebhookType> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/organizations/${id}/webhook`,
    body: JSON.stringify({ WebhookURL }),
  });
}

export function getOrganizations(signal?: AbortSignal): Promise<Organization[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/organizations`);
  return request(options);
}

export function removeOrganization(id: number | string | null): Promise<RemoveOrganization> {
  return request({
    method: 'DELETE',
    url: `${SERVER_URL}/organizations/${id}`,
  });
}

export function editOrganization(id: number, internalName: string, email: string, IsChargebeeUsed: number): Promise<OrganizationData> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/organizations/${id}`,
    body: JSON.stringify({
      internalName,
      email,
      IsChargebeeUsed,
    }),
  });
}

export function getOrganizationInfo(id: number, signal?: AbortSignal): Promise<OrganizationInfo> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/organizations/${id}`);
  return request(options);
}

export function updateOrganizationInfo(data: OrganizationSettings, id: number): Promise<OrganizationInfo> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/organizations/name/${id}`,
    body: JSON.stringify(data),
  });
}
