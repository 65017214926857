import React, { useState, useEffect, useMemo, useRef } from 'react';
import TextField from '../../../../components/text-field-component';
import TextFieldPassword from '../../../../components/text-field-password';
import { Button, Checkbox, Form, Input } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { formOnboarder, newUserFormValue, prepareUserData, findFalse, texts } from './NewUserUtil';
import { useLocation, Redirect } from 'react-router-dom';
import { getUrlParams } from '../../../../utils/GeneralUtil';
import { OnBoardingUrl, OnBoardingAdminUrl, PrivacyUrl, BaaUrl, TermsUrl } from '../../../../config/UrlsConfig';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { checkUserInvitation, registerNewUser } from '../../../../api/users/UsersAPI';
import LoaderComponent from '../../../../components/loader-component';
import { NewUser, NewUserOnboarding } from './interfaces';
import { FormError } from '../../../../models/General';

const NewUserOnBoardingComponent: React.FunctionComponent<NewUserOnboarding> = props => {
  const { liftingStateUp, accountInfo, setStep, isAdmin, onCloseUserRegistration, setError, setIsLinkExpired } = props;
  const [formValue, setFormValue] = useState<NewUser>(newUserFormValue);
  const [formError, setFormError] = useState<FormError>({});
  const [emailFromParams, setEmailFromParams] = useState<string | undefined>(undefined);
  const [invitationIdOn, setInvitationIdOn] = useState<string | undefined>(undefined);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [submitOnDisabled, setSubmitOnDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAutoFillPrevented, setIsAutofillPrevented] = useState(true);
  const params = new URLSearchParams(useLocation().search);
  const formRef = useRef<FormInstance | null>(null);

  useEffect(() => {
    if (formRef.current && formValue.password && formValue.confirmPassword) {
      formRef.current.checkForField('confirmPassword');
    }
  }, [formValue.password, formValue.confirmPassword]);

  const fillForm = (): void => {
    if (accountInfo) {
      setFormValue(accountInfo);
    }
  };

  const setDataFromParams = (): void => {
    const paramsObj = getUrlParams(params?.toString());
    const { email, invitationId } = paramsObj;

    if (!email || !invitationId) {
      return;
    }

    setIsLoading(true);

    checkUserInvitation(email)
      .then(res => {
        if (res.result) {
          setEmailFromParams(email);
          setInvitationIdOn(invitationId);
          setFormValue(prepareUserData(formValue, email, invitationId));
          setRedirect(true);
          return;
        } else {
          setIsLinkExpired(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setTimeout(() => setIsAutofillPrevented(false), 3000);
    fillForm();
    setDataFromParams();
  }, []);

  const activateNewUserAccount = () => {
    if (formValue && formValue.invitationId && formValue.email) {
      return registerNewUser(prepareUserData(formValue, formValue.email, formValue.invitationId));
    }

    return new Promise((resolve, reject) => reject);
  };

  const submit = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }

    if (!formValue.isTermsAgreed) {
      setSubmitOnDisabled(true);
    } else {
      if (isAdmin) {
        liftingStateUp(formValue, emailFromParams ? emailFromParams : '', invitationIdOn ? invitationIdOn : '');
        setStep();
      } else {
        activateNewUserAccount()
          .then(() => onCloseUserRegistration(true))
          .catch(() => setError(true));
      }
    }
  };

  const isDisabled = useMemo(() => {
    const result = findFalse(
      accountInfo ? accountInfo.email : emailFromParams,
      formValue.isTermsAgreed,
      formValue.password,
      formValue.confirmPassword,
      formValue.lastName,
      formValue.firstName,
    );

    return result;
  }, [formValue, emailFromParams]);

  const buttonElement = document.getElementById('disabledButton');
  buttonElement?.addEventListener('click', () => setSubmitOnDisabled(true));

  return (
    <div className='onboarder-wrap'>
      <div className='onboarder-title'>Activate your Medmo account!</div>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className='onboarder-form'>
          {redirect && <Redirect to={isAdmin ? OnBoardingAdminUrl : OnBoardingUrl} />}
          <Form
            ref={formRef}
            onChange={formValue => {
              setFormValue(formValue);
              setSubmitOnDisabled(false);
            }}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={formOnboarder}
            className='create-account-form'
            readOnly={isAutoFillPrevented}
          >
            <TextField name='email' accepter={Input} value={formValue.email} label='Email*' disabled={true} />
            <TextField
              error={formError.firstName}
              name='firstName'
              accepter={Input}
              label='First name*'
              placeholder='First name'
              value={formValue.firstName}
            />
            <TextField
              error={formError.lastName}
              name='lastName'
              accepter={Input}
              label='Last name*'
              placeholder='Last name'
              value={formValue.lastName}
            />
            <TextFieldPassword
              error={formError.password}
              name='password'
              label='Create a password*'
              placeholder='Create a password'
              rules='true'
              value={formValue.password}
            />
            <TextFieldPassword
              error={formError.confirmPassword}
              name='confirmPassword'
              label='Confirm password*'
              placeholder='Confirm a password'
              value={formValue.confirmPassword}
              withoutFocus
            />
            <div className='checkbox-wrapper'>
              <Checkbox
                id='uniqueID'
                onChange={() => {
                  setFormValue(prevState => ({
                    ...formValue,
                    isTermsAgreed: !prevState.isTermsAgreed,
                  }));
                  setSubmitOnDisabled(false);
                }}
                checked={formValue.isTermsAgreed}
              ></Checkbox>
              <label htmlFor='uniqueID'>
                I confirm that I have read and agree to Medmo’s{' '}
                <a href={PrivacyUrl} className='terms-link' rel='noreferrer' target='_blank'>
                  Privacy Policy
                </a>
                ,{' '}
                <a href={TermsUrl} className='terms-link' rel='noreferrer' target='_blank'>
                  Terms of Use
                </a>
                , and{' '}
                <a href={BaaUrl} className='terms-link' rel='noreferrer' target='_blank'>
                  BAA
                </a>
                .
              </label>
            </div>
            <div className='button-wrapper'>
              <span id='disabledButton' className={!isDisabled ? 'hide' : ''}></span>
              <Button disabled={isDisabled} type='submit' classPrefix='btn-model' onClick={submit}>
                {isAdmin ? 'Next' : 'Activate Account'}
              </Button>
            </div>
          </Form>
          {submitOnDisabled && <ErrorMessageComponent errorMessage={texts.submitOnDisabledButton} />}
        </div>
      )}
    </div>
  );
};

export default NewUserOnBoardingComponent;
