import React, { useEffect, useRef, useState } from 'react';
import saveFile from 'file-saver';
import { FileView, PDFFile, ViewportContentProps } from './interfaces';
import PDFViewer from './PDFViewer';
import ImageViewer from './ImageViewer';
import { isPDF, calculateFitScale } from './FileViewerUtils';
import PreviewBar from './PreviewBar';

const SCALE_DELTA = 0.25;

const ViewportContent: React.FC<ViewportContentProps> = ({ file, scale, containerRef, numPages, onLoadSuccess }) => (
  <div ref={containerRef} className={isPDF(file) ? 'preview-content' : 'react-doc-viewer'}>
    <div className='preview-file'>
      {isPDF(file) ? (
        <PDFViewer file={file} scale={scale} numPages={numPages} onLoadSuccess={onLoadSuccess} />
      ) : (
        <ImageViewer file={file} />
      )}
    </div>
  </div>
);

const FilePreviewer: React.FC<{ file: FileView }> = ({ file: initFile }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const [file, setFile] = useState<FileView>(initFile);

  const viewportRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFile(initFile);
  }, [initFile]);

  const onDownload = (): void => {
    const url = file.url || `data:${file.mimeType};base64,${file.data}`;
    return saveFile(url, file.name || file.fileTitle || file.originalName);
  };

  const onZoomIn = () => {
    setScale(scale + SCALE_DELTA);
  };

  const onZoomOut = () => {
    setScale(scale - SCALE_DELTA);
  };

  const onDocumentLoadSuccess = (file: PDFFile) => {
    file
      .getPage(1)
      .then(page => {
        const pageViewport = page.getViewport({ scale: 1 });
        if (viewportRef.current && pageViewport) {
          setScale(calculateFitScale(viewportRef.current, pageViewport));
        }
      })
      .finally(() => {
        setNumPages(file.numPages);
      });
  };

  if (!file.url && !file.data) {
    return <></>;
  }

  return (
    <div ref={viewportRef} className='preview-wrapper'>
      <PreviewBar onZoomIn={onZoomIn} onZoomOut={onZoomOut} onDownload={onDownload} />

      <ViewportContent file={file} scale={scale} containerRef={containerRef} numPages={numPages} onLoadSuccess={onDocumentLoadSuccess} />
    </div>
  );
};

export default FilePreviewer;
