import React, { useEffect, useRef, useState } from 'react';
import { ModalApiTokenProps } from './interfaces';
import { Button, Form, Modal } from 'rsuite';
import { revokeAPiToken } from '../../../../api/general/GeneralAPI';
import { DefaultApiTokenFormValue, TokenSchema } from '../general/GeneralUtil';
import TextField from '../../../../components/text-field-component';
import { FormInstance } from 'rsuite/Form';

const ApiTokenModal: React.FunctionComponent<ModalApiTokenProps> = props => {
  const { onClose, isRevoke, data, organizationID } = props;
  const [isCopiedTextShown, setIsCopiedTextShown] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<{ Token: string }>(data || DefaultApiTokenFormValue);
  const form = useRef<FormInstance | null>(null);

  useEffect(() => {
    data && setFormValue({ Token: data.Token });
  }, [data]);

  const handleRevoke = () => {
    data && revokeAPiToken(organizationID, data.ID).then(() => onClose(true));
  };
  const handleCopy = () => {
    if (data) {
      navigator.clipboard.writeText(data.Token);
      setIsCopiedTextShown(true);
    }
  };

  return (
    <div>
      <Modal size={'sm'} className='organization-edit-modal organization-edit-modal_api' open>
        <Modal.Header>
          <Modal.Title>{isRevoke ? 'Revoke API token' : 'Your new API token'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isRevoke ? (
            <p>Are you sure you want to permanently revoke your API token? Revoking an API token is not reversible.</p>
          ) : (
            <div>
              <p>Make sure you copy your new API token. You won`t be able to see this token again.</p>
              <Form ref={form} formValue={formValue} model={TokenSchema} className='form-edit-data'>
                <TextField label='' value={formValue.Token} name='Token' />
              </Form>
              {isCopiedTextShown ? (
                <div className='hint-copied'>
                  <span className='successfully-icon'></span>API token copied to clipboard
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close' onClick={() => onClose()}>
              {isRevoke ? 'Cancel' : 'Close'}
            </Button>
            {isRevoke ? (
              <Button classPrefix='btn-model' onClick={handleRevoke}>
                Revoke
              </Button>
            ) : (
              <Button classPrefix='btn-model' onClick={handleCopy}>
                Copy
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ApiTokenModal;
