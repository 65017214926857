import React from 'react';

const addBoldText = (arr: [string, string], boldText: JSX.Element): (string | JSX.Element)[] | string => {
  const firstEl = arr[0].trim();
  const secondEl = arr[1].trim();
  const text = boldText.props.children;
  switch (true) {
    case arr[0] === '' && arr[1] === '':
      return [boldText];
    case arr[0] === '':
      return [boldText, arr[1].startsWith(' ') || text.endsWith(' ') ? <>&nbsp;</> : '', secondEl];
    case arr[1] === '':
      return [firstEl, arr[0].endsWith(' ') || text.startsWith(' ') ? <>&nbsp;</> : '', boldText];
    default:
      return [firstEl, arr[0].endsWith(' ') ? <>&nbsp;</> : '', boldText, arr[1].startsWith(' ') ? <>&nbsp;</> : '', secondEl];
  }
};

export const highlightText = (value: string, searchQuery: string): (string | JSX.Element)[] | string => {
  const regexp = new RegExp(searchQuery.trim(), 'i');
  const matcher = value.match(regexp);
  if (matcher) {
    const boldText = matcher[0];
    const res = value.replace(regexp, '!*!').split('!*!');
    const arrWithPlaceForBoldText: [string, string] = [res[0], res[1]];
    return addBoldText(arrWithPlaceForBoldText, <b>{boldText}</b>);
  }
  return value;
};
