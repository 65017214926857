import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFViewerProps } from './interfaces';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

// Get the pdf.js worker from cloudflare content delivery network.
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewer: React.FC<PDFViewerProps> = ({ file, scale, numPages, onLoadSuccess }) => {
  const onLoadError = (error: any) => console.error(error);

  return (
    <Document onLoadSuccess={onLoadSuccess} onLoadError={onLoadError} file={file.url || `data:${file.mimeType};base64,${file.data}`}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
      ))}
    </Document>
  );
};

export default PDFViewer;
