import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { RequestFiles } from '../all-scans/interfaces';

export function uploadNotesFile(data: FormData): Promise<RequestFiles[]> {
  return request({
    url: `${SERVER_URL}/scan-request-notes/upload-note`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  });
}
