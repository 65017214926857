import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { Location } from '../../models/Location';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export function addLocation(data: Location): Promise<{ ID: number }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/locations`,
    body: JSON.stringify(data),
  });
}

export function editLocation(data: Location, id: number): Promise<{ ID: number }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/locations/${id}`,
    body: JSON.stringify(data),
  });
}

export function getLocations(signal?: AbortSignal): Promise<Location[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/locations`);
  return request(options);
}

export function deleteLocation(id: number): Promise<{ ID: number }> {
  return request({
    method: 'DELETE',
    url: `${SERVER_URL}/locations/${id}`,
  });
}
