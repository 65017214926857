const currencyTapMap: Record<string, string> = {
  USD: '$',
};

export const CustomerInitData = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  card_status: 'no_card',
};

export enum ModalType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const modalTexts: Record<string, string> = {
  success: 'Thank you for your message. Our team will get back to you shortly.',
  error: 'Please provide a message.',
};

export const planText: Record<string, string> = {
  active: 'You’re currently under the Unlimited Plan',
  cancelled: 'You Unlimited Plan is Inactive',
};

export const formatCurrency = (type: string, amount: number): string => {
  const amountArr = String(amount).split('');
  amountArr.splice(-2, 0, '.');
  return `${currencyTapMap[type]}${amountArr.join('')}`;
};
