import { FileType } from 'rsuite/Uploader';

let resolveLoadFn: ((value: string | PromiseLike<string>) => void) | undefined = undefined;
// let resolveRenameFn: ((value: File | null | PromiseLike<File | null>) => void) | undefined = undefined;

export const onLoadFile = async (item: FileType): Promise<any> => {
  const fileReader = new window.FileReader();

  fileReader.onload = fileLoad => {
    const { result } = fileLoad.target as FileReader;

    if (!resolveLoadFn) {
      return;
    }

    resolveLoadFn(result as string);
    resolveLoadFn = undefined;
  };

  fileReader.readAsDataURL(item.blobFile as Blob);

  return new Promise(res => {
    resolveLoadFn = res;
  });
};

export const renameFile = async (blobFile: File, fileName?: string): Promise<any> => {
  if (!/[^\w.]/.test(blobFile?.name) && !fileName) {
    return blobFile;
  }

  const arrayBuffer = await blobFile.arrayBuffer();
  const newFile = new File([arrayBuffer], fileName ? fileName : blobFile?.name.replace(/[^\w.]/g, ''), {
    type: blobFile.type,
    lastModified: blobFile.lastModified,
  });

  return newFile;
};
