import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, RadioGroup, Checkbox, Nav } from 'rsuite';
import TextField from '../../../components/text-field-component';
import InputMask from '../../../components/mask-field';
import { TASK_TABS_NAME, TaskStatuses } from '../TasksUtil';
import DetailsTaskTabComponent from './DetailsTaskTabComponent';
import FileListPreviewer from '../../../components/file-viewer/FileListPreviewerComponent';
import { ReportReviewDataType, RRTaskResponse, TaskProps } from '../interfaces';
import { completeReportReview } from '../../../api/tasks/TasksApi';
import { FormError } from '../../../models/General';
import { ReportReviewDefaultValue } from '../task/TaskUtil';
import InfoModalComponent from '../../../components/modals/info-modal';
import { Texts } from '../../../components/modals/interfaces';
import { FormInstance } from 'rsuite/Form';
import { UserContext, UserContextType } from '../../../contexts/UserContext';
import { ReportReviewSchema } from '../Schemas';
import moment from 'moment';
import { ReportReviewData } from '../../../api/tasks/interfaces';
import StaticReportsTaskComponent from './StaticReportsTaskComponent';
import { checkForEmptyString } from '../../../utils/GeneralUtil';
import Textarea from '../../../components/textarea';
import { useSignal } from '../../../utils/UseSignal';

const ReportReviewTaskComponent: React.FC<TaskProps> = ({ taskData, liftGoBackTextUp, getAllTaskInfo }) => {
  const { special, task, testRequest, insurance, reports, Patient } = taskData;
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [formValue, setFormValue] = useState<ReportReviewDataType>(ReportReviewDefaultValue);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setErrorText] = useState<Texts | null>(null);
  const formRef = useRef<FormInstance | null>(null);
  const currentUser = useContext<UserContextType>(UserContext);
  const { reviewedBy, reviewedOn } = formValue;
  const signal = useSignal();

  const isSubmitEnabled = useMemo(() => {
    return isChecked && !!reviewedBy && !!reviewedOn;
  }, [reviewedBy, reviewedOn]);

  useEffect(() => {
    setActiveNav(TASK_TABS_NAME.Details);
    liftGoBackTextUp(`Report review of Scan Request #${testRequest.ScanID}`);
    setIsChecked(!!(special as RRTaskResponse).IsReviewed);
    setFormValue({
      ...formValue,
      reviewedBy: checkForEmptyString((special as RRTaskResponse).Reviewer),
      reviewedOn: checkForEmptyString((special as RRTaskResponse).ReviewedOn),
      reviewNotes: checkForEmptyString((special as RRTaskResponse).ReviewNotes),
      taskNotes: checkForEmptyString(task.TaskNotes),
    });
  }, [testRequest, task]);

  const handleCheck = () =>
    setIsChecked(prev => {
      if (prev) {
        setFormValue({ ...formValue, reviewedBy: '', reviewedOn: '' });
      } else {
        setFormValue({
          ...formValue,
          reviewedBy: currentUser.FirstName + ' ' + currentUser.LastName,
          reviewedOn: moment().format('MM/DD/YYYY'),
        });
      }
      return !prev;
    });

  const handleClickSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    const preparedData: ReportReviewData = {
      currTaskID: task.ID,
      newIsReviewed: Number(isChecked),
      newReviewNotes: formValue.reviewNotes,
      newReviewerName: reviewedBy,
      newReviewedOn: new Date(reviewedOn),
      newTaskNotes: formValue.taskNotes,
    };

    completeReportReview(preparedData)
      .then(() => {
        if (!signal.aborted) {
          getAllTaskInfo(true);
        }
      })
      .catch(e => {
        if (!signal.aborted) {
          setErrorText({
            title: 'Complete error',
            content: e.message,
          });
        }
      });
  };

  const refreshTaskDetails = () => {
    getAllTaskInfo(false);
  };

  return (
    <>
      {task.TaskStatus === TaskStatuses.Open ? (
        <>
          <div className='task-content_form'>
            <Form
              className='task-form_report'
              ref={formRef}
              formValue={formValue}
              onCheck={formError => setFormError(formError)}
              onChange={formValue => setFormValue(formValue as ReportReviewDataType)}
              model={ReportReviewSchema}
            >
              <Form.Group className='col-12'>
                <Form.Control name='reviewStatus' accepter={RadioGroup}>
                  <Form.ControlLabel className='title'>Reviewed status:</Form.ControlLabel>
                  <div className='checkbox-wrapper'>
                    <Checkbox onChange={handleCheck}>This report has been reviewed</Checkbox>
                  </div>
                </Form.Control>
              </Form.Group>
              <div className={`content ${!isChecked && 'disabled'}`}>
                <div className='row row-wrap'>
                  <Form.Group className='form-new-scan-group'>
                    <TextField
                      name='reviewedBy'
                      error={formError.reviewedBy}
                      appearance='subtle'
                      searchable={false}
                      label='Reviewed by *:'
                      labelClassName='task-label'
                      value={reviewedBy}
                    />
                  </Form.Group>
                  <Form.Group className='form-new-scan-group'>
                    <TextField
                      accepter={InputMask}
                      className='birthdate'
                      name='reviewedOn'
                      autoComplete='off'
                      mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      format='MM/DD/YYYY'
                      label='Reviewed on *:'
                      labelClassName='task-label'
                      error={formError.reviewedOn}
                      value={reviewedOn}
                    />
                  </Form.Group>
                </div>
                <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                  <TextField
                    name='reviewNotes'
                    accepter={Textarea}
                    appearance='subtle'
                    searchable={false}
                    label='Review notes:'
                    labelClassName='task-label'
                  />
                </Form.Group>
                <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                  <TextField
                    name='taskNotes'
                    accepter={Textarea}
                    appearance='subtle'
                    searchable={false}
                    label='Task notes:'
                    labelClassName='task-label'
                  />
                </Form.Group>
                <Button className='rs-btn-model btn-lg' onClick={handleClickSubmit} disabled={!isSubmitEnabled}>
                  Mark as Completed
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <StaticReportsTaskComponent taskNotes={task.TaskNotes || ''} data={special as RRTaskResponse} />
      )}
      <div className='task-details'>
        <div className='task-details_tabs'>
          <div className='tabs-panel'>
            <Nav appearance='tabs' activeKey={activeNav} onSelect={setActiveNav}>
              <Nav.Item eventKey={TASK_TABS_NAME.Details}>Details</Nav.Item>
              <Nav.Item eventKey={TASK_TABS_NAME.UploadedReports}>Uploaded reports</Nav.Item>
            </Nav>
          </div>
          <div className='task-details_tabs_content'>
            {activeNav === TASK_TABS_NAME.Details && (
              <DetailsTaskTabComponent data={{ special, task, testRequest, insurance, Patient }} taskDetailsChanged={refreshTaskDetails} />
            )}
            {activeNav === TASK_TABS_NAME.UploadedReports && <FileListPreviewer filesList={reports} />}
          </div>
        </div>
      </div>
      {errorText && <InfoModalComponent onClose={() => setErrorText(null)} type='error' texts={errorText} />}
    </>
  );
};

export default ReportReviewTaskComponent;
