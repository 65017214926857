import React, { useState, useMemo, useRef, useEffect } from 'react';
import { transformDate } from '../../../../../utils/TimeUtil';
import { useParams } from 'react-router';
import { Button } from 'rsuite';
import { GrEdit } from 'react-icons/gr';
import { getScanDetails, requestNotes, requestPrescription } from '../../../../../api/all-scans/AllScansAPI';
import EditScanDetailsModal from './Edit';
import { texts, scanCoverageInfo, Details, DetailsDefaultValue } from './ScanDetailsUtil';
import FileViewer from '../../../../../components/doc-viewer-modal';
import LoaderComponent from '../../../../../components/loader-component';
import { useError } from '../../../../../utils/UseError';
import ErrorMessageComponent from '../../../../../components/error-message-component';
import { FileView } from '../../../../../components/file-viewer/interfaces';
import { useSignal } from '../../../../../utils/UseSignal';
import { RequestFiles } from '../../../../../api/all-scans/interfaces';
import { FileTypeWithTitle } from '../../../../new-scan/interfaces';
import { NavLink } from 'react-router-dom';
import { Props } from './interfaces';

const ScanDetailsComponent: React.FunctionComponent<Props> = ({ scanResult }) => {
  const [showEditScanDetailsModal, setShowEditScanDetailsModal] = useState<boolean>(false);
  const [scanDetails, setScanDetails] = useState<Details>(DetailsDefaultValue);
  const [prescription, setPrescription] = useState<RequestFiles[]>([]);
  const [notesArr, setNotesArr] = useState<FileTypeWithTitle[]>([]);
  const { scanId } = useParams<{ scanId: string }>();
  const [isShowType, setIsShowType] = useState<string | null>(null);
  const [file, setFile] = useState<FileView>({ url: '', name: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useError(null);
  const signal = useSignal();
  const activeId = useRef<number>(0);

  const getDetails = () => {
    getScanDetails(scanId, signal)
      .then(res => {
        setScanDetails(res);
      })
      .catch(e => {
        if (!signal.aborted) {
          setErrorText(e);
          setScanDetails(DetailsDefaultValue);
        }
      });
  };

  const getPrescription = (id: number) => {
    requestPrescription(id, signal)
      .then(res => setPrescription(res))
      .catch(e => {
        setErrorText(e);
        setNotesArr([]);
      });
  };

  const getNotes = (id: number): void => {
    requestNotes(id, signal)
      .then(res => {
        const arr: FileTypeWithTitle[] = [];

        res.forEach((el: RequestFiles, i: number) => {
          arr.push({
            ...el,
            id: i,
          });
        });

        setNotesArr(arr);
      })
      .catch(e => {
        if (!signal.aborted) {
          setErrorText(e);
          setNotesArr([]);
        }
      });
  };

  const getNeccessaryData = (): void => {
    getScanDetails(scanId, signal)
      .then(res => {
        setScanDetails(res);
      })
      .then(() => getPrescription(Number(scanId)))
      .then(() => getNotes(Number(scanId)))
      .catch(e => {
        if (!signal.aborted) {
          setErrorText(e);
          setScanDetails(DetailsDefaultValue);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getNeccessaryData();
  }, []);

  const getScanData = () => {
    getNotes(Number(scanId));
    getDetails();
  };

  const onCloseEditScanDetailsModal = () => {
    setShowEditScanDetailsModal(false);
  };

  const openEditModal = () => {
    setShowEditScanDetailsModal(true);
  };

  const officeFullAddress = useMemo(() => {
    if (scanDetails && scanDetails.Location) {
      const { Address1, Address2, State, ZipCode, City } = scanDetails.Location;
      const address = Address2 ? `${Address1} ${Address2}` : Address1;

      return `${address} ${City}, ${State} ${ZipCode}`;
    }

    return 'Some part of address is not provided';
  }, [scanDetails]);

  const scanCoverageText = useMemo(
    () => scanCoverageInfo(scanDetails.PatientPreferences.TestRequest.SelfPay),
    [scanDetails.PatientPreferences.TestRequest.SelfPay],
  );

  const patientAvailability = useMemo((): string | null => {
    const { IsAnyTime } = scanDetails.PatientPreferences.TestRequest;

    switch (IsAnyTime) {
      case 1:
        return texts.flexible;

      case 0:
        return texts.custom;

      case null:
        return texts.wait;

      default:
        return null;
    }
  }, [scanDetails.PatientPreferences.TestRequest.IsAnyTime]);

  const zipCodeValue = useMemo((): string | null => {
    const { ZipCode } = scanDetails.PatientPreferences.TestRequest;

    if (typeof ZipCode === 'string') {
      return ZipCode === 'null' ? texts.wait : ZipCode;
    }

    if (ZipCode === null) {
      return texts.wait;
    }

    return null;
  }, [scanDetails.PatientPreferences.TestRequest.ZipCode]);

  const downloadNotesFile = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null, id?: number, isDownload?: boolean) => {
    e && e.preventDefault();

    if (id || id === 0) {
      activeId.current = id;
    }

    if (notesArr.length && id !== undefined) {
      if (isDownload) {
        window.open(notesArr[id].url, '_blank');

        return;
      }

      setFile({
        url: notesArr[id].url,
        name: notesArr[id].key,
      });
      setIsShowType('notes');
    }
  };

  const downloadEpxFile = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null, isDownload?: boolean): void => {
    e && e.preventDefault();

    if (prescription.length) {
      if (isDownload) {
        window.open(prescription[0].url, '_blank');

        return;
      }

      setFile({
        url: prescription[0].url,
        name: prescription[0].key,
      });
      setIsShowType('epx');
    }
  };

  const onCloseFileViewer = (): void => {
    setIsShowType(null);
  };

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : errorText ? (
        <ErrorMessageComponent errorMessage={errorText} />
      ) : (
        <>
          <div className='page-content-wrap page-scan-tab page-scan-details'>
            <div className='page-scan-tab_title'>Scan Request Details</div>
            {scanDetails && !Object.keys(scanDetails).length && (
              <div className='page-scan-tab_nodata text-center'>
                This scan request has not be scheduled yet. Scheduling information will appear once the scan has been assigned to a center.
                If you have any questions or concerns please contact Medmo Support
              </div>
            )}
            {scanDetails && Object.keys(scanDetails).length ? (
              <div className='page-content-data'>
                <div className='page-content-data_item'>
                  <div className='h2'>Patient Information</div>
                  <ul>
                    <li>
                      <b>Patient name:&ensp;</b>
                      <NavLink className='' to={{ pathname: `/patients/${scanResult?.PatientID}`, state: { pathname: location.pathname } }}>
                        {scanDetails.Patient.PatientName}
                      </NavLink>
                    </li>
                    <li className='mr4'>
                      <b>Date of birth:&ensp;</b>
                      {scanDetails.Patient.DOB && transformDate(scanDetails.Patient.DOB)}
                    </li>
                    <li>
                      <b>Email address:&ensp;</b>
                      {scanDetails.Patient.Email || 'No Email Provided'}
                    </li>
                    <li>
                      <b>Phone number:&ensp;</b>
                      {scanDetails.Patient.Phone}
                    </li>
                    <li>
                      <b>Sex:&ensp;</b>
                      {scanDetails.Patient.Gender}
                    </li>
                  </ul>
                </div>
                <div className='page-content-data_item'>
                  <div className='h2'>Scan Information</div>
                  <ul>
                    <li>
                      <b>Scan type:&ensp;</b>
                      {scanDetails.ScanInfo.ScanService.FriendlyName}
                    </li>
                    <li className='mr4'>
                      <b>CPT code:&ensp;</b>
                      {scanDetails.ScanInfo.ScanService.CPTCode}
                    </li>
                    <li>
                      <b>Ordering physician:&ensp;</b>
                      {scanDetails.Prescriber.FirstName + ' ' + scanDetails.Prescriber.LastName}
                    </li>
                    <li>
                      <b>Office address:&ensp;</b>
                      {officeFullAddress}
                    </li>
                    <li>
                      <b>Fax number:&ensp;</b>
                      {scanDetails.Location.Fax || scanDetails.Location.DefaultFax}
                    </li>
                  </ul>
                  <div className='scan-popup-confirmation-content'>
                    <span className='scan-popup-confirmation-label'>
                      <b>Reason for exam:</b>
                    </span>
                    <div className='scan-popup-confirmation-area'>
                      <p>{scanDetails.ScanInfo.Reason}</p>
                    </div>
                  </div>
                  <div className='h2'>
                    <p>
                      <b>Notes</b> (You can upload clinical notes by clicking on the icon and uploading files from your computer)
                    </p>
                    <Button classPrefix='btn-edit' onClick={openEditModal}>
                      <GrEdit /> edit notes
                    </Button>
                  </div>
                  <div className='scan-popup-confirmation-content'>
                    <span className='scan-popup-confirmation-label'>
                      <b>Clinical notes:</b>
                    </span>
                    <div className='scan-popup-confirmation-area'>
                      <p>{scanDetails.ClinicalNotes}</p>
                    </div>
                  </div>
                  <div hidden={!notesArr.length} className='scan-popup-confirmation-prescription'>
                    <ol>
                      {notesArr &&
                        notesArr.length &&
                        notesArr.map((el, i) => {
                          return (
                            <li key={(el.key || '') + i} className='scan-popup-confirmation-uploader-content'>
                              <a href='#' onClick={e => downloadNotesFile(e, i)}>
                                {el.fileTitle || `Clinical note ${i + 1}`}
                              </a>
                            </li>
                          );
                        })}
                    </ol>
                  </div>
                </div>
                <div className='page-content-data_item'>
                  <div className='h2'>Prescription</div>
                  <div className='scan-popup-confirmation-prescription'>
                    <a href='#' onClick={e => downloadEpxFile(e)}>
                      {prescription?.[0]?.fileTitle}
                    </a>
                  </div>
                </div>
                <div className='page-content-data_item'>
                  <div className='h2'>Patient Preferences</div>
                  <ul
                    style={{
                      opacity:
                        !scanDetails.PatientPreferences.TestRequest.DateRequested || !scanDetails.PatientPreferences.PatientInsuranceID
                          ? '.3'
                          : '1',
                    }}
                  >
                    <li>
                      <b>Zip code:&ensp;</b>
                      {zipCodeValue}
                    </li>
                    <li>
                      <b>Patient availability:&ensp;</b>
                      {patientAvailability}
                    </li>
                    <li>
                      <b>Scan coverage:&ensp;</b>
                      {scanCoverageText}
                    </li>
                    <li hidden={scanDetails.PatientPreferences.TestRequest.SelfPay}>
                      <b>Insurance Provider:&ensp;</b>
                      {scanDetails.PatientPreferences.TestRequest.PolicyName
                        ? scanDetails.PatientPreferences.TestRequest.PolicyName
                        : texts.wait}
                    </li>
                    <li hidden={scanDetails.PatientPreferences.TestRequest.SelfPay}>
                      <b>Insurance Member ID:&ensp;</b>
                      {scanDetails.PatientPreferences.TestRequest.PolicyNumber
                        ? scanDetails.PatientPreferences.TestRequest.PolicyNumber
                        : texts.wait}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className='page-content-data'>&nbsp;</div>
            )}
            {showEditScanDetailsModal && (
              <EditScanDetailsModal
                scanId={Number(scanId)}
                notes={{
                  list: notesArr,
                  clinical: scanDetails.ClinicalNotes,
                  logistic: scanDetails.OtherNotes,
                  option: scanDetails.ClinicalNotesFile,
                }}
                onClose={() => onCloseEditScanDetailsModal()}
                getScanData={getScanData}
              />
            )}
          </div>
          <FileViewer
            isShow={!!isShowType}
            file={file}
            onDownloadFile={() => (isShowType === 'notes' ? downloadNotesFile(null, activeId.current, true) : downloadEpxFile(null, true))}
            onClose={() => onCloseFileViewer()}
          />
        </>
      )}
    </>
  );
};

export default ScanDetailsComponent;
