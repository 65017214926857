export const SettingsOrganizationTexts = {
  successAddLocation: {
    title: 'Organization Successfully Added',
    content: 'Organization successfully added',
  },
  successEditLocation: {
    title: 'Organization Successfully Updated',
    content: 'Organization updated',
  },
  successRemoveLocation: {
    title: 'Organization Successfully Removed',
    content: 'Organization successfully removed',
  },
};
