import React from 'react';
import { StaticReportTaskComponentProps } from '../interfaces';
import { transformToLocalDate } from '../../../utils/TimeUtil';

const StaticReportsTaskComponent: React.FC<StaticReportTaskComponentProps> = ({ data, taskNotes }) => {
  return (
    <div className='content-data'>
      <div className='content-data-filed'>
        <div className='label'>Reviewed by:</div>
        <div className='content-data-filed_content'>
          <p>{data.Reviewer}</p>
        </div>
      </div>
      <div className='content-data-filed'>
        <div className='label'>Reviewed on:</div>
        <div className='content-data-filed_content'>
          <p>{data.ReviewedOn && transformToLocalDate(data.ReviewedOn)}</p>
        </div>
      </div>
      <div className='content-data-filed'>
        <div className='label'>Review notes:</div>
        <div className='content-data-filed_content'>
          <p>{data.ReviewNotes}</p>
        </div>
      </div>
      <div className='content-data-filed'>
        <div className='label'>Task notes:</div>
        <div className='content-data-filed_content'>
          <p>{taskNotes}</p>
        </div>
      </div>
    </div>
  );
};

export default StaticReportsTaskComponent;
