// import * as R from 'ramda';
import { FileView } from './interfaces';

const PADDING = 12 * 2;

export const isPDF = (file: FileView): boolean => {
  if (
    file.mimeType === 'application/pdf' ||
    file.url?.includes('.pdf') ||
    file.name?.includes('.pdf') ||
    file.originalName?.includes('.pdf')
  ) {
    return true;
  }

  return false;
};

export const calculateFitScale = (viewportElem: HTMLDivElement, contentElem: { width: number; height: number }): number => {
  // Get the viewport ratio.
  const viewportWidth = viewportElem.offsetWidth;

  // Get the content ratio.
  const contentWidth = contentElem.width;

  // Get the scaling ratio, after removing the padding.
  return (viewportWidth - PADDING) / contentWidth - 0.15;
};
