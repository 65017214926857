import React from 'react';
import { Button, Tooltip, Whisper } from 'rsuite';
import { StateProps } from './interfaces';

const ScanRequestsStatus: React.FunctionComponent<StateProps> = props => {
  const { type, tooltipText, placement, iconColor } = props;
  return (
    <Button classPrefix={`btn-state ${type.toLowerCase()}` + `${tooltipText ? ' is-tooltip' : ''}`} appearance='subtle'>
      <span>{type}</span>
      {tooltipText && (
        <Whisper placement={placement || 'top'} controlId='control-id-hover' trigger='hover' speaker={<Tooltip>{tooltipText}</Tooltip>}>
          <span className={`tooltip${iconColor ? ` ${iconColor}` : ''}`}></span>
        </Whisper>
      )}
    </Button>
  );
};
export default ScanRequestsStatus;
