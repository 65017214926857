import CloseIcon from '@rsuite/icons/Close';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextField from '../text-field-component';
import Textarea from '../textarea';
import { Button, ButtonToolbar, Modal, Form } from 'rsuite';
import { TaskFormValue } from '../../views/tasks/TasksUtil';
import { TaskNoteSchema } from '../../views/tasks/Schemas';
import { FormInstance } from 'rsuite/Form';
import { saveTaskNote } from '../../api/tasks-notes/TasksApi';
import { TaskNote } from '../../models/TaskNote';
import { TaskNoteModal } from '../../views/tasks/interfaces';
import { FormError } from '../../models/General';
import { useSignal } from '../../utils/UseSignal';
import { useError } from '../../utils/UseError';

const SaveTaskNotesModalComponent: React.FunctionComponent<TaskNoteModal> = props => {
  const { onClose, taskNote, isShow } = props;
  const [formValue, setFormValue] = useState<TaskNote>(TaskFormValue);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setError] = useError('');
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const form = useRef<FormInstance | null>(null);
  const signal = useSignal();

  useEffect(() => {
    if (!taskNote) {
      return;
    }

    const data = { ...taskNote };
    setFormValue(data);
  }, [taskNote]);

  const handleSubmit = () => {
    if (form.current && !form.current.check()) {
      return;
    }

    setIsSubmitPressed(true);

    const data = { ...formValue };

    saveTaskNote(Number(data.ID), { MFPTaskNotes: data.MFPTaskNotes || null })
      .then(res => onClose(true, { ID: res.ID, MFPTaskNotes: res.MFPTaskNotes }))
      .catch(err => setError(err, formValue))
      .finally(() => {
        if (!signal.aborted) {
          setIsSubmitPressed(false);
        }
      });
  };

  const handleChange = (formValue: TaskNote) => {
    errorText && setError(null);
    setIsSubmitPressed(false);
    setFormValue(formValue);
  };

  const handleClickCancel = () => {
    if (!isSubmitPressed) {
      setFormError({});
      onClose(false);
      return;
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if ((formValue.MFPTaskNotes?.length ?? 0) > 100) {
      return true;
    }

    return formValue.MFPTaskNotes === taskNote.MFPTaskNotes;
  }, [formValue]);

  return (
    <div>
      <Modal size={'sm'} className={'task-notes-modal'} overflow={false} backdrop={'static'} open={isShow} onClose={handleClickCancel}>
        <Modal.Header>
          <Modal.Title>Task Notes</Modal.Title>
          <button className='btn-close' onClick={() => handleClickCancel()}>
            <CloseIcon className='btn-icon' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={form}
            onChange={formValue => handleChange(formValue as TaskNote)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={TaskNoteSchema}
          >
            <div className='notes-container'>
              <TextField
                name='MFPTaskNotes'
                accepter={Textarea}
                autoComplete='off'
                disabled={isSubmitPressed}
                error={formError.MFPTaskNotes}
                value={formValue.MFPTaskNotes}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
              <span className='task-notes-legend'>Add any notes for yourself or your teammates</span>
              <span className={(formValue.MFPTaskNotes?.length ?? 0) > 100 ? 'task-notes-length-error' : ''}>
                {formValue.MFPTaskNotes?.length}/100
              </span>
            </div>
          </Form>
          {errorText && <p className='block-error-text'>{errorText}</p>}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button classPrefix='btn-model btn-cancel' className='btn-lg' disabled={isSubmitPressed} onClick={handleClickCancel}>
              Cancel
            </Button>
            <Button
              classPrefix='btn-model btn-save'
              className='btn-lg'
              disabled={isSubmitPressed || isSubmitDisabled}
              onClick={handleSubmit}
            >
              Save task notes
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SaveTaskNotesModalComponent;
