import Close from '@rsuite/icons/Close';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Button } from 'rsuite';
import { checkCustomerExist, sendMessage } from '../../../../api/billing/BillingAPI';
import LoaderComponent from '../../../../components/loader-component';
import { UserContext, UserContextType } from '../../../../contexts/UserContext';
import { useSignal } from '../../../../utils/UseSignal';
import { CustomerInitData, modalTexts, ModalType } from './BillingUtil';
import BillingDashboardComponent from './components/BillingDashboard';
import BillingPlanAndTrialInfoComponent from './components/BillingPlanAndTrialInfo';
import { CardResponseData, CustomerResponseData } from './interfaces';

const ChargebeeBillingTabComponent: React.FunctionComponent = () => {
  const [customerData, setCustomerData] = useState<CustomerResponseData>(CustomerInitData);
  const [cardData, setCardData] = useState<CardResponseData>({});
  const [subscriptionData, setSubscriptionData] = useState<{ status: string }>({ status: '' });
  const [createNew, setCreateNew] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalTextType, setModalTextType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { updateUser } = useContext<UserContextType>(UserContext);
  const signal = useSignal();

  const getCustomer = (fn?: () => void) => {
    setIsLoading(true);
    checkCustomerExist(signal)
      .then(({ customer, card, subscriptions }) => {
        if (!signal.aborted && customer) {
          setCustomerData(customer);
          setCreateNew(customer.card_status === 'no_card');

          if (subscriptions?.length) {
            setSubscriptionData(subscriptions[0].subscription);
          }

          if (card) {
            setCardData(card);

            if (fn) {
              fn();
            }
          }
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          setCustomerData(CustomerInitData);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const unlockCustomer = () => {
    getCustomer(() => {
      updateUser();
    });
  };

  const submit = (e: SyntheticEvent) => {
    e && e.preventDefault();
    e.stopPropagation();
    if (!message) {
      setModalTextType(ModalType.ERROR);
      return;
    }
    sendMessage(message)
      .then(() => {
        if (!signal.aborted) {
          setModalTextType(ModalType.SUCCESS);
        }
      })
      .catch(e => {
        if (!signal.aborted) {
          console.error(e);
        }
      });
  };

  const closeDialog = (e: SyntheticEvent) => {
    e && e.preventDefault();
    setMessage('');
    setModalTextType('');
  };

  if (isLoading || !customerData.id) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='chargebee'>
        <div className='row'>
          <section className='chargebee-content'>
            {createNew ? (
              <BillingPlanAndTrialInfoComponent unlockCustomer={unlockCustomer} customerId={customerData.id} />
            ) : (
              <BillingDashboardComponent
                customer={customerData}
                cardData={cardData}
                subscriptionData={subscriptionData}
                updateUser={unlockCustomer}
                updateCustomer={getCustomer}
              />
            )}
          </section>
          <aside className='chargebee-sidebar'>
            <span className='icon-info'></span>
            <div className='title'>Interested in learning more?</div>
            <p>
              Send us a message below or learn more{' '}
              <a target='_blank' href='https://www.medmo.com/refer' rel='noreferrer'>
                here
              </a>
              .
            </p>
            <form action='' className='chargebee-comment'>
              <div className='title'>Have a questions? Get in touch here:</div>
              <textarea
                name='message'
                id='message'
                placeholder='Leave us a message...'
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
              <Button className='rs-btn-model' onClick={submit} disabled={!message}>
                Submit
              </Button>
            </form>
          </aside>
        </div>
      </div>
      {!!modalTextType && (
        <div className='chargebee-infoModel-hint'>
          <div className='modal'>
            <Button classPrefix='btn-close' onClick={closeDialog}>
              <Close />
            </Button>
            <p>{modalTexts[modalTextType]}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChargebeeBillingTabComponent;
