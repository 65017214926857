import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, Form, SelectPicker } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { inviteUser, editUserInfo } from '../../../../api/users/UsersAPI';
import { roleList, model, UserFormValue } from './UsersUtil';
import TextField from '../../../../components/text-field-component';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { useError } from '../../../../utils/UseError';
import { UserContextType, UserContext } from '../../../../contexts/UserContext';
import { FormError } from '../../../../models/General';
import { InviteUserProps, UserFormData } from './interfaces';

const InviteUserModal: React.FunctionComponent<InviteUserProps> = props => {
  const { onClose, editMode, userId, setIsLoading, user, isLoading } = props;
  // const [hasError, setHasError] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<UserFormData>(UserFormValue);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const { updateUser, Email } = useContext<UserContextType>(UserContext);
  const formRef = useRef<FormInstance | null>(null);
  const standard = 'Standard';
  const admin = 'Administrator';

  useEffect(() => {
    if (user && editMode) {
      const { Email, UserTypeID } = user;
      setFormValue({ ...UserFormValue, email: Email, role: UserTypeID === 3 ? standard : admin });
    }
  }, [user]);

  const handleChange = (formValue: UserFormData, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event.target.name;

    if (dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const handleSubmit = () => {
    const { email, role } = formValue;
    setErrorText(null);

    if ((formRef.current && !formRef.current.check()) || !role || !email) {
      return;
    }

    setIsLoading(true);
    setIsSubmitPressed(true);

    if (editMode) {
      if (!user) {
        return;
      }

      editUserInfo(userId ? userId : user.ID, email, role)
        .then(() => {
          if (Email === email) {
            updateUser();
          }

          onClose(true);
        })
        .catch(err => {
          setErrorText(err, formValue);
        })
        .finally(() => setIsLoading(false));
    } else {
      inviteUser(email, role)
        .then(() => onClose(true))
        .catch(err => {
          setErrorText(err, formValue);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = () => {
    if (!isSubmitPressed) {
      onClose(false);
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (editMode) {
      if (user?.UserTypeID === 3) {
        return formValue.role === standard;
      }
      return formValue.role === admin;
    }
    return false;
  }, [formValue.role]);

  return (
    <div>
      <Modal className='invite-user-model' size={'sm'} open onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{editMode ? 'Update User Information' : 'Invite User to Medmo for Physicians'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onChange={(formValue, event) => handleChange(formValue as UserFormData, event as ChangeEvent<HTMLInputElement>)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={model}
          >
            <div className='row'>
              <TextField
                error={formError.email || dataErrors.email}
                name='email'
                label='Email address*'
                placeholder='enter email address (i.e. kat@medmo.com)'
                value={formValue.email}
                disabled={editMode}
              />
              <TextField
                accepter={SelectPicker}
                data={roleList}
                appearance='subtle'
                label='User role*'
                placeholder='Set user role'
                searchable={false}
                value={formValue.role}
                error={formError.role || dataErrors.role}
                onSelect={(val: string) => setFormValue({ ...formValue, role: val })}
                className={`${formError.role ? 'error-border role' : 'role'}`}
                name='role'
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-close btn-lg' disabled={isLoading} onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitDisabled || isLoading} onClick={handleSubmit}>
              {editMode ? 'Update Information' : 'Invite user'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InviteUserModal;
