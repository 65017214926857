import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { Notifications, User } from '../../models/User';
import { UserInfo } from '../../views/portal-settings/tabs/general/interfaces';
import { ApiToken } from './interfaces';

export function changeUserInfo(data: UserInfo): Promise<User> {
  const { FirstName, LastName, Email } = data;

  return request({
    method: 'PUT',
    url: `${SERVER_URL}/users/self-update`,
    body: JSON.stringify({
      FirstName,
      LastName,
      Email,
    }),
  });
}

export function sendNotifications(data: Notifications): Promise<{ success: boolean }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/notifications`,
    body: JSON.stringify(data),
  });
}

export function sendReport(status: number, id: number): Promise<{ success: boolean }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/organizations/report-review-toggle/${id}`,
    body: JSON.stringify({
      IsReportReviewAutoCreate: status,
    }),
  });
}

export function getApiTokenListByPOID(poId: number): Promise<ApiToken[]> {
  return request({
    method: 'GET',
    url: `${SERVER_URL}/organizations/${poId}/api-token`,
  });
}

export function generateAPiToken(poId: number, payload: { Name: string }): Promise<ApiToken> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/organizations/${poId}/api-token`,
    body: JSON.stringify(payload),
  });
}

export function revokeAPiToken(poId: number, id: number): Promise<{ success: boolean }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/organizations/${poId}/api-token/${id}`,
  });
}
