import { Close } from '@rsuite/icons';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form, CheckPicker, DatePicker, Checkbox } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import FilterModalComponent from '../../components/modals/filter-modal';
import TextField from '../../components/text-field-component';
import { CommonContext, CommonContextType } from '../../contexts/CommonContext';
import { FormError } from '../../models/General';
import { isString } from '../../utils/GeneralUtil';
import { FieldNames, FilterCommentsComponentProps, FilterDataType } from './interfaces';
import { FilterSchema } from './Schemas';

const FilterCommentsComponent: React.FunctionComponent<FilterCommentsComponentProps> = ({
  applyFilters,
  initData,
  filterData,
  onClose,
}) => {
  const { owners, patients, scans } = filterData;
  const [formValue, setFormValue] = useState<FilterDataType>(initData);
  const [formError, setFormError] = useState<FormError>({});
  const [indeterminate, setIndeterminate] = useState<Record<FieldNames, boolean>>({
    ownerIds: false,
    patientIds: false,
    scanIds: false,
    commentOwnerIds: false,
    scanStatusesIds: false,
  });
  const [checkAll, setCheckAll] = useState<Record<FieldNames, boolean>>({
    ownerIds: false,
    patientIds: false,
    scanIds: false,
    commentOwnerIds: false,
    scanStatusesIds: false,
  });
  const withChanges = useRef<boolean>(false);
  const formRef = useRef<FormInstance>(null);
  const { ScanStatuses } = useContext<CommonContextType>(CommonContext);

  useEffect(() => {
    setCheckAll({
      ownerIds: initData.ownerIds?.length === allValues.ownerIds.length,
      patientIds: initData.patientIds?.length === allValues.patientIds.length,
      scanIds: initData.scanIds?.length === allValues.scanIds.length,
      commentOwnerIds: initData.commentOwnerIds?.length === allValues.commentOwnerIds.length,
      scanStatusesIds: initData.scanStatusesIds?.length === allValues.scanStatusesIds.length,
    });
    setIndeterminate({
      ownerIds: !!initData.ownerIds?.length && initData.ownerIds?.length !== allValues.ownerIds.length,
      patientIds: !!initData.patientIds?.length && initData.patientIds?.length !== allValues.patientIds.length,
      scanIds: !!initData.scanIds?.length && initData.scanIds?.length !== allValues.scanIds.length,
      commentOwnerIds: !!initData.commentOwnerIds?.length && initData.commentOwnerIds?.length !== allValues.commentOwnerIds.length,
      scanStatusesIds: !!initData.scanStatusesIds?.length && initData.scanStatusesIds?.length !== allValues.scanStatusesIds.length,
    });
  }, [initData]);

  useEffect(() => {
    if (formValue.from) {
      formRef.current?.checkForField('from');
    }
  }, [formValue.to]);

  useEffect(() => {
    if (formValue.to) {
      formRef.current?.checkForField('to');
    }
  }, [formValue.from]);

  const allValues: { ownerIds: number[]; patientIds: number[]; scanIds: number[]; commentOwnerIds: number[]; scanStatusesIds: number[] } =
    useMemo(
      () => ({
        ownerIds: Object.values(owners).map(item => item.value),
        patientIds: Object.values(patients).map(item => item.value) as number[],
        scanIds: Object.values(scans).map(item => item.value) as number[],
        commentOwnerIds: Object.values(owners).map(item => item.value) as number[],
        scanStatusesIds: Object.values(ScanStatuses).map(item => item.value) as number[],
      }),
      [patients, scans],
    );

  const handleChange = (formValue: FilterDataType) => {
    if (!withChanges.current) {
      withChanges.current = true;
    }

    setFormValue(formValue);
  };

  const handleSelect = (value: number[], key: FieldNames) => {
    setFormValue({ ...formValue, [key]: value });
    setIndeterminate({ ...indeterminate, [key]: value.length > 0 && value.length < allValues[key].length });
    setCheckAll({ ...checkAll, [key]: value.length === allValues[key].length });
  };

  const handleCheckAll = (checked: boolean, key: FieldNames) => {
    if (!withChanges.current) {
      withChanges.current = true;
    }

    const nextValue = checked ? allValues[key] : [];
    setFormValue({ ...formValue, [key]: nextValue });
    setIndeterminate({ ...indeterminate, [key]: false });
    setCheckAll({ ...checkAll, [key]: checked });
  };

  const modalControl = (val: boolean) => {
    if (!val) {
      onClose();
    } else {
      if (!formRef.current || !formRef.current.check()) {
        return;
      }

      applyFilters({
        patientIds: checkAll.patientIds ? undefined : formValue.patientIds,
        scanIds: checkAll.scanIds ? undefined : formValue.scanIds,
        ownerIds: checkAll.ownerIds ? undefined : formValue.ownerIds,
        commentOwnerIds: checkAll.commentOwnerIds ? undefined : formValue.commentOwnerIds,
        scanStatusesIds: checkAll.scanStatusesIds ? undefined : formValue.scanStatusesIds,
        from: formValue.from,
        to: formValue.to,
      });
    }
  };

  return (
    <FilterModalComponent isSubmitDisabled={!withChanges.current} modalControl={modalControl}>
      <Form
        ref={formRef}
        formValue={formValue}
        className='tasks-filter-body rs-form-inline'
        model={FilterSchema}
        onCheck={formError => setFormError(formError)}
        onChange={handleChange}
        fluid
      >
        <div className='row'>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='patientIds'
              data={patients}
              value={formValue.patientIds}
              appearance='subtle'
              searchable={true}
              label='Patient:'
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'patientIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.patientIds}
                    checked={checkAll.patientIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'patientIds')}
                  >
                    {!checkAll.patientIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <div className='col-6'>
            <div className='row'>
              <Form.Group className='form-new-scan-group comments-field-datePicker'>
                <TextField
                  name='from'
                  accepter={DatePicker}
                  format='MM/dd/yyyy'
                  placeholder='mm/dd/yyyy'
                  label='Date From:'
                  labelClassName='tasks-filter-label'
                  className={isString(formError.from) ? 'error-border' : ''}
                  placement='autoVerticalStart'
                />
              </Form.Group>
              <Form.Group className='form-new-scan-group comments-field-datePicker'>
                <TextField
                  name='to'
                  accepter={DatePicker}
                  format='MM/dd/yyyy'
                  placeholder='mm/dd/yyyy'
                  label='Date To:'
                  labelClassName='tasks-filter-label'
                  className={isString(formError.to) ? 'error-border' : ''}
                  placement='autoVerticalStart'
                />
              </Form.Group>
              <Form.ErrorMessage show={isString(formError.from) || isString(formError.to)} placement={'bottomStart'}>
                Start date must be before end date.
              </Form.ErrorMessage>
            </div>
          </div>
        </div>
        <div className='row'>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='scanIds'
              data={scans}
              value={formValue.scanIds}
              appearance='subtle'
              searchable={true}
              label='Scan Request ID:'
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'scanIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.scanIds}
                    checked={checkAll.scanIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'scanIds')}
                  >
                    {!checkAll.scanIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='ownerIds'
              data={owners}
              value={formValue.ownerIds}
              appearance='subtle'
              searchable={true}
              label='Scan Owner:'
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'ownerIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.ownerIds}
                    checked={checkAll.ownerIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'ownerIds')}
                  >
                    {!checkAll.ownerIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
        </div>
        <div className='row'>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='commentOwnerIds'
              data={owners}
              value={formValue.commentOwnerIds}
              appearance='subtle'
              searchable={true}
              label='Enter by:'
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'commentOwnerIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.commentOwnerIds}
                    checked={checkAll.commentOwnerIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'commentOwnerIds')}
                  >
                    {!checkAll.commentOwnerIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='scanStatusesIds'
              data={ScanStatuses}
              value={formValue.scanStatusesIds}
              appearance='subtle'
              searchable={true}
              label='Scan Status:'
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'scanStatusesIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.scanStatusesIds}
                    checked={checkAll.scanStatusesIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'scanStatusesIds')}
                  >
                    {!checkAll.scanStatusesIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
        </div>
        <button className='btn-reset' onClick={() => applyFilters({})}>
          <Close />
          Clear all filters
        </button>
      </Form>
    </FilterModalComponent>
  );
};

export default FilterCommentsComponent;
