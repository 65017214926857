import React from 'react';
import { Loader } from 'rsuite';
import { LoaderProps } from './interfaces';

const LoaderComponent: React.FunctionComponent<LoaderProps> = ({ textLoader, className }) => {
  return (
    <div className={className ? className + ' loader-block' : 'loader-block'}>
      <Loader size='md' />
      <span className='loader-text'>{textLoader}</span>
    </div>
  );
};
export default LoaderComponent;
