import React from 'react';
import { Button } from 'rsuite';
import { texts } from './ErrorUtil';

const InvalidOnBoardingComponent: React.FunctionComponent<{ isLinkExpired: boolean }> = ({ isLinkExpired }) => {
  return (
    <div className='onboarder-wrap'>
      <div className='onboarder-title'>Activate your Medmo account!</div>
      <div className='onboarder-form'>
        <div className='invalid-content'>
          <div className='invalid-icon'></div>
          <div className='invalid-title'>Invalid Link</div>
          <div className='invalid-text'>{isLinkExpired ? texts.expiredLinkText : texts.standardErrorText}</div>
          {!isLinkExpired && <Button classPrefix='btn-model'>Contact Customer Experience</Button>}
        </div>
      </div>
    </div>
  );
};

export default InvalidOnBoardingComponent;
