import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Button } from 'rsuite';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import FileViewer from '../doc-viewer-modal';
import { FilePreviewerThumbnail } from '.';
import { FileView } from './interfaces';
import { FileListPreviewerProps } from '../../views/tasks/interfaces';

const FileListPreviewer: React.FunctionComponent<FileListPreviewerProps> = ({ filesList }) => {
  const [currentFile, setCurrentFile] = useState<FileView>({
    url: '',
    name: '',
  });
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    setCurrentFile({
      url: filesList[0]?.url as string,
      name: filesList[0]?.originalName || filesList[0]?.key,
    });
  }, [filesList]);

  const goLeft = () => {
    if (currentFileIndex > 0) {
      const i = currentFileIndex - 1;
      setCurrentFile({
        url: filesList[i]?.url as string,
        name: filesList[i]?.originalName || filesList[i]?.key,
      });
      setCurrentFileIndex(i);
    }
  };

  const goRight = () => {
    if (currentFileIndex < filesList.length - 1) {
      const i = currentFileIndex + 1;
      setCurrentFile({
        url: filesList[i].url as string,
        name: filesList[i]?.originalName || filesList[i]?.key,
      });
      setCurrentFileIndex(i);
    }
  };

  const onDownload = () => {
    saveAs(currentFile.url as string, currentFile.name);
  };

  if (!filesList || filesList.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className='preview-pdf_nav'>
        <div className='row'>
          <div className='preview-pdf_nav_name'>{filesList[currentFileIndex].fileTitle}</div>
          <div className='preview-pdf_nav_number'>
            ({currentFileIndex + 1}&nbsp;of&nbsp;{filesList.length})
          </div>
        </div>
        <nav className='row'>
          <button className='arrow-nav arrow-left' disabled={currentFileIndex === 0} onClick={goLeft}></button>
          <button className='arrow-nav arrow-right' disabled={currentFileIndex === filesList.length - 1} onClick={goRight}></button>
        </nav>
      </div>
      <div className='preview-pdf_slider'>
        <div className='preview-bar'>
          <Button onClick={onDownload}>
            <FileDownloadIcon style={{ fontSize: '2em', marginRight: 10 }} />
          </Button>
        </div>
        <div onClick={() => setIsShow(true)}>
          <FilePreviewerThumbnail file={currentFile} style={{ width: '100%', height: '100%' }} width={300} height={400} />
        </div>
      </div>
      <FileViewer isShow={isShow} onDownloadFile={onDownload} file={currentFile} onClose={() => setIsShow(false)} />
    </>
  );
};

export default FileListPreviewer;
