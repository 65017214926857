import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { createSubscription, getHostSettings, getItemPriceList } from '../../../../../api/billing/BillingAPI';
import chargebee from '../../../../../assets/chargebee.jpg';
import { UserContextType, UserContext } from '../../../../../contexts/UserContext';
import { transformNumberToBillingDate } from '../../../../../utils/TimeUtil';
import { formatCurrency } from '../BillingUtil';
import { BillingPlanAndTrialInfoComponentProps, CardInstance, ExtendedWindowType } from '../interfaces';
import LoaderComponent from '../../../../../components/loader-component';
import { useSignal } from '../../../../../utils/UseSignal';
import { Button } from 'rsuite';

const BillingPlanAndTrialInfoComponent: React.FunctionComponent<BillingPlanAndTrialInfoComponentProps> = ({
  customerId,
  unlockCustomer,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [planPrice, setPlanPrice] = useState<string>('');
  const cbInstance = useRef<CardInstance | null>(null);
  const isSuccess = useRef<boolean>(false);
  const currentUser = useContext<UserContextType>(UserContext);
  const DaysLeft: number = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return currentUser.PO.Access.DaysLeft;
    }
    return 0;
  }, [currentUser]);
  const signal = useSignal();

  const getPlanItemPriceList = () => {
    setIsLoading(true);
    getItemPriceList(signal)
      .then(({ list }) => {
        const [
          {
            item_price: { price },
          },
        ] = list;

        if (!signal.aborted) {
          setPlanPrice(formatCurrency('USD', price));
        }
      })
      .catch(e => {
        if (!signal.aborted) {
          throw e;
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const EndPeriod = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return transformNumberToBillingDate(currentUser.PO.Access.TrialExpiration as Date);
    }
    return '';
  }, [currentUser]);

  useEffect(() => {
    try {
      cbInstance.current = (window as ExtendedWindowType).Chargebee.getInstance();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getPlanItemPriceList();
  }, []);

  const unlock = () => {
    if (!customerId) {
      return;
    }

    cbInstance.current?.openCheckout({
      hostedPage: () => {
        return getHostSettings(customerId, signal).then(({ hosted_page }) => {
          return hosted_page;
        });
      },
      error: (error?: string) => {
        console.log('error ' + error);
      },
      success: () => {
        isSuccess.current = true;
      },
      close: () => {
        if (isSuccess.current) {
          createSubscription()
            .then(() => unlockCustomer())
            .catch(() => null);
        }
      },
    });
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      {/* hide element when block with class chargebee-unlimited is visible */}
      <div className='chargebee-access-plan'>
        <h3>Free access to Unlimited Plan expires in:</h3>
        <div className='period'>
          {DaysLeft > 0 ? (
            <>
              <b> {DaysLeft} </b> day{DaysLeft === 1 ? '' : 's'} ({EndPeriod})
            </>
          ) : (
            <div className='expired'>Expired</div>
          )}
        </div>
      </div>
      <div className='chargebee-plan'>
        <h3>Unlock all features with the Unlimited Plan</h3>
        <div className='row'>
          <div className='col'>
            <div className='chargebee-plan_col'>
              <div className='title'>
                Base plan
                <span>Free</span>
              </div>
              <p>
                <strong>What You’ll Get:</strong>
              </p>
              <ul className='list'>
                <li>View and track existing orders</li>
                <li>Receive &amp; view patient reports</li>
              </ul>
            </div>
            <div className='chargebee-plan_col' hidden>
              <div className='title'>
                {DaysLeft > 0 ? (
                  <>
                    Your free access to our Unlimited Plan expires in
                    <br />
                    <strong> {DaysLeft} </strong> day{DaysLeft === 1 ? '' : 's'}
                  </>
                ) : (
                  <span>Your free access to our Unlimited Plan has expired</span>
                )}
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='chargebee-plan_col dark'>
              <div className='title'>
                Unlimited plan
                <div className='chargebee-plan_price'>
                  <span>{planPrice}</span>
                  /Month
                </div>
              </div>
              <p>
                <strong>What You’ll Get:</strong>
              </p>
              <ul className='list'>
                <li>Unlimited new imaging orders</li>
                <li>Prior-authorization support</li>
                <li>Peer-to-peer review coordination</li>
                <li>E-prescription</li>
                <li>24/7 patient coordination support</li>
                <li>Order status &amp; patient tracking</li>
                <li>Receive &amp; view patient reports</li>
              </ul>
              <Button appearance='primary' onClick={unlock}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='chargebee-unlimited' hidden>
        <h2>
          Unlock All Features With The <b>Unlimited Plan</b>
        </h2>
        <button className='btn'>Edit Billing Information</button>
        <div className='powered'>
          <p>Powered by </p>
          <img src={chargebee} alt='chargebee' />
        </div>
      </div>
    </>
  );
};

export default BillingPlanAndTrialInfoComponent;
