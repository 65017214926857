import { SERVER_URL } from '../../config/ServerConfig';
import { OwnerResponse } from '../../models/Owner';
import { prepareGetOptions } from '../../utils/GeneralUtil';
import { request } from '../Request';

export function getOwners(signal?: AbortSignal): Promise<OwnerResponse[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/scan-request/owners`);
  return request(options);
}

export function setOwner(scanID: number, ownerID: number | null): Promise<OwnerResponse> {
  return request({
    url: `${SERVER_URL}/scan-request/set-owner/${scanID}`,
    method: 'PUT',
    body: JSON.stringify({
      OwnerID: ownerID,
    }),
  });
}
