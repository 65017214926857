import React, { SyntheticEvent, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { transformDate, transformToLocalDateWithTime } from '../../../utils/TimeUtil';
import { TaskTypes } from '../task/TaskUtil';
import { P2PtaskResponse, TaskInfoWithoutFiles } from '../interfaces';
import { TaskStatuses } from '../TasksUtil';
import { chooseStatus } from '../TasksUtil';
import { checkForEmptyString } from '../../../utils/GeneralUtil';
import { FilePreviewerThumbnail } from '../../../components/file-viewer';
import FileViewer from '../../../components/doc-viewer-modal';
import { RequestFiles } from '../../../api/all-scans/interfaces';
import SaveTaskNotesModalComponent from '../../../components/task-notes-modal';
import { TaskNote } from '../../../models/TaskNote';

const DetailsTaskTabComponent: React.FunctionComponent<{ data: TaskInfoWithoutFiles; taskDetailsChanged: () => void }> = ({
  data,
  taskDetailsChanged,
}) => {
  const { task, special, testRequest, insurance, Patient } = data;
  const [currentFile, setCurrentFile] = useState<RequestFiles>({
    url: '',
    originalName: '',
    fileTitle: '',
    key: '',
  });
  const [isShow, setIsShow] = useState<boolean>(false);

  const preparedCallNumber = useMemo((): string => {
    if (task.TaskTypeID === TaskTypes.PeerToPeerReview && (special as P2PtaskResponse).CallNumber) {
      const { CallNumber } = special as P2PtaskResponse;

      if (CallNumber.match(/[)]/)) {
        const arr = CallNumber.split(')');
        return arr[0] + ') ' + arr[1];
      }

      return `(${CallNumber.slice(0, 3)}) ${CallNumber.slice(3, 6)}-${CallNumber.slice(6)}`;
    }

    return '';
  }, [special]);
  const [taskNote, setTaskNote] = useState<TaskNote>({ ID: null, MFPTaskNotes: '' });
  const [taskNoteOpen, setTaskNoteOpen] = useState<boolean>(false);

  const previewFile = (e: SyntheticEvent, file: RequestFiles) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentFile(file);
    setIsShow(true);
  };

  const onCloseFileViewer = (): void => {
    setIsShow(false);
  };

  const onDownload = () => {
    if (!currentFile.url) {
      return;
    }

    saveAs(currentFile.url, currentFile.fileTitle || currentFile.originalName);
  };

  const openTaskNote = (taskNote: TaskNote) => {
    setTaskNote(taskNote);
    setTaskNoteOpen(true);
  };

  const onTaskNoteClose = (wasTaskNoteSaved: boolean) => {
    if (wasTaskNoteSaved) {
      taskDetailsChanged();
    }

    setTaskNoteOpen(false);
  };

  return (
    <div className='task-details_tabs_item'>
      <div className='content'>
        <div className='item'>
          <div className='title'>Task Details:</div>
          <div className='row'>
            <div className='th'>Task Created By:</div>
            <div className='td'>{checkForEmptyString(task.CreatedBy)}</div>
          </div>
          <div className='row'>
            <div className='th'>Created On:</div>
            <div className='td'>{transformToLocalDateWithTime(task.TaskCreatedDate)}</div>
          </div>
          <div className='row'>
            <div className='th'>Task Type:</div>
            <div className='td'>{checkForEmptyString(task.TaskType)}</div>
          </div>
          <div className='row'>
            <div className='th'>Task Status:</div>
            <div className='td'>
              <button className={`task-status-button ${chooseStatus(task.TaskStatus as TaskStatuses)}`}>{task.TaskStatus}</button>
            </div>
          </div>
          <div className='row'>
            <div className='th'>Task Сomment:</div>
            <div className='td'>{checkForEmptyString(task.TaskComment) || 'No comment'}</div>
          </div>
          <div className='row'>
            <div className='th'>Task Notes:</div>
            <div className='td'>
              <span style={{ marginRight: '16px' }}>{checkForEmptyString(task.MFPTaskNotes) || 'No notes'}</span>
              {task.MFPTaskNotes ? (
                <a
                  className='task-note-link edit-note'
                  href='#'
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    openTaskNote({ ID: Number(task.ID), MFPTaskNotes: task.MFPTaskNotes?.toString() ?? '' });
                  }}
                >
                  <i className='task-note-icon'></i>
                  Edit
                </a>
              ) : (
                <a
                  className='task-note-link add-note'
                  href='#'
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    openTaskNote({ ID: Number(task.ID), MFPTaskNotes: task.MFPTaskNotes?.toString() ?? '' });
                  }}
                >
                  <i className='task-note-icon'></i>
                  Add notes
                </a>
              )}
            </div>
          </div>
        </div>
        {task.TaskTypeID === TaskTypes.PeerToPeerReview && (
          <div className='item'>
            <div className='title'>Peer-to-Peer Details:</div>
            <div className='row'>
              <div className='th'>Insurance Provider:</div>
              <div className='td'>{checkForEmptyString(insurance.PolicyName) || 'selfpay'}</div>
            </div>
            <div className='row'>
              <div className='th'>Member ID:</div>
              <div className='td'>{checkForEmptyString(insurance.PolicyNumber) || 'selfpay'}</div>
            </div>
            <div className='row'>
              <div className='th'>Peer-to-Peer Org.:</div>
              <div className='td'>{checkForEmptyString((special as P2PtaskResponse).CaseOrganization)}</div>
            </div>
            <div className='row'>
              <div className='th'>Peer-to-Peer Number:</div>
              <div className='td'>{checkForEmptyString(preparedCallNumber)}</div>
            </div>
            <div className='row'>
              <div className='th'>Peer-to-Peer Case ID:</div>
              <div className='td'>{checkForEmptyString((special as P2PtaskResponse).CaseID)}</div>
            </div>
          </div>
        )}
        <div className='item'>
          <div className='title'>Scan Details:</div>
          <div className='row'>
            <div className='th'>Scan ID:</div>
            <div className='td'>
              <NavLink to={`/all-scans/${testRequest.ScanID}`}>{testRequest.ScanID}</NavLink>
            </div>
          </div>
          <div className='row'>
            <div className='th'>Scan Type:</div>
            <div className='td'>{checkForEmptyString(task.FriendlyName)}</div>
          </div>
          <div className='row'>
            <div className='th'>Requested By:</div>
            <div className='td'>{checkForEmptyString(testRequest.SubmittedBy)}</div>
          </div>
          <div className='row'>
            <div className='th'>Requested On:</div>
            <div className='td'>{transformToLocalDateWithTime(testRequest.SubmittedOn)}</div>
          </div>
          <div className='row'>
            <div className='th'>Scan Owner:</div>
            <div className='td'>{checkForEmptyString(testRequest.OwnerName)}</div>
          </div>
          <div className='row'>
            <div className='th'>Physician:</div>
            <div className='td'>{checkForEmptyString(testRequest.OrderingPhysician)}</div>
          </div>
        </div>
        <div className='item'>
          <div className='title'>Patient Information:</div>
          <div className='row'>
            <div className='th'>Name:</div>
            <div className='td'>{checkForEmptyString(task.PatientName)}</div>
          </div>
          <div className='row'>
            <div className='th'>Biological sex:</div>
            <div className='td'>{checkForEmptyString(Patient?.Gender)}</div>
          </div>
          <div className='row'>
            <div className='th'>DOB:</div>
            <div className='td'>{transformDate(Patient?.DOB || '')}</div>
          </div>
          <div className='row'>
            <div className='th'>Phone:</div>
            <div className='td'>{checkForEmptyString(Patient?.Phone)}</div>
          </div>
          <div className='row'>
            <div className='th'>Email:</div>
            <div className='td'>{checkForEmptyString(Patient?.Email)}</div>
          </div>
        </div>
        {task.TaskTypeID === TaskTypes.PriorAuthTask && (
          <div className='item'>
            <div className='title'>Insurance Information:</div>
            <div className='row'>
              <div className='th'>Insurance Provider:</div>
              <div className='td'>{checkForEmptyString(insurance.PolicyName)}</div>
            </div>
            <div className='row'>
              <div className='th'>Member ID:</div>
              <div className='td'>{checkForEmptyString(insurance.PolicyNumber)}</div>
            </div>
            <div className='row task-details_preview'>
              {insurance.InsuranceCardFront?.key && (
                <div className='col'>
                  <div className='th'>Front:</div>
                  <FilePreviewerThumbnail
                    style={{ width: '150px', height: '95px' }}
                    file={insurance.InsuranceCardFront}
                    className='task-details_preview_pdf'
                    width={150}
                    height={95}
                    handleClick={(e: SyntheticEvent) => previewFile(e, insurance.InsuranceCardFront as RequestFiles)}
                  />
                </div>
              )}
              {insurance.InsuranceCardBack?.key && (
                <div className='col'>
                  <div className='th'>Back:</div>
                  <FilePreviewerThumbnail
                    style={{ width: '150px', height: '95px' }}
                    file={insurance.InsuranceCardBack}
                    className='task-details_preview_pdf'
                    width={150}
                    height={95}
                    handleClick={(e: SyntheticEvent) => previewFile(e, insurance.InsuranceCardBack as RequestFiles)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <FileViewer isShow={isShow} onDownloadFile={onDownload} file={currentFile} onClose={() => onCloseFileViewer()} />
      <SaveTaskNotesModalComponent taskNote={taskNote} isShow={taskNoteOpen} onClose={onTaskNoteClose} />
    </div>
  );
};

export default DetailsTaskTabComponent;
