import React, { useCallback, useEffect, useState } from 'react';
import CommentLogo from '../../../../../assets/svg/speech-bubbles.svg';
import { getComments } from '../../../../../api/comments/CommentsAPI';
import { removeComment } from '../../../../../api/comments/CommentsAPI';
import AddCommentFormComponent from './components/AddCommentFormComponent';
import ErrorMessageComponent from '../../../../../components/error-message-component';
import CommentComponent from './components/CommentComponent';
import { useError } from '../../../../../utils/UseError';
import { transformDateToNumber } from '../../../../../utils/TimeUtil';
import { Comment } from './interfaces';
import LoaderComponent from '../../../../../components/loader-component';
import { useSignal } from '../../../../../utils/UseSignal';

const ScanCommentsTabComponent: React.FunctionComponent<{ scanId: number; setIsUpdated?: (value: boolean) => void }> = props => {
  const { scanId, setIsUpdated } = props;
  const [errorText, setErrorText] = useError(null);
  const [comments, setComments] = useState<Comment[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const signal = useSignal();

  const sortCommentsByDate = (commentsArr: Comment[]): Comment[] => {
    return commentsArr.sort((a, b) => transformDateToNumber(b.Created) - transformDateToNumber(a.Created));
  };

  const getAllComments = useCallback(
    (isUpdated?: boolean) => {
      getComments(scanId, signal)
        .then(response => {
          setComments(sortCommentsByDate(response));
          setIsUpdated && setIsUpdated(!!isUpdated);
        })
        .catch(err => {
          if (!signal.aborted) {
            setErrorText(err.message);
            setComments([]);
          }
        })
        .finally(() => {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        });
    },
    [scanId],
  );

  useEffect(() => {
    getAllComments();
  }, []);

  const onConfirmRemove = useCallback((commentID: number) => {
    removeComment(commentID)
      .then(() => {
        if (!signal.aborted) {
          getAllComments(true);
        }
      })
      .catch(err => {
        if (!signal.aborted) {
          setErrorText(err);
        }
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className='page-content-wrap page-comment'>
          <AddCommentFormComponent getAllComments={() => getAllComments(true)} scanId={scanId} />
          <div className='no-comment' hidden={comments.length !== 0 || !!errorText}>
            <img width='128' src={CommentLogo} alt='speech-bubbles' />
            <p>There are no comments yet...</p>
          </div>
          {!errorText && comments.length !== 0 && (
            <ul className='comments-list'>
              {comments.map(comment => (
                <CommentComponent
                  key={comment.ID}
                  commentData={comment}
                  onConfirmRemove={onConfirmRemove}
                  getAllComments={() => getAllComments(true)}
                />
              ))}
            </ul>
          )}
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
        </div>
      )}
    </>
  );
};

export default ScanCommentsTabComponent;
