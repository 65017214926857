import React from 'react';
import SettingsOrganizationTabComponent from '../tabs/organization';
import { Panel } from 'rsuite';

const SettingsAdminWrapperComponent: React.FunctionComponent = () => {
  return (
    <div className='page-content-main'>
      <div className='tab-content'>
        <Panel header={<></>}>
          <SettingsOrganizationTabComponent />
        </Panel>
      </div>
    </div>
  );
};

export default SettingsAdminWrapperComponent;
