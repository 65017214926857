import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TextSignature from './TextSignature';
import TextField from '../../../../components/text-field-component';
import { Button, Modal, Form, SelectPicker } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import {
  model,
  FormDefaultValue,
  prepareSelectData,
  PhysicianTypeDefaultValue,
  findPhysicianTypeId,
  getEditPhysicianInfo,
  fieldsForCheck,
} from './PhysiciansUtil';
import { addPhysician, editPhysicianInfo, getPhysicianTypes, removePhysician } from '../../../../api/physicians/PhysiciansAPI';
import { checkForEmptyFields } from '../../../../utils/FormUtil';
import InputMask from '../../../../components/mask-field';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { Physician } from '../../../../models/Physician';
import { SettingsPhysicianTexts } from './Texts';
import { useError } from '../../../../utils/UseError';
import { FormStructurePhysicians, PhysicianProps, PhysicianTypeSchema } from './interfaces';
import { FormError } from '../../../../models/General';
import { useSignal } from '../../../../utils/UseSignal';

const PhysicianModal: React.FunctionComponent<PhysicianProps> = props => {
  const { physician, onCancel, removeMode, undoRemoveMode, liftStateUp } = props;
  const [formValue, setFormValue] = useState<FormStructurePhysicians>(physician ? physician : FormDefaultValue);
  const [formError, setFormError] = useState<FormError>({});
  const [types, setTypes] = useState<PhysicianTypeSchema[]>([PhysicianTypeDefaultValue]);
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [signature, setSignature] = useState('');
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const formRef = useRef<FormInstance | null>(null);
  const signal = useSignal();

  const onChangeHandler = useCallback(
    (newVal: string): void => {
      setFormValue(prevState => ({
        ...prevState,
        PhysicianTypeID: findPhysicianTypeId(types, newVal),
        PhysicianType: newVal,
      }));
    },
    [formValue, types],
  );

  const handleChange = (formValue: FormStructurePhysicians, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const getSelectedValue = () => {
    const newPatientFormValue: FormStructurePhysicians = { ...FormDefaultValue };
    const element = prepareSelectData(types).find((el: { [role: string]: number | string }) => el.role === formValue.PhysicianTypeID);

    if (element) {
      newPatientFormValue.PhysicianType = element.value;
    }

    setFormValue({
      ...formValue,
      PhysicianType: newPatientFormValue['PhysicianType'],
    });
  };

  useEffect(() => {
    if (!removeMode) {
      getPhysicianTypes(signal)
        .then(result => {
          const sortedResult = result.sort((a, b) => a.PhysicianType.localeCompare(b.PhysicianType));

          setTypes(sortedResult);
        })
        .catch(() => {
          if (!signal.aborted) {
            setTypes([PhysicianTypeDefaultValue]);
          }
        });
    }
  }, []);

  useEffect(() => {
    getSelectedValue();
  }, [types]);

  const onClose = (data: FormStructurePhysicians | Physician | null, shouldRemovePhysician = false): void => {
    if (!data && shouldRemovePhysician && physician) {
      removePhysician(physician.ID)
        .then(() => {
          liftStateUp(SettingsPhysicianTexts.successRemoveLocation);
          undoRemoveMode();
        })
        .catch(e => setErrorText(e))
        .finally(() => setIsSubmitPressed(false));
    }

    if (data) {
      if (physician) {
        const submitData = getEditPhysicianInfo(signature, data as Physician);
        editPhysicianInfo((data as Physician).ID, submitData)
          .then(() => {
            liftStateUp(SettingsPhysicianTexts.successEditLocation);
          })
          .catch(e => setErrorText(e, formValue))
          .finally(() => {
            if (!signal.aborted) {
              setIsSubmitPressed(false);
            }
          });
      } else {
        addPhysician(signature ? { ...data, Signature: signature } : data)
          .then(() => {
            liftStateUp(SettingsPhysicianTexts.successAddLocation);
          })
          .catch(e => setErrorText(e, formValue))
          .finally(() => setIsSubmitPressed(false));
      }
    }
  };

  const handleSubmit = (): void => {
    if (removeMode) {
      setIsSubmitPressed(true);
      onClose(null, true);
      return;
    }

    if (formRef.current && !formRef.current.check()) {
      return;
    }

    setIsSubmitPressed(true);

    const { ...data } = formValue;
    delete data.PhysicianType;

    onClose(data as Physician);
  };

  const handleClickCancel = (): void => {
    if (!isSubmitPressed) {
      setErrorText(null);

      if (formRef.current) {
        onCancel(checkForEmptyFields(formValue, fieldsForCheck));
        return;
      }

      onCancel(null, true);
      undoRemoveMode();
    }
  };

  const physicianName = useMemo((): null | string => {
    if (!physician) {
      return null;
    }

    return `${physician.FirstName} ${physician.LastName}`;
  }, [physician]);

  const title = useMemo((): string => {
    if (removeMode) {
      if (physicianName) {
        return `Remove ${physicianName} from Medmo for Physicians?`;
      }

      return 'There is no physician to remove';
    }

    return `${physician ? 'Edit' : 'Add'} Physician to Medmo for Physicians`;
  }, [removeMode]);

  const submitButtonText = useMemo((): string => {
    if (removeMode) {
      return 'Yes, Remove';
    }

    return physician ? 'Update Physician Information' : 'Add Physician';
  }, [removeMode]);

  const isSubmitDisabled = useMemo(() => {
    if (physician && !removeMode) {
      return (Object.keys(physician) as [keyof Physician]).every(key => physician[key] === formValue[key as keyof FormStructurePhysicians]);
    }

    return false;
  }, [formValue, physician]);

  return (
    <div>
      <Modal size={'sm'} open onClose={handleClickCancel} className='manage-physicians-model'>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {removeMode ? (
            <div className='model-desk'>
              <p>
                Are you sure you’d like to remove {physicianName} from Medmo for Physicians? They will no longer have access to the portal.
              </p>
            </div>
          ) : (
            <Form
              ref={formRef}
              onChange={(formValue, event) => handleChange(formValue as FormStructurePhysicians, event as ChangeEvent<HTMLInputElement>)}
              onCheck={formError => setFormError(formError)}
              formValue={formValue}
              model={model}
            >
              <TextField
                error={formError.FirstName || dataErrors.FirstName}
                name='FirstName'
                label='Physician first name*'
                value={formValue.FirstName}
              />
              <TextField
                error={formError.LastName || dataErrors.LastName}
                name='LastName'
                label='Physician last name*'
                value={formValue.LastName}
              />
              <TextField
                accepter={InputMask}
                error={formError.NPI || dataErrors.NPI}
                name='NPI'
                label='NPI code (10-digit identifier)*'
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                value={formValue.NPI}
              />
              <TextField
                accepter={SelectPicker}
                name='PhysicianType'
                style={{ width: 379 }}
                data={prepareSelectData(types)}
                className='department'
                error={formError.PhysicianType || dataErrors.PhysicianType}
                appearance='subtle'
                placeholder='Physician type*'
                searchable={false}
                onChange={onChangeHandler}
              />
              <div className='row'>
                <TextSignature
                  setSignature={setSignature}
                  className='text'
                  name={`${formValue.FirstName || ''} ${formValue.LastName || ''}`}
                  defaultImg={''}
                  x={0}
                  y={40}
                  height={68}
                />
              </div>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {errorText && <ErrorMessageComponent errorMessage={errorText} />}
          <div className='justify-content-end'>
            <Button classPrefix='btn-model btn-lg btn-close' disabled={isSubmitPressed} onClick={handleClickCancel}>
              Cancel
            </Button>
            <Button classPrefix='btn-model btn-lg' disabled={isSubmitPressed || isSubmitDisabled} onClick={handleSubmit}>
              {submitButtonText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PhysicianModal;
