import { transformDate } from '../../utils/TimeUtil';
import { Patient } from '../../models/Patient';
import { RowDataType } from '../../components/table-component/interfaces';
import { SelectOption } from '../../models/General';

export const updatePatientListService = (data: Patient[]): Patient[] => {
  if (!data.length) {
    return data;
  }

  return data.map(d => {
    const transformData = {
      DOB: d.DOB && transformDate(d.DOB),
      PatientName: d.FirstName + ' ' + d.LastName,
    };

    return Object.assign({}, d, transformData);
  });
};

export const updatePatientService = (data: Patient, Languages?: Array<SelectOption>): Patient => {
  const { ZipCode, State, PreferredLanguageID } = data;
  const Residence = [];

  ZipCode && Residence.push(ZipCode);
  State && Residence.push(State);

  const trData = {
    DOB: data.DOB && transformDate(data.DOB),
    Name: data.FirstName + ' ' + data.LastName,
    Residence: Residence.join(', '),
    PreferredLanguage: Languages?.find(el => el.value === PreferredLanguageID)?.label || '',
  };

  return Object.assign({}, data, trData);
};

export const patientColumns = [
  {
    dataKey: 'PatientName',
    align: 'left',
    headTitle: 'Patient',
    minWidth: 170,
    tooltip: 'Patient name',
    flexGrow: 1.4,
    sortable: true,
  },
  {
    dataKey: 'DOB',
    align: 'left',
    headTitle: 'DOB',
    minWidth: 110,
    tooltip: 'Patient date of birth',
    flexGrow: 0.6,
    sortable: true,
  },
  {
    dataKey: 'Phone',
    align: 'left',
    headTitle: 'Phone',
    minWidth: 170,
    tooltip: 'Patient phone number',
    flexGrow: 1.4,
    sortable: true,
  },
  {
    dataKey: 'Email',
    align: 'left',
    headTitle: 'Email',
    minWidth: 200,
    tooltip: 'Patient email address',
    flexGrow: 1.4,
    sortable: true,
  },
  {
    dataKey: 'MRN',
    align: 'left',
    headTitle: 'MRN',
    minWidth: 110,
    tooltip: 'Medical record number',
    flexGrow: 0.6,
    sortable: true,
  },
  {
    dataKey: 'ScansCount',
    align: 'left',
    headTitle: '# of scans',
    minWidth: 110,
    tooltip: 'Number of scans',
    flexGrow: 0.6,
    sortable: true,
  },
];

export const patientInformationTopColumns = [
  {
    dataKey: 'FirstName',
    width: 100,
    align: 'center',
    headTitle: 'First name',
    flexGrow: 1,
  },
  {
    dataKey: 'LastName',
    width: 100,
    align: 'center',
    headTitle: 'Last name',
    flexGrow: 1,
  },
  {
    dataKey: 'PreferredLanguage',
    width: 200,
    align: 'left',
    headTitle: 'Preferred language',
    flexGrow: 2,
  },
  {
    dataKey: 'MRN',
    width: 100,
    align: 'left',
    headTitle: 'MRN ID',
    flexGrow: 1,
  },
];

export const patientInformationTopColumns2 = [
  {
    dataKey: 'DOB',
    width: 100,
    align: 'center',
    headTitle: 'Date of birth',
    flexGrow: 1,
  },
  {
    dataKey: 'Gender',
    width: 100,
    align: 'center',
    headTitle: 'Sex',
    flexGrow: 1,
  },
  {
    dataKey: 'Residence',
    width: 200,
    align: 'center',
    headTitle: 'Residence (Zip code, State)',
    flexGrow: 2,
  },
  {
    dataKey: 'ScansCount',
    width: 100,
    align: 'left',
    headTitle: '# of scans',
    flexGrow: 1,
  },
];
/*
type: 'link',
  linkUrl: '/all-scans/{TestRequestPatientId}',*/
export const patientScanHistoryColumns = [
  {
    dataKey: 'ScanType',
    minWidth: 140,
    align: 'center',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    flexGrow: 0.8,
    sortable: true,
    type: 'scanTypeToolTip',
    fieldName: 'FriendlyName',
  },
  {
    dataKey: 'ScanID',
    minWidth: 70,
    align: 'center',
    type: 'link',
    headTitle: 'ID',
    tooltip: 'Scan Request ID',
    flexGrow: 0.4,
    sortable: true,
    linkUrl: '/scans',
  },
  {
    dataKey: 'PatientName',
    minWidth: 150,
    align: 'left',
    type: 'patient-link',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'DOB',
    minWidth: 110,
    align: 'left',
    headTitle: 'DOB',
    tooltip: `Patient date of birth`,
    flexGrow: 0.4,
    sortable: true,
  },
  {
    dataKey: 'OrderingPhysician',
    minWidth: 160,
    align: 'left',
    headTitle: 'Physician',
    tooltip: 'Ordering Physician',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'StageMFP',
    minWidth: 120,
    align: 'left',
    type: 'status',
    headTitle: 'Status',
    tooltip: 'Status of the scan requestan Status',
    flexGrow: 0.6,
    sortable: true,
    reasonField: 'Reason',
    infoColor: 'InfoColor',
  },
  {
    dataKey: 'AppointmentDate',
    minWidth: 128,
    align: 'left',
    type: 'scanResult',
    headTitle: 'Appointment',
    tooltip: 'Appointment Date',
    flexGrow: 0.7,
    sortable: true,
    reportsCount: 'Reports',
    onClickHandler: (data: RowDataType): void => {
      const link = `${window.location.pathname}/scans/${data.ScanID}#reports`;
      window.location.href = link;
    },
  },
  {
    dataKey: 'OwnerName',
    minWidth: 130,
    align: 'left',
    headTitle: 'Owner',
    flexGrow: 1,
    sortable: true,
  },
];

export const searchColumn = [
  {
    dataKey: 'PatientName',
    label: 'PatientName',
    headTitle: 'Patient',
    value: 1,
    minWidth: 140,
    flexGrow: 1,
    type: 'withBoldText',
    tooltip: 'Patient name',
  },
  {
    dataKey: 'DOB',
    label: 'Date',
    headTitle: 'DOB',
    value: 3,
    minWidth: 80,
    flexGrow: 0.4,
    type: 'withBoldText',
    tooltip: 'Date of birth',
  },
  {
    dataKey: 'Phone',
    label: 'PhoneNumber',
    headTitle: 'Phone',
    value: 3,
    minWidth: 140,
    flexGrow: 0.5,
    tooltip: 'Patient phone number',
  },
  {
    dataKey: 'Email',
    label: 'EmailAddress',
    headTitle: 'Email',
    value: 2,
    minWidth: 170,
    flexGrow: 1.4,
    tooltip: 'Patient email address',
  },
];
