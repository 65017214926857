const rememberLinks = ['/all-scans', '/patients', '/ceate-new-scan', '/settings', '/tasks', '/comments'];

export const rememberPreviousLink = ({ pathname, search }: { pathname: string; search: string }): void => {
  const isRemember = rememberLinks.some(link => pathname.includes(link));

  if (!isRemember) {
    return;
  }

  localStorage.setItem('nextPath', `${pathname}${search || ''}`);
};

export const resetNextLink = (): any => {
  localStorage.removeItem('nextPath');
};

export const getNextLink = (): any => {
  return localStorage.getItem('nextPath');
};
