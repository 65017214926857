import moment from 'moment';

export const transformDateToDayOfWeek = (date: string): string => {
  return moment.utc(date).format('dddd');
};

export const transformDateToLocal = (date: string | Date, reverse = false, divider = '/'): string => {
  const result = moment(date).format(`MM${divider}DD${divider}YYYY`);

  return reverse ? result.split(divider).reverse().join(divider) : result;
};

export const transformDate = (date: string | Date, reverse = false, divider = '/'): string => {
  const result = moment.utc(date).format(`MM${divider}DD${divider}YYYY`);

  return reverse ? result.split(divider).reverse().join(divider) : result;
};

export const transformToLocalDate = (date: string): string => moment(date).local().format('MM/DD/YYYY');

export const transformDateWithTime = (date: string): string => moment.utc(date).format('MM/DD/YYYY h:mm A');

export const transformToLocalDateWithTime = (date: string): string => moment.utc(date).local().format('MM/DD/YYYY h:mm A');

export const transformDateWithTimeWithoutUTC = (date: string): string => moment(date).format('MM/DD/YYYY h:mm A');

export const transformDateToTime = (date: string): string => moment.utc(date).format('h:mm A');

export const transformDateToYMD = (date: string, divider: string): string => moment.utc(date).format(`YYYY${divider}MM${divider}DD`);

export const transformDateToNumber = (date: string): number => moment.utc(date).valueOf();

export const transformNumberToBillingDate = (date: Date): string => moment.utc(date).format('MMM DD YYYY');

export const transformNumberToBillingDateNoYear = (date: Date): string => moment.utc(date).format('DD MMM');

/* Convert to local date format */
export const convertToLocalDate = (date: Date, format = 'YYYY-MM-DD HH:mm:ss'): string => moment.utc(date).local().format(format);

export const getUtcOffset = (): number => moment().utcOffset();
