import React from 'react';
import { Link } from 'react-router-dom';
import { LoginUrl } from '../../../../config/UrlsConfig';
import { ConfirmForgotPass } from '../../interfaces';

const ConfirmForgotPasswordFormComponent: React.FunctionComponent<ConfirmForgotPass> = props => {
  const { onSubmit, userEmail } = props;

  return (
    <div className='onboarder-wrap'>
      <div className='onboarder-title'>Can’t login?</div>
      <div className='onboarder-form forgot-form'>
        <div className='invalid-content'>
          <div className='successfully-icon'></div>
          <div className='invalid-title'>Link has been sent successfully</div>
          <div className='invalid-text text-left'>
            We sent a recovery link to you at <strong>{userEmail}</strong>
          </div>
          <div className='form-row-link text-center'>
            <Link to={LoginUrl}>Return to Login</Link>
            <a onClick={onSubmit}>Resend reset link</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmForgotPasswordFormComponent;
