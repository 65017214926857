import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'rsuite';
import TableComponent from '../../../../components/table-component';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { PageSpacingTable, TableAction } from '../../../../enum/TableActionEnum';
import PhysicianModal from './PhysicianModal';
import { managePhysicianColumns, DefaultPhysician } from './PhysiciansUtil';
import { Physician } from '../../../../models/Physician';
import { getPhysicians } from '../../../../api/physicians/PhysiciansAPI';
import ConfirmModalComponent from '../../../../components/modals/confirm-modal-component';
import { ConfirmModalTexts } from '../../PortalSettingsUtil';
import { addFullName } from '../../../../utils/GeneralUtil';
import useDynamicTableHeight from '../../../../utils/useDynamicTableHeight';
import LoaderComponent from '../../../../components/loader-component';
import { useError } from '../../../../utils/UseError';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { useSignal } from '../../../../utils/UseSignal';
import useDefaultColumnWidth from '../../../../utils/UseColumnWidth';

const SettingsPhysicianTabComponent: React.FunctionComponent = () => {
  const [infoModalTexts, setInfoModalTexts] = useState<Record<string, string>>({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showPhysicianModal, setShowPhysicianModal] = useState(false);
  const [selectedPhysician, setSelectedPhysician] = useState<Physician | null>(null);
  const [tableData, setTableData] = useState<Physician[]>([DefaultPhysician]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useError(null);
  const [removeMode, setRemoveMode] = useState(false);
  const { size } = useDynamicTableHeight(PageSpacingTable.Setting, 533);
  const signal = useSignal();
  const { panelRef, defaultWidth, calculateColumnWidth } = useDefaultColumnWidth(managePhysicianColumns.length, true);

  const getAllPhysicians = () => {
    setIsLoading(true);
    getPhysicians(signal)
      .then(result => {
        if (!result.length) {
          setTableData([]);
          calculateColumnWidth();
          return;
        }
        setTableData(addFullName(result));
      })
      .catch(e => {
        if (!signal.aborted) {
          setTableData([]);
          calculateColumnWidth();
          setErrorText(e);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getAllPhysicians();
  }, []);

  const onCloseConfirmModal = useCallback((value: boolean): void => {
    if (value) {
      setShowPhysicianModal(false);
    }
    setShowConfirmModal(false);
  }, []);

  const onCancel = useCallback(
    (isFormEmpty: boolean | null, isRemoveModeEnabled?: boolean): void => {
      if (isRemoveModeEnabled) {
        setShowPhysicianModal(false);
        return;
      }
      if (!selectedPhysician && !isFormEmpty) {
        setShowConfirmModal(true);
      } else {
        setShowPhysicianModal(false);
      }
    },
    [selectedPhysician],
  );

  const liftStateUp = (infoModalText: { title: string; content: string }): void => {
    getAllPhysicians();
    setShowPhysicianModal(false);
    setInfoModalTexts(infoModalText);
    setShowInfoModal(true);
    setSelectedPhysician(null);
  };

  const onTableAction = useCallback((actionName: TableAction, rowData: Physician | null): void => {
    setSelectedPhysician(rowData);
    if (actionName === TableAction.Remove) {
      setShowPhysicianModal(true);
      setRemoveMode(true);
    }
    if (actionName === TableAction.Edit) {
      setShowPhysicianModal(true);
    }
  }, []);

  const undoRemoveMode = useCallback((): void => setRemoveMode(false), []);

  return (
    <>
      {isLoading && <LoaderComponent className={'loader-block-center'} />}
      <div ref={panelRef}>
        <div className='btn-add'>
          <Button
            className='rs-btn-model btn-md'
            onClick={() => {
              setSelectedPhysician(null);
              setShowPhysicianModal(true);
            }}
          >
            + Add Ordering Physician
          </Button>
        </div>
        {!isLoading && (
          <div>
            <TableComponent
              columns={managePhysicianColumns}
              data={tableData}
              onAction={onTableAction}
              customHeight={size}
              defaultWidth={defaultWidth}
            />
          </div>
        )}
        {showInfoModal && <InfoModalComponent type='success' onClose={() => setShowInfoModal(false)} texts={infoModalTexts} />}
        {showPhysicianModal && (
          <PhysicianModal
            physician={selectedPhysician}
            onCancel={onCancel}
            removeMode={removeMode}
            undoRemoveMode={undoRemoveMode}
            liftStateUp={liftStateUp}
          />
        )}
        {showConfirmModal && <ConfirmModalComponent onClose={onCloseConfirmModal} info={ConfirmModalTexts.cancelAddingLocation} />}
        {errorText && <ErrorMessageComponent errorMessage={errorText} />}
      </div>
    </>
  );
};

export default SettingsPhysicianTabComponent;
