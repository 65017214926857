import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { EPrescriptionMulti, UploadPrescriptions } from './interfaces';

export function ePrescription(data: { [key: string]: any }): Promise<UploadPrescriptions> {
  return request({
    url: `${SERVER_URL}/scan-request/generate-pdf`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function ePrescriptionMulti(data: { [key: string]: any }): Promise<EPrescriptionMulti> {
  return request({
    url: `${SERVER_URL}/scan-request/generate-pdf-multi`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function disableEPrescription(data: { keys: any[] }): Promise<{ message: string }> {
  return request({
    url: `${SERVER_URL}/scan-request/disable-prescriptions`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function uploadEPrescriptionPdf(data: FormData): Promise<UploadPrescriptions[]> {
  return request({
    url: `${SERVER_URL}/scan-request/upload-pdf`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  });
}
