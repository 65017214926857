import React from 'react';
import Logo from '../../assets/logo_main.svg';

const PrivacyComponent: React.FunctionComponent = () => {
  return (
    <div className='page-text'>
      <header className='page-text-header'>
        <a href='/' className='page-header-logo'>
          <img src={Logo} alt='Medmo' width='125' height='30' />
        </a>
      </header>
      <div className='page-text-wrap'>
        <h1 className='page-text-h1'>Privacy Policy</h1>
        <p>
          BY USING THE MEDMO APP (REFERRED TO IN THIS PRIVACY POLICY AS &quot;MEDMO&quot;), YOU AGREE TO THE PRACTICES AND POLICIES OUTLINED
          IN THIS PRIVACY POLICY WHICH IS DEEMED INCORPORATED IN AND A PART OF THE TERMS OF USE AND YOU HEREBY CONSENT TO THE COLLECTION,
          USE, AND SHARING OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU CANNOT
          USE MEDMO.
        </p>
        <h2 className='page-text-h2'>What does the Privacy Policy cover?</h2>
        <p>
          As used in this Privacy Policy, terms have the same meaning as set forth in our Terms of Use unless otherwise expressly indicated.
          This policy does not apply to the practices of companies that Medmo does not own or control or to people that Medmo does not
          employ (i.e., such as our third party independent customer healthcare providers that license our technology or contract for items
          and services). Medmo, Inc. may be referred to in this Privacy Policy as &quot;we,&quot; &quot;us,&quot; &quot;our&quot; and
          &quot;ourselves.&quot; The terms &quot;website&quot;, &quot;services&quot;, and the like similarly mean the App. Any capitalized
          term used but not defined in this Privacy Policy shall have the same meaning as in our underlying Terms of Use.
        </p>
        <p>
          Unless otherwise stated, this Privacy Policy applies to personally identifiable information collected on the Medmo website and/or
          through a Medmo e-mail or contact system, including submission forms and telephone contact.
        </p>
        <p>
          This Privacy Policy applies to personally identifiable information collected on and after the date that this Privacy Policy is
          posted. However, for any Medmo users who registered on or used our website prior to this date, we will apply this Privacy Policy
          to personally identifiable information associated with that account for continued access to and use of Medmo, whether or not the
          user is specifically prompted to accept changes/additions.
        </p>
        <p>
          The term &quot;personally identifiable information&quot; means information that identifies a particular individual or enables a
          party to contact an individual, and includes the individual&apos;s name, postal address, e-mail address and telephone number as
          well as any &quot;Personal Information&quot; defined below. Personally identifiable information is sometimes referred to in this
          Privacy Policy as &quot;Personal Information&quot; whether or not the words are capitalized.
        </p>
        <h2 className='page-text-h2'>How do we collect the personally identifiable information of our users?</h2>
        <p>
          Medmo may collect certain personal identifiable information through a variety of means and sources about users who visit, contact,
          sign up/register for or otherwise use the Medmo website/App or who call us. For example, we collect such information in the
          following manners:
        </p>
        <h2 className='page-text-h2'>Information You Provide to Us</h2>
        <p>
          Much of the personally identifiable information we receive comes directly from users who are interested in signing up for Medmo or
          who are interested in obtaining information, services or products from us or our listed or independent customer third parties. We
          collect personal information when you register on, complete a profile on or contact us through our website/App or when you e-mail
          or call us. We may also collect other types of information (non-identifiable information), such as gender, age, personal interests
          and medical or health-related information that we may associate with personally identifiable information.
        </p>
        <p>
          When you access our website and other services, we may ask you to voluntarily provide us certain information that personally
          identifies (or could be used to personally identify) you (&quot;Personal Information&quot;). Personal Information includes (but is
          not limited to) the following categories of information: (1) contact data (such as your e-mail address and phone number); (2)
          demographic data (such as your gender, your date of birth and your zip code); (3) medical data (such as the doctors or other
          health care providers you click on, use or request, your reasons for services/care, your dates of service, your medical history,
          and other medical and health information you choose to share with us), and (4) other information that you voluntarily choose to
          provide to us, including without limitation social security number, unique identifiers such as passwords, and Personal Information
          in emails, contact submissions, letters that you send to us or calls. You might still be able to access and use some or all of
          Medmo if you choose not to provide us with any Personal Information, but the features and services of Medmo that require your
          Personal Information as then-applicable will not be accessible to you. We may also collect additional information, which may be
          Personal Information, as otherwise described to you at the point of collection or pursuant to your consent.
        </p>
        <h2 className='page-text-h2'>Information Collected Through Technology</h2>
        <p>
          We may collect information through technology to make our website more useful to you and to track user web/App activity and
          interests. Our website/App may use &quot;cookies,&quot; which are pieces of information a website/App sends to your computer or
          mobile device while you are viewing the website/App, for a variety of purposes. For example, when you return to our website/App
          after logging in, cookies may provide information to the site so that the site/App will remember who you are. We may also use
          information collected through web beacons, which are small pieces of data that are embedded in images on the pages of
          websites/App, to analyze the traffic patterns on our website/App, such as the frequency with which our users visit various parts
          or pages of our website/App. We may also use these technical methods in HTML e-mails that we send to our users to determine
          whether our users have opened those e-mails and/or clicked on links in those e-mails.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. The Help feature on most web
          browsers provides information on how to accept cookies, disable cookies or to notify you when receiving a new cookie.
        </p>
        <p>
          If you do not accept cookies, you may not be able to use some features of Medmo and we recommend that you leave them turned on.
        </p>
        <p>
          You may be able to engage in certain transactions without registering and logging on to our website/App. We refer to all of these
          transactions that you engage in without registering and logging on to our website as &quot;Non-Registered Transactions.&quot; When
          you engage in Non-Registered Transactions (in addition to those engaged in registered user transactions, if any), we may gather
          personally identifiable information, such as your computer&apos;s IP address and other traffic data. We may automatically collect
          the following traffic data: (1) IP address; (2) domain server; (3) type of device(s) used to access Medmo; (4) web browser(s) used
          to access Medmo; (5) referring webpage or other source through which you accessed Medmo; (6) geolocation information; and (7)
          other statistics and information associated with the interaction between your browser, mouse or mobile device and Medmo.
        </p>
        <p>When you place telephone calls to us, we may also log your telephone number and geographic location.</p>
        <p>
          Mobile device identifiers are data stored on your mobile device that may track mobile device and data and activities occurring on
          and through the mobile device, as well as the applications installed on it. Mobile device identifiers enable collection of
          Personal Information (such as media access control, address and location) and traffic data. As with other tracking tools, mobile
          device identifiers help Medmo learn more about our users&apos; demographics and internet behaviors.
        </p>
        <p>
          We may manage and place advertisements or sponsorships on our website/App on behalf of third parties referred to herein as
          &quot;network advertisers.&quot; We may also use cookies and other technology to collect information when you click on or move
          your cursor over banner advertisements. Please note that once you have clicked on any such advertisement and have left our
          website/App, our Privacy Policy no longer applies and you must read the privacy policy of the advertiser/sponsor to see how your
          personal information will be handled on its website or app. Advertisers or other third party companies will not have access to our
          own cookies or our other information-gathering technology.
        </p>
        <h2 className='page-text-h2'>Information That We Collect from Others</h2>
        <p>
          We may supplement personally identifiable information that we have collected directly from our users with other information that
          we obtain from third parties, such as third party independent customer healthcare providers and suppliers you seek services and
          purchases from, or otherwise.
        </p>
        <h2 className='page-text-h2'>HIPAA</h2>
        <p>
          Under a federal law called the HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT (HIPAA), SET FORTH IN 45 C.F.R. PARTS 160
          THROUGH 164, some of the demographic, health, payor and/or health-related information that Medmo collects as part of providing our
          administrative scheduling services with third party customer providers may be considered &quot;protected health information&quot;
          as defined under such law (&quot;PHI&quot;),{' '}
          <strong>
            but only to the extent the healthcare provider is one which submits claims to Medicare and/or Medicaid (or other federal health
            care benefit program).
          </strong>
        </p>
        <p>
          <strong>
            HOWEVER, WHEN MEDMO RECEIVES THE INFORMATION FROM YOU FOR OUR AND/OR YOUR GENERAL INFORMATIONAL PURPOSES OR IN ORDER TO SCHEDULE
            SERVICES WITH A THIRD PARTY INDEPENDENT CUSTOMER PROVIDER WHICH/WHO IS NOT COVERED BY HIPAA AS DESCRIBED ABOVE, THE INFORMATION
            IS NOT CONSIDERED PHI, AND, ACCORDINGLY, IS NOT SUBJECT TO ANY HIPAA PROTECTIONS OR ASSURANCES FROM US.
          </strong>
        </p>
        <h2 className='page-text-h2'>How is personally identifiable information used?</h2>
        <p>
          We will not rent, sell, disseminate or share personally identifiable information about you which can be used to identify or
          contact you with non-affiliated companies or third parties other than to facilitate any services and/or purchases through or via
          Medmo or in conjunction with Medmo and advertisements/sponsorships conducted via our website/App, as then-applicable, and to
          secure and store relevant information derived from those services, except when we have your permission and/or under the
          circumstances set forth in detail below; though we may do so in the event of the transfer of all or any portion of our business or
          assets to which this agreement relates and without any consideration to you.
        </p>
        <p>
          Personally identifiable information collected may be shared within and used by Medmo, and its affiliated companies, parents,
          subsidiaries, contractors and vendors for several purposes. For example, Medmo may use your personal information to send you
          promotional materials about goods and services, including special offers and promotions, either offered by Medmo or offered by
          third parties (sent by Medmo on behalf of these third parties). These promotional materials may be sent to you by the App, push
          notifications, postal mail, e-mail or other means.
        </p>
        <p>
          We may also use your personal information for &quot;Operational Uses,&quot; such as to complete transactions requested by you (for
          example, your purchase of or subscription to a product or service offered on or via our website or by a sponsor/third party
          provider), to send you administrative communications, and to render administrative scheduling services with third party
          independent customer providers.
        </p>
        <p>
          Medmo may take your personally identifiable information that is associated with other information about you (i.e. demographic
          information including age, sex, health condition, test sought etc.) and make it non-personally identifiable
          (&quot;non-identifiable&quot;) by removing all personally identifiable characteristics, such as your name, address, phone number
          and e-mail address, and using it for any purpose whatsoever.
        </p>
        <p>
          At times, Medmo may engage companies to help deliver products or services that you request from or via Medmo. Examples of these
          companies may eventually include call centers, packaging, printing, mailing or delivery companies. In these instances, there may
          be a need to share your information with these companies to fulfill your requests. Unless stated otherwise, these companies are
          allowed to gather, receive and use your information only for the purposes described in this paragraph, as required by law, or as
          you otherwise agree with them.
        </p>
        <p>
          Medmo may contract with a third party to help manage and optimize our Internet and mobile device business and communications. As
          described above, website tracking services help websites to measure the effectiveness of advertising and how visitors use a
          website through the use of web beacons and cookies provided by such company. The type of information we may collect includes, but
          is not limited to, the pages visited and links clicked by users. By supplementing our records, this information would help us to
          learn things such as what pages are most useful and attractive to our visitors and which of our tools and information most
          interest our visitors and users. Although the third party company would log the information coming from our website on our behalf,
          we would control how that data and information may and may not be used, though we cannot be responsible for misconduct, acts or
          omissions by such third parties and you hereby waive any claim against us for same, fully acknowledging the risk of using and/or
          registering for Medmo.
        </p>
        <p>
          Medmo will also disclose personal information when it believes in good faith that such disclosures (a) are required by law, such
          as to comply with a court order or subpoena, or (b) will help to enforce our Terms of Use including this Privacy Policy; protect
          your safety or security, including the safety and security of property that belongs to you; and/or protect the safety and security
          of our website/App, Medmo, and/or third parties, including the safety and security of property that belongs to Medmo or third
          parties.
        </p>
        <p>
          We may also use, publish and exploit any information that is not Personal Information or that is not otherwise HIPAA PHI as
          applicable (except to the extent the PHI is de-identified) for statistical, research, study, sale and other purposes, including
          for sale, without any consideration due to you. We may, however, share Personal Information with any third party companies that
          are commonly owned by us (even if distinct entities) or affiliated with us including for promotion of services to you (excluding
          information which counts as PHI, subject to appropriate HIPAA authorization as needed pursuant to such regulation).
        </p>
        <h2 className='page-text-h2'>Do Not Track Disclosure</h2>
        <p>
          Do Not Track (&quot;DNT&quot;) is a preference you can set in certain web browsers including Chrome, Firefox, Safari and Internet
          Explorer to inform websites that you do not want to be tracked. If a website operator receives a DNT signal, the website operator
          may refrain from collecting certain Personal Information about the browser&apos;s user. You can enable or disable Do Not Track by
          visiting the Preferences or Settings of your web browser although not all browsers offer a DNT option and there is currently no
          industry consensus as to what constitutes a DNT signal. Therefore, Medmo does not take any action to respond to DNT signals.
        </p>
        <h2 className='page-text-h2'>Minor&apos;s Privacy</h2>
        <p>
          Medmo does not service anyone under the age of 17 (&quot;Minors&quot;). We respect the privacy concerns of parents and guardians
          regarding Minors and their possible use of Medmo. Medmo does not allow Minors years 17 or younger to register or use this service,
          does not knowingly collect or solicit Personal Information from anyone under the age of 17, or knowingly allow such persons to
          register and/or use the service. If you are under 17 please do not attempt to register for Medmo and/or send any information about
          yourself to us including, but not limited to, your: name, address, telephone number, or email address. No one under the age of 17
          may provide Personal Information to us. In the event that we learn that we have collected Personal Information from someone under
          the age of 17 we will delete such information as quickly as possible. If you are a parent or guardian and you learn that your
          Minors have provided us with Personal Information, please contact us at info@medmo.com.
        </p>
        <h2 className='page-text-h2'>What choices do you have about the collection and use of your personally identifiable information?</h2>
        <p>As a threshold, you should not register for or use Medmo or schedule services via Medmo if you do not agree to these terms.</p>
        <p>
          With regard to personal information that you have provided under this Privacy Policy, there may be various opt-out choices
          available to you (where applicable). For example, you may be able to opt-out from e-mails (a) following any opt-out directions on
          the website/App or in the e-mail; and (b) by contacting us directly as described below.
        </p>
        <p>
          Please keep in mind that any opt-out choices you make will not apply in situations where (a) you either have made, simultaneously
          make or later make a specific request for information or action from Medmo or use particular Medmo functionality that necessitates
          use of the Personal Information, (b) Medmo uses your personal information for &quot;Operational Uses&quot; (as described above),
          (c) you either have engaged, simultaneously engage or later engage in Non-Registered Transactions as described above, or (d) Medmo
          collects your personally identifiable information under any of the provisions above as a result of your use of its website/App.
        </p>
        <p>
          Any opt-out choices may take time to be implemented and fully effective. Our systems may require time to update, and promotional
          mailings, by e-mail or otherwise, using personal information shared before your opt-out may already be in process. Thus, you might
          continue to receive promotional materials from Medmo based upon the personal information you have provided under this Privacy
          Policy for up to ninety (90) days.
        </p>
        <h2 className='page-text-h2'>What kinds of security measures do we take to safeguard your personally identifiable information?</h2>
        <p>
          The security of your information is of utmost importance to us. We limit access to personal information about you to employees or
          contractors (and third party independent customer healthcare service providers) who we believe reasonably need to come into
          contact with that information to provide products or services to you or in order to do their jobs and further as set forth above.
          Please be aware though that, despite our best efforts, no security measures are perfect or impenetrable.{' '}
          <strong>
            Once information including Personal Information is accessed by a viewer or recipient including third party customer healthcare
            providers, however, we have no control over the privacy or security of such information.
          </strong>
        </p>
        <h2 className='page-text-h2'>How can you ask questions or send comments to us about this Privacy Policy?</h2>
        <p>
          If you have any questions or wish to send us comments about this Privacy Policy, you can e-mail us directly at info@medmo.com.
        </p>
        <h2 className='page-text-h2'>How will you know if we amend this Privacy Policy?</h2>
        <p>
          We may amend this Privacy Policy at any time. If we make any material changes to this Privacy Policy, we will notify you by
          sending you an e-mail at the last e-mail address that you provided us and/or by prominently posting notice that the change was
          made or of the changes on the website/App covered by this Privacy Policy. Please note that, at all times, you are responsible for
          updating your personal information to provide us with your current e-mail address. In the event that the last e-mail address that
          you have provided us is not valid, or for any other reason is not capable of delivering to you the notice described above, our
          dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.
          In any event, changes to this Privacy Policy may affect our use of personal information that you provided us prior to our
          notification to you of the changes. If you do not wish to permit changes in our use of your information, you must notify us prior
          to the effective date of the changes that you wish to deactivate your account with us, through such mechanism we provide or make
          available to you. The effective date of this Privacy Policy is set forth at the bottom of this webpage. Your continued use of
          Medmo after the effective date (and after the effective date of any change) constitutes your acceptance of the amended Privacy
          Policy. We encourage you to periodically review this page for the latest information on our privacy practices. The amended Privacy
          Policy supersedes all previous versions. IF YOU DO NOT AGREE TO FUTURE CHANGES TO THIS PRIVACY POLICY, YOU MUST STOP USING THE
          WEBSITE/APP.
        </p>
        <h2 className='page-text-h2'>Information for California Residents</h2>
        <p>
          California residents have the right to request in writing from businesses with whom they have an established business relationship
          (1) a list of the categories of personal information, such as name, address, e-mail address and the type of services provided to
          that customer, that a business has disclosed to third parties (including affiliates that are separate legal entities) during the
          immediately preceding calendar year for the third parties&apos; direct marketing purposes, and (2) the names and addresses of all
          such third parties. You can request information by writing to us at: Medmo, Attn: Chief Executive Officer, Christopher Kelly, 245
          Fort Washington Avenue, Suite 6A, New York, NY, 10032. We will respond to such written requests within thirty (30) days following
          receipt at the mailing address above. We reserve the right not to respond to requests submitted other than to the address
          specified above or as otherwise exempted by law. Please note that we are required to respond to each customer only once per
          calendar year.
        </p>
        <h2 className='page-text-h2'>Third Party Software</h2>
        <p>
          We may incorporate third party software as part of Medmo, including without limitation open source third party software. Your use
          of such third party software is subject to any and all applicable additional terms and conditions governing such use provided by
          the third party software provider. Where applicable, additional notices relating to the third party software may be provided by
          us, which for example may contain differing privacy policy and disclaimer notices applicable to the third party software.
        </p>
        <h2 className='page-text-h2'>Special Note to International Users</h2>
        <p>
          This website is hosted in the United States and is intended for and directed to users in the United States. If you are a user
          accessing the website from the European Union, Asia, or any other region with laws or regulations governing personal data
          collection, use and/or disclosure that differ from United States laws or regulations, please be advised that through your
          continued use of this website, which is governed by U.S. law and regulation, this Privacy Policy, and our Terms of Use, you are
          transferring your personal information to the United States and you consent to that transfer.
        </p>
        <hr />
        <ul>
          <li>Effective date: January 10th, 2017</li>
          <li>Last updated date: January 10th, 2017</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyComponent;
