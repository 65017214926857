import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { transformDate } from '../../../utils/TimeUtil';
import { Form, SelectPicker } from 'rsuite';
import AddPatientModalComponent from '../../../components/add-patient-modal';
import TextField from '../../../components/text-field-component';
import { getPatientById } from '../../../api/patient/PatientAPI';
import InfoModalComponent from '../../../components/modals/info-modal';
import { SuccessAddModalText, SuccessEditModalText } from '../../patients/PatientsUtil';
import { initPatientInfo, patientModalContent } from '../NewScanUtil';
import { PatientInfoSchema } from '../Schemas';
import { ModalError, PatientInformationProps } from '../interfaces';
import { Patient } from '../../../models/Patient';
import ConfirmModalComponent from '../../../components/modals/confirm-modal-component';
import { FormError, SelectOption } from '../../../models/General';
import { getInfoByPatientName } from '../../../api/search/SearchBarAPI';
import { addFullName, getUrlParams } from '../../../utils/GeneralUtil';
import { useLocation } from 'react-router';
import { debounce } from 'debounce';
import { CommonContext, CommonContextType } from '../../../contexts/CommonContext';
import { FaPencilAlt } from 'react-icons/fa';
import { Plus } from '@rsuite/icons';
import { useSignal } from '../../../utils/UseSignal';
import { checkForEmptyFields } from '../../../utils/FormUtil';

const PatientInformation: React.FC<PatientInformationProps> = ({ formRef, setPatientData }: PatientInformationProps) => {
  const [showAddPatientModal, setShowAddPatientModal] = useState<boolean>(false);
  const [showEditPatientModal, setShowEditPatientModal] = useState<boolean>(false);
  const [showAddBtn, setShowAddBtn] = useState<boolean>(false);
  const [formData, setFormData] = useState<Patient>(initPatientInfo);
  const [formError, setFormError] = useState<FormError>({});
  const [editPatientModal, setEditPatientModal] = useState<boolean | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [agreeModal, setAgreeModal] = useState<boolean>(false);
  const [modalError, setModalError] = useState<ModalError>({
    title: '',
    content: '',
  });
  const [patients, setPatients] = useState<Patient[]>([]);
  const [isSearchingPatient, setIsSearchingPatient] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const { Languages, Genders } = useContext<CommonContextType>(CommonContext);
  const signal = useSignal();

  useEffect(() => {
    const paramsObj = getUrlParams(params?.toString());
    const { patientId } = paramsObj;

    if (patientId && !formData.Name) {
      getPatientInformationById(patientId);
    }
  }, []);

  const editPatientField = (value: string) => {
    if (!value) {
      setShowAddBtn(false);
      setPatients([]);
      setFormData(initPatientInfo);
      setPatientData(initPatientInfo);
      setIsSearchingPatient(false);
      return;
    } else if (value.length === 1) {
      setIsSearchingPatient(false);
      setPatients([]);
    }

    if (formData.PatientId) {
      setFormData({
        ...initPatientInfo,
        Name: value,
      });
      setPatientData(initPatientInfo);
    }

    if (value && value.length > 1) {
      findMatchesInPatients(value);
    }
    setShowAddBtn(true);
  };

  const debouncedEditPatientField = useCallback(debounce(editPatientField, 300), []);

  const findMatchesInPatients = (query: string) => {
    getInfoByPatientName(query, '', signal)
      .then(res => setPatients(addFullName(res, 'Name')))
      .catch(() => {
        if (!signal.aborted) {
          setModalError({ title: 'Error', content: 'Failed to search patients' });
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsSearchingPatient(false);
        }
      });
  };

  const editPatientInfo = (): void => {
    setShowAddPatientModal(false);
    setShowEditPatientModal(true);
    setEditPatientModal(true);
  };

  const addPatientInfo = (): void => {
    setFormData({
      ...formData,
      FirstName: formData.Name ? formData.Name.split(' ')[0] : '',
      LastName: formData.Name && formData.Name.includes(' ') ? formData.Name.split(' ')[1] : '',
    });
    setShowEditPatientModal(false);
    setShowAddPatientModal(true);
    setEditPatientModal(false);
  };

  const grabPatientData = (data: Patient) => {
    const { FirstName, LastName, MRN, DOB, Email, Phone, UserID, Gender, ID, State, ZipCode, PreferredLanguageID } = data;
    const selected = Genders.find(el => el.label === Gender);
    const language = Languages.find(el => el.value === PreferredLanguageID);

    const patientData = {
      FirstName,
      LastName,
      MRN,
      Phone,
      Email,
      GenderId: selected ? selected.value : null,
      Gender: selected ? selected.label : '',
      UserID: UserID,
      ID: ID,
      PatientId: ID,
      DOB: DOB && transformDate(DOB),
      State,
      PreferredLanguageID,
    };

    setFormError({ ...formError, Name: '' });

    setFormData({
      ...formData,
      ...patientData,
      Name: `${FirstName} ${LastName} (${transformDate(DOB as Date)})`,
      ZipCode,
    });

    setPatientData({
      ...formData,
      ...patientData,
      Name: `${FirstName} ${LastName}`,
      Language: language && language.label,
    });

    setPatients([]);
  };

  const getPatientInformationById = (id: number): void => {
    getPatientById(id, signal)
      .then(data => {
        grabPatientData(data);
      })
      .catch(() => {
        if (!signal.aborted) {
          setModalError({ title: 'Error', content: 'Failed to load patient information' });
        }
      });
  };

  const changeLanguage = (value: string, item: SelectOption): void => {
    setFormData({
      ...formData,
      PreferredLanguageID: +value,
    });

    setPatientData({ ...formData, PreferredLanguageID: +value, Language: item.label });
  };

  const onCloseAddPatient = (isUpdatedPatient: boolean, id?: number | null): void => {
    if (isUpdatedPatient) {
      const correctID = id ? id : formData.ID;
      setShowSuccessModal(true);

      if (correctID) {
        getPatientInformationById(correctID);
      }

      setShowAddPatientModal(false);
    }

    !editPatientModal && setFormData(prev => ({ ...prev, FirstName: '' }));
    setShowEditPatientModal(false);
    setShowAddBtn(false);
  };

  const onCloseSuccessModal = (): void => {
    setShowSuccessModal(false);
    setShowEditPatientModal(false);
    setEditPatientModal(null);
    setModalError({
      title: '',
      content: '',
    });
  };

  const onCloseModal = (res: boolean): void => {
    if (res) {
      setFormData(prev => ({ ...prev, FirstName: '' }));
      setShowAddPatientModal(false);
      setShowEditPatientModal(false);
    }

    setAgreeModal(false);
  };

  const checkFields = useCallback((formValue: Patient, fields: string[]): boolean => {
    const formStatusIsEmpty = checkForEmptyFields(formValue, fields);
    if (formStatusIsEmpty) {
      setShowAddPatientModal(false);
    } else {
      setAgreeModal(true);
    }
    return formStatusIsEmpty;
  }, []);

  const preparedData = useMemo(() => {
    return {
      ...formData,
      DOB: formData.DOB && transformDate(formData.DOB),
      Email: formData.Email,
    };
  }, [formData]);

  return (
    <>
      <Form
        className='form form-new-scan form-new-scan-patientInfo'
        ref={formRef}
        onChange={data => setFormData(data as Patient)}
        onCheck={error => setFormError(error)}
        formValue={formData}
        model={PatientInfoSchema}
      >
        <div className='form-container'>
          <h2>
            <b>Patient information</b>
          </h2>
          <div className='form-new-scan-block'>
            <Form.Group className='form-new-scan-group'>
              <TextField
                name='Name'
                value={formData.Name}
                onChange={(val: string) => {
                  if (val.length > 1) {
                    setIsSearchingPatient(true);
                  }
                  debouncedEditPatientField(val);
                }}
                error={formError.Name}
                label='Patient name*'
                autoComplete='off'
              />
              {isSearchingPatient ? (
                <div className='form-new-scan-loading'>... Loading</div>
              ) : (
                <ul className='form-new-scan-list'>
                  {patients.length
                    ? patients.map((el: Patient, i) => {
                        return (
                          <li key={i}>
                            <button
                              type='button'
                              onClick={() => {
                                grabPatientData(el);
                                setShowAddBtn(false);
                              }}
                            >
                              {el.Name} <span>{el.DOB ? transformDate(el.DOB) : ''}</span>
                            </button>
                          </li>
                        );
                      })
                    : null}
                  {formData.Name && showAddBtn && (
                    <li className='sticky'>
                      <button type='button' onClick={addPatientInfo}>
                        {`+ Add “${formData.Name}” to Medmo for Physicians`}
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </Form.Group>
            {formData.FirstName ? (
              <>
                <Form.Group className='form-new-scan-group width-m form-new-scan-lang'>
                  <TextField
                    accepter={SelectPicker}
                    name='PreferredLanguageID'
                    onSelect={changeLanguage}
                    value={formData.PreferredLanguageID}
                    data={Languages}
                    appearance='subtle'
                    placeholder=' '
                    label='Preferred language:'
                    searchable={false}
                  />
                </Form.Group>
                <a className='scan-link scan-link-edit' onClick={editPatientInfo}>
                  <FaPencilAlt />
                  Edit Patient information
                </a>
              </>
            ) : (
              <a className='scan-link' onClick={() => setShowAddPatientModal(true)}>
                <Plus />
                Add New Patient
              </a>
            )}
          </div>
        </div>
      </Form>

      {showSuccessModal && (
        <InfoModalComponent
          type={`success`}
          texts={editPatientModal ? SuccessEditModalText : SuccessAddModalText}
          onClose={onCloseSuccessModal}
        />
      )}

      {agreeModal && <ConfirmModalComponent info={patientModalContent} onClose={(res: boolean) => onCloseModal(res)} />}

      {(showEditPatientModal || showAddPatientModal) && (
        <AddPatientModalComponent
          patientData={preparedData}
          subtitle={
            showEditPatientModal ? 'Editing patient information from this scan request form will update throughout the portal.' : ''
          }
          isEdit={showEditPatientModal ? true : false}
          onClose={(isUpdatedPatient: boolean, id?: number | null) => onCloseAddPatient(isUpdatedPatient, id)}
          onCheckFields={checkFields}
        />
      )}

      {modalError.content && <InfoModalComponent type={`error`} texts={modalError} onClose={onCloseSuccessModal} />}
    </>
  );
};

export default PatientInformation;
