import React, { useMemo, useState } from 'react';
import { Whisper, Tooltip, Form } from 'rsuite';
import { TextFieldProps } from './interfaces';

const TextField: React.FunctionComponent<TextFieldProps> = props => {
  const {
    name,
    label,
    accepter,
    error,
    className,
    value,
    readOnly,
    autoComplete,
    disabled,
    labelClassName,
    tooltipText,
    placeholder,
    ...rest
  } = props;
  const [focus, setFocus] = useState<boolean>(false);

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }

    return null;
  }, [error]);

  return (
    <div className={`input-wrap ${className ? className : ''}`}>
      <Form.Group
        controlId={name}
        className={`${value || placeholder ? 'show' : ''} ${focus ? 'focus' : ''} ${errorMessage ? 'error' : ''} anim-placeholder ${
          props.componentClass
        }`}
      >
        <Form.ControlLabel className={labelClassName}>
          {label}
          {tooltipText && (
            <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={<Tooltip>{tooltipText}</Tooltip>}>
              <span className='tooltip'></span>
            </Whisper>
          )}
        </Form.ControlLabel>
        <Form.Control
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={name}
          readOnly={readOnly ? true : false}
          disabled={disabled ? true : false}
          accepter={accepter}
          autoComplete={autoComplete}
          placeholder={placeholder}
          errorMessage=''
          {...rest}
        />
        <Form.ErrorMessage show={!!errorMessage} placement={'bottomStart'}>
          {errorMessage}
        </Form.ErrorMessage>
      </Form.Group>
    </div>
  );
};

export default TextField;
