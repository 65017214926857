import { Schema } from 'rsuite';
import moment from 'moment';
import { notesTexts } from '../new-scan/NewScanUtil';
import { isAfter, isBefore } from 'date-fns';
import { FilterDataType } from './interfaces';

const { StringType, NumberType, DateType, ArrayType } = Schema.Types;

export const FilterSchema = Schema.Model({
  from: DateType().addRule((value: string | Date, data: FilterDataType): boolean => {
    if (data.to && isAfter(value as Date, data.to)) {
      return false;
    }

    return true;
  }, 'The start date must be earlier than the end date.'),
  to: DateType().addRule((value: string | Date, data: FilterDataType): boolean => {
    if (data.from && isBefore(value as Date, data.from)) {
      return false;
    }

    return true;
  }, 'The end date must be later than the start date.'),
});

export const PeerReviewTaskSchema = Schema.Model({
  newReviewerName: StringType().isRequired('This field is required'),
  newReviewerSpecialty: StringType(),
  newReviewResult: NumberType().isRequired('This field is required'),
  newAuthorizationID: StringType(),
  newTaskNotes: StringType(),
});

export const ReportReviewSchema = Schema.Model({
  reviewedBy: StringType().isRequired('This field is required'),
  reviewedOn: DateType()
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)')
    .addRule((value, data) => {
      if (moment(value).format('X') > moment().format('X') && data) {
        return false;
      }

      return true;
    }, 'You can not choose future date'),
  reviewNotes: StringType(),
  taskNotes: StringType(),
});

export const NotesSchema = Schema.Model({
  fileListNotes: ArrayType().addRule((value, data) => {
    if (!value.length && !data.clinicalNotes) {
      return false;
    }

    return true;
  }, notesTexts.error.requiredField),
  fileNoteName: StringType().isRequired('Please enter or upload clinical notes.'),
});

export const GeneralTaskSchema = Schema.Model({
  notes: StringType().isRequired('This field is required'),
});
