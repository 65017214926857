import { Schema } from 'rsuite';
import { Location } from '../../../../models/Location';

const { StringType, NumberType } = Schema.Types;

export const locationModel = Schema.Model({
  Name: StringType().isRequired('Location name is required'),
  Address1: StringType().isRequired('Location address is required'),
  Address2: StringType(),
  City: StringType().isRequired('City is required').containsLetter('Please add a valid city'),
  State: StringType().isRequired('State is required').containsLetterOnly('Please add a valid state'),
  ZipCode: NumberType('Please add a valid zip code')
    .pattern(/^\d{5}$/, 'The field should contain 5 digits')
    .isRequired('Zip code is required'),
  Phone: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number.')
    .isRequired('Phone is required'),
  IsActive: NumberType().pattern(/[0-1]/, 'Should be 1 or 0').isOneOf([0, 1], 'Should be one digit(1 or 0)'),
  DefaultFax: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid fax number.')
    .isRequired('Fax is required'),
  Extension: StringType(),
});

export const FormDefaultValue = {
  Name: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  ZipCode: '',
  Phone: '',
  DefaultFax: '',
  Extension: '',
  ID: 0,
};

export const manageLocationColumns = [
  {
    dataKey: 'Name',
    minWidth: 200,
    align: 'center',
    headTitle: 'Location Name',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'FullLocation',
    minWidth: 320,
    align: 'center',
    headTitle: 'Location',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'Phone',
    minWidth: 160,
    align: 'left',
    headTitle: 'Location Number',
    flexGrow: 0.7,
    sortable: true,
  },
];

export const prepareLocationsData = (data: Location[]): Location[] => {
  return data.map(location => ({
    ...location,
    FullLocation: `${location.Address1}, ${location.City}, ${location.State}`,
  }));
};

export const getEditLocationInfo = (data: Location): Location => {
  return {
    ...data,
    Address1: data.Address1 || '',
    Address2: data.Address2 || '',
    City: data.City || '',
    DefaultFax: data.DefaultFax || '',
    Name: data.Name || '',
    Phone: data.Phone || '',
    State: data.State || '',
    ZipCode: data.ZipCode || '',
    Extension: data.Extension || '',
  };
};

export const fieldsForCheck = ['Name', 'Address1', 'Address2', 'City', 'State', 'ZipCode', 'Phone', 'DefaultFax', 'Extension'];
