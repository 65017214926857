import { useEffect, useRef } from 'react';

export const useSignal = (): AbortSignal => {
  const abortController = useRef(new AbortController());

  useEffect(() => {
    return () => abortController.current.abort();
  }, []);

  return abortController.current.signal;
};
