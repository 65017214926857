import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { Button, Form, Toggle, Tooltip, Whisper } from 'rsuite';
import { getOrganizationInfo } from '../../../../api/organization/OrganizationAPI';
import OrganizationModal from './OrganizationModal';
import { UserContextType, UserContext } from '../../../../contexts/UserContext';
import { DefaultOrganizationValue, companyInfoEditedText, tokenTableColumns, generateAPiTokenData } from './OrganizationSettingsUtil';
import ErrorMessageComponent from '../../../../components/error-message-component';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { OrganizationInfo } from './Types';
import { debounce } from 'debounce';
import { getApiTokenListByPOID, sendReport } from '../../../../api/general/GeneralAPI';
import { Prompt } from 'react-router';
import LoaderComponent from '../../../../components/loader-component';
import { PO } from '../../../../models/User';
import { useSignal } from '../../../../utils/UseSignal';
import { ApiToken } from '../../../../api/general/interfaces';
import ApiTokenModal from './ApiTokenModal';
import TableComponent from '../../../../components/table-component';
import { RowDataType } from '../../../../components/table-component/interfaces';
import { ActionButton } from '../../../../components/action-button-component';
import GenerateApiTokenModal from './GenerateApiTokenModal';
import Webhook from '../../components/Webhook';

const SettingsGeneralOrgananizationTabComponent: React.FunctionComponent = () => {
  const [showOrganizationModal, setShowOrganizationModal] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<OrganizationInfo>(DefaultOrganizationValue);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [apiTokens, setApiTokens] = useState<ApiToken[] | []>([]);
  const [genToken, setGenToken] = useState<ApiToken | null>(null);
  const [isApiTokenModalShown, setIsApiTokenModalShown] = useState<boolean>(false);
  const [isRevokeMode, setIsRevokeMode] = useState<boolean>(false);
  const [isCreateReportDisable, setIsCreateReportDisable] = useState<boolean>(false);
  const [isGenerateTokenModalOpen, setIsGenerateTokenModalOpen] = useState<boolean>(false);
  const [togglerStatus, setTogglerStatus] = useState(1);
  const [isTogglerStatusLoading, setIsTogglerStatusLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useContext<UserContextType>(UserContext);
  const organizationData = useRef<Partial<PO>>({
    IsReportReviewAutoCreate: 1,
    Name: DefaultOrganizationValue.Name,
    Description: DefaultOrganizationValue.Description,
  });
  const signal = useSignal();

  const organizationID = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return currentUser.PO.ID;
    }

    return 0;
  }, [currentUser]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    return () => {
      if (localStorage.getItem('isAuth') && !isTogglerStatusLoading) {
        currentUser.expandUser({
          PO: {
            ...currentUser.PO,
            ...organizationData.current,
          },
        });
      }
      return undefined;
    };
  }, []);

  useEffect(() => {
    organizationData.current = {
      IsReportReviewAutoCreate: togglerStatus,
      Name: formValue.Name,
      Description: formValue.Description,
    };
  }, [togglerStatus, formValue.Name, formValue.Description]);

  const getInfo = () => {
    getOrganizationInfo(organizationID as number, signal)
      .then(res => {
        console.log('getOrganizationInfo', res);
        setFormValue({
          ...res,
          Description: res.Description || '',
        });
        if (res.Tokens) {
          const result = generateAPiTokenData(res.Tokens);
          setApiTokens(result);
        }
      })
      .catch(e => {
        if (!signal.aborted) {
          setFormValue(DefaultOrganizationValue);
          setErrorText(e.message);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
          currentUser.PO && setTogglerStatus(currentUser.PO?.IsReportReviewAutoCreate);
        }
      });
  };

  const getApiTokenList = () => {
    getApiTokenListByPOID(organizationID)
      .then(res => {
        const result = generateAPiTokenData(res);
        setApiTokens(result);
      })
      .catch(() => setApiTokens([]));
  };

  const onCloseApiTokenModal = useCallback(
    (isRevoke?: boolean): void => {
      isApiTokenModalShown && setIsApiTokenModalShown(false);
      isRevokeMode && setIsRevokeMode(false);
      if (isRevoke) {
        setGenToken(null);
        getApiTokenList();
      }
    },
    [isApiTokenModalShown, isRevokeMode],
  );

  const onCloseModal = useCallback((flag?: boolean | null): void => {
    if (flag === null) {
      return;
    }

    if (!flag) {
      setShowOrganizationModal(false);
      return;
    }

    if (flag) {
      setShowOrganizationModal(false);
      setShowInfoModal(true);
      getInfo();
      return;
    }
  }, []);

  const oncloseInfoModal = (): void => {
    setShowOrganizationModal(false);
    setShowInfoModal(false);
  };

  const handleChangeReport = (status: number) => {
    sendReport(status, organizationID)
      .then(() => setErrorText(null))
      .catch(() => {
        setTogglerStatus(prev => +!prev);
        setErrorText('Failed to switch notification');
      })
      .finally(() => setIsTogglerStatusLoading(false));
  };

  const debouncedChangeReport = useCallback(debounce(handleChangeReport, 200), []);

  const changeReport = (checked: boolean) => {
    const status = +checked;
    setIsTogglerStatusLoading(true);
    setTogglerStatus(status);

    debouncedChangeReport(status);
  };

  const handleRevokeClick = (data: ApiToken) => {
    setGenToken(data);
    setIsRevokeMode(true);
  };

  const onCloseGenerateTokenModal = (tokenData: ApiToken | null) => {
    if (tokenData) {
      setGenToken(tokenData);
      getApiTokenList();
      setIsCreateReportDisable(false);
      setIsGenerateTokenModalOpen(false);
      setIsApiTokenModalShown(true);
      return;
    }
    setIsCreateReportDisable(false);
    setIsGenerateTokenModalOpen(false);
  };

  const handleCreateAPiTokenSubmit = () => {
    setIsGenerateTokenModalOpen(true);
    if (isCreateReportDisable) {
      return;
    }
    setIsCreateReportDisable(true);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <div className='g-setting-panel org-setting-panel'>
      <div className='g-setting-panel_title'>Organization details</div>
      <button className='btn-edit big' onClick={() => setShowOrganizationModal(true)}>
        <span className='icon-edit'></span>
        Edit organization details
      </button>
      <div className='organization-opt row mb2'>
        <Form className='form-profile-info form-profile-info-org' formValue={formValue}>
          <Prompt when={isTogglerStatusLoading} message={() => 'Do you want to save changes?'} />
          <div className='textfiled-col'>
            <div className='textfiled-col_label'>Organization Name</div>
            <div className='textfiled-col_content'>{formValue.Name}</div>
          </div>
          <div className='textfiled-col textfiled-col_desk'>
            <div className='textfiled-col_label'>Organization Description (optional)</div>
            <div className='textfiled-col_content'>{formValue.Description}</div>
          </div>
        </Form>
        <div className='organization-opt_logo'>
          <div className='col'>
            <div className='g-setting-panel_title'>
              Organization Logo <span>(optional)</span>
            </div>
            <div className='desk'>
              {formValue.Logo?.url ? (
                <div className='organization-logo'>
                  <img src={formValue.Logo?.url} alt='organization logo' />
                </div>
              ) : (
                <p>No file uploaded. To add a logo, click on “Edit organization details”.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='g-setting-panel_title'>Organization settings</div>
      <div className='checkbox-setting-nav_item checkbox-row mb1'>
        <Toggle checked={!!togglerStatus} checkedChildren='On' unCheckedChildren='Off' onChange={value => changeReport(value)} />
        <div className='flex-container'>
          <div className='wrap'>
            <p>Generate New Report Task</p>
            <Whisper
              placement='top'
              controlId='control-id-hover'
              trigger='hover'
              speaker={
                <Tooltip>
                  Turn on this setting to allow the system to automatically create tasks for the scan owner to review when a new post-scan
                  report is uploaded
                </Tooltip>
              }
            >
              <span className='tooltip'></span>
            </Whisper>
          </div>
        </div>
      </div>
      {formValue?.IsAPIAvailable ? (
        <div className='org-setting-api'>
          <Webhook organizationID={organizationID} WebhookURL={formValue.WebookURL} />
        </div>
      ) : null}
      <div className='org-setting-api'>
        <div className='g-setting-panel_title'>Integration API tokens</div>
        {apiTokens.length ? (
          <div>
            <Button
              disabled={isCreateReportDisable}
              appearance='primary'
              className='org-setting-api_btn org-setting-api_mb'
              onClick={handleCreateAPiTokenSubmit}
            >
              Create API token
            </Button>
            <TableComponent
              columns={tokenTableColumns}
              data={apiTokens}
              isEdit={false}
              isRemove={false}
              widthColumnAction={100}
              actionComponentRender={(rowData: RowDataType) =>
                rowData.IsActive ? (
                  <ActionButton
                    clickAction={(actionID, rowData) => handleRevokeClick(rowData)}
                    rowData={rowData}
                    buttonText='Revoke'
                    actionKey={5}
                    isDisabled={false}
                  />
                ) : (
                  'N/A'
                )
              }
            />
          </div>
        ) : (
          <div>
            <div className='org-setting-api_title'>You don`t have any API tokens</div>
            <Button disabled={isCreateReportDisable} className='rs-btn-model btn-md' onClick={handleCreateAPiTokenSubmit}>
              Create API token
            </Button>
          </div>
        )}
      </div>

      {(isApiTokenModalShown || isRevokeMode) && (
        <ApiTokenModal organizationID={organizationID} isRevoke={isRevokeMode} data={genToken} onClose={onCloseApiTokenModal} />
      )}
      {isGenerateTokenModalOpen && <GenerateApiTokenModal onClose={onCloseGenerateTokenModal} organizationID={organizationID} />}
      {showOrganizationModal && <OrganizationModal dataForUpdate={formValue} onClose={onCloseModal} organizationID={organizationID} />}
      {errorText && <ErrorMessageComponent errorMessage={errorText} />}
      {showInfoModal && <InfoModalComponent type={'success'} texts={companyInfoEditedText} onClose={oncloseInfoModal} />}
    </div>
  );
};

export default SettingsGeneralOrgananizationTabComponent;
