import React, { useEffect, useState } from 'react';
import ScanCommentsTabComponent from '../../all-scans/scan/tabs/comments';
import { Scan } from '../../all-scans/interfaces';
import { getScanInfo } from '../../../api/all-scans/AllScansAPI';
import LoaderComponent from '../../../components/loader-component';
import ScanRequestsStatus from '../../../components/scan-requests-status';
import CloseIcon from '@rsuite/icons/Close';
import { Modal } from 'rsuite';
import { Link } from 'react-router-dom';
import { useSignal } from '../../../utils/UseSignal';

const CommentModal: React.FunctionComponent<{ scanId: number; onClose: (value: boolean) => void }> = ({ scanId, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState<Scan | null>(null);
  const signal = useSignal();

  const getScanDetails = (): void => {
    getScanInfo(scanId, signal)
      .then(response => setScanResult(response))
      .catch(() => {
        if (!signal.aborted) {
          setScanResult(null);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (scanId) {
      getScanDetails();
    }
  }, [scanId]);

  return (
    <Modal size='lg' className='comment-modal' open>
      <Modal.Header>
        <button className='btn-close' onClick={() => onClose(isUpdated)}>
          <CloseIcon className='btn-icon' />
        </button>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <LoaderComponent />}
        {scanResult && (
          <>
            <div className='model-comments_title'>
              <b>Scan</b>
              <p>{scanResult.FriendlyName}</p>
            </div>
            <div className='model-comments_info row'>
              <div className='item'>
                <div className='title'>SRID</div>
                <div>
                  <Link className='rs-btn-link' to={'/all-scans/' + scanId}>
                    {scanId}
                  </Link>
                </div>
              </div>
              <div className='item'>
                <div className='title'>Status</div>
                <div>
                  <ScanRequestsStatus
                    tooltipText={scanResult.ReasonForCancel as string}
                    placement='topEnd'
                    type={
                      scanResult.StageMFP === 'Cancelled'
                        ? 'Closed'
                        : scanResult.StageMFP === 'Report Uploaded'
                        ? 'Completed'
                        : scanResult.StageMFP
                    }
                  />
                </div>
              </div>
              <div className='item'>
                <div className='title'>Patient</div>
                <div>{scanResult.PatientName}</div>
              </div>
              <div className='item'>
                <div className='title'>St. Address</div>
                <div>{scanResult.LocationAddress}</div>
              </div>
              <div className='item'>
                <div className='title'>Physician</div>
                <div>{scanResult.OrderingPhysician}</div>
              </div>
              <div className='item'>
                <div className='title'>Owner</div>
                <div>{scanResult.OwnerName}</div>
              </div>
            </div>
            <ScanCommentsTabComponent scanId={scanId} setIsUpdated={(value: boolean) => setIsUpdated(value)} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CommentModal;
