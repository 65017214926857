import { RefObject, useEffect, useRef, useState } from 'react';

const useListVisible = (
  initialValue: number | null,
): { listRef: RefObject<HTMLUListElement>; fileOpenedKey: number | null; setFileOpenedKey: (a: number | null) => void } => {
  const listRef = useRef<HTMLUListElement>(null);
  const [fileOpenedKey, setFileOpenedKey] = useState<number | null>(initialValue);

  const handleClickOutside = (event: { target: any }) => {
    if (listRef.current && !listRef.current.contains(event.target) && event.target.dataset.list !== 'list') {
      setFileOpenedKey(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { listRef, fileOpenedKey, setFileOpenedKey };
};

export default useListVisible;
