import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Nav, Panel } from 'rsuite';
import noTastData from '../../assets/svg/no-tast-data.svg';
import { GetRecentActivity } from '../../api/request-scan/RequestScanAPI';
import { useHistory } from 'react-router-dom';
import SearchBarComponent from '../../components/search-bar-component';
import { searchBarColumns } from '../all-scans/AllScansUtil';
import { RowDataType } from '../../components/table-component/interfaces';
import { RecentActivityExpand, StatisticResponse, Tab } from './interfaces';
import CreatScanButton from '../../components/new-scan-button';
import TableComponent from '../../components/table-component';
import { columnsObject, options, texts, prepareDataForDonut, DashboardTabs, updateRecentActivities } from './DashboardUtil';
import { getUserTaskList, reassignTask, getStatistic } from '../../api/tasks/TasksApi';
import { Task } from '../../models/Task';
import LoaderComponent from '../../components/loader-component';
import TaskActionComponent from '../../components/action-component';
import { AllCommentsUrl, TasksUrl } from '../../config/UrlsConfig';
import { UserContext, UserContextType } from '../../contexts/UserContext';
import useDynamicTableHeight from '../../utils/useDynamicTableHeight';
import { PageSpacingTable } from '../../enum/TableActionEnum';
import { getOwners } from '../../api/owner/OwnerAPI';
import InfoModalComponent from '../../components/modals/info-modal';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Texts } from '../../components/modals/interfaces';
import { getInfoBySearchQuery } from '../../api/search/SearchBarAPI';
import { SelectOption } from '../../models/General';
import { Comment } from '../comments/interfaces';
import { getCommentsList } from '../../api/comments/CommentsAPI';
import { updateData } from '../../utils/GeneralUtil';
import { transformDate, transformToLocalDateWithTime } from '../../utils/TimeUtil';
import { useSignal } from '../../utils/UseSignal';

ChartJS.register(ArcElement, Tooltip);

const DashboardComponent: React.FunctionComponent = () => {
  const [recentActivityArr, setRecentActivityArr] = useState<RecentActivityExpand[] | null>(null);
  const [taskList, setTaskList] = useState<Task[] | null>(null);
  const [comments, setComments] = useState<Comment[] | null>(null);
  const [owners, setOwners] = useState<SelectOption[]>([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [statistic, setStatistic] = useState<StatisticResponse | null>(null);
  const [errorText, setErrorText] = useState<Texts | null>(null);
  const [isStatisticLoading, setIsStatisticLoading] = useState(true);
  const currentUser = useContext<UserContextType>(UserContext);
  const [activeNav, setActiveNav] = useState<string>(DashboardTabs.Tasks);
  const [isTabDataLoading, setIsTabDataLoading] = useState(true);
  const signal = useSignal();
  const history = useHistory();
  const { navRef, size } = useDynamicTableHeight(PageSpacingTable.IsDeshboardPage, 300);
  const allTabsDataIsNull = taskList === null && comments === null && recentActivityArr === null;

  useEffect(() => {
    getNeccessaryData();
  }, []);

  const getAllOwners = (): void => {
    const filledArray: SelectOption[] = [];

    getOwners(signal)
      .then(res => {
        Object.values(res).map(owner => {
          filledArray.push({
            label: owner.OwnerName,
            value: owner.ID,
          });
        });
        setOwners(filledArray);
      })
      .catch(() => {
        if (!signal.aborted) {
          setOwners([]);
          setErrorText(texts.ownersErrorText);
          setShowInfoModal(true);
        }
      });
  };

  const getAllRecentActivity = (): void => {
    setIsTabDataLoading(true);
    GetRecentActivity(signal)
      .then(res => {
        const data = updateRecentActivities(res);
        setRecentActivityArr(data);
      })
      .catch(() => {
        if (!signal.aborted) {
          setErrorText(texts.recentActivityErrorText);
          setShowInfoModal(true);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsTabDataLoading(false);
        }
      });
  };

  const getAllTasks = (): void => {
    setIsTabDataLoading(true);
    getUserTaskList(signal)
      .then(res => {
        const preparedData = updateData(res.Elements, { TaskCreatedDate: transformDate, DOB: transformDate });
        setTaskList(preparedData);
      })
      .catch(() => {
        if (!signal.aborted) {
          setErrorText(texts.tasksErrorText);
          setShowInfoModal(true);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsTabDataLoading(false);
        }
      });
  };

  const getAllStatistic = (): void => {
    getStatistic(signal)
      .then(res => setStatistic(res))
      .catch(() => {
        if (!signal.aborted) {
          setErrorText(texts.statisticsErrorText);
          setShowInfoModal(true);
        }
      })
      .catch(() => {
        if (signal.aborted) {
          return;
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsStatisticLoading(false);
        }
      });
  };

  const getComments = () => {
    setIsTabDataLoading(true);
    getCommentsList({ perPage: 20, field: 'Created', order: 'desc' }, signal)
      .then(res => {
        const preparedData = updateData(res.Elements, { DOB: transformDate, Created: transformToLocalDateWithTime });
        setComments(preparedData);
      })
      .catch(() => {
        if (!signal.aborted) {
          setErrorText(texts.statisticsErrorText);
          setShowInfoModal(true);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsTabDataLoading(false);
        }
      });
  };

  const getNeccessaryData = (): void => {
    getAllStatistic();
    getAllOwners();
    getAllTasks();
  };

  const handleRowClick = (rowData: RowDataType): void => {
    const { TestRequestID } = rowData;
    history.push(`/all-scans/${TestRequestID}`);
  };

  const tasksAmount = useMemo(() => {
    return statistic?.Counts?.Tasks || 0;
  }, [statistic?.Counts?.Tasks]);

  const otherTasksAmount = useMemo(() => {
    if (statistic) {
      return statistic?.Tasks?.PriorAuth + statistic?.Tasks?.Standard;
    }
    return 0;
  }, [statistic?.Tasks]);

  const onSelect = (taskID: number, ownerID: number) => {
    reassignTask(taskID, { newAssignedUserID: ownerID })
      .then(() => {
        getAllTasks();
        getAllStatistic();
      })
      .catch(() => {
        setErrorText(texts.reassignErrorText);
        setShowInfoModal(true);
      });
  };

  const poName = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return currentUser.PO.Name || 'No PO name provided';
    }
    return 'No PO name provided';
  }, [currentUser.PO]);

  const data = {
    labels: ['Requested', 'Scheduled', 'In-Progress', 'On-Hold'],
    datasets: [
      {
        data: statistic ? prepareDataForDonut(statistic.TestRequests) : [],
        backgroundColor: ['rgba(228, 235, 247, 1)', 'rgba(201, 214, 239, 1)', 'rgba(217, 230, 245, 1)', 'rgba(220, 225, 235, 1)'],
      },
    ],
  };

  const totalScanCount = useMemo(() => {
    const scanCount = statistic?.TestRequests
      ? statistic.TestRequests.RequestedTestRequestCount +
        statistic.TestRequests.ScheduledTestRequestCount +
        statistic.TestRequests.InProgressTestRequestCount +
        statistic.TestRequests.OnHoldTestRequestCount
      : 0;

    return scanCount;
  }, [statistic]);

  const tableData: Record<Tab, (Task | Comment | RecentActivityExpand)[]> = {
    Tasks: taskList || [],
    Comments: comments || [],
    Activity: recentActivityArr || [],
  };

  const handleSelectTab = (tab: string) => {
    if (tab === DashboardTabs.Tasks) {
      getAllTasks();
      setActiveNav(tab);
    } else if (tab === DashboardTabs.Comments) {
      getComments();
      setActiveNav(tab);
    } else {
      getAllRecentActivity();
      setActiveNav(tab);
    }
  };

  return (
    <div className='page-content dashboard'>
      <div className='nav-top_wrap' ref={navRef}>
        <div className='nav-top row'>
          <h1 className='h1'>Portal Dashboard - {poName}</h1>
        </div>
        <div className='dashboard-header justify-content-between'>
          <div className='input-search'>
            <SearchBarComponent
              handleRowClick={handleRowClick}
              placeholderText={'Search for scan requests by scan ID, patient name or DOB'}
              columns={searchBarColumns}
              searchMethod={getInfoBySearchQuery}
              searchText={'Begin typing a scan request’s ID, a patient’s name or DOB to search for results.'}
            />
          </div>
          <CreatScanButton className='rs-btn rs-btn-model btn-w-lg' buttonText='+ Request a New Scan' />
        </div>
        {!isStatisticLoading && (
          <h2>
            Welcome back <b>{currentUser.FirstName}</b>, there {tasksAmount === 1 ? 'is ' : 'are '}
            {tasksAmount} task{tasksAmount === 1 ? '' : 's'} that need your attention
          </h2>
        )}
      </div>
      <div className='row'>
        <div className='dashboard-content'>
          {isStatisticLoading ? (
            <LoaderComponent />
          ) : (
            <div className='dashboard-nav row'>
              <div className='dashboard-nav-row row'>
                <div className='dashboard-nav_col dashboard-nav_chart'>
                  <div className='wrap'>
                    <span className='scan-count'>{totalScanCount}</span>
                    <Doughnut data={data} options={options} />
                    <div className='title'>Active requests</div>
                  </div>
                </div>
                <div className='dashboard-nav_col'>
                  <div className='wrap'>
                    <div className='num'>{statistic?.Tasks?.ReportReview || 0}</div>
                    <div className='title'>Reports ready for review</div>
                  </div>
                </div>
                <div className='dashboard-nav_col'>
                  <div className='wrap'>
                    <div className='num'>{statistic?.Tasks?.PeerToPeerReview || 0}</div>
                    <div className='title'>Peer-to-Peer required</div>
                  </div>
                </div>
                <div className='dashboard-nav_col'>
                  <div className='wrap'>
                    <div className='num'>{statistic?.Tasks?.PendingClinicalNotes || 0}</div>
                    <div className='title'>Awaiting clinical notes</div>
                  </div>
                </div>
                <div className='dashboard-nav_col'>
                  <div className='wrap'>
                    <div className='num'>{otherTasksAmount}</div>
                    <div className='title'>Other tasks</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Panel className='dashboard-table'>
            {allTabsDataIsNull ? (
              <LoaderComponent />
            ) : (
              <>
                <div className='dashboard-table_nav'>
                  <Nav appearance='tabs' activeKey={activeNav} onSelect={handleSelectTab}>
                    <Nav.Item eventKey={String(DashboardTabs.Tasks)}>
                      My tasks <span className='num'>{tasksAmount}</span>
                    </Nav.Item>
                    <Nav.Item eventKey={String(DashboardTabs.Comments)}>
                      Latest comments <span className='num'>{statistic?.Counts?.Comments || 0}</span>
                    </Nav.Item>
                    <Nav.Item eventKey={String(DashboardTabs.Activity)}>
                      Recent activities <span className='num'>{statistic?.Counts?.Activities || 0}</span>
                    </Nav.Item>
                  </Nav>
                  <div className='dashboard-table_nav_links'>
                    {activeNav === DashboardTabs.Tasks && <a href={TasksUrl}>View all tasks</a>}
                    {activeNav === DashboardTabs.Comments && <a href={AllCommentsUrl}>View all comments</a>}
                    {/* {activeNav === DashboardTabs.Activity && <a href='#'>View all activity</a>} */}
                  </div>
                </div>
                {isTabDataLoading ? (
                  <LoaderComponent />
                ) : tableData[DashboardTabs[activeNav] as Tab] !== null && tableData[DashboardTabs[activeNav] as Tab].length ? (
                  <TableComponent
                    data={tableData[DashboardTabs[activeNav] as Tab]}
                    noLastCellIcon={true}
                    columns={columnsObject[DashboardTabs[activeNav] as Tab]}
                    actionComponentRender={
                      activeNav === DashboardTabs.Tasks ? (rowData: any) => <TaskActionComponent rowData={rowData} /> : undefined
                    }
                    isEdit={false}
                    isRemove={false}
                    onSelect={onSelect}
                    widthColumnAction={
                      window.innerWidth > 2100 && activeNav === DashboardTabs.Tasks
                        ? 130
                        : activeNav === DashboardTabs.Tasks
                        ? 90
                        : undefined
                    }
                    selectOptions={owners}
                    customHeight={size}
                  />
                ) : (
                  <div className='no-data'>
                    <img src={noTastData} alt={`No ${DashboardTabs[activeNav]} Data`} />
                    <p>There are no {DashboardTabs[activeNav].toLowerCase()} yet</p>
                  </div>
                )}
              </>
            )}
          </Panel>
        </div>
      </div>
      {showInfoModal && <InfoModalComponent onClose={() => setShowInfoModal(false)} type='error' texts={errorText || {}} />}
    </div>
  );
};

export default DashboardComponent;
