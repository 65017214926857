// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getServerUrl = (NODE_ENV, SERVER_URL) => {
  if (NODE_ENV) {
    switch (NODE_ENV) {
      case 'local':
        return 'http://localhost:3000/api/v1';
      case 'local-test':
        return 'https://mfp-be-dev.medmo.com/api/v1';
      default:
        return SERVER_URL;
    }
  }
  return SERVER_URL;
};
