import React from 'react';
import { Button, Modal } from 'rsuite';
import { FilePreviewer } from '../file-viewer';
import { ViewerProps } from './interfaces';

const FileViewer: React.FC<ViewerProps> = ({ onClose, onDownloadFile, isShow, file }) => {
  const modalClose = () => {
    onClose();
  };

  return (
    <Modal className='file-viewer' backdrop={false} open={isShow} onClose={modalClose}>
      <Modal.Body>
        <FilePreviewer file={file} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onDownloadFile(true)} className='rs-btn-model btn-md'>
          Download
        </Button>
        <Button onClick={modalClose} className='rs-btn-model btn-close btn-md'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileViewer;
