import { Schema } from 'rsuite';
import { NewUser } from './interfaces';

const { StringType } = Schema.Types;

export const formOnboarder = Schema.Model({
  firstName: StringType().isRequired('This field is required'),
  lastName: StringType().isRequired('This field is required'),
  password: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
  confirmPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, 'The passwords are not equal'),
});

export const newUserFormValue = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  invitationId: '',
  isTermsAgreed: false,
};

export const prepareUserData = (data: NewUser, email: string, invitationId: string): NewUser => {
  return {
    ...data,
    email,
    invitationId,
  };
};

export const findFalse = (...args: any[]): boolean => {
  return args.some(item => !item === true);
};

export const texts = {
  submitOnDisabledButton: 'You must agree to Medmo’s Privacy Policy, Terms of Use, and BAA to create an account.',
};
