export enum TableAction {
  Edit,
  Remove,
  RequestScanRequest,
  Resend,
}

export enum PageSpacingTable {
  IsSearchPagination = 156,
  IntoPatient = 586,
  IsDeshboardPage = 425,
  Setting = 330,
  SettingOrganization = 245,
}
