import React, { SyntheticEvent } from 'react';
import { Form, RadioGroup, Radio, Whisper, Tooltip } from 'rsuite';
import { RadioGroupProps } from './interfaces';

const RadioGroupField: React.FunctionComponent<RadioGroupProps> = props => {
  const { label, name, data, error, componentClass, value, disabled, tooltipText, onChange } = props;

  return (
    <div className={`radio-group-controller${error ? '--error' : ''} ${componentClass || ''}`}>
      <Form.ControlLabel className='title'>
        {label}
        {tooltipText && (
          <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={<Tooltip>{tooltipText}</Tooltip>}>
            <span className='tooltip'></span>
          </Whisper>
        )}
      </Form.ControlLabel>
      <Form.Control
        name={name}
        accepter={RadioGroup}
        className={error ? 'error' : ''}
        value={value}
        errorMessage=''
        onChange={onChange}
        onBlur={(e: SyntheticEvent) => e.stopPropagation()}
        onFocus={(e: SyntheticEvent) => e.stopPropagation()}
        inline
      >
        {data.map(({ value, label }) => (
          <Radio key={value} value={value} disabled={disabled}>
            {label}
          </Radio>
        ))}
      </Form.Control>
      {error && <div className='error-text-new-scan mb2'>{error}</div>}
    </div>
  );
};

export default RadioGroupField;
