import React, { useRef, useState } from 'react';
import TextField from '../../../../components/text-field-component';
import { Button, Form, Input } from 'rsuite';
import { Link } from 'react-router-dom';
import { FormInstance } from 'rsuite/Form';
import { formOnboarder } from './FormUtil';
import { LoginUrl } from '../../../../config/UrlsConfig';
import { ForgotPassword } from '../../interfaces';
import { FormError } from '../../../../models/General';

const forgotPasswordFormComponent: React.FunctionComponent<ForgotPassword> = props => {
  const { onSubmit } = props;
  const [formValue, setFormValue] = useState<{ email?: string }>({ email: '' });
  const [formError, setFormError] = useState<FormError>({});
  const formRef = useRef<FormInstance | null>(null);

  const handleSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }
    if (formValue.email) {
      onSubmit(formValue.email);
    }
  };

  return (
    <div className='onboarder-wrap'>
      <div className='onboarder-title'>Can’t login?</div>
      <div className='onboarder-form forgot-form'>
        <div className='title'>Please enter the email address associated with your account and we’ll send you a reset password link</div>
        <Form
          ref={formRef}
          onChange={formValue => setFormValue(formValue)}
          onCheck={formError => setFormError(formError)}
          formValue={formValue}
          model={formOnboarder}
        >
          <TextField name='email' value={formValue.email} accepter={Input} label='Email*' error={formError.email} />
          <div className='form-text'>
            <p>
              If you forgot your email address you should contact your organization administrator or{' '}
              <a href='#'>contact Medmos Customer Experience team</a>
              for more help.
            </p>
          </div>
          <Button classPrefix='btn-model' onClick={handleSubmit}>
            Send reset link
          </Button>
        </Form>
        <div className='form-row-link text-center'>
          <Link to={LoginUrl}>Return to Login</Link>
        </div>
      </div>
    </div>
  );
};

export default forgotPasswordFormComponent;
