import { Schema } from 'rsuite';
import { Organization } from './interfaces';

const { StringType } = Schema.Types;

export const DefaultOrganizationFormValue = {
  name: '',
  description: '',
  POID: 0,
  logoData: [],
};

export const formOnboarder = Schema.Model({
  name: StringType().isRequired('This field is required'),
  description: StringType(),
});

export const prepareOrganizationData = (logo: string, data: Organization, POID: number): Organization => {
  return {
    ...data,
    POID,
    logoData: logo,
  };
};
