export const texts = {
  error: {
    fileFormat: 'Upload only png | jpg | pdf | jpeg',
    fileSize: 'File size should not be greater than 20mb',
  },
};

export const wrongLogoTexts = {
  wrongFormat: 'Upload only png | jpg | jpeg',
  wrongSize: 'File size should not be greater than 20mb',
};
