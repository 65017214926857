import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, CheckPicker, DatePicker, Form } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { FormError } from '../../../models/General';
import TextField from '../../../components/text-field-component';
import { FilterSchema } from './FilterScansModalUtil';
import { Props, FilterDataType } from './interfaces';
import FilterModalComponent from '../../../components/modals/filter-modal';
import { CommonContext, CommonContextType } from '../../../contexts/CommonContext';
import { isString } from '../../../utils/GeneralUtil';

const FilterScansModal: React.FunctionComponent<Props> = ({ applyFilters, initData, filterData, onClose }) => {
  const { owners, locations, prescribers } = filterData;
  const [formValue, setFormValue] = useState<FilterDataType>(initData);
  const [formError, setFormError] = useState<FormError>({});
  const [indeterminate, setIndeterminate] = useState<Record<string, boolean>>({
    owner: false,
    location: false,
    prescriber: false,
    status: false,
  });
  const [checkAll, setCheckAll] = useState<Record<string, boolean>>({ owner: false, location: false, prescriber: false, status: false });
  const { ScanStatuses } = useContext<CommonContextType>(CommonContext);

  const withChanges = useRef<boolean>(false);
  const formRef = useRef<FormInstance>(null);

  const allValues: { owner: number[]; location: number[]; prescriber: number[]; status: number[] } = useMemo(
    () => ({
      owner: Object.values(owners).map(item => item.value),
      location: Object.values(locations).map(item => item.value) as number[],
      prescriber: Object.values(prescribers).map(item => item.value) as number[],
      status: Object.values(ScanStatuses).map(item => item.value) as number[],
    }),
    [],
  );

  useEffect(() => {
    setCheckAll({
      owner: initData.owner?.length === allValues.owner.length,
      location: initData.location?.length === allValues.location.length,
      prescriber: initData.prescriber?.length === allValues.prescriber.length,
      status: initData.status?.length === allValues.status.length,
    });

    setIndeterminate({
      owner: !!initData.owner?.length && initData.owner?.length !== allValues.owner.length,
      location: !!initData.location?.length && initData.location?.length !== allValues.location.length,
      prescriber: !!initData.prescriber?.length && initData.prescriber?.length !== allValues.prescriber.length,
      status: !!initData.status?.length && initData.status?.length !== allValues.status.length,
    });
  }, [initData]);

  useEffect(() => {
    if (formValue.from) {
      formRef.current?.checkForField('from');
    }
  }, [formValue.to]);

  useEffect(() => {
    if (formValue.to) {
      formRef.current?.checkForField('to');
    }
  }, [formValue.from]);

  useEffect(() => {
    if (formValue.orderFrom) {
      formRef.current?.checkForField('orderFrom');
    }
  }, [formValue.orderTo]);

  useEffect(() => {
    if (formValue.orderTo) {
      formRef.current?.checkForField('orderTo');
    }
  }, [formValue.orderFrom]);

  const handleChange = (formValue: FilterDataType) => {
    if (!withChanges.current) {
      withChanges.current = true;
    }

    setFormValue(formValue);
  };

  const handleSelect = (value: number[], key: 'owner' | 'location' | 'prescriber' | 'status') => {
    setFormValue({ ...formValue, [key]: value });
    setIndeterminate({ ...indeterminate, [key]: value.length > 0 && value.length < allValues[key].length });
    setCheckAll({ ...checkAll, [key]: value.length === allValues[key].length });
  };

  const handleCheckAll = (checked: boolean, key: 'owner' | 'location' | 'prescriber' | 'status') => {
    if (!withChanges.current) {
      withChanges.current = true;
    }

    const nextValue = checked ? allValues[key] : [];
    setFormValue({ ...formValue, [key]: nextValue });
    setIndeterminate({ ...indeterminate, [key]: false });
    setCheckAll({ ...checkAll, [key]: checked });
  };

  const modalControl = (val: boolean) => {
    if (!val) {
      onClose();
    } else {
      if (!formRef.current || !formRef.current.check()) {
        return;
      }

      applyFilters({
        prescriber: checkAll.prescriber ? undefined : formValue.prescriber,
        location: checkAll.location ? undefined : formValue.location,
        owner: checkAll.owner ? undefined : formValue.owner,
        status: checkAll.status ? undefined : formValue.status,
        from: formValue.from,
        to: formValue.to,
        orderFrom: formValue.orderFrom,
        orderTo: formValue.orderTo,
      });
    }
  };

  return (
    <FilterModalComponent isSubmitDisabled={!withChanges.current} modalControl={modalControl}>
      <Form
        ref={formRef}
        formValue={formValue}
        onCheck={formError => setFormError(formError)}
        onChange={handleChange}
        className='filter-model-body'
        model={FilterSchema}
        fluid
      >
        <div className='row row-wrap'>
          <Form.Group className='form-new-scan-group col-6'>
            <TextField
              accepter={CheckPicker}
              name='status'
              labelClassName='title'
              data={ScanStatuses}
              appearance='subtle'
              label='Statuses:'
              value={formValue.status}
              placeholder='Select'
              searchable={false}
              onChange={(value: number[]) => handleSelect(value, 'status')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.status}
                    checked={checkAll.status}
                    onChange={(value, checked) => handleCheckAll(checked, 'status')}
                  >
                    {!checkAll.owner ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group col-6'>
            <TextField
              accepter={CheckPicker}
              name='owner'
              labelClassName='title'
              data={owners}
              appearance='subtle'
              label='Owners:'
              value={formValue.owner}
              placeholder='Select'
              onChange={(value: number[]) => handleSelect(value, 'owner')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.owner}
                    checked={checkAll.owner}
                    onChange={(value, checked) => handleCheckAll(checked, 'owner')}
                  >
                    {!checkAll.owner ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
        </div>
        <div className='row row-wrap'>
          <Form.Group className='form-new-scan-group col-6'>
            <TextField
              accepter={CheckPicker}
              name='prescriber'
              labelClassName='title'
              data={prescribers}
              appearance='subtle'
              label='Physicians:'
              value={formValue.prescriber}
              placeholder='Select'
              onChange={(value: number[]) => handleSelect(value, 'prescriber')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.prescriber}
                    checked={checkAll.prescriber}
                    onChange={(value, checked) => handleCheckAll(checked, 'prescriber')}
                  >
                    {!checkAll.prescriber ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group col-6'>
            <TextField
              accepter={CheckPicker}
              name='location'
              labelClassName='title'
              data={locations}
              appearance='subtle'
              label='Locations:'
              value={formValue.location}
              placeholder='Select'
              onChange={(value: number[]) => handleSelect(value, 'location')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.location}
                    checked={checkAll.location}
                    onChange={(value, checked) => handleCheckAll(checked, 'location')}
                  >
                    {!checkAll.location ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
        </div>
        <div className='title'>
          Date Range <i>(date of appointment)</i>
        </div>
        <div className='row row-wrap'>
          <Form.Group className='form-new-scan-group col-6 comments-field-datePicker'>
            <TextField
              name='from'
              accepter={DatePicker}
              format='MM/dd/yyyy'
              placeholder='mm/dd/yyyy'
              label='Start Date:'
              labelClassName='title'
              className={isString(formError.from) ? 'error-border' : ''}
              placement='autoVerticalStart'
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group col-6 comments-field-datePicker'>
            <TextField
              name='to'
              accepter={DatePicker}
              format='MM/dd/yyyy'
              placeholder='mm/dd/yyyy'
              label='End Date:'
              labelClassName='title'
              className={isString(formError.to) ? 'error-border' : ''}
              placement='autoVerticalStart'
            />
          </Form.Group>
          <Form.ErrorMessage show={isString(formError.from) || isString(formError.to)} placement={'bottomStart'}>
            Start date must be before end date.
          </Form.ErrorMessage>
        </div>
        <div className='title'>
          Order Date Range <i>(date order received by medmo)</i>
        </div>
        <div className='row row-wrap'>
          <Form.Group className='form-new-scan-group col-6 comments-field-datePicker'>
            <TextField
              name='orderFrom'
              accepter={DatePicker}
              format='MM/dd/yyyy'
              placeholder='mm/dd/yyyy'
              label='Start Date:'
              labelClassName='title'
              className={isString(formError.orderFrom) ? 'error-border' : ''}
              placement='autoVerticalStart'
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group col-6 comments-field-datePicker'>
            <TextField
              name='orderTo'
              accepter={DatePicker}
              format='MM/dd/yyyy'
              placeholder='mm/dd/yyyy'
              label='End Date:'
              labelClassName='title'
              className={isString(formError.orderTo) ? 'error-border' : ''}
              placement='autoVerticalStart'
            />
          </Form.Group>
          <Form.ErrorMessage show={isString(formError.orderFrom) || isString(formError.orderTo)} placement={'bottomStart'}>
            Start date must be before end date.
          </Form.ErrorMessage>
        </div>
      </Form>
    </FilterModalComponent>
  );
};

export default FilterScansModal;
