import { Schema } from 'rsuite';

const { StringType } = Schema.Types;
export const formOnboarder = Schema.Model({
  password: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
  confirmPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, 'The passwords are not equal'),
});

export const NewPasswordFormValue = {
  password: '',
  cnfPassword: '',
};

export const ConfirmModalAccountText = {
  successAccount: {
    title: `Password Successfully Updated`,
    content: `Your password has been successfully updated!`,
    buttonText: 'Log In',
  },
};

export const ExpiredTokenTextText = 'Password reset token is invalid or has expired.';
