import * as React from 'react';
import classNames from 'classnames';
import MoreIcon from '@rsuite/icons/More';
import PaginationButton from './PaginationButton';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PageNextIcon from '@rsuite/icons/PageNext';
import PageTopIcon from '@rsuite/icons/PageTop';
import PageEndIcon from '@rsuite/icons/PageEnd';
import { PaginationProps, RenderItems } from './interfaces';
import { PaginationLocale } from './PaginationUtil';

class CustomPagination extends React.Component<PaginationProps> {
  /**
   * Note that `handledProps` are generated automatically during
   * build with `babel-plugin-transform-react-flow-handled-props`
   */
  static handledProps = [];

  renderPageButtons(): (JSX.Element | null)[] {
    const pageButtons = [];
    let startPage;
    let endPage;
    let hasHiddenPagesAfter;
    let hiddenPagesBefore = 0;

    const { maxButtons, activePage, pages, ellipsis, boundaryLinks } = this.props;

    if (maxButtons) {
      hiddenPagesBefore = activePage - Math.floor(maxButtons / 2);
      startPage = hiddenPagesBefore > 1 ? hiddenPagesBefore : 1;
      hasHiddenPagesAfter = startPage + maxButtons < pages - 1;

      if (!hasHiddenPagesAfter) {
        endPage = pages;
        startPage = activePage === endPage ? pages - maxButtons + 1 : startPage;
        if (startPage < 1) {
          startPage = 1;
        }
      } else {
        endPage = startPage + maxButtons - 1;
      }
    } else {
      startPage = 1;
      endPage = pages;
    }

    for (let pagenumber = startPage; pagenumber <= endPage; pagenumber += 1) {
      pageButtons.push(
        this.renderItem({
          key: pagenumber,
          eventKey: pagenumber,
          active: pagenumber === activePage,
          children: pagenumber,
        }),
      );
    }

    if (boundaryLinks && ellipsis && hiddenPagesBefore > 3) {
      pageButtons.unshift(
        this.renderItem({
          key: 'ellipsisFirst',
          disabled: true,
          children: <span aria-label='More'>{ellipsis === true ? <MoreIcon /> : ellipsis}</span>,
        }),
      );
    }

    if (boundaryLinks && startPage > 1) {
      if (hiddenPagesBefore <= 3) {
        for (let pagenumber = startPage - 1; pagenumber >= 1; pagenumber -= 1) {
          pageButtons.unshift(
            this.renderItem({
              key: pagenumber,
              eventKey: pagenumber,
              children: pagenumber,
            }),
          );
        }
      } else {
        pageButtons.unshift(
          this.renderItem({
            key: 1,
            eventKey: 1,
            children: 1,
          }),
        );
      }
    }

    if (maxButtons && hasHiddenPagesAfter && ellipsis) {
      pageButtons.push(
        this.renderItem({
          key: 'ellipsis',
          disabled: true,
          children: (
            <span aria-label='More' title={PaginationLocale.more}>
              {ellipsis === true ? <MoreIcon /> : ellipsis}
            </span>
          ),
        }),
      );

      if (boundaryLinks && endPage !== pages) {
        if (!hasHiddenPagesAfter) {
          for (let pagenumber = endPage; pagenumber <= pages; pagenumber += 1) {
            pageButtons.push(
              this.renderItem({
                key: pagenumber,
                eventKey: pagenumber,
                children: pagenumber,
              }),
            );
          }
        } else {
          pageButtons.push(
            this.renderItem({
              key: pages,
              eventKey: pages,
              disabled: false,
              children: pages,
            }),
          );
        }
      }
    }
    return pageButtons;
  }

  renderPrev(): JSX.Element | null {
    const { activePage, prev } = this.props;

    if (!prev) {
      return null;
    }

    return this.renderItem({
      key: 'prev',
      eventKey: activePage - 1,
      disabled: activePage === 1,
      children: (
        <span aria-label='Previous' title={PaginationLocale.prev}>
          {prev === true ? <PagePreviousIcon /> : prev}
        </span>
      ),
    });
  }
  renderNext(): JSX.Element | null {
    const { pages, activePage, next } = this.props;

    if (!next) {
      return null;
    }

    return this.renderItem({
      key: 'next',
      eventKey: activePage + 1,
      disabled: activePage >= pages,
      children: (
        <span aria-label='Next' title={PaginationLocale.next}>
          {next === true ? <PageNextIcon /> : next}
        </span>
      ),
    });
  }

  renderFirst(): JSX.Element | null {
    const { activePage, first } = this.props;

    if (!first) {
      return null;
    }

    return this.renderItem({
      key: 'first',
      eventKey: 1,
      disabled: activePage === 1,
      children: (
        <span aria-label='First' title={PaginationLocale.first}>
          {first === true ? <PageTopIcon /> : first}
        </span>
      ),
    });
  }

  renderLast(): JSX.Element | null {
    const { pages, activePage, last } = this.props;
    if (!last) {
      return null;
    }

    return this.renderItem({
      key: 'last',
      eventKey: pages,
      disabled: activePage >= pages,
      children: (
        <span aria-label='Last' title={PaginationLocale.last}>
          {last === true ? <PageEndIcon /> : last}
        </span>
      ),
    });
  }

  renderItem(props: RenderItems): JSX.Element | null {
    const { onSelect, disabled } = this.props;

    let disabledButton = props.disabled;

    if (typeof disabled === 'function') {
      disabledButton = disabled(props.eventKey);
    } else if (typeof disabled === 'boolean') {
      disabledButton = disabled;
    }

    return <PaginationButton {...props} disabled={disabledButton} onSelect={disabledButton ? undefined : onSelect} />;
  }

  render(): JSX.Element {
    const { className, hidden, size } = this.props;

    return (
      <ul className={classNames('rs-pagination', className, `rs-pagination-${size || 'lg'}`)} hidden={hidden}>
        {this.renderFirst()}
        {this.renderPrev()}
        {this.renderPageButtons()}
        {this.renderNext()}
        {this.renderLast()}
      </ul>
    );
  }
}

export default CustomPagination;
