import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export const model = Schema.Model({
  FirstName: StringType().isRequired('This field is required'),
  LastName: StringType().isRequired('This field is required'),
});

export const GenerateTokenSchema = Schema.Model({
  TokenName: StringType('Please enter identifier').isRequired('This field is required'),
});
export const TokenSchema = Schema.Model({
  Token: StringType('Please enter identifier').isRequired('This field is required'),
});
export const WebhookSchema = Schema.Model({
  webhookURL: StringType()
    .isURL('Please enter the correct URL address')
    .maxLength(4000, 'The field cannot be more than 4000 characters')
    .isRequired('This field is required'),
});
export const PasswordSchema = Schema.Model({
  password: StringType('Please enter 8-digit identifier')
    .isRequired('The password you entered is not correct, please try again')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
  newPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
  confirmPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character')
    .addRule((value, data) => {
      if (value !== data.newPassword) {
        return false;
      }
      return true;
    }, 'The passwords are not equal'),
});

export const DefaultGenerateApiTokenFormValue = {
  TokenName: '',
};
export const DefaultApiTokenFormValue = {
  Token: '',
};

export const DefaultFormValue = {
  Email: '',
  FirstName: '',
  LastName: '',
  Password: '',
};

export const PasswordDefaultValue = {
  password: '',
  newPassword: '',
  confirmPassword: '',
};

export const passwordUpdatedText = {
  title: 'Password Successfully Changed',
  content: 'Your password has been successfully changed!',
};

export const profileUpdatedText = {
  title: 'Profile Successfully Updated',
  content: 'Your profile information has been successfully updated!',
};

export const notificationUpdatedText = {
  title: 'Profile Successfully Updated',
  content: 'Your notification settings has been successfully updated!',
};

export const togglerError = {
  title: 'Error',
  content: 'Failed to switch notification',
};

export const ConfirmModalCancelText = {
  title: `Are you sure you want to leave?`,
  text: `Your notification settings will not be saved`,
  cancelBtnText: 'Cancel',
  submitBtnText: 'Yes, Leave',
};
