import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { Patient, PatientResponse } from '../../models/Patient';
import { convertToQueryString, prepareGetOptions } from '../../utils/GeneralUtil';
import { Patients } from './interfaces';
import { Scan } from '../../views/all-scans/interfaces';

export function getPatients({ perPage, page, field, order }: Patients, signal?: AbortSignal): Promise<PatientResponse> {
  const paginate = {
    currentPage: page || 1,
    perPage: perPage || 25,
    sortBy: field,
    order,
  };

  const options = prepareGetOptions(signal, `${SERVER_URL}/patients/patients-paginate?${convertToQueryString(paginate)}`);
  return request(options);
}

export function createPatient(data: Patient): Promise<{ ID: number }> {
  return request({
    url: `${SERVER_URL}/patients`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function editPatient(id: number, data: Patient): Promise<{ ID: number }> {
  return request({
    url: `${SERVER_URL}/patients/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function getPatientById(id: number, signal?: AbortSignal): Promise<Patient> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/patients/${id}`);
  return request(options);
}

export function removePatientById(id: number): Promise<{ ID: number }> {
  return request({
    url: `${SERVER_URL}/patients/${id}`,
    method: 'DELETE',
  });
}

export function getPatientScanHistory(id: number, signal?: AbortSignal): Promise<Scan[]> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/patients/scan-history/${id}`);
  return request(options);
}
