import React from 'react';
import MaskedInput from 'react-text-mask';
import { InputMaskFieldProps } from './interfaces';

const InputMask: React.FunctionComponent<InputMaskFieldProps> = ({ onChange, ...rest }) => {
  return (
    <MaskedInput
      {...rest}
      className='rs-input'
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
};

export default InputMask;
