import { debounce } from 'debounce';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Dropdown, Popover } from 'rsuite';
import { SelectOption } from '../../models/General';

const RenderSelect = React.forwardRef<
  HTMLDivElement,
  {
    rowId: number;
    selectOptions?: SelectOption[];
    onSelect?: (rowId: number, value: number) => void;
  }
>(({ rowId, onSelect, selectOptions, ...rest }, ref) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<SelectOption[]>(selectOptions || []);

  useEffect(() => {
    if (selectOptions) {
      setOptions(selectOptions);
    }
  }, [selectOptions]);

  const sortOptions = (value: string) => {
    setOptions(options.filter(o => o.label.toLowerCase().includes(value.toLowerCase())));
  };

  const debouncedSort = useCallback(debounce(sortOptions, 300), []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedSort(e.target.value);
  };

  const handleSelectAction = (e: SyntheticEvent, rowId: number, eventKey: number) => {
    e.preventDefault();
    e.stopPropagation();

    if (onSelect) {
      onSelect(rowId, eventKey);
    }
  };

  return (
    <Popover className='rs-picker-menu fade in placement-bottom-start rs-picker-select-menu' ref={ref} {...rest} full>
      <div className='rs-picker-search-bar'>
        <input
          className='rs-picker-search-bar-input'
          placeholder='Search'
          value={searchValue}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={handleChange}
        />
      </div>
      <Dropdown.Menu
        className='scroll-menu'
        onSelect={(eventKey: string | undefined, e: SyntheticEvent) => handleSelectAction(e, rowId, +(eventKey || ''))}
      >
        {options?.map(option => (
          <Dropdown.Item key={option.value} eventKey={option.value} as='p'>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Popover>
  );
});

RenderSelect.displayName = 'RenderSelect';

export default RenderSelect;
