import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { AllScansFilterData, CommentsFilterData, ScanOptionsData, TasksFilterData, CommonData } from './interfaces';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export function getSelectOptions<T = CommentsFilterData | AllScansFilterData | TasksFilterData | ScanOptionsData | CommonData>(
  params: string[],
  signal?: AbortSignal,
): Promise<T> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/dictionaries?dictionary=${params}`);
  return request(options);
}
