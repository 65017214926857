import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import FileViewer from '../doc-viewer-modal';
import { FilePreviewerThumbnail } from '.';
import { FileView } from './interfaces';
import { FileListPreviewerProps } from '../../views/tasks/interfaces';
import { RequestFiles } from '../../api/all-scans/interfaces';

const FileThumbnailsList: React.FunctionComponent<FileListPreviewerProps> = ({ filesList }) => {
  const [currentFile, setCurrentFile] = useState<FileView>({
    url: '',
    name: '',
  });
  const [isShow, setIsShow] = useState<boolean>(false);

  const previewFile = (file: RequestFiles) => {
    setCurrentFile({
      url: file.url as string,
      name: file?.originalName || file?.key,
    });
    setIsShow(true);
  };

  const onDownload = () => {
    saveAs(currentFile.url as string, currentFile.name);
  };

  if (!filesList || filesList.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className='content-data-pdf_content'>
        {filesList.map(file => {
          return (
            <div className='item' key={file.key}>
              <span className='preview' onClick={() => previewFile(file)}>
                <FilePreviewerThumbnail
                  file={{
                    url: file?.url as string,
                    name: file?.originalName || file?.key,
                  }}
                  style={{ width: '100%', height: '100%' }}
                />
              </span>
              <span onClick={() => previewFile(file)}>{file.fileTitle}</span>
            </div>
          );
        })}
      </div>
      <FileViewer isShow={isShow} onDownloadFile={onDownload} file={currentFile} onClose={() => setIsShow(false)} />
    </>
  );
};

export default FileThumbnailsList;
