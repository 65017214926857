import React, { ChangeEvent, useContext, useMemo, useRef, useState } from 'react';
import TextField from '../../../../components/text-field-component';
import { Button, Modal, Form } from 'rsuite';
import { model, profileUpdatedText } from './GeneralUtil';
import { FormInstance } from 'rsuite/Form';
import { FormError } from '../../../../models/General';
import { GeneralProps, UserInfo } from './interfaces';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { changeUserInfo } from '../../../../api/general/GeneralAPI';
import { UserContext, UserContextType } from '../../../../contexts/UserContext';
import { useError } from '../../../../utils/UseError';
import { useSignal } from '../../../../utils/UseSignal';

const GeneralEditModal: React.FunctionComponent<GeneralProps> = props => {
  const { onClose, general, setInfoModalText } = props;
  const [formValue, setFormValue] = useState<UserInfo>(general);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const currentUser = useContext<UserContextType>(UserContext);
  const formRef = useRef<FormInstance | null>(null);
  const signal = useSignal();

  const handleSubmit = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }
    setIsSubmitPressed(true);
    changeUserInfo(formValue)
      .then(() => {
        currentUser.updateUser();
        onClose(isSubmitPressed);
        setInfoModalText(profileUpdatedText);
      })
      .catch(err => {
        setErrorText(err, formValue);
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsSubmitPressed(false);
        }
      });
  };

  const handleChange = (value: UserInfo, event: ChangeEvent<HTMLInputElement>): void => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(value);
  };

  const isSubmitDisabled = useMemo(() => {
    return general.FirstName === formValue.FirstName && general.LastName === formValue.LastName;
  }, [general, formValue]);

  return (
    <Modal size={'sm'} className='edit-profile-modal' open onClose={() => onClose(isSubmitPressed)}>
      <Modal.Header>
        <Modal.Title>Edit Profile Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          ref={formRef}
          onChange={(formValue, event) => handleChange(formValue as UserInfo, event as ChangeEvent<HTMLInputElement>)}
          onCheck={formError => setFormError(formError)}
          formValue={formValue}
          model={model}
        >
          <TextField name='Email' value={formValue.Email} label='Email' disabled />
          <TextField error={formError.FirstName || dataErrors.FirstName} name='FirstName' label='First name' value={formValue.FirstName} />
          <TextField error={formError.LastName || dataErrors.LastName} name='LastName' label='Last name' value={formValue.LastName} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {errorText && <ErrorMessageComponent errorMessage={errorText} />}
        <div className='justify-content-end'>
          <Button classPrefix='btn-model btn-close btn-lg' disabled={isSubmitPressed} onClick={() => onClose(isSubmitPressed)}>
            Cancel
          </Button>
          <Button classPrefix='btn-model btn-lg' disabled={isSubmitPressed || isSubmitDisabled} onClick={handleSubmit}>
            Save &amp; Update Profile Information
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GeneralEditModal;
