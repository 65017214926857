export const settingsConfirmModal = {
  cancelBtnText: 'Cancel',
  submitBtnText: 'Yes, Remove',
};

export const actions = {
  isShowModal: true,
};

export const settingModal = {
  modalTitle: 'Patient Information',
  btnCloseText: 'NO, CONTINUE MAKING EDITS',
  btnNextText: 'YES, DISCARD UNSAVED EDITS',
  modalContent: 'Are you sure you’d like to discard the edits you’ve made to the patient’s information?',
};
