import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../../components/table-component';
import { Task } from '../../../../../models/Task';
import { tasksColumns } from './TasksUtil';
import { getTasksForScan, reassignTask } from '../../../../../api/tasks/TasksApi';
import LoaderComponent from '../../../../../components/loader-component';
import TaskActionComponent from '../../../../../components/action-component';
import { TasksResponse } from '../../../../../api/tasks/interfaces';
import { getOwners } from '../../../../../api/owner/OwnerAPI';
import { SelectOption } from '../../../../../models/General';
import { updateData } from '../../../../../utils/GeneralUtil';
import { transformDate } from '../../../../../utils/TimeUtil';
import { useSignal } from '../../../../../utils/UseSignal';

const ScanTasksTabComponent: React.FC<{ scanId: number }> = ({ scanId }) => {
  const [taskData, setTaskData] = useState<Task[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [owners, setOwners] = useState<SelectOption[]>([]);
  const signal = useSignal();

  const getAllScans = () => {
    setIsLoading(true);
    getTasksForScan(scanId, signal)
      .then((res: TasksResponse) => {
        const preparedData = updateData(res.Elements, { TaskCreatedDate: transformDate, DOB: transformDate });
        setTaskData(preparedData);
      })
      .catch(() => {
        if (!signal.aborted) {
          setTaskData([]);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const getAllOwners = () => {
    getOwners(signal)
      .then(res => {
        const filledArray: SelectOption[] = [];

        Object.values(res).map(el => {
          filledArray.push({
            label: el.OwnerName,
            value: el.ID,
          });
        });

        setOwners(filledArray);
      })
      .catch(() => {
        if (!signal.aborted) {
          setOwners([]);
        }
      });
  };

  useEffect(() => {
    getAllScans();
  }, [scanId]);

  useEffect(() => {
    getAllOwners();
  }, []);

  const onTableAction = (actionID: number, rowData: any): void => {
    console.log(actionID, rowData);
  };

  const onSelect = (taskID: number, ownerID: number) => {
    reassignTask(taskID, { newAssignedUserID: ownerID }).then(() => {
      if (!signal.aborted) {
        getAllScans();
      }
    });
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='page-content-wrap page-scan-tab'>
      <div className='page-scan-tab_title'>Tasks</div>
      <TableComponent
        data={taskData}
        columns={tasksColumns}
        onAction={onTableAction}
        actionComponentRender={(rowData: any) => <TaskActionComponent rowData={rowData} />}
        isEdit={false}
        isRemove={false}
        widthColumnAction={150}
        onSelect={onSelect}
        selectOptions={owners}
      />
    </div>
  );
};

export default ScanTasksTabComponent;
