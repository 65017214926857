import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export const model = Schema.Model({
  NoteText: StringType().isRequired('This field is required'),
});

export const FormDefaultValue = {
  NoteText: '',
  notify: 1,
};

export const EditFormDefaultValue = {
  NoteText: '',
};

export const isAdmin = (userType: string): string => (userType.toLowerCase().includes('admin') ? 'admin' : '');
