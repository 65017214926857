import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, CheckPicker, Form } from 'rsuite';
import Close from '@rsuite/icons/Close';
import TextField from '../../../components/text-field-component';
import { FilterSchema } from '../Schemas';
import { FilterTaskComponentProps, FilterDataType, ListKeys } from '../interfaces';
import FilterModalComponent from '../../../components/modals/filter-modal';
import { CommonContext, CommonContextType } from '../../../contexts/CommonContext';

const FilterTaskComponent: React.FunctionComponent<FilterTaskComponentProps> = ({ applyFilters, filterData, initData, onClose }) => {
  const { owners, locations, prescribers } = filterData;
  const [formValue, setFormValue] = useState<FilterDataType>(initData);
  const withChanges = useRef<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<Record<string, boolean>>({
    ownerIds: false,
    locationIds: false,
    prescriberIds: false,
  });
  const [checkAll, setCheckAll] = useState<Record<ListKeys, boolean>>({
    type: false,
    status: false,
    ownerIds: false,
    locationIds: false,
    prescriberIds: false,
  });
  const { TasksStatuses, TasksTypes } = useContext<CommonContextType>(CommonContext);

  const allValues: { type: number[]; status: number[]; ownerIds: number[]; locationIds: number[]; prescriberIds: number[] } = useMemo(
    () => ({
      type: Object.values(TasksTypes).map(item => item.value),
      status: Object.values(TasksStatuses).map(item => item.value),
      ownerIds: Object.values(owners).map(item => item.value),
      locationIds: Object.values(locations).map(item => item.value) as number[],
      prescriberIds: Object.values(prescribers).map(item => item.value) as number[],
    }),
    [TasksTypes, TasksStatuses],
  );

  useEffect(() => {
    setCheckAll({
      type: initData.type?.length === allValues.type.length,
      status: initData.status?.length === allValues.status.length,
      ownerIds: initData.ownerIds?.length === allValues.ownerIds.length,
      locationIds: initData.locationIds?.length === allValues.locationIds.length,
      prescriberIds: initData.prescriberIds?.length === allValues.prescriberIds.length,
    });
    setIndeterminate({
      type: !!initData.type?.length && initData.type?.length !== allValues.type.length,
      status: !!initData.status?.length && initData.status?.length !== allValues.status.length,
      ownerIds: !!initData.ownerIds?.length && initData.ownerIds?.length !== allValues.ownerIds.length,
      locationIds: !!initData.locationIds?.length && initData.locationIds?.length !== allValues.locationIds.length,
      prescriberIds: !!initData.prescriberIds?.length && initData.prescriberIds?.length !== allValues.prescriberIds.length,
    });
  }, [initData]);

  const handleChange = (formValue: FilterDataType) => {
    if (!withChanges.current) {
      withChanges.current = true;
    }

    setFormValue(formValue);
  };

  const handleSelect = (value: number[], key: ListKeys) => {
    setFormValue({ ...formValue, [key]: value });
    setIndeterminate({ ...indeterminate, [key]: value.length > 0 && value.length < allValues[key].length });
    setCheckAll({ ...checkAll, [key]: value.length === allValues[key].length });
  };

  const handleCheckAll = (checked: boolean, key: ListKeys) => {
    if (!withChanges.current) {
      withChanges.current = true;
    }

    const nextValue = checked ? allValues[key] : [];
    setFormValue({ ...formValue, [key]: nextValue });
    setIndeterminate({ ...indeterminate, [key]: false });
    setCheckAll({ ...checkAll, [key]: checked });
  };

  const modalControl = (val: boolean) => {
    if (!val) {
      onClose();
    } else {
      applyFilters({
        prescriberIds: checkAll.prescriberIds ? undefined : formValue.prescriberIds,
        locationIds: checkAll.locationIds ? undefined : formValue.locationIds,
        ownerIds: checkAll.ownerIds ? undefined : formValue.ownerIds,
        type: checkAll.type ? undefined : formValue.type,
        status: checkAll.status ? undefined : formValue.status,
      });
    }
  };

  return (
    <FilterModalComponent isSubmitDisabled={!withChanges.current} modalControl={modalControl}>
      <Form formValue={formValue} className='tasks-filter-body rs-form-inline' model={FilterSchema} onChange={handleChange}>
        <div className='row'>
          <Form.Group className='form-new-scan-group width-l'>
            <TextField
              accepter={CheckPicker}
              name='type'
              data={TasksTypes}
              appearance='subtle'
              label='Task Type:'
              value={formValue.type}
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'type')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.type}
                    checked={checkAll.ownerIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'type')}
                  >
                    {!checkAll.type ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group width-l'>
            <TextField
              accepter={CheckPicker}
              name='status'
              data={TasksStatuses}
              appearance='subtle'
              label='Task Status:'
              value={formValue.status}
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'status')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.status}
                    checked={checkAll.status}
                    onChange={(value, checked) => handleCheckAll(checked, 'status')}
                  >
                    {!checkAll.status ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
        </div>
        <div className='full-row'>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='ownerIds'
              data={owners}
              appearance='subtle'
              label='Owners:'
              value={formValue.ownerIds}
              labelClassName='tasks-filter-label'
              onChange={(value: number[]) => handleSelect(value, 'ownerIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.ownerIds}
                    checked={checkAll.ownerIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'ownerIds')}
                  >
                    {!checkAll.ownerIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='prescriberIds'
              data={prescribers}
              appearance='subtle'
              label='Physicians:'
              labelClassName='tasks-filter-label'
              value={formValue.prescriberIds}
              onChange={(value: number[]) => handleSelect(value, 'prescriberIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.prescriberIds}
                    checked={checkAll.prescriberIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'prescriberIds')}
                  >
                    {!checkAll.prescriberIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
          <Form.Group className='form-new-scan-group'>
            <TextField
              accepter={CheckPicker}
              name='locationIds'
              data={locations}
              appearance='subtle'
              label='Locations:'
              labelClassName='tasks-filter-label'
              value={formValue.locationIds}
              onChange={(value: number[]) => handleSelect(value, 'locationIds')}
              renderExtraFooter={() => (
                <div className='check-sticky-footer'>
                  <Checkbox
                    indeterminate={indeterminate.locationIds}
                    checked={checkAll.locationIds}
                    onChange={(value, checked) => handleCheckAll(checked, 'locationIds')}
                  >
                    {!checkAll.locationIds ? 'Check All' : 'Uncheck All'}
                  </Checkbox>
                </div>
              )}
            />
          </Form.Group>
        </div>
        <button className='btn-reset' onClick={() => applyFilters({})}>
          <Close />
          Clear all filters
        </button>
      </Form>
    </FilterModalComponent>
  );
};

export default FilterTaskComponent;
