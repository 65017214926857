import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationList } from './interfaces';
import { NavLinks } from './NavigationUtil';

const NavigationListWrapper: React.FunctionComponent<NavigationList> = ({ isAdmin }) => {
  return (
    <nav className='sidebar-nav'>
      <ul>
        {NavLinks.filter(l => (isAdmin ? l.title === 'Portal Settings' : l)).map(link => (
          <li key={link.url}>
            <NavLink to={link.url} activeClassName='active'>
              <span className={link.icoName}>&nbsp;</span>
              <span className='title'>{link.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationListWrapper;
