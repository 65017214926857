import React, { useState, useEffect, useMemo, useRef, ChangeEvent } from 'react';
import { Button, Modal, Form } from 'rsuite';
import { PasswordSchema, PasswordDefaultValue, passwordUpdatedText } from '../tabs/general/GeneralUtil';
import TextFieldPassword from '../../../components/text-field-password';
import { FormInstance } from 'rsuite/Form';
import ErrorMessageComponent from '../../../components/error-message-component';
import { Password } from '../tabs/general/interfaces';
import { PasswordModalProps } from './interfaces';
import { changeUserPassword } from '../../../api/users/UsersAPI';
import { useError } from '../../../utils/UseError';
import { isString } from '../../../utils/GeneralUtil';

const PasswordModal: React.FunctionComponent<PasswordModalProps> = props => {
  const { onClose, setInfoModalText } = props;
  const [formValue, setFormValue] = useState<Password>(PasswordDefaultValue);
  const [formError, setFormError] = useState<Password>({});
  const [errorText, setPasswordError, dataErrors, setDataErrors] = useError(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const formRef = useRef<FormInstance | null>(null);

  useEffect(() => {
    if (formRef.current && formValue.newPassword && formValue.confirmPassword) {
      formRef.current.checkForField('confirmPassword');
    }
  }, [formValue.newPassword, formValue.confirmPassword]);

  const handleSubmit = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }

    setIsSubmitPressed(true);

    changeUserPassword(formValue)
      .then(() => {
        onClose();
        setInfoModalText(passwordUpdatedText);
      })
      .catch(err => setPasswordError(err.message, formValue))
      .finally(() => setIsSubmitPressed(false));
  };

  const handleChange = (value: Password, event: ChangeEvent<HTMLInputElement>): void => {
    errorText && setPasswordError(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(value);
  };

  const handleClose = (): void => {
    onClose();
  };

  const isDisabled = useMemo(() => {
    return !formValue.password || !formValue.newPassword || !formValue.confirmPassword;
  }, [formValue]);

  const handleHide = () => {
    if (!isSubmitPressed) {
      onClose();
    }
  };

  return (
    <Modal size={'sm'} className='model-change-password' open onClose={handleHide}>
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          ref={formRef}
          onChange={(formValue, event) => handleChange(formValue as Password, event as ChangeEvent<HTMLInputElement>)}
          onCheck={formError => setFormError(formError)}
          formValue={formValue}
          model={PasswordSchema}
          className='form-edit-data'
        >
          <TextFieldPassword
            value={formValue.password}
            error={formError.password || dataErrors.password}
            name='password'
            label='Current password*'
          />
          <TextFieldPassword
            value={formValue.newPassword}
            error={formError.newPassword || dataErrors.newPassword}
            name='newPassword'
            label='New password*'
            rules='true'
          />
          <TextFieldPassword
            value={formValue.confirmPassword}
            error={(isString(formError.confirmPassword) ? formError.confirmPassword : undefined) || dataErrors.confirmPassword}
            name='confirmPassword'
            label='Confirm new password*'
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {errorText && <ErrorMessageComponent errorMessage={errorText} />}
        <div className='justify-content-end'>
          <Button classPrefix='btn-model btn-close' disabled={isSubmitPressed} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isDisabled || isSubmitPressed} classPrefix='btn-model' onClick={handleSubmit}>
            Change Password
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordModal;
