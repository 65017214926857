import { FileType } from 'rsuite/Uploader';
import { ExpandScanResult, Scan } from '../views/all-scans/interfaces';
import { texts as globalTexts } from '../utils/Texts';
import { FileTypeWithTitle } from '../views/new-scan/interfaces';
import { transformDate, transformDateToLocal } from './TimeUtil';
import { RequestMain } from '../api/Request';

export const DEFAULT_MIN_COLUMN_WIDTH = 90;
export const DEFAULT_MIN_COLUMN_TABLE_HEIGHT = 46;
export const MAX_COLUMN_TABLE_HEIGHT = 60;
export const MIN_WINDOW_WIDTH_4K = 2100;

export const isString = (val: any) => typeof val === 'string';

export const checkFileSize = (file: FileTypeWithTitle): boolean => {
  const sizeLimit = 20971520;

  if (file.blobFile) {
    return file.blobFile.size > sizeLimit;
  }

  return false;
};

export const getUrlParams = (search = ''): any => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split('=');
    return {
      ...acc,
      [key]: decodeURIComponent(val),
    };
  }, {});
};

export const convertToQueryString = (params: Record<string, string | number | string[] | number[] | undefined>): string => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if ((!value && value !== 0) || (Array.isArray(value) && value.length === 0)) {
      return acc;
    }

    const divider = acc === '' ? '' : '&';

    return acc + `${divider}${key}=${value}`;
  }, '');
};

export const fixedEncodeURIComponent = (str: string): string => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
};

interface knownFields {
  FirstName: string;
  LastName: string;
}

export function addFullName<DataType extends knownFields>(data: DataType[], key = 'FullName'): DataType[] {
  return data.map(person => ({
    ...person,
    [key]: `${person.FirstName} ${person.LastName}`,
  }));
}

const reasonFieldsMap: Record<string, (el: Scan) => string> = {
  Cancelled: (el: Scan) => el.ReasonForCancel || '',
  'On-Hold': (el: Scan) => el.OnHoldReason || '',
  Completed: (el: Scan) =>
    el.IsReportCollectionStatusFinal !== null
      ? `${el.ReportCollectionStatus}${el.ExternalReportCollectionStatusNotes ? ` - ${el.ExternalReportCollectionStatusNotes}` : ''}`
      : '',
};

export const updateScan = (scan: Scan): ExpandScanResult => {
  return {
    ...scan,
    DOB: scan.DOB && transformDate(scan.DOB),
    SubmittedOn: scan.SubmittedOn && transformDateToLocal(scan.SubmittedOn),
    OwnerName: scan.OwnerName || 'No Owner',
    AppointmentDate: scan.DateCompleted ? scan.DateCompleted : scan.WindowOpen || null,
    OrderDate: scan.OrderDate ? transformDateToLocal(scan.OrderDate) : '-',
    Reason: reasonFieldsMap[scan.StageMFP]?.(scan) || '',
    InfoColor:
      scan.IsReportCollectionStatusFinal === 0 && scan.StageMFP === 'Completed'
        ? 'green'
        : scan.IsReportCollectionStatusFinal === 1 && scan.StageMFP === 'Completed'
        ? 'red'
        : '',
    StageMFP: scan.StageMFP === 'Cancelled' ? 'Closed' : scan.StageMFP === 'Report Uploaded' ? 'Completed' : scan.StageMFP,
  };
};

export const updateScanList = (scanList: Scan[]): ExpandScanResult[] => {
  return scanList.map(el => updateScan(el));
};

export const checkFileExtension = (file: FileType): boolean => {
  return /(.png|.jpg|.jpeg)/i.test(file.name as string);
};

export const checkForWrongFile = (files: FileTypeWithTitle[]): false | string => {
  if (files && files.length) {
    const size = files.some(el => checkFileSize(el));

    if (size) {
      return globalTexts.error.fileSize;
    }

    const regExp = new RegExp(/(.png|.jpg|.jpeg|.pdf)/i);
    const types = files.some(el => !el.key && !regExp.test(el.name as string));

    if (types) {
      return globalTexts.error.fileFormat;
    }
  }

  return false;
};

export const checkForEmptyString = (value?: string | null): string => {
  if (!value || value === 'null') {
    return '';
  }

  return value;
};

export function updateData<Type>(data: Type[], fieldsForUpdate: Record<string, (val: any) => any>): Type[] {
  data.forEach(el => {
    Object.keys(fieldsForUpdate).forEach(field => (el[field as keyof Type] = fieldsForUpdate[field](el[field as keyof Type]) || 'pending'));
  });
  return data;
}

export const prepareGetOptions = (signal: AbortSignal | undefined, url: string): RequestMain => {
  let options: RequestMain = {
    method: 'GET',
    url,
  };

  if (signal) {
    options = { ...options, signal };
  }

  return options;
};
