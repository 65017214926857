import React from 'react';
import { SelectOption } from '../models/General';
import { InsurancePlans } from '../models/Insurance';
import { ScanType } from '../views/new-scan/interfaces';

export type CommonContextType = {
  TasksStatuses: SelectOption[];
  TasksTypes: SelectOption[];
  ScanStatuses: SelectOption[];
  Languages: SelectOption[];
  Genders: SelectOption[];
  InsurancePlans: InsurancePlans[];
  ScanTypes: ScanType[];
  updateCommonData: () => void;
};

export const CommonContext = React.createContext<CommonContextType>({
  TasksStatuses: [],
  TasksTypes: [],
  ScanStatuses: [],
  Languages: [],
  Genders: [],
  InsurancePlans: [],
  ScanTypes: [],
  updateCommonData: () => {
    return;
  },
});
