import { Schema } from 'rsuite';
import { addFullName } from '../../../../utils/GeneralUtil';
import { UserFromSettings as User } from '../../../../models/UserFromSettings';
import { UserFormData } from './interfaces';
import { UserTypes } from '../../../../models/User';

const { StringType } = Schema.Types;

export const roleList = [
  {
    label: 'Administrator',
    value: 'Administrator',
  },
  {
    label: 'Standard',
    value: 'Standard',
  },
];

export const model = Schema.Model({
  email: StringType().isEmail('Pease enter a valid email address').isRequired('Email adress is required'),
  role: StringType().isRequired('User role is required'),
});

export const UserFormValue: UserFormData = {
  email: '',
  role: '',
};

export const manageUserColumns = [
  {
    dataKey: 'FullName',
    minWidth: 230,
    align: 'center',
    headTitle: 'Name',
    flexGrow: 1,
    sortable: true,
    tooltip: 'First and Last name',
  },
  {
    dataKey: 'UserRole',
    minWidth: 200,
    align: 'center',
    headTitle: 'User Role',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'Email',
    minWidth: 300,
    align: 'left',
    headTitle: 'Email Address',
    type: 'email',
    flexGrow: 1.5,
    sortable: true,
  },
  {
    dataKey: 'Status',
    minWidth: 140,
    align: 'left',
    headTitle: 'Account Status',
    flexGrow: 0.5,
    sortable: true,
  },
];

export const prepareUsersData = (data: User[]): User[] => {
  return addFullName(data).map(user => ({ ...user, UserRole: UserTypes[user.UserTypeID] }));
};
