import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Panel, Button } from 'rsuite';
import TableComponent from '../../../components/table-component';
import AddPatientModalComponent from '../../../components/add-patient-modal';
import HeaderComponent from '../../../components/header-component';
import {
  patientInformationTopColumns,
  patientInformationTopColumns2,
  patientScanHistoryColumns,
  updatePatientService,
} from '../PatientsService';
import { useParams } from 'react-router';
import { getPatientById, getPatientScanHistory, removePatientById } from '../../../api/patient/PatientAPI';
import ConfirmModalComponent from '../../../components/modals/confirm-modal-component';
import { confirmModalText, SuccessRemoveModalText, SuccessEditModalText } from '../PatientsUtil';
import InfoModalComponent from '../../../components/modals/info-modal';
import { useHistory } from 'react-router-dom';
import { updateScanList } from '../../../utils/GeneralUtil';
import useDynamicTableHeight from '../../../utils/useDynamicTableHeight';
import { PageSpacingTable } from '../../../enum/TableActionEnum';
import LoaderComponent from '../../../components/loader-component';
import InvalidPageComponent from '../../invalid';
import { Scan } from '../../all-scans/interfaces';
import CreatScanButton from '../../../components/new-scan-button';
import { Patient } from '../../../models/Patient';
import { ContentInvalidPatient } from '../../invalid/invalidUtil';
import Trash from '@rsuite/icons/Trash';
import { GrEdit } from 'react-icons/gr';
import { FaRegEnvelope } from 'react-icons/fa';
import { useSignal } from '../../../utils/UseSignal';
import { PatientsUrl } from '../../../config/UrlsConfig';
import { CommonContext, CommonContextType } from '../../../contexts/CommonContext';
import { transformDate } from '../../../utils/TimeUtil';

const PatientInfoComponent: React.FunctionComponent = (): React.ReactElement | null => {
  const { patientId } = useParams<{ patientId: string }>();
  const history = useHistory();
  const [confirmInfoModalText, setConfirmInfoModalText] = useState({ title: confirmModalText.title, text: confirmModalText.text(null) });
  const [showEditPatientModal, setShowEditPatientModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<Patient | null>(null);
  const [removePatientModal, setRemovePatientModal] = useState<boolean>(false);
  const [patientScanHistoryData, setPatientScanHistoryData] = useState<Scan[]>([]);
  const [isPatientInfoEdited, setIsPatientInfoEdited] = useState<boolean>(false);
  const { size } = useDynamicTableHeight(PageSpacingTable.IntoPatient, 465);
  const [isLoading, setIsLoading] = useState(true);
  const [showInvalidPage, setShowInvalidPage] = useState(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const { Languages } = useContext<CommonContextType>(CommonContext);
  const signal = useSignal();

  const getPatientInfo = async (id: number) => {
    return new Promise((resolve, reject) => {
      getPatientById(id, signal)
        .then(data => {
          const result: Patient = updatePatientService(data, Languages);
          const name = result.Name || null;
          setConfirmInfoModalText({ title: confirmModalText.title, text: confirmModalText.text(name) });
          setPatientData(result);
          resolve(data);
        })
        .catch(error => {
          if (!signal.aborted) {
            if (error.status === 404) {
              setShowInvalidPage(true);
              return;
            }
            setPatientData(null);
            reject();
          }
        });
    });
  };

  const getPatientInformationById = (id: number) => {
    getPatientInfo(id)
      .then(() => {
        getPatientScanHistoryById(id);
      })
      .catch(() => null);
  };

  const getPatientScanHistoryById = (id: number) => {
    getPatientScanHistory(id, signal)
      .then((data: Scan[]) => {
        const updatedData = updateScanList(data);

        setPatientScanHistoryData(updatedData);
      })
      .catch(() => {
        if (!signal.aborted) {
          setPatientScanHistoryData([]);
          setPatientData(null);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!patientId || !Languages.length) {
      return;
    }

    getPatientInformationById(Number(patientId));
  }, [Languages]);

  const onCloseAddPatient = (isUpdatedPatient: boolean) => {
    setShowEditPatientModal(false);
    if (isUpdatedPatient) {
      setIsPatientInfoEdited(true);
      getPatientInformationById(Number(patientId));
    }
  };

  const onCloseConfirmRemovePatient = (isRemovePatient: boolean) => {
    setIsSubmitPressed(true);
    if (isRemovePatient) {
      removePatientById(Number(patientId))
        .then(() => setShowSuccessModal(true))
        .catch(() => setPatientData(null))
        .finally(() => {
          setRemovePatientModal(false);
          setIsSubmitPressed(false);
        });
    } else {
      setIsSubmitPressed(false);
      setRemovePatientModal(false);
    }
  };

  const onCloseSuccessEditModal = useCallback(() => {
    setIsPatientInfoEdited(false);
  }, []);

  const onCloseSuccessModal = () => {
    setShowSuccessModal(false);
    history.push('/patients');
  };

  const handleRowClick = (rowData: Record<string, string | number>) => {
    history.push(`/patients/${patientId}/scans/${rowData.ScanID}`);
  };

  if (showInvalidPage) {
    return <InvalidPageComponent id={patientId} content={ContentInvalidPatient} />;
  }

  if (!patientData) {
    return null;
  }

  const goBack = () => {
    history.push(PatientsUrl);
  };

  return (
    <div className='row-column flex-1'>
      {isLoading ? (
        <LoaderComponent className={'loader-block-center'} />
      ) : (
        <>
          <HeaderComponent goBack={goBack} title={`${patientData.Name} (DOB: ${transformDate(patientData?.DOB || '')})`} />
          <div className='patient-info-content'>
            <Panel className='patient-panel patient-panel-into' header='Patient Information'>
              <div className='row align-items-center'>
                <div className='patient-info-content-item'>
                  <div className='patient-table-info'>
                    {Object.keys(patientData).length ? (
                      <TableComponent
                        columns={patientInformationTopColumns}
                        data={[patientData]}
                        noLastCellIcon
                        isEdit={false}
                        isRemove={false}
                      />
                    ) : null}
                  </div>
                  <div className='patient-table-info'>
                    {Object.keys(patientData).length ? (
                      <TableComponent
                        columns={patientInformationTopColumns2}
                        data={[patientData]}
                        noLastCellIcon
                        isEdit={false}
                        isRemove={false}
                      />
                    ) : null}
                  </div>
                </div>
                <div className='patient-info-content-item patient-user-list'>
                  <div className='row'>
                    <div className='col'>
                      <div className='title'>
                        <FaRegEnvelope />
                        Email address
                      </div>
                      <Button appearance='link'>{patientData?.Email || 'No Email Provided'}</Button>
                    </div>
                    <div className='col'>
                      <div className='title'> Phone number</div>
                      <div className='text'>{patientData?.Phone}</div>
                    </div>
                  </div>
                </div>
                {!!patientData.IsActive && (
                  <button className='btn-edit big' onClick={() => setShowEditPatientModal(true)}>
                    <GrEdit />
                    Edit patient information
                  </button>
                )}
                {!!patientData.IsActive && (
                  <button className='btn-remove' onClick={() => setRemovePatientModal(true)}>
                    <Trash />
                    Remove patient from portal
                  </button>
                )}
              </div>
            </Panel>
            <Panel className='patient-panel' header='Patient Scan History'>
              <div className='btn-add'>
                <CreatScanButton
                  className='rs-btn rs-btn-model btn-w-lg'
                  buttonText='+ Request New Scan for Patient'
                  patientId={patientId}
                  disabled={patientData.IsActive === 0}
                />
              </div>
              <TableComponent
                columns={patientScanHistoryColumns}
                data={patientScanHistoryData}
                onRowClick={rowData => handleRowClick(rowData)}
                customHeight={size}
                isEdit={false}
                isRemove={false}
              />
            </Panel>
          </div>
        </>
      )}
      {showEditPatientModal && (
        <AddPatientModalComponent
          patientData={patientData}
          isEdit={true}
          onClose={(isUpdatedPatient: boolean) => onCloseAddPatient(isUpdatedPatient)}
        />
      )}
      {removePatientModal && (
        <ConfirmModalComponent
          info={confirmInfoModalText}
          onClose={(isDeletePatient: boolean) => onCloseConfirmRemovePatient(isDeletePatient)}
          disableButtons={isSubmitPressed}
        />
      )}
      {(showSuccessModal || isPatientInfoEdited) && (
        <InfoModalComponent
          type='success'
          texts={isPatientInfoEdited ? SuccessEditModalText : SuccessRemoveModalText}
          onClose={isPatientInfoEdited ? onCloseSuccessEditModal : onCloseSuccessModal}
        />
      )}
    </div>
  );
};

export default PatientInfoComponent;
