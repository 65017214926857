import React, { ReactNode } from 'react';
import { Modal } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';

type Props = {
  modalSize?: 'lg' | 'md' | 'sm' | 'xs';
  texts?: {
    title: string;
    cancelBtnText: string;
    submitBtnText: string;
  };
  isSubmitDisabled: boolean;
  className?: string;
  modalControl: (val: boolean) => void;
  children: ReactNode;
};

const FilterModalComponent: React.FC<Props> = ({ modalSize, texts, isSubmitDisabled, modalControl, children, className }) => {
  return (
    <Modal size={modalSize || 'md'} className={className || 'filter-modal'} open>
      <Modal.Header>
        <Modal.Title>{texts?.title || 'Filters'}</Modal.Title>
        <button className='btn-close' onClick={() => modalControl(false)}>
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <button className='btn-model btn-close btn-lg' onClick={() => modalControl(false)}>
          {texts?.cancelBtnText || 'Cancel'}
        </button>
        <button className='btn-model btn-lg' disabled={isSubmitDisabled} onClick={() => modalControl(true)}>
          {texts?.submitBtnText || 'Apply Filters'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModalComponent;
