import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';
import { Form } from 'rsuite';
import { Card } from '../interfaces';

const CARD_OPTIONS: { [key: string]: any } = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#1C243C',
      color: '#1C243C',
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        font: '400 16px/1.5 Poppins, sans-serif',
        color: 'rgba(#1C243C, .5)',
      },
    },
    invalid: {
      iconColor: '#FC3653',
      color: '#FC3653',
    },
  },
};

const CardField: React.FunctionComponent<Card> = props => (
  <div className='rs-form-group form-new-scan-group width-l'>
    <div className={`rs-form-control-label title ${props.error && 'error-text-label'}`}>Card details*</div>
    <div className='form-new-scan-group-fields' style={{ borderColor: `${props.error ? 'rgba(194, 0, 43, 1)' : '#DDDFE4'}` }}>
      <div className='form-new-scan-group-fields_stripe'>
        <CardElement options={CARD_OPTIONS} onChange={props.onChange} />
      </div>
    </div>
    <Form.ErrorMessage className='error-text' show={typeof props.error === 'string'} placement={'bottomStart'}>
      {props.error}
    </Form.ErrorMessage>
  </div>
);

export default CardField;
