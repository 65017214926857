import React, { useCallback, useEffect, useState } from 'react';
import logo from '../../assets/logo_main_white.svg';
import ForgotPasswordFormComponent from './components/form';
import InvalidForgotPasswordFormComponent from './components/error';
import CreateNewPasswordFormComponent from './components/new-password';
import LoaderComponent from '../../components/loader-component';
import { resetPassword } from '../../api/login/LoginAPI';
import ConfirmForgotPasswordFormComponent from './components/confirm';
import { useHistory } from 'react-router';
import { useError } from '../../utils/UseError';

const ForgotPasswordComponent: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [showReset, setShowReset] = useState<boolean>(false);
  const [errorText, setErrorText] = useError(null);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes('reset')) {
      setShowReset(true);
    }
  }, []);

  const handleSubmitResetPass = useCallback((email: string): void => {
    setIsLoading(true);

    resetPassword(email)
      .then(() => {
        setUserEmail(email);
        setShowConfirm(true);
      })
      .catch(err => {
        setErrorText(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleSubmitConfirm = useCallback(() => {
    setShowConfirm(false);
  }, []);

  return (
    <div className='onboarder-main'>
      <header className='onboarder-head'>
        <div className='container'>
          <div className='onboarder-head_logo'>
            <img src={logo} alt='' />
          </div>
        </div>
      </header>
      <main className='main'>
        <div className='container'>
          {showConfirm && <ConfirmForgotPasswordFormComponent onSubmit={handleSubmitConfirm} userEmail={userEmail} />}
          {isLoading ? <LoaderComponent /> : null}
          {errorText ? (
            <InvalidForgotPasswordFormComponent errorText={errorText} />
          ) : (
            <>
              {!showConfirm && !showReset && <ForgotPasswordFormComponent onSubmit={handleSubmitResetPass} />}
              {showReset && <CreateNewPasswordFormComponent />}
            </>
          )}
        </div>
      </main>
      <footer className='onboarder-footer'>
        <div className='container'>
          {/*<ul>*/}
          {/*  <li>*/}
          {/*    <a href='#'>Terms & Privacy</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href='#'>Need Help? Contact Medmo Customer Support</a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
      </footer>
    </div>
  );
};

export default ForgotPasswordComponent;
