import React, { useState, useMemo, useRef, ChangeEvent } from 'react';
import { Button, Form } from 'rsuite';
import { WebhookSchema } from '../tabs/general/GeneralUtil';
import { FormInstance } from 'rsuite/Form';
import { WebhookProps, WebhookType } from './interfaces';
import { useError } from '../../../utils/UseError';
import TextField from '../../../components/text-field-component';
import { addWebhookOrganization } from '../../../api/organization/OrganizationAPI';

const noWebhookText = 'No registered URL';
const Webhook: React.FunctionComponent<WebhookProps> = ({ organizationID, WebhookURL = '' }) => {
  const [newWebhookUrl, setNewWebhookUrl] = useState<string | undefined>(WebhookURL || noWebhookText);
  const [formValue, setFormValue] = useState<WebhookType>({ webhookURL: WebhookURL });
  const [formError, setFormError] = useState<WebhookType>();
  const [errorText, setWebhookError, dataErrors, setDataErrors] = useError(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const formRef = useRef<FormInstance | null>(null);

  const handleSubmit = () => {
    if (formRef.current && !formRef.current.check() && !organizationID) {
      return;
    }

    setIsSubmitPressed(true);
    addWebhookOrganization(organizationID, formValue.webhookURL || '')
      .then(() => {
        setNewWebhookUrl(formValue.webhookURL);
      })
      .catch(err => setWebhookError(err.message, formValue))
      .finally(() => setIsSubmitPressed(false));
  };

  const handleChange = (value: WebhookType, event: ChangeEvent<HTMLInputElement>): void => {
    errorText && setWebhookError(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(value);
  };

  const isDisabled = useMemo(() => {
    return !formValue.webhookURL;
  }, [formValue]);

  return (
    <div className='webhook-url-form'>
      <div className='g-setting-panel_title'>Webhook URL</div>
      <div className='row row-input mb2'>
        <Form
          ref={formRef}
          onChange={(formValue, event) => handleChange(formValue as WebhookType, event as ChangeEvent<HTMLInputElement>)}
          onCheck={formError => setFormError(formError)}
          formValue={formValue}
          model={WebhookSchema}
          className='form-edit-data pt0'
        >
          <span className='textfiled-col_label mb0'>Webhook URL:</span>
          <TextField value={formValue.webhookURL} error={formError?.webhookURL || dataErrors.webhookURL} name='webhookURL' />
          <Button disabled={isDisabled || isSubmitPressed} classPrefix='btn-model' onClick={handleSubmit}>
            {newWebhookUrl === noWebhookText ? 'Add' : 'Update'}
          </Button>
        </Form>
      </div>
      <div className='row'>
        <span className='textfiled-col_label mb0'>Current Webhook URL:</span>
        <span className='url-content'>{newWebhookUrl}</span>
      </div>
    </div>
  );
};

export default Webhook;
